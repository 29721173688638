import {bodyShaper} from '../../syncable-commons-utils/utils/VuexHelper';
import Campaign from '../orm/models/Campaign';
import DateHelper from '../../syncable-commons-utils/utils/DateHelper';
import {CAMPAIGN_TYPE} from '../../syncable-commons-consts/enums';
import User from '../orm/models/User';
import Vue from 'vue';
import CustomAuthor from '../orm/models/CustomAuthor';
import {LINK_NAME, ROUTER_TO} from '../consts/links';
import GoogleAnalyticsHelper from '../../syncable-commons-utils/utils/GoogleAnalyticsHelper';
import {DEFAULT_TARGET_AMOUNT_FOR_CAMPAIGN} from '../consts/consts';
import Associate from '../orm/models/Associate';

export const getIsInCampaignCreatePage = currentRouteName => [
    LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.CAMPAIGN.CREATE.CAMPAIGN_TYPE,
    LINK_NAME.PORTFOLIO.PORTFOLIO_ID.CAMPAIGNS.CREATE.CAMPAIGN_TYPE,
    LINK_NAME.CAMPAIGN.CAMPAIGN_ID.CREATE_SUPPORT_FUNDING.CAMPAIGN_TYPE,
].includes(currentRouteName);
const getDefaultTitleForSupportFunding = (parentCampaignTitle, associateName) => `私は${parentCampaignTitle}に賛同して寄付集めをしています。是非寄付にご協力をお願いします。寄付金は${associateName}に直接寄付されます。`;

const initializeState = () => ({
    input: {
        id: null,
        associateId: null,
        portfolioId: null,
        type: null,
        targetAmount: null,
        targetDonors: null,
        startedAt: null,
        finishedAt: null,
        eventMonth: null,
        eventDay: null,
        twitterId: '',
        title: '',
        summary: '',
        story: '',
        mainVisual: {
            file: '',
        },
        isCreatorInfoPublic: false,
        promotionCode: '',
        customAuthor: {},
    },
    loading: false,
    cached: false,
    discountHasBeenApplied: false,
    parentCampaign: undefined,
});

export const state = initializeState;

export const mutations = {
    setInput(state, val = {}) {
        state.input = {...state.input, ...val};
    },
    setDiscountHasBeenApplied(state, val) {
        state.discountHasBeenApplied = val;
    },
    // eslint-disable-next-line no-unused-vars
    resetState(state) {
        state = initializeState();
    },
    startCreateInput(state, {associate, portfolioId, type, targetAmount, targetDonors, birthday}) {
        state.input.associateId = portfolioId ? null : associate.id; // いずれかのみ
        state.input.portfolioId = associate ? null : portfolioId; // いずれかのみ
        state.input.type = type;
        state.input.targetAmount = targetAmount;
        state.input.targetDonors = targetDonors;
        state.input.title = state.parentCampaign
            ? getDefaultTitleForSupportFunding(state.parentCampaign.title, associate.name)
            : '';
        if (!state.parentCampaign && birthday) {
            state.input.eventMonth = Number(DateHelper.format(birthday, 'M'));
            state.input.eventDay = Number(DateHelper.format(birthday, 'D'));
        }
        if (state.parentCampaign) {
            state.input.startedAt = DateHelper.format(new Date(), 'YYYY-MM-DD');
            state.input.finishedAt = DateHelper.format(state.parentCampaign.finished_at, 'YYYY-MM-DD');
        }
    },
    /**
     * startedAtとfinishedAtのデフォルト値をeventから設定する
     * @param state
     * @param eventMonth
     * @param eventDay
     */
    setDefaultDateByEvent(state, {eventMonth, eventDay}) {
        if (!eventMonth || !eventDay) {
            return;
        }
        // eventDateをYYYY-MM-DDで計算
        const eventDateThisYear = DateHelper.format(
            `${DateHelper.currentYear()}-${eventMonth}-${eventDay}`,
            'YYYY-MM-DD',
            'YYYY-M-D',
        );
        const today = DateHelper.format(new Date(), 'YYYY-MM-DD');

        let nextEventDateWithBuffer = DateHelper.add(eventDateThisYear, 1, 'week');
        if (DateHelper.isAfter(today, eventDateThisYear)) {
            // eventDateThisYearを１週間以上過ぎていたらすでに過ぎていたら次回と判断
            nextEventDateWithBuffer = DateHelper.add(nextEventDateWithBuffer, 1, 'year');
        }

        // startedAtを自動で3週間前とする
        let startedAt = DateHelper.add(nextEventDateWithBuffer, -3, 'week');
        if (DateHelper.isAfter(today, startedAt)) {
            // すでに過ぎていたら今日
            startedAt = today;
        }

        // 1ヶ月
        const finishedAt = DateHelper.add(startedAt, 1, 'month');

        state.input.startedAt = DateHelper.format(startedAt, 'YYYY-MM-DD');
        state.input.finishedAt = DateHelper.format(finishedAt, 'YYYY-MM-DD');
    },

    apiStart(state) {
        state.loading = true;
    },
    apiFinish(state) {
        state.loading = false;
    },

    draftCreateSuccess(state, {id}) {
        state.input.id = id;
    },

    fetchForEditSuccess(state, data = {}) {
        state.input.id = data.id;
        state.input.associateId = data.associate_id;
        state.input.portfolioId = data.portfolio_id;
        state.input.type = data.type;
        state.input.targetAmount = data.target_amount;
        state.input.targetDonors = data.target_donors;
        state.input.startedAt = data.started_at;
        state.input.finishedAt = data.finished_at;
        state.input.eventMonth = data.event_month ? Number(data.event_month) : null;
        state.input.eventDay = data.event_month ? Number(data.event_day) : null;
        state.input.twitterId = data.twitter_id;
        state.input.title = data.title;
        state.input.summary = data.summary;
        state.input.story = data.story;
        state.input.mainVisual = data.main_visual;
        state.input.isCreatorInfoPublic = data.is_creator_info_public;
        state.discountHasBeenApplied = !!data.discount;
    },
    setParentCampaign(state, val) {
        state.parentCampaign = val;
    },
    clearParentCampaign(state) {
        state.parentCampaign = initializeState().parentCampaign;
    },
};

export const actions = {
    updateInput({commit}, val) {
        commit('setInput', val);
    },
    async startCreateInput({commit, state}, {associateId, portfolioId, type, isSupportFunding}) {
        if ((associateId && portfolioId) || (!associateId && !portfolioId)) {
            throw new Error('Either of associate_id or portfolio_id is required.');
        }

        if (!isSupportFunding) {
            commit('clearParentCampaign');
        }

        commit('resetState');
        const birthday = type !== CAMPAIGN_TYPE.BIRTHDAY ? null : await this.$db().model(User).getters('me')(this).private_profile.birthday;
        commit('startCreateInput', {
            associate: associateId ? Associate.find(associateId) : null,
            portfolioId: portfolioId,
            type: type,
            targetAmount: DEFAULT_TARGET_AMOUNT_FOR_CAMPAIGN,
            targetDonors: 0,
            birthday: birthday,
        });
        if (state.input.eventMonth && state.input.eventDay) {
            commit('setDefaultDateByEvent', {
                eventMonth: state.input.eventMonth,
                eventDay: state.input.eventDay,
            });
        }
    },
    async draftCreate({state, commit}) {
        commit('apiStart');
        const idField = state.input.associateId ? 'associate_id' : 'portfolio_id';
        const idValue = state.input.associateId || state.input.portfolioId;
        const requestData = {
            [idField]: idValue,
            title: state.input.title,
            type: state.input.type,
            target_amount: state.input.targetAmount,
            target_donors: state.input.targetDonors,
            started_at: state.input.startedAt,
            finished_at: state.input.finishedAt,
            event_month: state.input.eventMonth,
            event_day: state.input.eventDay,
            twitter_id: state.input.twitterId,
            promotion_code: state.input.promotionCode,
        };
        if (state.parentCampaign) {
            requestData.parent_campaign_id = state.parentCampaign.id;
        }
        const data = await this.$axios.$post('/campaign/', bodyShaper(requestData, {
            dateKeys: [
                'started_at',
                'finished_at',
            ],
        }))
            .catch(() => {})
            .finally(() => {
                commit('apiFinish');
            });
        commit('setInput', { promotionCode: '' });
        await this.$db().model(Campaign).insertOrUpdate({
            data: data,
        });

        if (state.input.customAuthor.author_name || state.input.customAuthor.custom_author_image) {
            await CustomAuthor.dispatch('createRequest', {
                campaignId: data.id,
                ...state.input.customAuthor,
            });
        }

        commit('draftCreateSuccess', {id: data.id});
        Vue.$ga.event('campaign', 'create', 'draft-create');

        return data;
    },
    async fetchForEdit({commit}, {campaignId}) {
        commit('apiStart');
        const data = await this.$axios.$get(`/campaign/${campaignId}/edit/`).catch(() => {
        }).finally(() => {
            commit('apiFinish');
        });

        await this.$db().model(Campaign).insertOrUpdate({
            data: data,
        });

        commit('fetchForEditSuccess', data);

        if (Vue.$ga) {
            // SSRで動くこともあるので...
            Vue.$ga.event('campaign', 'edit', 'fetch-for-edit');
        }

        return data;
    },
    async __execPatch({state, commit}, body) {
        commit('apiStart');
        const data = await this.$axios.$patch(`/campaign/${state.input.id}/`, body).finally(() => {
            commit('apiFinish');
        });

        await this.$db().model(Campaign).insertOrUpdate({
            data: data,
        });

        return data;
    },
    async draftSave({state, commit, dispatch}) {
        try {
            const response = await dispatch('__execPatch', bodyShaper({
                title: state.input.title,
                summary: state.input.summary,
                story: state.input.story,
                main_visual: state.input.mainVisual,
            }, {
                imageKeys: [
                    'main_visual',
                ],
            }));
            commit('setDiscountHasBeenApplied', !!response['discount']);

            Vue.$ga.event('campaign', 'edit', 'draft-save');
        } catch (e) {} // eslint-disable-line no-unused-vars, no-empty
    },
    uploadImage({state}, file) {
        return this.$axios.$post(`/campaign/${state.input.id}/upload-story-image/`, {file: file});
    },
    async draftSaveConfigs({state, commit, dispatch}) {
        try {
            const response = await dispatch('__execPatch', bodyShaper({
                target_amount: state.input.targetAmount,
                target_donors: state.input.targetDonors,
                started_at: state.input.startedAt,
                finished_at: state.input.finishedAt,
                event_month: state.input.eventMonth,
                event_day: state.input.eventDay,
                twitter_id: state.input.twitterId,
                is_creator_info_public: state.input.isCreatorInfoPublic,
                promotion_code: state.input.promotionCode,
            }, {
                dateKeys: [
                    'started_at',
                    'finished_at',
                ],
            }));
            commit('setDiscountHasBeenApplied', !!response['discount']);
            commit('setInput', {promotionCode: ''});

            Vue.$ga.event('campaign', 'edit', 'draft-save-configs');
        } catch (e) {} // eslint-disable-line no-unused-vars, no-empty
    },
    async release({commit}, {campaignId}) {
        commit('apiStart');
        const data = await this.$axios.$patch(`/campaign/${campaignId}/release/`)
            .catch(() => {})
            .finally(() => {
                commit('apiFinish');
            });
        if (!data) return;

        await this.$db().model(Campaign).insertOrUpdate({
            data: data,
        });


        Vue.$ga.event({
            eventCategory: 'cv',
            eventAction: 'release',
            eventLabel: 'campaign',
            eventValue: null,
        });

        GoogleAnalyticsHelper.sendEvent(this.$ga, {
            eventCategory: 'campaign',
            eventAction: 'edit',
            eventLabel: 'release',
            customDimension: {
                'dimension4': campaignId,
            },
        });

        return data;
    },
    async toPrivate({commit}, {campaignId}) {
        commit('apiStart');

        const data = await this.$axios.$patch(`/campaign/${campaignId}/to_private/`)
            .catch(() => {})
            .finally(() => {
                commit('apiFinish');
            });
        await this.$db().model(Campaign).insertOrUpdate({
            data: data,
        });

        Vue.$ga.event('campaign', 'edit', 'to-private');

        return data;
    },
    /**
     * 団体がキャンペーン開催者の情報を見て良いかどうかを変更する
     */
    async changePublishStateOfCreatorInfo({commit}, {campaignId, publishState}) {
        commit('apiStart');

        const data = await this.$axios.$patch(`/campaign/${campaignId}/`, {
            is_creator_info_public: publishState,
        }).catch(() => {
        }).finally(() => {
            commit('apiFinish');
        });

        await this.$db().model(Campaign).insertOrUpdate({
            data: data,
        });
    },
    async destroy({commit}, {campaignId}) {
        commit('apiStart');
        const campaign = await this.$db().model(Campaign).query().where('id', Number(campaignId)).first();
        await this.$axios.$delete(`/campaign/${campaignId}/`)
            .then(() => {
                Vue.$ga.event('campaign', 'edit', 'destroy');
                campaign.$delete();
            })
            .catch(() => {})
            .finally(() => {
                commit('apiFinish');
            });
    },
    async stretchTarget({commit}, {campaignId, val}) {
        commit('apiStart');

        const data = await this.$axios.$post(`/campaign/${campaignId}/stretch-target/`, {
            val: val,
        })
            .catch(() => {})
            .finally(() => {
                commit('apiFinish');
            });
        await this.$db().model(Campaign).insertOrUpdate({
            data: data,
        });

        Vue.$ga.event('campaign', 'edit', 'stretch-target');

        return data;
    },
    async goToEdit({state}) {
        const campaignId = getIsInCampaignCreatePage(this.$router.currentRoute.name)
            ? state.input.id
            : this.$router.currentRoute.params.campaignId;

        await this.$router.push({
            ...ROUTER_TO[LINK_NAME.CAMPAIGN.CAMPAIGN_ID.EDIT.INDEX],
            params: {
                campaignId: campaignId,
            },
        });
    },
    async next({dispatch, rootState}) {
        if (!rootState.auth.isLoggedIn) {
            await this.$alert({message: 'ログインが必要です。'});
            return dispatch('layout/openLoginDialog', {}, {root: true});
        }

        const currentRouteName = this.$router.currentRoute.name;
        if (getIsInCampaignCreatePage(currentRouteName)) {
            await dispatch('draftCreate');
            await dispatch('goToEdit');
        } else if (currentRouteName === LINK_NAME.CAMPAIGN.CAMPAIGN_ID.EDIT.CONFIG) {
            await dispatch('draftSaveConfigs');
            await dispatch('goToEdit');
        } else if (currentRouteName === LINK_NAME.CAMPAIGN.CAMPAIGN_ID.EDIT.INDEX) {
            const campaignId = this.$router.currentRoute.params.campaignId;

            await dispatch('draftSave');
            await this.$router.push({
                ...ROUTER_TO[LINK_NAME.CAMPAIGN.CAMPAIGN_ID.INDEX.INDEX],
                params: {
                    campaignId: campaignId,
                },
            });
        }
    },
};

export const getters = {};
