// ブラウザの場合のみwindow.Omiseが取れる
const Omise = process.browser ? window.Omise : {
    setPublicKey: () => '',
    createToken: () => '',
};

// hide global variable
process.browser && delete window.Omise;

// set public key
Omise.setPublicKey(process.env.OMISE_PUBLIC_KEY);


class PaymentHelper {
    /**
     *
     * @param card
     * @returns {Promise<any>}
     */
    static createToken(card = {}) {
        return new Promise((resolve, reject) => {
            Omise.createToken('card', {
                name: card.name,
                number: card.number,
                expiration_month: card.expiration_month,
                expiration_year: card.expiration_year,
                security_code: card.security_code,
            }, (statusCode, response) => {
                if (statusCode === 200) {
                    return resolve(response.id);
                }
                reject(response);
            });
        });
    }
}

export default PaymentHelper;
