<template lang="pug">
    syncable-app
        layout-image-light-box

        base-layout-dialog

        base-layout-wall

        layout-auth-dialog

        layout-header

        layout-drawer-menu

        v-content.default-layout-content
            nuxt

        layout-footer
</template>

<script>
import LayoutFooter from '../components/organisms/LayoutFooter';
import LayoutHeader from '../components/organisms/LayoutHeader';
import BaseDivider from '../../syncable-commons-components/atoms/BaseDivider';
import SyncableApp from '../components/SyncableApp';
import LayoutImageLightBox from '../components/organisms/LayoutImageLightBox';
import LayoutDrawerMenu from '../components/organisms/LayoutDrawerMenu';
import LayoutAuthDialog from '../components/organisms/LayoutAuthDialog';
import BaseLayoutDialog from '../../syncable-commons-components/organisms/BaseLayoutDialog';
import BaseLayoutWall from '../../syncable-commons-components/organisms/BaseLayoutWall';

export default {
    components: {
        BaseLayoutDialog,
        BaseLayoutWall,
        LayoutAuthDialog,
        LayoutDrawerMenu,
        LayoutImageLightBox,
        SyncableApp,
        BaseDivider,
        LayoutHeader,
        LayoutFooter,
    },
};
</script>

<style scoped lang="scss">
</style>
