import User from '../models/User';
import {bodyShaper} from '../../../syncable-commons-utils/utils/VuexHelper';
import RecurringDonation from '../models/RecurringDonation';
import Donation from '../models/Donation';
import PortfolioDonation from '../models/PortfolioDonation';

const initializeState = () => ({
    myId: 0,
    notification_preference: null,
    input: {
        avatar_image: {
            file: '',
        },
        nickname: '',
        description: '',
    },
    /**
     * ページネーション用のクエリを組み立てるためのステート.
     */
    search: {
        params: {
            sortBy: '-created_at',
        },
        // Vuetifyのテーブルが要求する形に合わせるためこのような形式になっているらしい...
        pagination: {
            descending: true,
            page: 1,
            rowsPerPage: 6,
            total: 0,
            totalPages: 1,
        },
        results: {},
        currentKey: '',
    },
});

export default {
    state: initializeState,
    mutations: {
        setMyId(state, val) {
            state.myId = val;
        },
        setInput(state, val = {}) {
            state.input = {...state.input, ...val};
        },
        setNotificationPreference(state, val) {
            state.notification_preference = (val === null) ? null : Number(val); // レスポンスが返ってくるまでに"OFF"としてレンダリングされない用
        },
        setPagination(state, val) {
            state.search.pagination = {
                ...state.search.pagination,
                ...val,
            };
        },
        /**
         * 既に存在するstate.searchを、引数で受け取ったvalで更新する.
         * valに存在しないキーの値はstate.searchのものを引き続き使用する.
         */
        setSearchConditions(state, val = {}) {
            const page = val.page;
            delete val.page;

            const newParams = ((state, val) => {
                const defaultParams = initializeState().search.params;
                return {
                    ...state.search.params,
                    ...defaultParams,
                    ...val,
                };
            })(state, val);

            const newPagination = ((page, state) => {
                const parsedPagination = {
                    page: parseInt(page || '1', 10),
                };
                return {
                    ...state.search.pagination,
                    ...parsedPagination,
                };
            })(page, state);

            state.search = {
                ...state.search,
                ...{params: newParams},
                ...{pagination: newPagination},
            };
        },
        setSearchResults(state, val = {}) {
            state.search.results = {...state.search.results, ...val};
        },
        clearSearchResults(state) {
            state.search.results = {};
        },
        setSearchPagination(state, val = {}) {
            state.search.pagination = {...state.search.pagination, ...val};
        },
        setSearchCurrentKey(state, val = '') {
            state.search.currentKey = val;
        },
    },
    actions: {
        async fetchMe({commit, dispatch}) {
            const data = await this.$axios.$get('/user/me/');
            await commit('setMyId', data.id);
            await commit('setNotificationPreference', data.notification_preference);
            await dispatch('insertOrUpdate', {data: data});
        },
        async fetchMyPage() {
            const data = await this.$axios.$get('/user/my_page/');
            await this.$db().model(User).insertOrUpdate({
                data: data,
            });
        },
        async fetchMyRecurringDonationToAssociate({ dispatch }, queryParameters) {
            await dispatch('_fetchPaginatedResult', {
                endPoint: '/user/my_page/recurring_donation/associate/',
                queryParameters: queryParameters,
                entity: this.$db().model(RecurringDonation),
            });
        },
        async fetchMyRecurringDonationToPortfolio({ dispatch }, queryParameters) {
            await dispatch('_fetchPaginatedResult', {
                endPoint: '/user/my_page/recurring_donation/portfolio/',
                queryParameters: queryParameters,
                entity: this.$db().model(RecurringDonation),
            });
        },
        /**
         * 非ログインユーザーの継続寄付を取得する。
         * マイページとは異なり、通常の寄付とポートフォリオを同時に取得する。
         *
         * @param context
         * @param queryParameters tokenを渡すことを忘れないこと
         */
        async fetchAnonymousRecurringDonations({ dispatch }, queryParameters) {
            await dispatch('_fetchPaginatedResult', {
                endPoint: '/user/anonymous/recurring-donation/',
                queryParameters: queryParameters,
                entity: this.$db().model(RecurringDonation),
            });
        },
        async fetchMyDonationHistoryToAssociate({ dispatch }, queryParameters) {
            await dispatch('_fetchPaginatedResult', {
                endPoint: '/user/my_page/donation/associate/',
                queryParameters: queryParameters,
                entity: this.$db().model(Donation),
            });
        },
        async fetchMyDonationHistoryToPortfolio({ dispatch }, queryParameters) {
            await dispatch('_fetchPaginatedResult', {
                endPoint: '/user/my_page/donation/portfolio/',
                queryParameters: queryParameters,
                entity: this.$db().model(PortfolioDonation),
            });
        },
        async _fetchPaginatedResult({ state, commit, getters }, payload) {
            const queryParameters = payload.queryParameters;
            const endPoint = payload.endPoint;
            const entity = payload.entity;

            commit('setSearchConditions', queryParameters);

            if (!state.search.results[getters.resultsKey]) {
                const data = await this.$axios.$get(endPoint, {
                    params: getters.searchParams,
                    ext: {
                        longtime: true,
                    },
                });

                await entity.insertOrUpdate({
                    data: data.results,
                });

                commit('setSearchResults', {
                    [getters.resultsKey]: {
                        ids: data.results.map(r => r.id),
                        pagination: {
                            total: data.count,
                            totalPages: Math.ceil(data.count / state.search.pagination.rowsPerPage),
                        },
                    },
                });
            }

            const result = state.search.results[getters.resultsKey] || {};
            commit('setSearchPagination', result.pagination);
            commit('setSearchCurrentKey', getters.resultsKey);
        },
        async fetchAnonymousDonationHistory(context, payload) {
            try {
                return this.$axios.$get('/user/anonymous/donation/', {
                    params: payload,
                });
            } catch (e) {} // eslint-disable-line no-unused-vars, no-empty
        },
        clearSearchedCache({ commit }) {
            commit('clearSearchResults', {});
            commit('setSearchCurrentKey', '');
        },
        async logout({commit}) {
            commit('setMyId', 0);
            // ここでユーザー関連データを削除したいがバグりやすいのでログアウト後にページをリロードして対応しています
        },
        updateInput({commit}, val) {
            commit('setInput', val);
        },
        async onLandedMeProfilePage({commit, getters}) {
            const user = getters['me'](this);
            await commit('setInput', {
                avatar_image: user.avatar_image,
                nickname: user.profile.nickname,
                description: user.profile.description,
            });
        },
        async updateUserProfile({state}) {
            // const me = getters['me'];
            const updateData = {
                avatar_image: state.input.avatar_image,
                profile: {
                    nickname: state.input.nickname,
                    description: state.input.description,
                },
            };
            let body = bodyShaper(updateData, ({imageKeys: ['avatar_image']}));
            const data = await this.$axios.$patch('/user/me/', body);
            await this.$db().model(User).insertOrUpdate({
                data: data,
            });
        },
        async updateUsername(_, newUsername) {
            const body = bodyShaper({username: newUsername}, {});
            const data = await this.$axios.$patch('/user/me/', body);
            await this.$db().model(User).insertOrUpdate({
                data: data,
            });
        },
        async updateEmail(_, newEmail) {
            const UserModel = this.$db().model(User);
            const body = bodyShaper({email: newEmail}, {});
            await this.$axios.$post('/auth/email-change', body);
            // response contains no data, using `me` to update
            await UserModel.insertOrUpdate({data: {...UserModel.getters('me')(this), email: newEmail}});
        },
        async stopRecurringDonation(context, id) {
            if (!id) {
                return;
            }

            await this.$axios.$patch(`/donation/recurring/${id}/stop/`);
            await this.$db().model(RecurringDonation).delete(Number(id));
            deleteRecurringDonationFromCache(context, id);

            function deleteRecurringDonationFromCache({commit, getters}, id) {
                const reducedCurrentIds = [...getters.currentIds].filter(e => e !== id);
                commit('setSearchResults', {
                    [getters.resultsKey]: {
                        ids: reducedCurrentIds,
                    },
                });
            }
        },
        /**
         * トークンを用いて継続寄付を停止する。
         * 非登録ユーザー用。
         */
        async executeAnonymousStopRecurringDonation(context, {id, token}) {
            if (!id || !token) {
                return;
            }

            await this.$axios.$patch(`/user/anonymous/recurring-donation/${id}/stop/`, {
                token: token,
            });
            await this.$db().model(RecurringDonation).delete(Number(id));
            deleteRecurringDonationFromCache(context, id);

            function deleteRecurringDonationFromCache({commit, getters}, id) {
                const reducedCurrentIds = [...getters.currentIds].filter(e => e !== id);
                commit('setSearchResults', {
                    [getters.resultsKey]: {
                        ids: reducedCurrentIds,
                    },
                });
            }
        },
        /**
         * トークンを用いて継続寄付にひもづくカード情報を変更する。
         * 非登録ユーザー用。
         */
        async executeAnonymousUpdateRecurringDonationCard({dispatch}, {id, token, card}) {
            if (!id || !token) {
                return;
            }
            await dispatch('entities/paymentCreditCards/updateInput', {
                card: card,
            }, {root: true});
            const omiseToken = await dispatch('entities/paymentCreditCards/createToken', {}, {root: true});

            return this.$axios.$patch(`/user/anonymous/recurring-donation/${id}/update-card/`, {
                token: token,
                omise_token: omiseToken,
            });
        },
        async updateNotificationPreference({commit}, payload) {
            const body = bodyShaper({notification_preference: payload.notification_preference}, {});
            await this.$axios.$patch('/user/me/', body);
            const UserModel = this.$db().model(User);
            await UserModel.insertOrUpdate({data: {...UserModel.getters('me')(this), notification_preference: payload.notification_preference}});

            commit('setNotificationPreference', payload.notification_preference);
        },
        async fetchAnonymousUniqueDonor(context, params) {
            try {
                const {data} = await this.$axios.get('/user/anonymous/unique-donor/', params);
                return data;
            } catch (e) {} // eslint-disable-line no-unused-vars, no-empty
        },
    },
    getters: {
        me: state => store => {
            const UserModel = store.$db().model(User);
            return UserModel.query()
                .where('id', state.myId)
                .withAllRecursive()
                .first() || UserModel.newInstance();
        },
        meQuery: state => store => {
            return store.$db().model(User).query().where('id', state.myId);
        },
        searchParams(state) {
            return {
                ...state.search.params,
                page: state.search.pagination.page,
                rowsPerPage: state.search.pagination.rowsPerPage,
            };
        },
        resultsKey(state, getters) {
            return Object.values(getters.searchParams).toString();
        },
        currentIds(state) {
            const result = state.search.results[state.search.currentKey] || {};
            return result.ids || [];
        },
    },
};
