<template lang="pug">
    v-list-group.drawer-list-group(
        v-model="active"
        append-icon="icon-arrow-down"
    )
        drawer-item.drawer-list-group__item.drawer-list-group__activator(
            slot="activator"
            :title="title"
            :icon="icon"
            bold
        )
        drawer-item.drawer-list-group__item(
            v-for="(item,j) in items"
            :key="j"
            :to="item.to"
            :linkTarget="item.linkTarget"
            :href="item.href"
            :icon="item.icon"
            :appendIcon="item.appendIcon"
            :title="item.title"
            :is-hidden="item.isHidden"
            child
        )
</template>

<script>
import BaseText from '../atoms/BaseText';
import DrawerItem from './DrawerItem';

export default {
    name: 'DrawerItemGroup',
    components: {BaseText, DrawerItem},
    props: {
        items: {
            type: Array,
            required: true,
        },
        icon: {
            type: String,
            required: false,
            default: '',
        },
        title: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            active: true,
        };
    },
    methods: {
        close() {
            this.active = false;
        },
        open() {
            this.active = true;
        },
    },
};
</script>

<style scoped lang="scss">
    a.drawer-item {
        text-decoration: none;
    }

    .drawer-list-group.list__group {
        border-left: 4px solid transparent;

        &::before, &::after {
            content: none;
        }

        .drawer-list-group__item {
            box-shadow: inset 0px -1px 0px #F3F5F4;
        }

        & /deep/ {
            .icon {
                font-size: 20px;
            }

            .list__group__header {
                box-shadow: inset 0px -1px 0px #F3F5F4;

                .list__group__header__prepend-icon {
                    .icon {
                        color: $c-primary !important;
                    }
                }

                .list__tile {
                    color: $c-text !important;
                }
            }

            .list__group__header__prepend-icon {
                padding-left: 14px;
                padding-right: 6px;
                min-width: 20px;
            }

            .list__group__header__append-icon {
                padding-left: 14px;
                padding-right: 14px;
                color: $c-disabled;
            }
        }
    }
</style>
