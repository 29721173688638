<template lang="pug">
    v-layout.loading-mask.align-center
        v-flex.loading-mask-circle-container
            v-progress-circular(indeterminate color="primary")
</template>

<script>
export default {
    name: 'LoadingMask',
    data() {
        return {
            listener: null,
        };
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
    .loading-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 99999;

        &-circle-container {
            text-align: center;
            z-index: 999999
        }
    }
</style>
