import Vue from 'vue';
import User from '../orm/models/User';
import Associate from '../orm/models/Associate';
import Campaign from '../orm/models/Campaign';
import GoogleAnalyticsHelper from '../../syncable-commons-utils/utils/GoogleAnalyticsHelper';

export default function ({store, route}) {
    if (!process.client) {
        return;
    }
    if (process.env.GOOGLE_OPTIMIZE_CONTAINER_ID) {
        // Google Optimizeというものがあるらしい。試してみる
        Vue.$ga.require(process.env.GOOGLE_OPTIMIZE_CONTAINER_ID);
    }

    if (store.state.auth.isLoggedIn) {
        const me = store.$db().model(User).getters('me')(store);
        Vue.$ga.set('userId', me.id);
    }

    const routeName = route.name;

    if (routeName && routeName.match(/(associateUid)|(campaignId)/g)) {
        window.addEventListener('load', () => {
            const associate = routeName.match(/associateUid/g)
                ? store.$db().model(Associate).getters('browsingAssociate')(store)
                : store.$db().model(Campaign).getters('browsingCampaign')(store).associate;

            // ポートフォリオのキャンペーンではassociateがないので計測しない
            if (!associate) {
                return;
            }

            GoogleAnalyticsHelper.sendPvToAssociate(associate.tracking_id, route.fullPath);
        });
    }
}
