const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['loading'] = require('../middleware/loading.js')
middleware['loading'] = middleware['loading'].default || middleware['loading']

middleware['redirect'] = require('../middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['useragent'] = require('../middleware/useragent.js')
middleware['useragent'] = middleware['useragent'].default || middleware['useragent']

export default middleware
