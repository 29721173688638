import Campaign from '../models/Campaign';
import { bodyShaper } from '../../../syncable-commons-utils/utils/VuexHelper';
import Donation from '../models/Donation';
import Portfolio from '../models/Portfolio';
import PortfolioDonation from '../models/PortfolioDonation';
import DonationMessage from '../models/DonationMessage';
import TextHelper from '../../../syncable-commons-utils/utils/TextHelper';
import _ from 'lodash';

const state = () => (
    {
        campaignId: '',
        portfolioId: '',
        input: {
            message: '',
            is_name_public: true,
        },
        fetch: {
            campaignId: {
                loading: false,
                offset: 0,
                next: '',
            },
            portfolioId: {
                loading: false,
                offset: 0,
                next: '',
            },
        },
    }
);

export default {
    state,
    getters: {
        /**
         * キャンペーンかポートフォリオかを判別するためのフラグ
         */
        isCampaign(state) {
            return !!state.campaignId;
        },
        campaign: state => store => {
            const CampaignModel = store.$db().model(Campaign);
            return CampaignModel
                .query()
                .where('id', state.campaignId)
                .withAllRecursive()
                .first() || CampaignModel.newInstance();
        },
        portfolio: state => store => {
            const PortfolioModel = store.$db().model(Portfolio);
            const CampaignModel = store.$db().model(Campaign);
            const portfolioId = state.portfolioId ? state.portfolioId : _findPortfolioIdFromCampaign(state.campaignId);

            return PortfolioModel
                .query()
                .where('id', portfolioId)
                .withAllRecursive()
                .first() || PortfolioModel.newInstance();

            function _findPortfolioIdFromCampaign(campaignId) {
                if (!campaignId) {
                    return null;
                }

                return _.get(CampaignModel.find(campaignId), 'portfolio.id');
            }
        },
    },
    mutations: {
        setCampaignId(state, val) {
            state.campaignId = Number(val);
        },
        setPortfolioId(state, val) {
            state.portfolioId = Number(val);
        },
        setInput(state, val = {}) {
            state.input = {...state.input, ...val};
        },

        fetchByCampaignStart(state) {
            state.fetch.campaignId.loading = true;
        },
        fetchByCampaignFinish(state) {
            state.fetch.campaignId.loading = false;
        },
        fetchByCampaignSuccess(state, {next}) {
            state.fetch.campaignId.next = next;
            state.fetch.campaignId.offset = !next ? 0 : TextHelper.getParam('offset', next);
        },
        fetchByPortfolioStart(state) {
            state.fetch.portfolioId.loading = true;
        },
        fetchByPortfolioFinish(state) {
            state.fetch.portfolioId.loading = false;
        },
        fetchByPortfolioSuccess(state, {next}) {
            state.fetch.portfolioId.next = next;
            state.fetch.portfolioId.offset = !next ? 0 : TextHelper.getParam('offset', next);
        },
    },
    actions: {
        async onLandedDonationMessagePage({dispatch}, payload) {
            const isPortfolio = payload.isPortfolio;
            if (isPortfolio) {
                return dispatch('_initWithPortfolioInfo');
            }

            return dispatch('_initWithAssociateInfo');
        },
        /**
         * ポートフォリオではないキャンペーンに紐づくDonationMessageの情報で当インスタンスの状態を初期化する.
         * - キャンペーンID以外のキャンペーン情報を取得する.
         */
        async _initWithAssociateInfo({commit, dispatch}) {
            const donationMessage = this.$db().model(Donation).getters('donationMessage');
            const campaignId = donationMessage.campaign; // この時campaign_idはnullなので注意すること、API側を弄ってレスポンスを変えても良いかもしれない
            const message = donationMessage.message;

            await dispatch('entities/campaigns/fetchById', campaignId, {root: true});
            commit('setCampaignId', campaignId);
            commit('setInput', {
                message: message,
                is_name_public: true, // UIでは必ずtrueにする
            });
        },
        /**
         * - ポートフォリオに紐づくDonationMessageの情報で当インスタンスの状態を初期化する.
         * - ポートフォリオID以外のポートフォリオ情報を取得する.
         */
        async _initWithPortfolioInfo({commit, dispatch}) {
            const donationMessage = this.$db().model(PortfolioDonation).getters('donationMessage');
            const portfolioId = donationMessage.portfolio ? donationMessage.portfolio : (this.$db().model(Campaign).find(donationMessage.campaign)).portfolio_id;
            const message = donationMessage.message;

            await dispatch('entities/portfolios/fetchById', portfolioId, {root: true});
            commit('setPortfolioId', portfolioId);
            commit('setInput', {
                message: message,
                is_name_public: true,
            });
        },
        async onExitDonationMessagePage({commit, getters}) {
            if (getters.isCampaign) {
                return commit('setCampaignId', '');
            }

            commit('setPortfolioId', '');
        },
        updateInput({commit}, val) {
            commit('setInput', val);
        },
        /**
         * ドネーションメッセージをCREATEする.
         */
        async postDonationMessage(context, {message, isNamePublic, messageType, userId, campaignId, portfolioId}) {
            let body = bodyShaper({
                message: message,
                is_name_public: isNamePublic,
                type: messageType,
                user: userId,
                campaign: campaignId,
                portfolio: portfolioId,
            }, {});

            const responseData = await this.$axios.$post('/donation/donation-message/', body);
            await this.$db().model(DonationMessage).insertOrUpdate({
                data: responseData,
            });
        },
        /**
         * ドネーションメッセージをアップデートする.
         * - 寄付完了後画面で呼び出されるのはPOSTではなくこちら
         *   - レコード自体は寄付が成立した時点で作成済みであるため、寄付完了後画面ではメッセージとpublicフラグのみ上書きする.
         */
        async updateDonationMessage({state, getters}) {
            let body = bodyShaper({
                message: state.input.message,
                is_name_public: state.input.is_name_public,
            }, {});

            const db = this.$db();

            const id = getters.isCampaign
                ? db.model(Donation).getters('donationMessage').id
                : db.model(PortfolioDonation).getters('donationMessage').id;
            const data = await this.$axios.$patch(`/donation/donation-message/${id}/`, body);
            await db.model(DonationMessage).insertOrUpdate({
                data: data,
            });
        },
        async fetchByCampaignId({dispatch, commit}, {campaignId, limit = null, offset = null}) {
            commit('fetchByCampaignStart');
            const data = await dispatch('fetch', {
                campaign: campaignId,
                sortBy: '-published_from',
                published: true,
                limit: limit,
                offset: offset,
            }).finally(() => {
                commit('fetchByCampaignFinish');
            });
            commit('fetchByCampaignSuccess', {
                next: data.next,
            });
        },
        async fetchByPortfolioId({dispatch, commit}, {portfolioId, limit = null, offset = null}) {
            commit('fetchByPortfolioStart');
            const data = await dispatch('fetch', {
                portfolio: portfolioId,
                sortBy: '-published_from',
                published: true,
                limit: limit,
                offset: offset,
            }).finally(() => {
                commit('fetchByPortfolioFinish');
            });
            commit('fetchByPortfolioSuccess', {
                next: data.next,
            });
        },
        async fetch({dispatch}, params = null) {
            try {
                if (params.id) {
                    return await dispatch('_fetchById', {
                        id: params.id,
                    });
                }

                return await dispatch('_fetchWithQueryParam', params);
            } catch {
                return {
                    count: 0,
                    next: null,
                    previous: null,
                    results: [],
                };
            }
        },
        async _fetchById({dispatch}, {id}) {
            if (!id) {
                return null;
            }

            const data = await this.$axios.$get(`/donation/donation-message/${id}`);
            await dispatch('insertOrUpdate', {data: data});
            return data;
        },
        async _fetchWithQueryParam({dispatch}, params) {
            const data = await this.$axios.$get('/donation/donation-message/',
                {
                    params: params,
                });
            await dispatch('insertOrUpdate', {data: data.results});
            return data;
        },
    },
};
