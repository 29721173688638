import {Model} from '@vuex-orm/core';
import User from './User';
import Associate from './Associate';

export default class Follower extends Model {
    static fields() {
        return {
            id: this.number(null),
            user: this.belongsTo(User, 'user_id'),
            associate: this.belongsTo(Associate, 'associate_id'),
            updated_at: this.string(''),

            user_id: this.number(null),
            associate_id: this.number(null),
        };
    }
}

Follower.entity = 'followers';
