import {Model} from '@vuex-orm/core';
import CampaignImage from './CampaignImage';
import CampaignMainVisual from './CampaignMainVisual';
import CampaignUsage from './CampaignUsage';
import Associate from './Associate';
import User from './User';
import {CAMPAIGN_STATUS, CAMPAIGN_TYPE} from '../../../syncable-commons-consts/enums';
import _ from 'lodash';
import DateHelper from '../../../syncable-commons-utils/utils/DateHelper';
import DonationMessage from './DonationMessage';
import Portfolio from './Portfolio';
import {LINK_NAME, ROUTER_TO} from '../../consts/links';
import CustomAuthor from './CustomAuthor';
import CampaignStatistic from './CampaignStatistic';

export default class Campaign extends Model {
    static fields() {
        return {
            id: this.number(null),

            // 状態
            is_released: this.boolean(false),
            released_at: this.attr(''),
            status: this.number(CAMPAIGN_STATUS.BEFORE_START),
            type: this.number(CAMPAIGN_TYPE.BIRTHDAY),
            event_type: this.number(null),
            is_creator_info_public: this.boolean(false),
            can_be_parent: this.boolean(false),

            // コンテンツ
            title: this.string(''),
            summary: this.string(''),
            story: this.string(''),

            // 設定
            started_at: this.attr(null),
            finished_at: this.attr(null),
            event_month: this.number(null),
            event_day: this.number(null),
            twitter_id: this.string(''),
            target_amount: this.number(null),
            target_amount_stretch_1: this.number(null),
            target_amount_stretch_2: this.number(null),
            target_donors: this.number(null),
            target_donors_stretch_1: this.number(null),
            target_donors_stretch_2: this.number(null),
            is_private: this.boolean(false),

            // 運用
            pickup: this.number(null),
            m_firebase_id: this.string(null),

            created_at: this.string(''),
            updated_at: this.string(''),

            is_cached: this.boolean(false), // clientでcacheするよう

            // FK
            associate: this.belongsTo(Associate, 'associate_id'),
            associate_id: this.attr(null),
            portfolio: this.belongsTo(Portfolio, 'portfolio_id'),
            portfolio_id: this.attr(null),
            user: this.belongsTo(User, 'user_id'),
            user_id: this.attr(null),
            image: this.hasOne(CampaignImage, 'campaign_id'),
            main_visual: this.hasOne(CampaignMainVisual, 'campaign_id'),
            donation_messages: this.hasMany(DonationMessage, 'campaign_id'),
            usages: this.hasMany(CampaignUsage, 'campaign_id'),
            custom_author: this.hasOne(CustomAuthor,'campaign_id'),
            parent_campaign: this.belongsTo(Campaign, 'parent_campaign_id'),
            parent_campaign_id: this.attr(null),
            statistic: this.hasOne(CampaignStatistic,'campaign_id'),
        };
    }

    static newInstance() {
        return new Campaign({
            associate: Associate.newInstance(),
            portfolio: Portfolio.newInstance(),
            user: User.newInstance(),
        });
    }

    get isBirthday() {
        return this.type === CAMPAIGN_TYPE.BIRTHDAY;
    }

    get isFreeDonation() {
        return this.type === CAMPAIGN_TYPE.FREE;
    }

    get isMonthlyFunding() {
        return this.type === CAMPAIGN_TYPE.MONTHLY;
    }

    get isAnnualFunding() {
        return this.type === CAMPAIGN_TYPE.ANNUAL;
    }

    get isMonthlyFundingWithoutCommission() {
        return this.type === CAMPAIGN_TYPE.MONTHLY_WITHOUT_COMMISSION;
    }

    get isRecurringFunding() {
        return this.isMonthlyFunding || this.isAnnualFunding || this.isMonthlyFundingWithoutCommission;
    }

    get isParentCampaignReleased() {
        return this.parent_campaign ? this.parent_campaign.is_released : false;
    }

    get isPublishable() {
        if (!this.main_visual || !this.main_visual.file || (this.parent_campaign && !this.parent_campaign.is_released)) {
            return false;
        }

        const requiredKeys = [
            'title',
            'started_at',
            'finished_at',
            'story',
        ];
        return !_.some(requiredKeys, k => {
            return !this[k];
        });
    }

    get isReachFirstGoal() {
        return this.currentValue >= this.targetValue;
    }

    get eventDateMD() {
        const month = this.event_month;
        const day = this.event_day;
        if (!month || !day) {
            return '';
        }
        return DateHelper.format(`${month}-${day}`, 'M月D日', 'M-D');
    }

    get isFinished() {
        return this.status === CAMPAIGN_STATUS.FINISHED;
    }

    get isDonatable() {
        return this.status === CAMPAIGN_STATUS.STARTED && this.is_released === true;
    }

    /**
     * 残り1日、と言われたとき、人は「今日で終わりだ」と考えるので、日付のdiffに対して+1すれば良い
     * - 今日で終わり -> diff = 0 -> remainingDays = 1
     * - 昨日で終わった -> diff = -1 -> remainingDays = 0
     */
    get remainingDays() {
        if (!this.finished_at) {
            return 0;
        }

        const todayWithoutTimes = DateHelper.format(new Date(), 'YYYY-MM-DD');
        const daysDiff = DateHelper.count(this.finished_at, todayWithoutTimes, 'days');
        return daysDiff >= 0 ? daysDiff + 1 : 0;
    }

    get daysUntilCampaignStarts() {
        const current = DateHelper.format(new Date(), 'YYYY-MM-DD');
        return DateHelper.count(this.started_at, current, 'days');
    }

    /**
     * キャンペーンが残り何日か
     * - 未設定
     * - x日
     * - x時間
     * - 開始前
     */
    get remainingDaysText() {
        if (!this.finished_at) {
            return '未設定';
        }
        if (this.daysUntilCampaignStarts > 0) {
            return '開始前';
        }
        // remainingDays < 0 にならないので
        if (DateHelper.isAfter(new Date(), this.finished_at)) {
            return '終了';
        }

        if (this.remainingDays !== 1) {
            return `${this.remainingDays}日`;
        }

        // 当日
        const current = DateHelper.format(new Date(), 'YYYY-MM-DD HH:mm:ss');
        const finishedAt = DateHelper.format(new Date(), 'YYYY-MM-DD 23:59:59');
        const remainingHours = DateHelper.count(finishedAt, current, 'hours');
        if (remainingHours > 0) {
            return `${remainingHours}時間`;
        }

        const remainingMinutes = DateHelper.count(finishedAt, current, 'minutes');
        return `${remainingMinutes}分`;
    }

    /**
     * 1日以内に終わるか
     *
     * @return {Boolean}
     */
    get endsSoon() {
        return this.remainingDays === 1;
    }

    get isCampaignFinished() {
        return DateHelper.isAfter(new Date(), this.finished_at);
    }

    get isCampaignStarted() {
        const current = DateHelper.format(new Date(), 'YYYY-MM-DD');
        const daysUntilCampaignStarts = DateHelper.count(this.started_at, current, 'days');
        return daysUntilCampaignStarts <= 0;
    }

    /**
     * 目標が金額ではなく支援者数(特殊)
     */
    get targetIsDonor() {
        return (this.target_donors && !this.target_amount);
    }

    /**
     * 現在の目標値。
     * 目標が金額か人数かやストレッチゴールの設定の有無によって返す値が変わる
     */
    get currentTargetValue() {
        if (this.targetIsDonor) {
            return this.target_donors_stretch_2 || this.target_donors_stretch_1 || this.target_donors || 0;
        }
        return this.target_amount_stretch_2 || this.target_amount_stretch_1 || this.target_amount || 0;
    }

    /**
     * 前回の達成した目標値。
     * 目標が金額か人数かやストレッチゴールの設定の有無によって返す値が変わる
     */
    get previousReachedTargetValue() {
        if (!!this.targetValueStretch2 && this.currentValue >= this.targetValueStretch2) {
            return this.targetValueStretch2;
        }
        if (!!this.targetValueStretch1 && this.currentValue >= this.targetValueStretch1) {
            return this.targetValueStretch1;
        }
        return this.targetValue;
    }

    get currentValue() {
        return this.targetIsDonor ? this.statistic.total_donors : this.statistic.total_donation_amount;
    }

    get targetValue() {
        return this.targetIsDonor ? this.target_donors : this.target_amount;
    }

    get targetValueStretch1() {
        return this.targetIsDonor ? this.target_donors_stretch_1 : this.target_amount_stretch_1;
    }

    get targetValueStretch2() {
        return this.targetIsDonor ? this.target_donors_stretch_2 : this.target_amount_stretch_2;
    }

    /**
     * 最低でも一回は公開されたことがある
     */
    get atLeastReleasedOnce() {
        return !!this.released_at;
    }

    /**
     * 達成率
     * - 目標支援者数が設定されているときは支援者数を元に計算
     */
    get progressRate() {
        const calcProgressRate = (targetAmount, currentAmount) => {
            if (!currentAmount || !targetAmount) {
                return 0;
            }
            return Math.floor((currentAmount / targetAmount) * 100);
        };
        return calcProgressRate(this.targetValue, this.currentValue);
    }

    /**
     * キャンペーン作成者のロゴ
     * キャンペーン対象が団体の場合マンスリーファンディングかアニュアルファンディングならば団体のロゴ
     */
    get logoImage() {
        if (this.custom_author && this.custom_author.custom_author_image && this.custom_author.custom_author_image.file) {
            return this.custom_author.custom_author_image.file;
        }
        if (this.portfolio) {
            return _.get(this.user, 'avatar_image.file');
        }
        return this.isRecurringFunding ? _.get(this.associate, 'logo.file') : _.get(this.user, 'avatar_image.file');
    }

    /**
     * キャンペーン作成者の名前
     * キャンペーン対象が団体の場合マンスリーファンディングかアニュアルファンディングならば団体の名前
     */
    get creatorName() {
        if (this.custom_author && this.custom_author.author_name) {
            return this.custom_author.author_name;
        }
        if (this.portfolio_id) {
            return _.get(this.user, 'profile.nickname');
        }
        return this.isRecurringFunding ? _.get(this.associate, 'fullName') : _.get(this.user, 'profile.nickname');
    }

    /**
     * amazon pay決済可否
     * @returns {*|default.props.isAmazonpayUsable|{default, type,
     *     required}|default.computed.isAmazonpayUsable|default.props.isAmazonpayUsable|{type, required}}
     */
    get isAmazonpayUsable() {
        if (this.portfolio) {
            return this.portfolio.isAmazonpayUsable;
        }
        return this.associate.isAmazonpayUsable;
    }

    /**
     * キャンペーン対象の名前
     * @returns {*}
     */
    get targetName() {
        if (this.portfolio) {
            return this.portfolio.name;
        }
        return this.associate.fullName;
    }

    /**
     * 寄付ページのリンク名
     * @returns {string}
     */
    get donationPageName() {
        if (this.portfolio) {
            return LINK_NAME.PORTFOLIO.PORTFOLIO_ID.DONATE.INDEX;
        }
        if (this.isAnnualFunding) {
            return LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.DONATE.MEMBERSHIP;
        }
        return LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.DONATE.INDEX;
    }

    /**
     * routerに渡す寄付ページのto
     */
    get donationPageTo() {
        if (!this.associate && !this.portfolio) {
            return {};
        }
        const idKey = this.associate ? 'associateUid' : 'portfolioId';
        const idVal = this.associate ? this.associate.associate_uid : this.portfolio.id;
        return {
            ...ROUTER_TO[this.donationPageName],
            params: {
                [idKey]: idVal,
            },
            query: {
                campaignId: this.id,
            },
        };
    }

    get isDeletedLogically() {
        return this.status === CAMPAIGN_STATUS.REMOVED;
    }
}

Campaign.entity = 'campaigns';
