import _ from 'lodash';
import User from '../models/User';
import PaymentHelper from '../../utils/PaymentHelper';

const state = () => (
    {
        input: {
            email: '',
            card: {
                number: '',
                expiration_month: '',
                expiration_year: '',
                name: '',
                security_code: '',
            },
        },
    }
);

export default {
    state,
    mutations: {
        setInput(state, val = {}) {
            state.input = {...state.input, ...val};
        },
    },
    actions: {
        updateInput({commit}, val) {
            commit('setInput', val);
        },
        async updateCard({state}) {
            try {
                const myCard = await this.$db().model(User).getters('me')(this).user_credit_card;

                const maskedDate = myCard.getViewData();
                const isEdited = _.some(maskedDate, (val, key) => val !== state.input.card[key]);
                if (!isEdited) {
                    // マスクされた各値とのうち一つも編集されていない場合はapiを叩かない。
                    // 一つでも、少しでも編集されていたらapiを叩き、その後のエラー処理も任せる
                    return myCard;
                }

                // 継続寄付持ちのユーザーなら警告
                const associatedRecurringInfo = await this.$axios.$post('/donation/recurring/my_donation/count-card-active/'); // ユーザーに紐づく継続寄付の情報
                const userHasRecurringDonation = getUserHasRecurringDonation(associatedRecurringInfo);
                if (userHasRecurringDonation) {
                    const associatedRecurringDonationList = getAssociatedRecurringDonationList(associatedRecurringInfo);

                    try {
                        await this.$confirm({
                            title: '',
                            message: 'ご登録アカウントに紐づく下記の継続寄付も、このカードで決済されるように変更されます。' +
                                '<br>' +
                                'よろしいですか？' +
                                '<br>' +
                                '<br>' +
                                associatedRecurringDonationList,
                        });
                    } catch (e) {
                        throw new CardUpdatingCanceledError();
                    }
                }

                const token = await PaymentHelper.createToken(state.input.card);
                const data = await this.$axios.$patch('/payment/credit', {
                    token: token,
                });

                const entities = await myCard.$update(data);
                return entities.paymentCreditCards[0];
            } catch (e) {
                if (!(e instanceof CardUpdatingCanceledError)) {
                    this.$alert({
                        message: (e && e.message) || this.app.i18n.t('error.general'),
                    });
                }

                throw e;
            }
        },
        async createToken({state}) {
            try {
                return PaymentHelper.createToken(state.input.card);
            } catch (e) {
                this.$alert({
                    message: (e && e.message) || this.app.i18n.t('error.general'),
                });
                throw e;
            }
        },
        async createCustomerCode({state, dispatch}) {
            try {
                const token = await dispatch('createToken');
                const data = await this.$axios.$post('/payment/non-member-credit', {
                    token: token,
                    email: state.input.email,
                });
                return data.customer_id;
            } catch (e) {
                this.$alert({
                    message: (e && e.message) || this.app.i18n.t('error.general'),
                });
                throw e;
            }
        },
    },
};

/**
 * ユーザーに紐づく継続寄付があるかどうかを計算する.
 * @param {Object} associatedRecurringInfo
 * @returns {Boolean}
 */
function getUserHasRecurringDonation(associatedRecurringInfo) {
    if (!associatedRecurringInfo || !Array.isArray(associatedRecurringInfo.recurring_donations)) {
        return false;
    }

    return associatedRecurringInfo.recurring_donations.length > 0;
}

/**
 * 現在のユーザーに紐づく継続寄付の一覧（表示用）をフォーマットして取得する。
 * @param {Object} associatedRecurringInfo
 * @returns {String}
 */
function getAssociatedRecurringDonationList(associatedRecurringInfo) {
    return associatedRecurringInfo.recurring_donations.reduce((acc, cur) => {
        if (acc !== '') {
            acc += '<br><br>';
        }

        const label = cur.portfolio_name ? '寄付先ポートフォリオ名' : '寄付先団体名';
        const value = cur.portfolio_name ? cur.portfolio_name : cur.associate_name;

        acc += `${label}： ${value}` +
            '<br>' +
            `支払者： ${cur.recurring_donation_name}`;

        return acc;
    }, '');
}

/**
 * カード情報の更新が中断された際に投げられるエラー
 */
export class CardUpdatingCanceledError extends Error {
    // カスタム例外の定義方法は以下を参考にした
    // https://qiita.com/necojackarc/items/c77cf3b5368b9d33601b

    constructor(...args) {
        super(...args);

        Object.defineProperty(this, 'name', {
            configurable: true,
            enumerable: false,
            value: this.constructor.name,
            writable: true,
        });

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CardUpdatingCanceledError);
        }
    }
}
