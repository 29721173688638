<template lang="pug">
    base-label(
    v-if="deductible"
    highlighted
    icon="deductible"
    :level="level"
    ) 寄付金控除対象
</template>

<script>
import BaseLabel from '../../../syncable-commons-components/molecules/BaseLabel';

export default {
    name: 'AssociateLabelDeductible',
    components: {BaseLabel},
    props: {
        deductible: Boolean,
        level: String,
    },
};
</script>
