export default (ctx, inject) => {
    (function (d, s, id) {
        let js;
        let fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = '//connect.facebook.net/ja_JP/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    const options = {
        appId: process.env.FACEBOOK_APP_ID,
        autoLogAppEvents: true,
        status: true,
        xfbml: true,
        version: 'v3.3',
    };

    window.fbAsyncInit = function onSDKInit() {
        window.FB.init(options);
        window.FB.AppEvents.logPageView();

        window.FB.getLoginStatus((response) => {
            ctx.store.dispatch('auth/onFBLoginStatusChanged', response);
        });

        // Inject FB to the context as $FB
        ctx.$FB = window.FB;
        inject('FB', window.FB);

        delete window.FB;
        window.dispatchEvent(new Event('fb-sdk-ready'));
    };
};
