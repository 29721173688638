<template lang="pug">
    client-only
        .base-input-set-date.py-5(:class="{'is--mobile': showMobileDatePicker,'is--accessible': isAccessible}")
            datepicker.base-input-set-date-date-picker(
                v-model="localValue"
                input-class="base-input-set-date-date-picker-input"
                :language="language === 'ja' ? ja : en"
                ref="datePicker"
                :initial-view="initialView"
                :disabled="disabled"
                :disabledDates="disabledDates"
                v-show="!isAccessible"
            )
                template(slot="afterDateInput")
                    base-input-set.base-input-date-input(
                        @click.native="toggleDatePicker"
                        v-model="displayDate"
                        readonly
                        :label="label"
                        :required="required"
                        :placeholder="placeholder"
                        :disabled="disabled"
                        :appendIcon="computedAppendIcon"
                        :rules="computedRules"
                        :hint="hint"
                        :errorTemplate="errorTemplate"
                    )
            input.base-input-set-date-default-date-picker(
                type="date"
                v-model="localValue"
                :aria-label="label"
                :aria-required="required"
            )
</template>

<script>
// due to https://github.com/charliekassel/vuejs-datepicker/issues/491, use hard-code path here
import ja from '~/node_modules/vuejs-datepicker/src/locale/translations/ja';
import en from '~/node_modules/vuejs-datepicker/src/locale/translations/en';
import {mapGetters} from 'vuex';
import DateHelper from '../../syncable-commons-utils/utils/DateHelper';
import BaseInputSet from './BaseInputSet';
import * as moment from 'moment';

export default {
    name: 'BaseInputSetDate',
    components: {
        BaseInputSet,
    },
    mounted() {
        this.initializeDatePickerRef();
    },
    props: {
        mode: {
            type: String,
            default: 'auto',
            validator: (val) => (['pc', 'mobile', 'auto'].includes(val)),
        },
        ...BaseInputSet.props,
        max: {
            default: new Date().toISOString().substr(0, 10),
            type: String,
        },
        min: {
            default: '1900-01-01',
            type: String,
        },
        initialView: {
            type: String,
            default: 'year',
            validator: (val) => (['year', 'month', 'day'].includes(val)),
        },
        language: {
            type: String,
            default: 'ja',
        },
    },
    data() {
        return {
            ja,
            en,
        };
    },
    computed: {
        ...mapGetters('useragent', [
            'isMobile',
            'isAndroid',
        ]),
        ...mapGetters('accessibility', ['isAccessible']),
        localValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', DateHelper.format(val, 'YYYY-MM-DD'));
            },
        },
        /**
         * モバイルブラウザデフォルトの date pickerを表示するかどうか
         */
        showMobileDatePicker() {
            // androidのdatepickerが使いにくいので、pcと同じものを表示することで対応
            return this.isMobile && !this.isAndroid && !this.isAccessible;
        },
        disabledDates() {
            return {
                to: new Date(this.min), // Disable all dates up to specific date
                from: moment(this.max).add(1, 'days').toDate(), // Disable all dates after specific date
            };
        },
        computedRules() {
            return this.rules || [];
        },
        computedAppendIcon() {
            return 'icon-calendar';
        },
        displayFormat() {
            return this.language === 'ja' ? 'YYYY年M月D日' : 'YYYY/M/D';
        },
        displayDate() {
            return !DateHelper.isDate(this.localValue) ? '' : DateHelper.format(this.localValue, this.displayFormat);
        },
    },
    methods: {
        toggleDatePicker() {
            if (this.showMobileDatePicker || this.isAccessible) {
                return;
            }
            this.$refs.datePicker.showCalendar();
        },
        initializeDatePickerRef(count=10) {
            this.$nextTick(() => {
                if (this.$refs.datePicker) {
                    return;
                } else if (count > 0) {
                    this.initializeDatePickerRef(count - 1);
                }
                return;
            });
        },
    },
};
</script>

<style scoped lang="scss">
    .base-input-set-date {
        &-default-date-picker {
            display: none;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }
        }
    }

    .base-input-set-date.is--mobile {
        position: relative;

        .base-input-set-date {
            &-date-picker {
                z-index: 1;
            }

            &-default-date-picker {
                display: inherit;
                z-index: 2;
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                color: transparent;
            }
        }

        input[type="date"] {
            &::-webkit-inner-spin-button,
            &::-webkit-clear-button {
                -webkit-appearance: none;
            }

            &::-webkit-calendar-picker-indicator {
                opacity: 0;
                color: transparent;
                background-color: transparent;
                width: 100%;
                height: 100%;

                &:before {
                    content: "";
                }
            }

            &::-webkit-datetime-edit {
                color: transparent;
            }

            &:focus {
                outline: none;
            }
        }
    }

    .base-input-set-date.is--accessible {
        .base-input-set-date {
            &-default-date-picker {
                display: inline-block;

                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                }
            }
        }
    }
</style>
<style lang="scss">
    .base-input-set-date {
        &-date-picker-input {
            display: none;
        }
    }
</style>
