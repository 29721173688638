import _ from 'lodash';
import Associate from '../models/Associate';
import { ASSOCIATE_SOCIAL_CHALLENGE_VALUE } from '../../../syncable-commons-consts/enums';
import { createAwaitGetter } from '../../../syncable-commons-utils/utils/VuexHelper';

const socialChallenges = _.reduce(ASSOCIATE_SOCIAL_CHALLENGE_VALUE, (result, value) => {
    result[value] = 0;
    return result;
}, {});

const state = () => ({
    browsingUid: '',
    counts: {
        total: 0,
        social_challenges: socialChallenges,
    },
    search: {
        params: {
            name: '',
            socialChallenge: null,
            sortBy: '-opened_at',
            isDeductible: false,
            followers: false,
        },
        pagination: {
            descending: false,
            page: 1,
            rowsPerPage: 16,
            total: 0,
            totalPages: 1,
            sortBy: '',
        },
        results: {},
        currentKey: '',
    },
});

export default {
    state,
    mutations: {
        setBrowsingUid(state, val) {
            state.browsingUid = val;
        },
        setCounts(state, val) {
            state.counts = val;
        },
        setSearchConditions(state, val) {
            const {name, followers, isDeductible, socialChallenge, page, sortBy} = val;
            const pagination = {
                page: parseInt(page || '1', 10),
            };
            const defaultCondition = {
                name: '',
                social_challenge: null,
                sortBy: '-opened_at',
                is_deductible: false,
                followers: false,
            };
            const condition = {
                name: name || defaultCondition.name,
                social_challenge: socialChallenge || defaultCondition.social_challenge,
                sortBy: sortBy || defaultCondition.sortBy,
                is_deductible: isDeductible === true || defaultCondition.is_deductible,
                followers: followers === true || defaultCondition.followers,
            };
            const newParams = {
                ...state.search.params,
                ...condition,
            };
            const newPagination = {
                ...state.search.pagination,
                ...pagination,
            };
            state.search = {
                ...state.search,
                ...{params: newParams},
                ...{pagination: newPagination},
            };
        },
        setPagination(state, val = {}) {
            state.search.pagination = {...state.search.pagination, ...val};
        },
        setResults(state, val = {}) {
            state.search.results = {...state.search.results, ...val};
        },
        setCurrentKey(state, val = '') {
            state.search.currentKey = val;
        },
    },
    actions: {
        async onLandedAssociatePage({commit, dispatch}, val) {
            await commit('setBrowsingUid', val);
            await dispatch('fetchByUid', val);
        },
        async onLandedAssociateVisionPage({dispatch}) {
            await dispatch('fetchVision');
        },
        async onExitAssociatePage({commit, dispatch}) {
            await dispatch('layout/hideHeaderAdditionalContent', '', {root: true});

            setTimeout(() => {
                commit('setBrowsingUid', '');
            }, 0);
        },
        async fetchByUid({dispatch}, associateUid) {
            const associate = await this.$db().model(Associate).query().where('associate_uid', associateUid).withAll().first();
            if (!associateUid || (associate && associate.is_cached)) {
                // すでにキャッシュしている場合は取らない
                return;
            }
            const data = await this.$axios.$get(`/associate/${associateUid}/`).catch(() => null);
            if (!data) {
                return;
            }
            await dispatch('insertOrUpdate', {
                data: {
                    ...data,
                    is_cached: true,
                },
            });
        },
        async fetchVision({getters, state, dispatch}) {
            const associateUid = state.browsingUid;
            const associate = getters['browsingAssociate'](this);
            if (associate.isVisionFetched()) {
                // すでにキャッシュしている場合は取らない
                return;
            }
            const data = await this.$axios.$get(`/associate/${associateUid}/vision/`).catch(() => null);
            if (!data) {
                return;
            }
            dispatch('entities/associateVisions/insertOrUpdate', {
                where: data.id,
                data: data,
            }, {root: true});
        },
        async fetchCounts({commit}) {
            const counts = await this.$axios.$get('/associate/count_by_social_challenges/');
            await commit('setCounts', counts);
        },
        async _fetchAndCache({commit, dispatch, state, getters}) {
            const data = await this.$axios.$get('/associate/',
                {
                    params: getters.searchParams,
                    ext: {
                        longtime: true,
                    },
                },
            );
            await dispatch('insertOrUpdate', {data: data.results});

            await commit('setResults', {
                [getters.resultsKey]: {
                    ids: data.results.map(r => r.id),
                    pagination: {
                        total: data.count,
                        totalPages: Math.ceil(data.count / state.search.pagination.rowsPerPage),
                    },
                },
            });
        },
        async fetch({commit, dispatch, state, getters}, condition) {
            await commit('setSearchConditions', condition);
            if (!state.search.results[getters.resultsKey]) {
                await dispatch('_fetchAndCache');
            }
            const result = state.search.results[getters.resultsKey] || {};
            commit('setPagination', result.pagination);
            commit('setCurrentKey', getters.resultsKey);
        },
    },
    getters: {
        browsingUid(state) {
            return state.browsingUid;
        },
        browsingAssociate: state => store => {
            const AssociateModel = store.$db().model(Associate);
            return AssociateModel
                .query()
                .where('associate_uid', state.browsingUid)
                .withAllRecursive()
                .first() || AssociateModel.newInstance();
        },
        /**
         * field==valueでであるmodelが取れるまで待つ
         * nestしたページのnuxtのfetchでmodelが欲しい場合などに使う
         * @returns {function(*=, *=): Promise<*>}
         */
        awaitGetter: () => store => {
            return createAwaitGetter(store.$db().model(Associate), (associate) => associate && associate.is_cached);
        },
        searchParams(state) {
            return {
                name: state.search.params.name,
                sortBy: state.search.params.sortBy,
                is_deductible: state.search.params.is_deductible,
                social_challenges: state.search.params.social_challenge,
                followers: state.search.params.followers,
                page: state.search.pagination.page,
                rowsPerPage: state.search.pagination.rowsPerPage,
            };
        },
        resultsKey(state, getters) {
            return Object.values(getters.searchParams).toString();
        },
        currentIds(state) {
            const result = state.search.results[state.search.currentKey] || {};
            return result.ids || [];
        },
    },
};
