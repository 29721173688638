<template lang="pug">
    base-scroll-container(fluid)
        base-scroll-container-content(
        v-for="item in items"
        :key="item.key"
        )
            a.base-link-tab-link(
            v-if="shouldBePlainAnchor(item)"
            :href="getStaticHref(item)"
            :class="getClasses(item)"
            )
                base-text(note) {{item.label}}
            nuxt-link.base-link-tab-link(
            v-else
            :to="item.to"
            :class="getClasses(item)"
            )
                base-text(note) {{item.label}}
</template>

<script>
import _ from 'lodash';
import BaseScrollContainerContent from '../../../syncable-commons-components/atoms/BaseScrollContainerContent';
import BaseScrollContainer from '../../../syncable-commons-components/atoms/BaseScrollContainer';
import BaseText from '../../../syncable-commons-components/atoms/BaseText';
import {isNextDeployedRoute} from '../../../syncable-commons-consts/partial-deployment';

export default {
    name: 'BaseLinkTab',
    components: {BaseText, BaseScrollContainer, BaseScrollContainerContent},
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        /**
             * trueの場合、activeのチェックをする際にhashを含めない
             * `href=/hoge#huga` の時、`/hoge#huga` だけでなく `/hoge`などもactiveにする
             */
        ignoreHash: Boolean,
    },
    methods: {
        shouldBePlainAnchor(item) {
            return isNextDeployedRoute(item.to);
        },
        getStaticHref(item) {
            return this.$router.resolve(item.to).href;
        },
        getClasses(item) {
            const needExact = _.includes(['home'], item.key);
            const classes = {
                'need-exact': needExact,
            };
            if (!this.ignoreHash) {
                return classes;
            }

            // toにhashを含む場合, hashまで一致しないとactiveとみなされないデフォルトの挙動を改造
            // hashを含まなくてもactiveにする
            const resolved = this.$router.resolve(item.to).resolved;
            // 最後の'/'をsliceしたpath
            const slashSlicedResolvedPath = resolved.path.slice(0, -1);
            return {
                ...classes,
                'nuxt-link-active': this.$route.path.includes(slashSlicedResolvedPath),
                'nuxt-link-exact-active': needExact && resolved.name === this.$route.name,
            };
        },
    },
};
</script>

<style scoped lang="scss">
    #base-link-tab-link-active {
        .base-text {
            color: inherit;
            font-weight: bold;
        }

        color: $c-primary;
        border-bottom: $border-primary;
        border-bottom-width: 3px;
    }

    .base-link-tab-link {
        height: 3.125rem;
        display: flex;
        align-items: center;
        padding-right: 15px; // 15px = old $padding-container
        padding-left: 15px; // 15px = old $padding-container

        border-bottom: $border-primary;
        border-bottom-color: $c-bg;
        border-bottom-width: 3px;

        &.need-exact.nuxt-link-exact-active {
            @extend #base-link-tab-link-active
        }

        &:not(.need-exact) {
            &.nuxt-link-active {
                @extend #base-link-tab-link-active
            }
        }
    }
</style>