export const state = () => ({
    inTestMode: false,
});

export const mutations = {
    setInTestMode(state, val) {
        state.inTestMode = val;
    },
};

export const actions = {
    enableTestMode({commit}) {
        commit('setInTestMode', true);
    },
};
