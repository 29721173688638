import Firebase from 'firebase/app';
import 'firebase/auth';

export default (ctx, inject) => {
    const firebaseOptions = {
        'production': {
            apiKey: 'AIzaSyCb59Plq18mm89CV1CLjHwHQ3pw19yLJEQ',
            authDomain: 'p-f4n-bafc9.firebaseapp.com',
            databaseURL: 'https://p-f4n-bafc9.firebaseio.com',
            storageBucket: 'p-f4n-bafc9.appspot.com',
            messagingSenderId: '563260198710',
        },
        'development': {
            apiKey: 'AIzaSyBTSONmXrqzRVD6rGeECuxbgvI3eggD-7U',
            authDomain: 'syncabletest.firebaseapp.com',
            databaseURL: 'https://syncabletest.firebaseio.com',
            storageBucket: 'syncabletest.appspot.com',
            messagingSenderId: '388405625505',
        },
    };

    const firebase = Firebase.initializeApp(firebaseOptions[process.env.NODE_ENV], 'syncable');

    firebase.auth().onAuthStateChanged((user) => {
        ctx.store.dispatch('migration/onAuthStateChanged', user);
    });

    let facebookProvider = new Firebase.auth.FacebookAuthProvider();
    facebookProvider.setCustomParameters({
        'display': 'popup',
    });
    firebase.FacebookAuthProvider = facebookProvider;

    // Inject firebase to the context as $firebase
    ctx.$firebase = firebase;
    inject('firebase', firebase);
};
