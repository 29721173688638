<template lang="pug">
    v-text-field.base-input(
        ref="textField"
        :value="currentValue"
        @input='handleInput'
        flat
        solo
        :placeholder="placeholder"
        :rules="rules"
        :append-icon="appendIcon"
        :prepend-icon="prependIcon"
        :required="required"
        :counter="counter"
        :disabled="disabled"
        :readonly="readonly"
        :hint="hint"
        :multi-line="multiLine"
        :persistent-hint="persistentHint"
        :name="name"
        :autocomplete="autocomplete"
        :type="type"
        rows="7"
        :label="label"
        :maxlength="maxLength"
    )
</template>

<script>
export default {
    name: 'BaseInput',
    props: {
        value: {
            type: [String, Number],
            required: false,
            default: '',
        },
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * 入力欄の下側に現れるヒント.
         * placeholderではない.
         */
        hint: {
            type: String,
            required: false,
            default: '',
        },
        /**
         * カウンター兼上限文字数.
         * 上限文字数のバリデーション自体はしないのでrulesを使用すること.
         */
        counter: {
            type: String,
            required: false,
            default: null,
        },
        multiLine: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * テキスト入力欄の右側にくっつけるアイコン
         */
        appendIcon: {
            type: String,
            required: false,
            default: '',
        },
        /**
         * テキスト入力欄の左側にくっつけるアイコン
         */
        prependIcon: {
            type: String,
            required: false,
            default: '',
        },
        rules: {
            type: Array,
            required: false,
            default: () => [],
        },
        name: {
            type: String,
            required: false,
            default: '',
        },
        autocomplete: {
            type: String,
            required: false,
            default: '',
        },
        type: {
            type: String,
            required: false,
            default: '',
        },
        /**
         * hintをpersistent化（フォーカスしなくても常に表示されるように）するか否か
         */
        persistentHint: {
            type: Boolean,
            required: false,
            default: true,
        },
        twoWaySetter: {
            type: Function,
            required: false,
            default: (val) => {
                return val;
            },
        },
        onInput: {
            type: Function,
            required: false,
            default: () => {},
        },
        label: {
            type: String,
            required: false,
            default: '',
        },
        maxLength: {
            type: Number,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            currentValue: this.value,
            hasError: false,
            id: `input_${this._uid}`,
        };
    },
    watch: {
        'value'(val) {
            this.setCurrentValue(val);
        },
    },
    mounted() {
        this.$refs.textField.$watch('hasError', this.onErrorStateChanged);
    },
    methods: {
        setCurrentValue(value) {
            if (value === this.twoWaySetter(this.currentValue)) {
                return;
            }
            this.currentValue = value;
            this.$nextTick(() => {
                this.onInput(value);
            });
        },
        handleInput(value) {
            this.$emit('input', this.twoWaySetter(value));
            this.setCurrentValue(value);
        },
        onErrorStateChanged(val) {
            this.hasError = val;
            this.$emit('error', {
                message: this.$refs.textField.validations[0],
            });
        },
    },
};
</script>

<style scoped lang="scss">
    @import "BaseInput";
</style>

<style lang="scss">
.base-input {
    /* labelがplaceholderと被るので非表示にする */
    label {
        display: none;
    }
}
</style>
