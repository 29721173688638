<script>
import _ from 'lodash';

export default {
    props: {
        level: {
            type: String,
            required: true,
            validator(val) {
                return _.includes(['1', '2', '3', '4', '5', '6'], val);
            },
        },
        center: Boolean,
        white: Boolean,
        highlighted: Boolean,
        to: Object,
        pre: Boolean,
    },
    computed: {
        classes() {
            return {
                'base-heading': true,
                [`base-heading-${this.level}`]: true,
                'base-heading--center': this.center,
                'base-heading--white': this.white,
                'base-heading--highlighted': this.highlighted,
                'base-heading--pre': this.pre,
            };
        },
    },
    render(createElement) {
        const tag = 'h' + this.level;
        const data = this.$slots.default;
        let el = createElement(
            tag,
            {
                class: this.classes,
            },
            data,
        );

        if (!this.to) {
            return el;
        }
        // toがあればリンクにする
        const routerTag = 'nuxt-link';
        const routerData = {
            props: {
                to: this.to,
            },
            attrs: {
                target: this.targetBlank ? '_blank' : '',
            },
        };
        return createElement(routerTag, routerData, [el]);
    },
};
</script>

<style scoped lang="scss">
    .base-heading {
        color: $c-heading;
        font-weight: bold;
        &--center {
            text-align: center;
        }
        &--white {
            color: $c-bg;
        }
        &--highlighted {
            color: $c-primary;
        }
        &--pre {
            white-space: pre-wrap; /* Since CSS 2.1 */
            white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
            white-space: -o-pre-wrap; /* Opera 7 */
            word-wrap: break-word; /* Internet Explorer 5.5+ */
        }
    }

    @for $i from 1 through 6 {
        .base-heading-#{$i} {
            font-size: map-get(map-get($heading, $i), fs);
            line-height: map-get(map-get($heading, $i), lh);
        }
    }
</style>