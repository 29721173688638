<template lang="pug">
    nuxt-link.base-link(
        v-if="!shouldBePlainAnchor"
        :target="target"
        :to="computedTo"
        :event="disabled ? '' : 'click'"
    )
        slot
    a.base-link(
        v-else
        :target="target"
        :href="computedTo"
    )
        slot
</template>

<script>
import {isNextDeployedRoute} from '../../syncable-commons-consts/partial-deployment';

export default {
    name: 'BaseLink',
    props: {
        to: {
            type: [Object, String],
            required: true,
        },
        blank: Boolean,
        disabled: Boolean,
    },
    computed: {
        shouldBePlainAnchor() {
            return isNextDeployedRoute(this.to);
        },
        target() {
            return this.blank ? '_blank' : '';
        },
        computedTo() {
            return this.shouldBePlainAnchor ? this.$router.resolve(this.to).href : this.to;
        },
    },
};
</script>

<style scoped lang="scss">
    .base-link {
        color: $c-primary;
    }
</style>
