import {Model} from '@vuex-orm/core';
import AssociateDonationSettingPrice from './AssociateDonationSettingPrice';

export default class AssociateDonationSetting extends Model {
    static fields() {
        return {
            id: this.attr(null),

            is_bitcoin: this.boolean(false),
            is_brandpledge: this.boolean(false),
            is_credit: this.boolean(false),
            mail_flag: this.boolean(false),
            receipt_flag: this.boolean(false),
            is_amazon_pay: this.boolean(false),
            price_lists: this.hasMany(AssociateDonationSettingPrice, 'donation_setting'),

            associate: this.attr(null),
        };
    }

    static newInstance() {
        return new AssociateDonationSetting();
    }

    get onetimePrices() {
        return this.price_lists.filter((p) => p.isOnetime());
    }

    get recurringPrices() {
        return this.price_lists.filter((p) => p.isRecurring());
    }
}

AssociateDonationSetting.entity = 'associateDonationSettings';
