import {Model} from '@vuex-orm/core';

export default class AssociateDonationSettingPriceImage extends Model {
    static fields() {
        return {
            id: this.attr(null),

            file: this.attr(''),

            donation_setting_price: this.attr(null),
        };
    }
}

AssociateDonationSettingPriceImage.entity = 'associateDonationSettingPriceImages';
