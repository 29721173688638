import Vue from 'vue';

class AmazonPay {
    constructor({sellerId, button = {}, loginOptions = {}}) {
        this._sellerId = sellerId;
        this._button = button;
        this._loginOptions = loginOptions;

        return this;
    }

    /**
     * amazonPayに渡すStateを更新する。
     * Stateの値は、AmazonPayからリダイレクトして来る際に、クエリパラメータのようにハッシュ値として渡される。
     *
     * @param queryParameter amazonPayからリダイレクトする際にくっつけておきたいクエリパラメータ
     */
    updateQueryParameter(queryParameter) {
        this._loginOptions = {
            ...this._loginOptions,
            state: JSON.stringify(queryParameter),
        };
    }

    /**
     * amazon pay buttonを表示
     * @param buttonId ボタン要素のid
     * @param queryParameter
     */
    showLoginButton({buttonId = 'AmazonPayButton', queryParameter = null}) {
        if (queryParameter) {
            this.updateQueryParameter(queryParameter);
        }

        window.OffAmazonPayments.Button(buttonId, this._sellerId, {
            type: this._button.type,
            color: this._button.color,
            size: this._button.size,

            authorization: () => {
                window.amazon.Login.authorize(this._loginOptions, `${process.env.SYNCABLE_BASE_URL}/redirect/amazon-pay`);
            },
            onError: (/* error */) => {
                // console.log(error);
                // your error handling code.
                // alert("The following error occurred: "
                //  + error.getErrorCode()
                //  + ' - ' + error.getErrorMessage());
            },
        });
    }

    /**
     * scopeで指定したprofile情報が取得できます。
     * profile:{
     *     Name, PrimaryEmail, PostalCode, CustomerId
     * }
     * @param accessToken
     * @returns {Promise<any>}
     */
    retrieveProfile(accessToken) {
        return new Promise((resolve, reject) => {
            window.amazon.Login.retrieveProfile(accessToken, (profileResponse) => {
                // this callback is executed Asynchronously!!
                // ceck if the profile request was successful
                if (!profileResponse.success) {
                    return reject(new Error());
                }
                // request was successful
                resolve(profileResponse.profile);
            });
        });
    }

    /**
     * 住所widgetを表示
     * @param addressBookId 住所widget要素のid
     * @param onReady (optional) onReadyの処理, orderReferenceIdが取得できます
     * @param isRecurring (optional)
     */
    showAddressBookWidget({addressBookId = 'addressBookWidgetDiv', onReady = null, isRecurring = false}) {
        const onReadyOnetime = (orderReference) => {
            // Enter code here you want to be executed
            // when the address widget has been rendered.

            let orderReferenceId = orderReference.getAmazonOrderReferenceId();
            let el;
            if ((el = document.getElementById('orderReferenceId'))) {
                el.value = orderReferenceId;
            }

            onReady && onReady(orderReferenceId);
        };
        const onReadyRecurring = (billingAgreement) => {
            let billingAgreementId = billingAgreement.getAmazonBillingAgreementId();
            let el;
            if ((el = document.getElementById('billingAgreementId'))) {
                el.value = billingAgreementId;
            }

            onReady && onReady(billingAgreementId);
        };
        // AddressBook
        new window.OffAmazonPayments.Widgets.AddressBook({
            sellerId: this._sellerId,
            agreementType: isRecurring ? 'BillingAgreement' : null,
            onOrderReferenceCreate: () => {
                // Here is where you can grab the Order Reference ID.
            },
            onReady: isRecurring ? onReadyRecurring : onReadyOnetime,
            onAddressSelect: async () => {
                // Replace the following code with the action that you want
                // to perform after the address is selected. The
                // amazonOrderReferenceId can be used to retrieve the address
                // details by calling the GetOrderReferenceDetails operation.
                // If rendering the AddressBook and Wallet widgets
                // on the same page, you do not have to provide any additional
                // logic to load the Wallet widget after the AddressBook widget.
                // The Wallet widget will re-render itself on all subsequent
                // onAddressSelect events, without any action from you.
                // It is not recommended that you explicitly refresh it.
            },
            design: {
                designMode: 'responsive',
            },
            onError: (error = {}) => {
                // Your error handling code.
                // During development you can use the following
                // code to view error messages:
                // console.log(error.getErrorCode() + ': ' + error.getErrorMessage());
                // See "Handling Errors" for more information.

                // エラー処理
                // エラーが発生した際にonErrorハンドラーを使って処理することをお勧めします。
                // @see https://payments.amazon.com/documentation/lpwa/201954960
                // console.log('OffAmazonPayments.Widgets.AddressBook', error.getErrorCode(), error.getErrorMessage());
                switch (error.getErrorCode()) {
                    case 'AddressNotModifiable':
                        // オーダーリファレンスIDのステータスが正しくない場合は、お届け先の住所を変更することができません。
                        break;
                    case 'BuyerNotAssociated':
                        // 購入者とリファレンスIDが正しく関連付けられていません。
                        // ウィジェットを表示する前に購入者はログインする必要があります。
                        break;
                    case 'BuyerSessionExpired':
                        // 購入者のセッションの有効期限が切れました。
                        // ウィジェットを表示する前に購入者はログインする必要があります。
                        break;
                    case 'InvalidAccountStatus':
                        // マーチャントID（セラーID）がリクエストを実行する為に適切な状態ではありません。
                        // 考えられる理由 ： 制限がかかっているか、正しく登録が完了されていません。
                        break;
                    case 'InvalidOrderReferenceId':
                        // オーダーリファレンスIDが正しくありません。
                        break;
                    case 'InvalidParameterValue':
                        // 指定されたパラメータの値が正しくありません。
                        break;
                    case 'InvalidSellerId':
                        // マーチャントID（セラーID）が正しくありません。
                        break;
                    case 'MissingParameter':
                        // 指定されたパラメータが正しくありません。
                        break;
                    case 'PaymentMethodNotModifiable':
                        // オーダーリファレンスIDのステータスが正しくない場合はお支払い方法を変更することができません。
                        break;
                    case 'ReleaseEnvironmentMismatch':
                        // 使用しているオーダーリファレンスオブジェクトがリリース環境と一致しません。
                        break;
                    case 'StaleOrderReference':
                        // 使用しているオーダーリファレンスIDがキャンセルされています。
                        // キャンセルされたオーダーリファレンスIDでウィジェットを関連付けすることはできません。
                        break;
                    case 'UnknownError':
                        // 不明なエラーが発生しました。(UnknownError)
                        break;
                    default:
                    // 不明なエラーが発生しました。
                }
            },
        }).bind(addressBookId);
    }

    /**
     *
     * @param amazonReferenceId orderReferenceId or billingAgreementId
     * @param walletId 支払い方法widget要素のid
     * @param isRecurring
     */
    showWalletWidget({amazonReferenceId, walletId = 'walletWidgetDiv', isRecurring = false}) {
        // Wallet
        new window.OffAmazonPayments.Widgets.Wallet({
            sellerId: this._sellerId,
            agreementType: isRecurring ? 'BillingAgreement' : null,
            amazonBillingAgreementId: isRecurring ? amazonReferenceId : null,
            amazonOrderReferenceId: isRecurring ? null : amazonReferenceId,
            onReady: () => {
                // console.log(orderReference.getAmazonOrderReferenceId());
            },
            onPaymentSelect: () => {
                // Replace this code with the action that you want to perform
                // after the payment method is selected.

                // Ideally this would enable the next action for the buyer
                // including either a "Continue" or "Place Order" button.
            },
            design: {
                designMode: 'responsive',
            },
            onError: (/* error */) => {
                // エラー処理
                // エラーが発生した際にonErrorハンドラーを使って処理することをお勧めします。
                // @see https://payments.amazon.com/documentation/lpwa/201954960
                // console.log('OffAmazonPayments.Widgets.Wallet', error.getErrorCode(), error.getErrorMessage());
            },
        }).bind(walletId);
    }

    /**
     *
     * @param billingAgreementId
     * @param consentId
     * @param onConsent
     */
    showConsentWidget({billingAgreementId, consentId = 'consentWidgetDiv', onConsent = null}) {
        // Consent
        new window.OffAmazonPayments.Widgets.Consent({
            sellerId: this._sellerId,
            amazonBillingAgreementId: billingAgreementId,
            onReady: (billingAgreementConsentStatus) => {
                console.log(billingAgreementConsentStatus.getConsentStatus());
            },
            onConsent: (billingAgreementConsentStatus) => {
                const strStatus = billingAgreementConsentStatus.getConsentStatus();
                onConsent && onConsent(strStatus === 'true');
            },
            design: {
                designMode: 'responsive',
            },
            onError: (error) => {
                // エラー処理
                // エラーが発生した際にonErrorハンドラーを使って処理することをお勧めします。
                // @see https://payments.amazon.com/documentation/lpwa/201954960
                console.log('OffAmazonPayments.Widgets.Consent', error.getErrorCode(), error.getErrorMessage());
            },
        }).bind(consentId);
    }
}

export default (ctx) => {
    (function (d, s) {
        let js;
        let fjs = d.getElementsByTagName(s)[0];
        js = d.createElement(s);
        js.src = process.env.AMAZON_PAY_WIDGET_URL;
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script'));

    const CLIENT_ID = 'amzn1.application-oa2-client.880da3a969a54d68a5edde449d3a02cc';

    window.onAmazonLoginReady = () => {
        window.amazon.Login.setClientId(CLIENT_ID);
        window.amazon.Login.setUseCookie(true);
    };
    window.onAmazonPaymentsReady = () => {
        window.dispatchEvent(new Event('amazon-pay-ready'));
    };

    const options = {
        sellerId: 'A1MLHWE2IJ04LD',
        button: {
            type: 'PwA',
            color: 'Gold',
            size: 'large',
        },
        loginOptions: {
            scope: 'profile postal_code payments:widget payments:shipping_address',
            popup: false,
            interactive: 'always',
        },
    };
    window.addEventListener('amazon-pay-ready', () => {
        const amazonPay = new AmazonPay(options);
        ctx.$AmazonPay = amazonPay;
        // 本当は inject でやりたいがこれだとundefinedになってしまう。。
        // this.$FBもセットできていないので、外部から非同期がいけない？
        // inject('AmazonPay', amazonPay);
        ctx.store.$AmazonPay = amazonPay;
        Vue.use({
            install(Vue) {
                const key = '$AmazonPay';
                if (Vue.prototype.hasOwnProperty(key)) {
                    return;
                }
                Object.defineProperty(Vue.prototype, key, {
                    get() {
                        return amazonPay;
                    },
                });
            },
        });
    });
};
