export const state = () => ({
    input: {
        email: '',
    },
    recoveryInfo: {
        user_id: '',
        password_recovery_code: '',
        password: '',
    },
});

export const mutations = {
    setInput(state, val) {
        state.input = {...state.input, ...val};
    },
    setRecoveryInfo(state, val) {
        if (val && val.password_recovery_code) {
            val.password_recovery_code = val.password_recovery_code.substring(0, 32);
        }

        state.recoveryInfo = {
            ...state.recoveryInfo,
            ...val,
        };
    },
};

export const actions = {
    updateInput({commit}, val) {
        commit('setInput', val);
    },
    updateRecoveryInfo({commit}, val) {
        commit('setRecoveryInfo', val);
    },
    async recovery({state}) {
        await this.$axios.$post('/auth/password-recovery', {
            email: state.input.email,
        });
    },
    async update({state}) {
        await this.$axios.$post('/auth/password-update', {...state.recoveryInfo});
    },
};
