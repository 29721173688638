import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1689c1f5 = () => interopDefault(import('../pages/campaign/index.vue' /* webpackChunkName: "pages/campaign/index" */))
const _151b7fb0 = () => interopDefault(import('../pages/law.vue' /* webpackChunkName: "pages/law" */))
const _752fdaa1 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _5e21fe0c = () => interopDefault(import('../pages/me.vue' /* webpackChunkName: "pages/me" */))
const _5b039d35 = () => interopDefault(import('../pages/me/campaign.vue' /* webpackChunkName: "pages/me/campaign" */))
const _b78e3822 = () => interopDefault(import('../pages/me/logout.vue' /* webpackChunkName: "pages/me/logout" */))
const _0ccac820 = () => interopDefault(import('../pages/me/mypage.vue' /* webpackChunkName: "pages/me/mypage" */))
const _025a49b7 = () => interopDefault(import('../pages/me/mypage/campaigns/index.vue' /* webpackChunkName: "pages/me/mypage/campaigns/index" */))
const _d197161c = () => interopDefault(import('../pages/me/mypage/donations.vue' /* webpackChunkName: "pages/me/mypage/donations" */))
const _6f040416 = () => interopDefault(import('../pages/me/mypage/donations/index.vue' /* webpackChunkName: "pages/me/mypage/donations/index" */))
const _6e9b7056 = () => interopDefault(import('../pages/me/mypage/donations/histories.vue' /* webpackChunkName: "pages/me/mypage/donations/histories" */))
const _26064ad0 = () => interopDefault(import('../pages/me/mypage/donations/histories/index.vue' /* webpackChunkName: "pages/me/mypage/donations/histories/index" */))
const _673b49de = () => interopDefault(import('../pages/me/mypage/donations/histories/_donationType.vue' /* webpackChunkName: "pages/me/mypage/donations/histories/_donationType" */))
const _62265d24 = () => interopDefault(import('../pages/me/mypage/donations/_donationType.vue' /* webpackChunkName: "pages/me/mypage/donations/_donationType" */))
const _29012a78 = () => interopDefault(import('../pages/me/mypage/followings/index.vue' /* webpackChunkName: "pages/me/mypage/followings/index" */))
const _7694316a = () => interopDefault(import('../pages/me/settings/index.vue' /* webpackChunkName: "pages/me/settings/index" */))
const _68515062 = () => interopDefault(import('../pages/me/settings/profile.vue' /* webpackChunkName: "pages/me/settings/profile" */))
const _624f34d0 = () => interopDefault(import('../pages/portfolios/index.vue' /* webpackChunkName: "pages/portfolios/index" */))
const _ea351cce = () => interopDefault(import('../pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _17d64a18 = () => interopDefault(import('../pages/sandbox/index.vue' /* webpackChunkName: "pages/sandbox/index" */))
const _04be2a58 = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _6c21d066 = () => interopDefault(import('../pages/terms-associate.vue' /* webpackChunkName: "pages/terms-associate" */))
const _344aa1fa = () => interopDefault(import('../pages/anonymous/history/index.vue' /* webpackChunkName: "pages/anonymous/history/index" */))
const _4c643b9d = () => interopDefault(import('../pages/anonymous/recurring-donations.vue' /* webpackChunkName: "pages/anonymous/recurring-donations" */))
const _b2121668 = () => interopDefault(import('../pages/auth/migration/index.vue' /* webpackChunkName: "pages/auth/migration/index" */))
const _7ea79442 = () => interopDefault(import('../pages/auth/password-recovery.vue' /* webpackChunkName: "pages/auth/password-recovery" */))
const _695fb4b6 = () => interopDefault(import('../pages/auth/password-update.vue' /* webpackChunkName: "pages/auth/password-update" */))
const _3c55269a = () => interopDefault(import('../pages/explore/associate/index.vue' /* webpackChunkName: "pages/explore/associate/index" */))
const _08e0d9f2 = () => interopDefault(import('../pages/privacy/en.vue' /* webpackChunkName: "pages/privacy/en" */))
const _631a2900 = () => interopDefault(import('../pages/redirect/amazon-pay.vue' /* webpackChunkName: "pages/redirect/amazon-pay" */))
const _b412f31a = () => interopDefault(import('../pages/redirect/aozora.vue' /* webpackChunkName: "pages/redirect/aozora" */))
const _67a5f193 = () => interopDefault(import('../pages/terms/en.vue' /* webpackChunkName: "pages/terms/en" */))
const _2686f965 = () => interopDefault(import('../pages/auth/application/edit-recurring-donations/index.vue' /* webpackChunkName: "pages/auth/application/edit-recurring-donations/index" */))
const _393dd15d = () => interopDefault(import('../pages/auth/application/stop-recurring-donations/index.vue' /* webpackChunkName: "pages/auth/application/stop-recurring-donations/index" */))
const _4bdf2276 = () => interopDefault(import('../pages/auth/application/edit-recurring-donations/complete.vue' /* webpackChunkName: "pages/auth/application/edit-recurring-donations/complete" */))
const _44383a22 = () => interopDefault(import('../pages/explore/associate/social-challenge/_socialChallengeSlug.vue' /* webpackChunkName: "pages/explore/associate/social-challenge/_socialChallengeSlug" */))
const _41ba961f = () => interopDefault(import('../pages/anonymous/history/donation/_donationId/receipt-pdf/index.vue' /* webpackChunkName: "pages/anonymous/history/donation/_donationId/receipt-pdf/index" */))
const _7eb2590e = () => interopDefault(import('../pages/associate/_associateUid.vue' /* webpackChunkName: "pages/associate/_associateUid" */))
const _342f5d11 = () => interopDefault(import('../pages/associate/_associateUid/index.vue' /* webpackChunkName: "pages/associate/_associateUid/index" */))
const _4f8c12d4 = () => interopDefault(import('../pages/associate/_associateUid/index/index.vue' /* webpackChunkName: "pages/associate/_associateUid/index/index" */))
const _36f81b5f = () => interopDefault(import('../pages/associate/_associateUid/index/businesses/index.vue' /* webpackChunkName: "pages/associate/_associateUid/index/businesses/index" */))
const _6b27bb01 = () => interopDefault(import('../pages/associate/_associateUid/index/campaign/index.vue' /* webpackChunkName: "pages/associate/_associateUid/index/campaign/index" */))
const _7364374a = () => interopDefault(import('../pages/associate/_associateUid/index/contribute.vue' /* webpackChunkName: "pages/associate/_associateUid/index/contribute" */))
const _1bcdea9d = () => interopDefault(import('../pages/associate/_associateUid/index/contribute/complete.vue' /* webpackChunkName: "pages/associate/_associateUid/index/contribute/complete" */))
const _015cc7a0 = () => interopDefault(import('../pages/associate/_associateUid/index/contribute/confirmation-bank.vue' /* webpackChunkName: "pages/associate/_associateUid/index/contribute/confirmation-bank" */))
const _43cf1b5a = () => interopDefault(import('../pages/associate/_associateUid/index/contribute/message.vue' /* webpackChunkName: "pages/associate/_associateUid/index/contribute/message" */))
const _afdd6b46 = () => interopDefault(import('../pages/associate/_associateUid/index/donate.vue' /* webpackChunkName: "pages/associate/_associateUid/index/donate" */))
const _6fe1d720 = () => interopDefault(import('../pages/associate/_associateUid/index/donate/index.vue' /* webpackChunkName: "pages/associate/_associateUid/index/donate/index" */))
const _78380232 = () => interopDefault(import('../pages/associate/_associateUid/index/donate/card.vue' /* webpackChunkName: "pages/associate/_associateUid/index/donate/card" */))
const _f5ee6252 = () => interopDefault(import('../pages/associate/_associateUid/index/donate/confirmation.vue' /* webpackChunkName: "pages/associate/_associateUid/index/donate/confirmation" */))
const _142712b4 = () => interopDefault(import('../pages/associate/_associateUid/index/donate/donor.vue' /* webpackChunkName: "pages/associate/_associateUid/index/donate/donor" */))
const _7f2e2c78 = () => interopDefault(import('../pages/associate/_associateUid/index/donate/membership.vue' /* webpackChunkName: "pages/associate/_associateUid/index/donate/membership" */))
const _ddaa6944 = () => interopDefault(import('../pages/associate/_associateUid/index/members/index.vue' /* webpackChunkName: "pages/associate/_associateUid/index/members/index" */))
const _23fe2559 = () => interopDefault(import('../pages/associate/_associateUid/index/vision/index.vue' /* webpackChunkName: "pages/associate/_associateUid/index/vision/index" */))
const _63225ca0 = () => interopDefault(import('../pages/associate/_associateUid/index/campaign/create/index.vue' /* webpackChunkName: "pages/associate/_associateUid/index/campaign/create/index" */))
const _70671ed2 = () => interopDefault(import('../pages/associate/_associateUid/index/campaign/create/_campaignType.vue' /* webpackChunkName: "pages/associate/_associateUid/index/campaign/create/_campaignType" */))
const _36ad62ac = () => interopDefault(import('../pages/associate/_associateUid/index/businesses/_businessId/index.vue' /* webpackChunkName: "pages/associate/_associateUid/index/businesses/_businessId/index" */))
const _6556e4e0 = () => interopDefault(import('../pages/campaign/_campaignId/index.vue' /* webpackChunkName: "pages/campaign/_campaignId/index" */))
const _2244a813 = () => interopDefault(import('../pages/campaign/_campaignId/index/index.vue' /* webpackChunkName: "pages/campaign/_campaignId/index/index" */))
const _7af1ba7a = () => interopDefault(import('../pages/campaign/_campaignId/index/reports/index.vue' /* webpackChunkName: "pages/campaign/_campaignId/index/reports/index" */))
const _93dcff4a = () => interopDefault(import('../pages/campaign/_campaignId/index/reports/create.vue' /* webpackChunkName: "pages/campaign/_campaignId/index/reports/create" */))
const _c3ce529c = () => interopDefault(import('../pages/campaign/_campaignId/index/reports/_campaignReportId/index.vue' /* webpackChunkName: "pages/campaign/_campaignId/index/reports/_campaignReportId/index" */))
const _2b96a34c = () => interopDefault(import('../pages/campaign/_campaignId/index/reports/_campaignReportId/edit.vue' /* webpackChunkName: "pages/campaign/_campaignId/index/reports/_campaignReportId/edit" */))
const _9f230b94 = () => interopDefault(import('../pages/portfolios/_portfolioId.vue' /* webpackChunkName: "pages/portfolios/_portfolioId" */))
const _1a880039 = () => interopDefault(import('../pages/portfolios/_portfolioId/index.vue' /* webpackChunkName: "pages/portfolios/_portfolioId/index" */))
const _20d09008 = () => interopDefault(import('../pages/portfolios/_portfolioId/index/index.vue' /* webpackChunkName: "pages/portfolios/_portfolioId/index/index" */))
const _49c385e9 = () => interopDefault(import('../pages/portfolios/_portfolioId/index/reports.vue' /* webpackChunkName: "pages/portfolios/_portfolioId/index/reports" */))
const _fcb78ca8 = () => interopDefault(import('../pages/portfolios/_portfolioId/index/reports/index.vue' /* webpackChunkName: "pages/portfolios/_portfolioId/index/reports/index" */))
const _4ad172dc = () => interopDefault(import('../pages/portfolios/_portfolioId/index/reports/create.vue' /* webpackChunkName: "pages/portfolios/_portfolioId/index/reports/create" */))
const _7436da1e = () => interopDefault(import('../pages/portfolios/_portfolioId/index/reports/_portfolioReportId/index.vue' /* webpackChunkName: "pages/portfolios/_portfolioId/index/reports/_portfolioReportId/index" */))
const _8c1e238a = () => interopDefault(import('../pages/portfolios/_portfolioId/index/reports/_portfolioReportId/edit.vue' /* webpackChunkName: "pages/portfolios/_portfolioId/index/reports/_portfolioReportId/edit" */))
const _8dd0e3d4 = () => interopDefault(import('../pages/portfolios/_portfolioId/contribute.vue' /* webpackChunkName: "pages/portfolios/_portfolioId/contribute" */))
const _58519045 = () => interopDefault(import('../pages/portfolios/_portfolioId/contribute/complete/index.vue' /* webpackChunkName: "pages/portfolios/_portfolioId/contribute/complete/index" */))
const _7326310e = () => interopDefault(import('../pages/portfolios/_portfolioId/contribute/message.vue' /* webpackChunkName: "pages/portfolios/_portfolioId/contribute/message" */))
const _3c930998 = () => interopDefault(import('../pages/portfolios/_portfolioId/donate.vue' /* webpackChunkName: "pages/portfolios/_portfolioId/donate" */))
const _523003ca = () => interopDefault(import('../pages/portfolios/_portfolioId/donate/index.vue' /* webpackChunkName: "pages/portfolios/_portfolioId/donate/index" */))
const _42b8e052 = () => interopDefault(import('../pages/portfolios/_portfolioId/donate/card.vue' /* webpackChunkName: "pages/portfolios/_portfolioId/donate/card" */))
const _dc9a3108 = () => interopDefault(import('../pages/portfolios/_portfolioId/donate/confirmation.vue' /* webpackChunkName: "pages/portfolios/_portfolioId/donate/confirmation" */))
const _7b2d39af = () => interopDefault(import('../pages/portfolios/_portfolioId/donate/donor.vue' /* webpackChunkName: "pages/portfolios/_portfolioId/donate/donor" */))
const _54a92b6a = () => interopDefault(import('../pages/portfolios/_portfolioId/campaigns/create/_campaignType.vue' /* webpackChunkName: "pages/portfolios/_portfolioId/campaigns/create/_campaignType" */))
const _5f57a3bc = () => interopDefault(import('../pages/campaign/_campaignId/edit.vue' /* webpackChunkName: "pages/campaign/_campaignId/edit" */))
const _729d2582 = () => interopDefault(import('../pages/campaign/_campaignId/edit/index.vue' /* webpackChunkName: "pages/campaign/_campaignId/edit/index" */))
const _1723d4a5 = () => interopDefault(import('../pages/campaign/_campaignId/edit/config.vue' /* webpackChunkName: "pages/campaign/_campaignId/edit/config" */))
const _33b241de = () => interopDefault(import('../pages/donation-annual/_donationId/receipt/index.vue' /* webpackChunkName: "pages/donation-annual/_donationId/receipt/index" */))
const _277ac0fd = () => interopDefault(import('../pages/donation/_donationId/receipt/index.vue' /* webpackChunkName: "pages/donation/_donationId/receipt/index" */))
const _072a1b62 = () => interopDefault(import('../pages/donation/_donationId/receipt-pdf/index.vue' /* webpackChunkName: "pages/donation/_donationId/receipt-pdf/index" */))
const _29ecfd47 = () => interopDefault(import('../pages/donation-annual/_donationId/receipt/error.vue' /* webpackChunkName: "pages/donation-annual/_donationId/receipt/error" */))
const _6b40df33 = () => interopDefault(import('../pages/donation/_donationId/receipt/error.vue' /* webpackChunkName: "pages/donation/_donationId/receipt/error" */))
const _f132be4c = () => interopDefault(import('../pages/campaign/_campaignId/create-support-funding/_campaignType.vue' /* webpackChunkName: "pages/campaign/_campaignId/create-support-funding/_campaignType" */))
const _369ca960 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/campaign",
    component: _1689c1f5,
    name: "campaign"
  }, {
    path: "/law",
    component: _151b7fb0,
    name: "law"
  }, {
    path: "/maintenance",
    component: _752fdaa1,
    name: "maintenance"
  }, {
    path: "/me",
    component: _5e21fe0c,
    name: "me",
    children: [{
      path: "campaign",
      component: _5b039d35,
      name: "me-campaign"
    }, {
      path: "logout",
      component: _b78e3822,
      name: "me-logout"
    }, {
      path: "mypage",
      component: _0ccac820,
      name: "me-mypage",
      children: [{
        path: "campaigns",
        component: _025a49b7,
        name: "me-mypage-campaigns"
      }, {
        path: "donations",
        component: _d197161c,
        children: [{
          path: "",
          component: _6f040416,
          name: "me-mypage-donations"
        }, {
          path: "histories",
          component: _6e9b7056,
          children: [{
            path: "",
            component: _26064ad0,
            name: "me-mypage-donations-histories"
          }, {
            path: ":donationType",
            component: _673b49de,
            name: "me-mypage-donations-histories-donationType"
          }]
        }, {
          path: ":donationType",
          component: _62265d24,
          name: "me-mypage-donations-donationType"
        }]
      }, {
        path: "followings",
        component: _29012a78,
        name: "me-mypage-followings"
      }]
    }, {
      path: "settings",
      component: _7694316a,
      name: "me-settings"
    }, {
      path: "settings/profile",
      component: _68515062,
      name: "me-settings-profile"
    }]
  }, {
    path: "/portfolios",
    component: _624f34d0,
    name: "portfolios"
  }, {
    path: "/privacy",
    component: _ea351cce,
    name: "privacy"
  }, {
    path: "/sandbox",
    component: _17d64a18,
    name: "sandbox"
  }, {
    path: "/terms",
    component: _04be2a58,
    name: "terms"
  }, {
    path: "/terms-associate",
    component: _6c21d066,
    name: "terms-associate"
  }, {
    path: "/anonymous/history",
    component: _344aa1fa,
    name: "anonymous-history"
  }, {
    path: "/anonymous/recurring-donations",
    component: _4c643b9d,
    name: "anonymous-recurring-donations"
  }, {
    path: "/auth/migration",
    component: _b2121668,
    name: "auth-migration"
  }, {
    path: "/auth/password-recovery",
    component: _7ea79442,
    name: "auth-password-recovery"
  }, {
    path: "/auth/password-update",
    component: _695fb4b6,
    name: "auth-password-update"
  }, {
    path: "/explore/associate",
    component: _3c55269a,
    name: "explore-associate"
  }, {
    path: "/privacy/en",
    component: _08e0d9f2,
    name: "privacy-en"
  }, {
    path: "/redirect/amazon-pay",
    component: _631a2900,
    name: "redirect-amazon-pay"
  }, {
    path: "/redirect/aozora",
    component: _b412f31a,
    name: "redirect-aozora"
  }, {
    path: "/terms/en",
    component: _67a5f193,
    name: "terms-en"
  }, {
    path: "/auth/application/edit-recurring-donations",
    component: _2686f965,
    name: "auth-application-edit-recurring-donations"
  }, {
    path: "/auth/application/stop-recurring-donations",
    component: _393dd15d,
    name: "auth-application-stop-recurring-donations"
  }, {
    path: "/auth/application/edit-recurring-donations/complete",
    component: _4bdf2276,
    name: "auth-application-edit-recurring-donations-complete"
  }, {
    path: "/explore/associate/social-challenge/:socialChallengeSlug?",
    component: _44383a22,
    name: "explore-associate-social-challenge-socialChallengeSlug"
  }, {
    path: "/anonymous/history/donation/:donationId?/receipt-pdf",
    component: _41ba961f,
    name: "anonymous-history-donation-donationId-receipt-pdf"
  }, {
    path: "/associate/:associateUid?",
    component: _7eb2590e,
    children: [{
      path: "",
      component: _342f5d11,
      children: [{
        path: "",
        component: _4f8c12d4,
        name: "associate-associateUid-index"
      }, {
        path: "businesses",
        component: _36f81b5f,
        name: "associate-associateUid-index-businesses"
      }, {
        path: "campaign",
        component: _6b27bb01,
        name: "associate-associateUid-index-campaign"
      }, {
        path: "contribute",
        component: _7364374a,
        name: "associate-associateUid-index-contribute",
        children: [{
          path: "complete",
          component: _1bcdea9d,
          name: "associate-associateUid-index-contribute-complete"
        }, {
          path: "confirmation-bank",
          component: _015cc7a0,
          name: "associate-associateUid-index-contribute-confirmation-bank"
        }, {
          path: "message",
          component: _43cf1b5a,
          name: "associate-associateUid-index-contribute-message"
        }]
      }, {
        path: "donate",
        component: _afdd6b46,
        children: [{
          path: "",
          component: _6fe1d720,
          name: "associate-associateUid-index-donate"
        }, {
          path: "card",
          component: _78380232,
          name: "associate-associateUid-index-donate-card"
        }, {
          path: "confirmation",
          component: _f5ee6252,
          name: "associate-associateUid-index-donate-confirmation"
        }, {
          path: "donor",
          component: _142712b4,
          name: "associate-associateUid-index-donate-donor"
        }, {
          path: "membership",
          component: _7f2e2c78,
          name: "associate-associateUid-index-donate-membership"
        }]
      }, {
        path: "members",
        component: _ddaa6944,
        name: "associate-associateUid-index-members"
      }, {
        path: "vision",
        component: _23fe2559,
        name: "associate-associateUid-index-vision"
      }, {
        path: "campaign/create",
        component: _63225ca0,
        name: "associate-associateUid-index-campaign-create"
      }, {
        path: "campaign/create/:campaignType?",
        component: _70671ed2,
        name: "associate-associateUid-index-campaign-create-campaignType"
      }, {
        path: "businesses/:businessId?",
        component: _36ad62ac,
        name: "associate-associateUid-index-businesses-businessId"
      }]
    }]
  }, {
    path: "/campaign/:campaignId",
    component: _6556e4e0,
    children: [{
      path: "",
      component: _2244a813,
      name: "campaign-campaignId-index"
    }, {
      path: "reports",
      component: _7af1ba7a,
      name: "campaign-campaignId-index-reports"
    }, {
      path: "reports/create",
      component: _93dcff4a,
      name: "campaign-campaignId-index-reports-create"
    }, {
      path: "reports/:campaignReportId?",
      component: _c3ce529c,
      name: "campaign-campaignId-index-reports-campaignReportId"
    }, {
      path: "reports/:campaignReportId?/edit",
      component: _2b96a34c,
      name: "campaign-campaignId-index-reports-campaignReportId-edit"
    }]
  }, {
    path: "/portfolios/:portfolioId",
    component: _9f230b94,
    children: [{
      path: "",
      component: _1a880039,
      children: [{
        path: "",
        component: _20d09008,
        name: "portfolios-portfolioId-index"
      }, {
        path: "reports",
        component: _49c385e9,
        children: [{
          path: "",
          component: _fcb78ca8,
          name: "portfolios-portfolioId-index-reports"
        }, {
          path: "create",
          component: _4ad172dc,
          name: "portfolios-portfolioId-index-reports-create"
        }, {
          path: ":portfolioReportId",
          component: _7436da1e,
          name: "portfolios-portfolioId-index-reports-portfolioReportId"
        }, {
          path: ":portfolioReportId/edit",
          component: _8c1e238a,
          name: "portfolios-portfolioId-index-reports-portfolioReportId-edit"
        }]
      }]
    }, {
      path: "contribute",
      component: _8dd0e3d4,
      name: "portfolios-portfolioId-contribute",
      children: [{
        path: "complete",
        component: _58519045,
        name: "portfolios-portfolioId-contribute-complete"
      }, {
        path: "message",
        component: _7326310e,
        name: "portfolios-portfolioId-contribute-message"
      }]
    }, {
      path: "donate",
      component: _3c930998,
      children: [{
        path: "",
        component: _523003ca,
        name: "portfolios-portfolioId-donate"
      }, {
        path: "card",
        component: _42b8e052,
        name: "portfolios-portfolioId-donate-card"
      }, {
        path: "confirmation",
        component: _dc9a3108,
        name: "portfolios-portfolioId-donate-confirmation"
      }, {
        path: "donor",
        component: _7b2d39af,
        name: "portfolios-portfolioId-donate-donor"
      }]
    }, {
      path: "campaigns/create/:campaignType?",
      component: _54a92b6a,
      name: "portfolios-portfolioId-campaigns-create-campaignType"
    }]
  }, {
    path: "/campaign/:campaignId/edit",
    component: _5f57a3bc,
    children: [{
      path: "",
      component: _729d2582,
      name: "campaign-campaignId-edit"
    }, {
      path: "config",
      component: _1723d4a5,
      name: "campaign-campaignId-edit-config"
    }]
  }, {
    path: "/donation-annual/:donationId?/receipt",
    component: _33b241de,
    name: "donation-annual-donationId-receipt"
  }, {
    path: "/donation/:donationId?/receipt",
    component: _277ac0fd,
    name: "donation-donationId-receipt"
  }, {
    path: "/donation/:donationId?/receipt-pdf",
    component: _072a1b62,
    name: "donation-donationId-receipt-pdf"
  }, {
    path: "/donation-annual/:donationId?/receipt/error",
    component: _29ecfd47,
    name: "donation-annual-donationId-receipt-error"
  }, {
    path: "/donation/:donationId?/receipt/error",
    component: _6b40df33,
    name: "donation-donationId-receipt-error"
  }, {
    path: "/campaign/:campaignId/create-support-funding/:campaignType?",
    component: _f132be4c,
    name: "campaign-campaignId-create-support-funding-campaignType"
  }, {
    path: "/",
    component: _369ca960,
    name: "index"
  }, {
    path: "/campaign/:campaignId/report",
    redirect: "/campaign/:campaignId/reports"
  }, {
    path: "/campaign/:campaignId/report/create",
    redirect: "/campaign/:campaignId/reports/create"
  }, {
    path: "/campaign/:campaignId/report/:campaignReportId",
    redirect: "/campaign/:campaignId/reports/:campaignReportId"
  }, {
    path: "/campaign/:campaignId/report/:campaignReportId/edit",
    redirect: "/campaign/:campaignId/reports/:campaignReportId/edit"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
