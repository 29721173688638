export const state = () => ({
    dialog: {
        publishCampaign: false,
        stretchGoal: false,
    },
});

export const mutations = {
    setDialog(state, val = {}) {
        state.dialog = {...state.dialog, ...val};
    },
    setPublishCampaign(state, val) {
        state.dialog.publishCampaign = val;
    },
    showPublishCampaignDialog(state) {
        state.dialog.publishCampaign = true;
    },
};

export const actions = {
    async updateDialogStretchGoal({commit}, val) {
        commit('setDialog', {stretchGoal: val});
    },
};
