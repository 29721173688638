import _ from 'lodash';
import {COUNTRY, DONATION_TYPE, GENDER, PAYMENT_METHOD, PORTFOLIO_TYPE} from '../../../syncable-commons-consts/enums';
import {LINK_NAME, ROUTER_TO} from '../../consts/links';
import User from '../models/User';
import UserPrivateProfile from '../models/UserPrivateProfile';
import PaymentCreditCard from '../models/PaymentCreditCard';
import Portfolio from '../models/Portfolio';
import Campaign from '../models/Campaign';
import { CardUpdatingCanceledError } from './paymentCreditCards';

const initializeState = () => ({
    valid: {
        amount: false,
        donor: false,
        card: false,
        confirmation: false,
    },
    // input(入力中のもの) と inputted(確定したもの)の２段構成に後から変更したので、ややこしい作りになっています。
    input: {
        amount: '',
        payment_method: PAYMENT_METHOD.CREDIT_CARD,
        isRecurring: true,

        card: {
            number: '',
            expiration_month: '',
            expiration_year: '',
            name: '',
            security_code: '',
        },
        donor: {
            phone: '',
            gender: GENDER.UNSPECIFIED,
            birthday: '',
            country: COUNTRY.JP,
            zip_code: '',
            area: '',
            city: '',
            address1: '',
            address2: '',
            last_name: '',
            first_name: '',
        },
        email: '',

        message: '',
        need_receipt: false,
        need_news_letter: false,

        password: '',
    },
    inputted: {
        amount: '',
        payment_method: PAYMENT_METHOD.CREDIT_CARD,
        isRecurring: true,

        card: {
            number: '',
            expiration_month: '',
            expiration_year: '',
            name: '',
            security_code: '',
        },

        donor: {
            phone: '',
            gender: GENDER.UNSPECIFIED,
            birthday: '',
            country: COUNTRY.JP,
            zip_code: '',
            area: '',
            city: '',
            address1: '',
            address2: '',
            last_name: '',
            first_name: '',
        },
        email: '',
    },
    bodySource: {
        customer_id: '',
        portfolio_id: '',

        agency_commission_code: '',
        campaignId: null,
    },
    mode: {
        hideFrequency: false,
        isEdit: false,
        isCampaign: false,
    },
    amazonPay: {
        token: '',
        amazonReferenceId: '',
        isAddressBookTobeRefreshed: false,
        consentStatus: false,
    },
    donationMessage: {},
    defaultRoute: {
        params: '',
        query: '',
        name: '',
    },
    recaptchaToken: null,
});
export default {
    state: initializeState,
    mutations: {
        setValid(state, val = {}) {
            state.valid = {...state.valid, ...val};
        },
        setInput(state, val = {}) {
            state.input = {...state.input, ...val};
        },
        setInputted(state, val = {}) {
            state.inputted = {...state.inputted, ...val};
        },
        setBodySource(state, val = {}) {
            state.bodySource = {...state.bodySource, ...val};
        },
        setMode(state, val = {}) {
            state.mode = {...state.mode, ...val};
        },
        setDefaultRoute(state, val = {}) {
            state.defaultRoute = {...state.defaultRoute, ...val};
        },
        setAmazonPay(state, val = {}) {
            state.amazonPay = {...state.amazonPay, ...val};
        },
        setDonationMessage(state, val = {}) {
            state.donationMessage = {...state.donationMessage, ...val};
        },
        setRecaptchaToken(state, val) {
            state.recaptchaToken = val;
        },
    },

    actions: {
        onExitDonatePage({commit}) {
            const initState = initializeState();
            commit('setValid', initState.valid);
            commit('setInput', initState.input);
            commit('setDefaultRoute', initState.defaultRoute);
            commit('setAmazonPay', initState.amazonPay);
        },
        onExitCompletePage({commit, dispatch}) {
            const initState = initializeState();
            commit('setMode', initState.mode);
            commit('setDonationMessage', initState.donationMessage);
            dispatch('layout/updateLang', 'ja', {root: true});
        },
        updateValid({commit}, val) {
            commit('setValid', val);
        },
        updateInput({commit}, val) {
            commit('setInput', val);
        },
        updateDefaultInput({commit}, val) {
            commit('setInput', val);
            commit('setInputted', val);
        },
        updateMode({commit}, val) {
            commit('setMode', val);
        },
        updateAmazonPay({commit}, val) {
            commit('setAmazonPay', val);
        },
        /**
         * /donate/にランディングした時の処理
         * クエリパラメータ、ログイン中のユーザー、対象の団体などの情報を初期値としてストア内に保持していく
         *
         * @param commit
         * @param dispatch
         * @param route
         * @returns {Promise<void>}
         */
        async onLandedDonationPage({dispatch}, route = {}) {
            await dispatch('updateDefaultRoute', route);
            await dispatch('setCurrentUserDataToDefaultInput');
            await dispatch('updateBodysourceWithBrowsingPortfolio');

            // 非分配型であるかつリダイレクト時でない場合のみ初期値を単発に
            const browsingPortfolio = await this.$db().model(Portfolio).getters('browsingPortfolio')(this);
            const amazonAccesstoken = _.get(route, 'query.access_token');
            if (browsingPortfolio.type === PORTFOLIO_TYPE.NON_DISTRIBUTABLE && !amazonAccesstoken) {
                await dispatch('updateDefaultInput', {
                    isRecurring: false,
                });
            }
        },
        /**
         * /donate/cardにランディングした時の処理
         * フォームと直接バインディングされているinputの中身を全てクリアする
         *
         * @param dispatch
         * @returns {Promise<void>}
         */
        onLandedDonationCardPage({dispatch}) {
            const clearedEntity = {
                card: initializeState().input.card,
            };

            dispatch('updateInput', clearedEntity);
        },
        /**
         * ランディング時のroute情報の更新と
         * それに伴う初期値の設定
         * @param commit
         * @param dispatch
         * @param route
         * @returns {Promise<void>}
         */
        async updateDefaultRoute({commit, dispatch}, route = {}) {
            await commit('setDefaultRoute', {
                name: route.name,
                params: route.params,
                query: route.query,
            });

            const query = route.query || {};
            // 各種クエリパラメーターによる初期値の設定やモードの切り替え
            // 今の所、全て併用で問題ない
            const queryRecurring = query.recurring;
            const queryAmount = query.amount;
            const queryAgency = query.agency;
            const queryLang = query.lang;
            const queryCampaignId = query.campaignId;
            const accessToken = query.access_token;

            if (queryLang === 'en') {
                await dispatch('layout/updateLang', 'en', {root: true});
            }

            const hasValidRecurring = (queryRecurring === 'true' || queryRecurring === true) ||
                (queryRecurring === 'false' || queryRecurring === false);
            const isSingle = !queryAgency && (queryRecurring === 'false' || queryRecurring === false);

            let paymentMethod;
            if (accessToken) {
                paymentMethod = {
                    payment_method: PAYMENT_METHOD.AMAZON_PAY,
                };

                commit('setAmazonPay', {
                    token: accessToken,
                });
            }

            await dispatch('updateDefaultInput', {
                amount: queryAmount ? Number(queryAmount) : '',
                isRecurring: !isSingle,
                ...paymentMethod,
            });
            commit('setBodySource', {
                agency_commission_code: queryAgency || '',
                campaignId: Number(queryCampaignId),
            });

            commit('setMode', {
                hideFrequency: !accessToken && (hasValidRecurring || !!queryAgency),
                isCampaign: !!Number(queryCampaignId),
            });
        },
        /**
         * ログイン中のユーザーのデータをdefaultInputに入れます。
         * ログインしていない場合は空が入ります。
         *
         * @param commit
         * @param dispatch
         * @param state
         * @returns {Promise<void>}
         */
        async setCurrentUserDataToDefaultInput({commit, dispatch, state}) {
            if (state.input.password) {
                // 寄付をして会員登録中なのでスキップ
                return;
            }

            const UserModel = this.$db().model(User);
            const UserPrivateProfileModel = this.$db().model(UserPrivateProfile);
            const PaymentCreditCardModel =this.$db().model(PaymentCreditCard);

            const user = await UserModel.getters('me')(this) || new UserModel();
            const privateProfile = user.private_profile || new UserPrivateProfileModel();
            const donor = privateProfile.getDonorInfo();
            const email = user.email;
            const card = user.user_credit_card || new PaymentCreditCardModel();

            await dispatch('updateDefaultInput', {
                donor: donor,
                email: email,
                card: card.getViewData(),
            });
            commit('setBodySource', {
                customer_id: card.customer_code,
            });
        },
        /**
         * 閲覧中のポートフォリオのデータでBodySourceを更新します。
         */
        async updateBodysourceWithBrowsingPortfolio({commit}) {
            const portfolio = await this.$db().model(Portfolio).getters('awaitGetter')(this)('id', Portfolio.getters('browsingId'));
            commit('setBodySource', {
                portfolio_id: portfolio.id,
            });
        },
        /**
         * ログイン中ユーザーのカードを更新して
         * 決済用のcustomer_idをbodySourceに入れます。
         * @param dispatch
         * @param commit
         * @param state
         * @returns {Promise<void>}
         */
        async updateCardForUser({dispatch, commit, state}) {
            await dispatch('entities/paymentCreditCards/updateInput', {
                card: state.input.card,
            }, {root: true});
            const card = await dispatch('entities/paymentCreditCards/updateCard', {}, {root: true});

            const cord = card.customer_code;
            commit('setInput', {
                card: card.getViewData(),
            });
            commit('setBodySource', {
                customer_id: cord,
            });
        },
        /**
         * 非ログインユーザーの場合、
         * 入力されたカード情報から、customer_idを生成してbodySourceに入れます
         * @param dispatch
         * @param commit
         * @param state
         * @returns {Promise<void>}
         */
        async createCustomerCodeForGuest({dispatch, commit, state}) {
            await dispatch('entities/paymentCreditCards/updateInput', {
                email: state.input.email,
                card: state.input.card,
            }, {root: true});
            const code = await dispatch('entities/paymentCreditCards/createCustomerCode', {}, {root: true});
            commit('setBodySource', {
                customer_id: code,
            });
        },
        /**
         * 入力情報をもとに、ユーザーの寄付者情報を更新します。
         * @param dispatch
         * @param state
         * @returns {Promise<void>}
         */
        async updateDonorForUser({dispatch, state}) {
            await dispatch('meSettingsDonor/init', {}, {root: true});
            await dispatch('meSettingsDonor/updateInput', state.input.donor, {root: true});
            await dispatch('meSettingsDonor/update', {}, {root: true});
            await dispatch('meSettingsDonor/reset', {}, {root: true});
        },
        /**
         * 次のステップに行きます。
         * @param commit
         * @param state
         * @param getters
         * @param rootGetters
         * @param dispatch
         * @returns {Promise<void>}
         */
        async goNextStep({commit, state, getters, rootGetters, dispatch}) {
            try {
                const isLoggedIn = rootGetters['auth/isLoggedIn'];
                const prePushCommon = async () => {
                    commit('setMode', {
                        isEdit: false,
                    });
                };
                const prePushOnIndex = async () => {
                    commit('setInputted', {
                        amount: state.input.amount,
                        payment_method: state.input.payment_method,
                        isRecurring: state.input.isRecurring,
                    });
                };
                const prePushOnDonor = async () => {
                    if (isLoggedIn) {
                        await dispatch('updateDonorForUser');
                    }
                    commit('setInputted', {
                        donor: state.input.donor,
                        email: state.input.email,
                    });
                };
                const prePushOnCardCancel = () => {
                    commit('setInput', state.inputted);
                };
                const prePushOnCardConfirm = async () => {
                    isLoggedIn ? await dispatch('updateCardForUser') : await dispatch('createCustomerCodeForGuest');
                    commit('setInputted', {
                        card: state.input.card,
                    });
                };
                const prePushOnCard = async () => {
                    const card = state.input.card;
                    if (_.every(card, i => i === '')) {
                        return prePushOnCardCancel();
                    }

                    return prePushOnCardConfirm();
                };

                const prePushFunctions = {
                    [LINK_NAME.PORTFOLIO.PORTFOLIO_ID.DONATE.INDEX]: prePushOnIndex,
                    [LINK_NAME.PORTFOLIO.PORTFOLIO_ID.DONATE.DONOR]: prePushOnDonor,
                    [LINK_NAME.PORTFOLIO.PORTFOLIO_ID.DONATE.CARD]: prePushOnCard,
                };

                const prePush = prePushFunctions[this.app.context.route.name];
                if (!_.isFunction(prePush)) {
                    return;
                }
                await prePush();
                await prePushCommon();
                this.$router.push({
                    ...ROUTER_TO[getters['nextLinkName']],
                    ...getters['routerOptions'],
                });
            } catch (e) {
                if (e instanceof CardUpdatingCanceledError) {
                    return;
                }

                this.$alert({
                    message: (e && e.message) || this.app.i18n.t('error.general'),
                });
            }
        },
        /**
         * 寄付します。
         * @param commit
         * @param state
         * @param dispatch
         * @param rootGetters
         * @param getters
         * @param payload
         * @returns {Promise<void>}
         */
        async donate({commit, state, dispatch, rootGetters, getters}, payload = {}) {
            const executor = async () => {
                if (!rootGetters['auth/isLoggedIn']) {
                    await dispatch('preDonationForGuest');
                }
                if (state.input.isRecurring) {
                    await dispatch('createRecurringDonation');
                    return;
                }

                await dispatch('createOnetimeDonation');
            };

            try {
                commit('setRecaptchaToken', payload.recaptchaToken);
                await executor();

                const linkName = LINK_NAME.PORTFOLIO.PORTFOLIO_ID.CONTRIBUTE.MESSAGE;

                let routerOptions = {
                    ...ROUTER_TO[linkName],
                    ...getters['routerOptions'],
                };
                routerOptions.query = {
                    ...routerOptions.query,
                };

                this.$router.push(routerOptions);
            } catch (e) {
                dispatch('refreshAmazonPayAddressBookWidget');
                throw e;
            }
        },
        /**
         * 非ログインユーザー用の寄付の前の処理
         * @param dispatch
         * @param state
         * @returns {Promise<void>}
         */
        async preDonationForGuest({dispatch, state}) {
            if (state.inputted.payment_method === PAYMENT_METHOD.AMAZON_PAY) {
                await dispatch('preDonationForAmazonGuest');
                return;
            }

            await dispatch('preDonationForCardGuest');
        },
        /**
         * amazon payを使用しない非ログインユーザー用の寄付の前の処理
         * @param state
         * @param dispatch
         * @returns {Promise<void>}
         */
        async preDonationForCardGuest({state, dispatch}) {
            if (!state.input.password) {
                // 会員登録しない
                // customer_idをセット
                await dispatch('createCustomerCodeForGuest');
                return;
            }
            // 会員登録する
            await dispatch('auth/updateInput', {
                email: state.input.email,
                password: state.input.password,
            }, {root: true});
            await dispatch('auth/registerAndLogin', {}, {root: true});
            await Promise.all([
                dispatch('updateDonorForUser'), // 寄付者情報を登録
                dispatch('updateCardForUser'), // カード情報を登録
            ]);
        },

        /**
         * amazon payを使用する非ログインユーザー用の寄付の前の処理
         *
         * - パスワードが入力された時、会員登録をする
         * - パスワードが入力された時、会員登録をしない
         *
         * @param state
         * @param dispatch
         * @returns {Promise<void>}
         */
        async preDonationForAmazonGuest({state, dispatch}) {
            if (!state.input.password) {
                return;
            }

            const {PrimaryEmail} = await this.$AmazonPay.retrieveProfile(state.amazonPay.token);
            dispatch('updateDefaultInput', {
                email: PrimaryEmail,
            });
            await dispatch('auth/updateInput', {
                email: state.input.email,
                password: state.input.password,
            }, {root: true});
            await dispatch('auth/registerAndLogin', {}, {root: true});
            await Promise.all([
                dispatch('updateDonorForUser'), // 寄付者情報を登録
                dispatch('updateCardForUser'), // カード情報を登録
            ]);
        },

        /**
         * 年会費会員
         * @param commit
         * @param getters
         * @returns {Promise<void>}
         */
        async createAnnualDonation({commit, getters}) {
            const data = await this.$axios.$post('/donation/recurring/annual/', getters['requestBody']);

            commit('setDonationMessage', data.portfolio_donations[0].donation_message);
        },
        /**
         * 毎月寄付
         * @param state
         * @param commit
         * @param getters
         * @returns {Promise<void>}
         */
        async createRecurringDonation({state, commit, getters}) {
            const endpoint = state.inputted.payment_method === PAYMENT_METHOD.AMAZON_PAY ? '/donation/recurring/portfolio/amazon_pay/' : '/donation/recurring/portfolio/';
            const data = await this.$axios.$post(endpoint, getters['requestBody']);

            commit('setDonationMessage', data.portfolio_donations[0].donation_message);

            if (data.campaign) {
                await this.$db().model(Campaign).insertOrUpdate({
                    data: data.campaign,
                });
            }
        },
        /**
         * 単発寄付
         * @param commit
         * @param getters
         * @param state
         * @returns {Promise<void>}
         */
        async createOnetimeDonation({commit, getters, state}) {
            const endpoint = state.inputted.payment_method === PAYMENT_METHOD.AMAZON_PAY ? '/donation/portfolio/amazon_pay/' : '/donation/portfolio/';
            const data = await this.$axios.$post(endpoint, getters['requestBody']);

            commit('setDonationMessage', data.donation_message);

            if (data.campaign) {
                await this.$db().model(Campaign).insertOrUpdate({
                    data: data.campaign,
                });
            }
        },

        refreshAmazonPayAddressBookWidget({commit}) {
            commit('setAmazonPay', {
                isAddressBookTobeRefreshed: true,
            });
        },
    },

    getters: {
        /**
         * 次にいくべきページのnameを返します。
         * @param state
         * @param getters
         * @param rootState
         * @param rootGetters
         * @returns {*}
         */
        nextLinkName(state, getters, rootState, rootGetters) {
            let isAmountInputted = state.valid.amount;
            let isCardInputted = state.bodySource.customer_id || state.valid.card;

            // if logged in, we just check existences
            // if not, check strictly
            const isLoggedIn = rootGetters['auth/isLoggedIn'];
            let isDonorInputted = isLoggedIn ? _.every(state.input.donor, (v, key) => {
                if (key === 'address2') {
                    // 必須項目ではないもの
                    return true;
                }
                return !!v;
            }) : state.valid.donor;

            const returnAmountPage = () => {
                return LINK_NAME.PORTFOLIO.PORTFOLIO_ID.DONATE.INDEX;
            };
            if (!isAmountInputted) {
                return returnAmountPage();
            }

            if (state.input.payment_method === PAYMENT_METHOD.AMAZON_PAY) {
                // amazon payの場合は、カードと寄付者情報の入力は不要
                // 金額は必須
                if (state.amazonPay.token) {
                    return LINK_NAME.PORTFOLIO.PORTFOLIO_ID.DONATE.CONFIRMATION;
                }
                return returnAmountPage();
            }
            if (!isDonorInputted) {
                return LINK_NAME.PORTFOLIO.PORTFOLIO_ID.DONATE.DONOR;
            }
            if (!isCardInputted) {
                return LINK_NAME.PORTFOLIO.PORTFOLIO_ID.DONATE.CARD;
            }

            return LINK_NAME.PORTFOLIO.PORTFOLIO_ID.DONATE.CONFIRMATION;
        },
        routerOptions(state) {
            return {
                params: state.defaultRoute.params,
                query: state.defaultRoute.query,
            };
        },
        /**
         * POSTの時のbody
         */
        requestBody(state, getters, rootState) {
            const confirmationInput = {
                message: state.input.message,
                need_receipt: state.input.need_receipt,
                need_news_letter: state.input.need_news_letter,
            };
            let commonFields = {
                ...confirmationInput,
                type: DONATION_TYPE.PORTFOLIO,
                portfolio_id: state.bodySource.portfolio_id,
                amount: state.inputted.amount,
                recaptcha_token: state.recaptchaToken,
                lang: rootState.layout.lang,
            };
            if (state.mode.isCampaign) {
                // キャンペーンのみのパラメータ
                commonFields = {
                    ...commonFields,
                    campaign_id: state.bodySource.campaignId,
                    type: DONATION_TYPE.CAMPAIGN,
                };
            }
            if (state.inputted.isRecurring) {
                commonFields = {
                    ...commonFields,
                    agency_commission_code: state.bodySource.agency_commission_code,
                };
            }
            if (state.inputted.payment_method === PAYMENT_METHOD.AMAZON_PAY) {
                // amazon pay
                // donor情報はサーバーサイドで取得
                return {
                    ...commonFields,
                    payment_method: PAYMENT_METHOD.AMAZON_PAY,
                    amazon_pay: {
                        amazon_reference_id: state.amazonPay.amazonReferenceId,
                        token: state.amazonPay.token,
                    },
                };
            }
            // amazon pay以外(今はomiseのみ)
            commonFields = {
                ...commonFields,
                payment_method: state.inputted.payment_method,
                customer_id: state.bodySource.customer_id,
            };
            const donor = {
                ...state.inputted.donor,
                email: state.inputted.email,
            };

            if (state.inputted.isRecurring) {
                // 継続と単発で寄付者情報の入れ方が違うので注意
                return {
                    ...commonFields,
                    ...donor,
                };
            }
            return {
                ...commonFields,
                donor: donor,
            };
        },
        donationMessage(state) {
            return state.donationMessage;
        },
    },
};
