import {Model} from '@vuex-orm/core';
import AssociateBusinessMainVisual from './AssociateBusinessMainVisual';
import AssociateBusinessActivityImage from './AssociateBusinessActivityImage';
import AssociateBusinessCostImage from './AssociateBusinessCostImage';
import AssociateBusinessPurposeImage from './AssociateBusinessPurposeImage';
import AssociateBusinessResultImage from './AssociateBusinessResultImage';
import {PUBLISHING_STATUS} from '../../../syncable-commons-consts/enums';

export default class AssociateBusiness extends Model {
    static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            purpose: this.string(''),
            cost: this.string(''),
            activity: this.string(''),
            result: this.string(''),
            publishing_status: this.number(PUBLISHING_STATUS.UNPUBLISHED),
            main_visual: this.hasOne(AssociateBusinessMainVisual, 'business_id'),
            activity_images: this.hasMany(AssociateBusinessActivityImage, 'business_id'),
            cost_images: this.hasMany(AssociateBusinessCostImage, 'business_id'),
            purpose_images: this.hasMany(AssociateBusinessPurposeImage, 'business_id'),
            result_images: this.hasMany(AssociateBusinessResultImage, 'business_id'),

            associate: this.attr(null),
            associate_id: this.attr(null),
        };
    }

    get images() {
        return {
            purpose: this.purpose_images.map(image => image.file),
            activity: this.activity_images.map(image => image.file),
            result: this.result_images.map(image => image.file),
            cost: this.cost_images.map(image => image.file),
        };
    }
}

AssociateBusiness.entity = 'associateBusinesses';
