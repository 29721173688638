import {Model} from '@vuex-orm/core';
import CampaignReportMainVisual from './CampaignReportMainVisual';
import Campaign from './Campaign';

export default class CampaignReport extends Model {
    static entity = 'campaignReports';

    static fields() {
        return {
            id: this.number(null),
            title: this.string(''),
            body: this.string(''),
            main_visual: this.hasOne(CampaignReportMainVisual, 'report_id'),

            campaign: this.belongsTo(Campaign, 'campaign_id'),
            campaign_id: this.number(null),

            created_at: this.string(null),
            updated_at: this.string(null),
        };
    }
}
