<template lang="pug">
    v-btn.base-button(
        :class='classes'
        :style='styles'
        nuxt
        :to="overrideTo"
        :href="overrideHref"
        :target="overrideTarget"
        depressed
        :block="block"
        :large="large"
        :small="small"
        :loading="loading"
        :disabled="disabled"
        :flat="text"
        :icon="icon"
        :round="round"
        @click="click"
    )
        base-icon.base-button-prepend-icon(v-if="prependIcon" :icon="prependIcon")
        slot
</template>

<script>
import BaseIcon from './BaseIcon';
import {isNextDeployedRoute} from '../../syncable-commons-consts/partial-deployment';

export default {
    name: 'BaseButton',
    components: {BaseIcon},
    props: {
        /**
         * 通常遷移
         */
        to: Object,
        /**
         * 別タブ遷移
         */
        href: String,
        target: String,
        inverted: Boolean,
        block: Boolean,
        large: Boolean,
        small: Boolean,
        xSmall: Boolean,
        loading: Boolean,
        disabled: Boolean,
        circle: Boolean,
        white: Boolean,
        red: Boolean,
        negative: Boolean,
        highlighted: Boolean,
        text: Boolean,
        twitter: Boolean,
        facebook: Boolean,
        width: String,
        icon: Boolean,
        /**
         * 角を丸くするか（border-radius）
         */
        round: Boolean,
        bold: Boolean,
        /**
         * v-btnのmarginを消す。(いつか作り直す...)
         */
        marginZero: Boolean,
        prependIcon: String,
    },
    computed: {
        classes() {
            return {
                'base-button--inverted': this.inverted,
                'base-button--circle': this.circle,
                'base-button--text': this.text,
                'base-button--white': this.white,
                'base-button--red': this.red,
                'base-button--negative': this.negative,
                'base-button--highlighted': this.highlighted,
                'base-button--twitter': this.twitter,
                'base-button--facebook': this.facebook,
                'base-button--x-small': this.xSmall,
                'base-button--bold': this.bold,
                'base-button--margin-zero': this.marginZero,
            };
        },
        styles() {
            return {
                width: this.width || '',
            };
        },
        shouldBePlainAnchor() {
            return this.to && typeof this.to === 'object' && isNextDeployedRoute(this.to);
        },
        overrideTo() {
            if (this.shouldBePlainAnchor) {
                return void 0;
            }
            return this.to;
        },
        overrideHref() {
            if (this.shouldBePlainAnchor) {
                return this.$router.resolve(this.to).href;
            }
            return this.href;
        },
        overrideTarget() {
            if (this.shouldBePlainAnchor) {
                return void 0;
            }
            return this.target;
        },
    },
    methods: {
        click(e) {
            this.$emit('click', e);
        },
    },
};
</script>

<style scoped lang="scss">
    .base-button {
        border-radius: 4px;

        &-prepend-icon {
            position: absolute;
            left: 20px;
        }

        &:not(.base-button--circle):not(.btn--icon) {
            min-width: calc-rem(88px); // vw=750pxの時にvuetify defaultの88pxとなるようにした
        }

        &.base-button--circle {
            margin: 0;
            min-width: 70px;
            width: 70px;
            min-height: 70px;
            height: 70px;
            border-radius: 50%;
            box-shadow: 2px 2px 8px 0 rgba(55, 55, 55, 0.25);

            &.btn--small {
                min-width: 50px;
                width: 50px;
                min-height: 50px;
                height: 50px;
            }

            &.btn--large {
                @include mq(md-and-up) {
                    min-width: 120px;
                    width: 120px;
                    min-height: 120px;
                    height: 120px;
                }
            }
        }
    }
</style>
<style lang="scss">
    // override vuetify
    .base-button {
        &:not(.btn--icon) {
            &.btn {
                .btn__content {
                    font-size: map-get(map-get($text, 2), fs);
                    line-height: map-get(map-get($text, 2), lh);
                    font-weight: bold;
                }

                &.btn--small {
                    .btn__content {
                        font-size: map-get(map-get($text, 3), fs);
                        line-height: map-get(map-get($text, 3), lh);
                    }
                }

                &.btn--depressed {
                    .btn__content {
                        text-align: center;
                        padding: 14px;
                    }

                    &.btn--small {
                        .btn__content {
                            padding: 8px 10px;
                        }
                    }
                }

                &.btn--flat {
                    &.base-button {
                        &--text {
                            width: auto;
                            margin: 0;
                            min-width: inherit;
                            text-transform: none;

                            .btn__content {
                                padding: 0;
                            }
                        }

                        &--highlighted {
                            color: $c-button !important;
                        }
                    }
                }
            }
        }
    }

    @mixin set-color($bgc,$color,$border-color) {
        background-color: $bgc !important;
        color: $color !important;
        border: 1px solid $border-color;
    }

    // vuetifyのスタイルをグローバルで上書きなど
    .base-button.btn.btn--depressed {
        .btn__content {

            white-space: normal;
            width: 100%;

            .base-text {
                font-weight: bold;
                color: inherit;
            }

            .base-icon {
                font-weight: bold;
                color: inherit;
            }
        }

        &:not(.btn--icon) {
            text-transform: none !important;
            background-color: $c-button !important;
            color: $c-button-text !important;

            &.base-button {
                &.btn--disabled {
                  @include set-color(#8ED5C5, $c-button-text, #8ED5C5);
                }

                &--inverted {
                    @include set-color($c-button-text, $c-button, $c-button);

                    &.base-button--circle {
                        border: 1px solid $c-bg !important;
                    }
                }

                &--facebook {
                    @include set-color($c-button-facebook, $c-button-text-facebook, $c-button-facebook);
                }

                &--twitter {
                    @include set-color($c-button-twitter, $c-button-text-twitter, $c-button-twitter);
                }

                &--white {
                    @include set-color($c-button-white, $c-button-text-white, $c-border);
                    box-shadow: 0 0 3px 0 rgba(55, 55, 55, 0.15);
                }

                &--red {
                    @include set-color($c-selection-control-error, $c-text-light-bg, $c-border);
                    box-shadow: 0 0 3px 0 rgba(55, 55, 55, 0.15);

                    &.btn--disabled {
                        @include set-color(#EDB7B5, $c-button-text, #e2e8f0);
                    }
                }

                &--negative {
                    @include set-color($c-button-negative, $c-button-text-negative, $c-button-negative);
                }

                &--highlighted {
                    @include set-color($c-button-text, $c-button, $c-border);
                    box-shadow: 0 0 3px 0 rgba(55, 55, 55, 0.15) !important;

                    &.btn--disabled {
                        @include set-color($c-button-text, $c-border, $c-border);

                        &.base-button--circle {
                            background-color: $c-bg !important;

                            .base-circle-text, .base-icon {
                                color: $c-button-text-disabled;
                            }
                        }
                    }
                }

                &--x-small {
                    $x-small-size: 40px;
                    min-width: $x-small-size;
                    width: $x-small-size;
                    min-height: $x-small-size;
                    height: $x-small-size;

                    .btn__content .base-circle-button-content {
                        .base-text, .base-icon {
                            font-size: map-get(map-get($text, 4), fs);
                            line-height: map-get(map-get($text, 4), lh);
                        }
                    }
                }

                &--bold {
                    font-weight: bold;
                }

                &--margin-zero {
                    margin: 0;
                }
            }
        }
    }
</style>
