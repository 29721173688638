import {Model} from '@vuex-orm/core';
import Associate from './Associate';
import {
    ASSOCIATE_SOCIAL_CHALLENGE_VALUE,
    ASSOCIATE_SOCIAL_CHALLENGE_VALUE_STRING,
    ASSOCIATE_SOCIAL_CHALLENGE_VALUE_TEXT,
} from '../../../syncable-commons-consts/enums';

export default class PortfolioSocialChallenge extends Model {
    static fields() {
        return {
            id: this.number(null),
            ratio: this.number(null),
            value: this.number(ASSOCIATE_SOCIAL_CHALLENGE_VALUE.OTHERS),

            created_at: this.string(''),
            updated_at: this.string(''),

            revision_id: this.attr(null),
        };
    }

    static newInstance() {
        return new PortfolioSocialChallenge({
            associate: Associate.newInstance(),
        });
    }

    get text() {
        return ASSOCIATE_SOCIAL_CHALLENGE_VALUE_TEXT[this.value];
    }

    get valueString() {
        return ASSOCIATE_SOCIAL_CHALLENGE_VALUE_STRING[this.value];
    }
}

PortfolioSocialChallenge.entity = 'portfolioSocialChallenges';
