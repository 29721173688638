import { render, staticRenderFns } from "./LayoutImageLightBox.vue?vue&type=template&id=2199f1db&scoped=true&lang=pug&"
import script from "./LayoutImageLightBox.vue?vue&type=script&lang=js&"
export * from "./LayoutImageLightBox.vue?vue&type=script&lang=js&"
import style0 from "./LayoutImageLightBox.vue?vue&type=style&index=0&id=2199f1db&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2199f1db",
  null
  
)

export default component.exports