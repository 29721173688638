import {Model} from '@vuex-orm/core';

export default class AssociateLogo extends Model {
    static fields() {
        return {
            id: this.number(null),
            file: this.attr(''),

            associate: this.attr(null),
        };
    }

    static newInstance() {
        return new AssociateLogo();
    }
}

AssociateLogo.entity = 'associateLogos';
