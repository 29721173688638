import _ from 'lodash';

class LayoutHelper {
    /**
     * vuecomponentからgenerateAndSetDataUrlのメソッドを持つコンポーネントを探して実行します.
     *
     * @param children vuecomponent
     * @param depth
     */
    static searchAndExecuteImage(children, depth = 0) {
        _.each(children, child => {
            if (_.isFunction(child.generateAndSetDataUrl)) {
                return child.generateAndSetDataUrl();
            }
            LayoutHelper.searchAndExecuteImage(child.$children, depth + 1);
        });
    }

    /**
     * 1rem=32pxの場合rootPxをremにして返します。
     * @param rootPx
     * @param rem
     * @returns {string}
     */
    static calcRemFromPx(rootPx, rem = 32) {
        return `${rootPx / rem}rem`;
    }

    /**
     * クリップボードコピー関数
     * 入力値をクリップボードへコピーする
     */
    static copyTextToClipboard(textVal) {
        const selectTextArea = (domElement, userAgent) => {
            if (userAgent.match(/ipad|ipod|iphone/i)) {
                let range = document.createRange();
                range.selectNode(domElement);
                window.getSelection().addRange(range);
                return;
            }

            domElement.select();
        };

        // テキストエリアを用意する
        let copyFrom = document.createElement('textarea');
        // テキストエリアへ値をセット
        copyFrom.textContent = textVal;

        // bodyタグの要素を取得
        let bodyElm = document.getElementsByTagName('body')[0];
        // 子要素にテキストエリアを配置
        bodyElm.appendChild(copyFrom);

        // テキストエリアの値を選択
        selectTextArea(copyFrom, navigator.userAgent);
        // コピーコマンド発行
        let retVal = document.execCommand('copy');
        // 追加テキストエリアを削除
        bodyElm.removeChild(copyFrom);
        // 処理結果を返却
        return retVal;
    }

    static getBase64FromUrl(url) {
        return new Promise(resolve => {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
            xhr.addEventListener('load', () => {
                let reader = new FileReader();
                reader.readAsDataURL(xhr.response);
                reader.addEventListener('loadend', () => {
                    resolve(reader.result);
                });
            });
        });
    }
}

export default LayoutHelper;
