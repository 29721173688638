<template lang="pug">
    .error-page.px-15
        base-logo.error-page-logo.pb-20(vertical)
        base-heading(level="1") Sorry...{{emoji}}
        base-heading(level="1") {{ error.statusCode }}
        base-text(level="1" v-html="error.message")
        base-button(
            @click="toHome"
            :loading="loading"
        ) ホームへ
</template>
<script>
import BaseLogo from '../../syncable-commons-components/atoms/BaseLogo';
import BaseHeading from '../../syncable-commons-components/atoms/BaseHeading';
import BaseText from '../../syncable-commons-components/atoms/BaseText';
import BaseButton from '../../syncable-commons-components/atoms/BaseButton';

export default {
    components: {BaseButton, BaseText, BaseHeading, BaseLogo},
    props: {
        error: {
            type: Object,
            required: true,
        },
    },
    layout: 'vanilla',
    data() {
        const emojiMap = {
            1: '😞‍',
            2: '🙇‍',
            3: '🙊‍',
            4: '😖',
            5: '😣',
            6: '😿',
            7: '🙀',
            8: '🐶',
            9: '😞',
        };
        return {
            emoji: emojiMap[Math.round(Math.random() * 10)] || '😞',
            loading: false,
        };
    },
    methods: {
        toHome() {
            this.loading = true;
            location.replace('/');
        },
    },
};
</script>

<style scoped lang="scss">
    .error-page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    // ビルドした後コンポーネント内のcssに上書きされるのを防ぐために詳細度を上げている
    img.error-page-logo {
        height: auto;
        width: 50vw;

        @include mq(md-and-up) {
            width: 300px;
        }
    }
</style>
