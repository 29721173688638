import _ from 'lodash';

export default async function ({app, store}) {
    const showLoading = (config) => {
        if (_.get(config, 'ext.longtime', false)) {
            store.dispatch('layout/updateLoading', true, {root: true});
        }
    };

    const hideLoading = () => {
        store.dispatch('layout/updateLoading', false, {root: true});
    };

    app.$axios.onRequest(showLoading);

    app.$axios.onResponse(hideLoading);

    app.$axios.onError(hideLoading);
}
