<template lang="pug">
    v-footer.layout-footer(
    height="auto"
    )
        v-layout(column)
            .sections(v-if="showFooter")
                section.footer-section
                    header
                        base-heading(level='3') 団体を見つける
                        hr.footer-section-divider
                    ul.ul
                        li.li
                            base-text(level='3' :to="links.find_assoc_from_challenge") 社会課題から探す
                        li.li
                            base-text(level='3' :to="links.find_assoc_from_index") すべての団体から探す
                        li.li
                            base-text(level='3' :to="links.campaign") キャンペーンを探す
                section.footer-section
                    header
                        base-heading(level='3') Syncableについて
                        hr.footer-section-divider
                    ul.ul
                        li.li
                            base-text(level='3' :href="links.to_associate" target="_blank") 掲載希望団体の方へ
                        li.li
                            base-text(level='3' :href="links.company" target="_blank") 運営会社

                        li.li.narrow-only
                            base-text(level='3' :to="links.privacy") プライバシーポリシー
                        li.li.narrow-only
                            base-text(level='3') 利用規約（
                                base-text(:to="links.terms" level='3' inline) 一般の方
                                |  /
                                base-text(:to="links.terms_associate" level='3' inline) 掲載団体
                                | ）
                        li.li.narrow-only
                            base-text(level='3' :to="links.law") 特定商取引法に基づく表記
                section.footer-section
                    header
                        base-heading(level='3') お問い合わせ
                        hr.footer-section-divider
                    ul.ul
                        li.li
                            base-text(level='3' :href="links.faq" target="_blank") よくある質問
                        li.li
                            base-text(level='3' :href="links.inquiry" target="_blank") お問い合わせ
                section.footer-section
                    header
                        base-heading(level='3') 関連サービス
                        hr.footer-section-divider
                    ul.ul
                        li.li
                            base-text(level='3' :href="links.unveil" target="_blank") UNVEIL
                        li.li
                            base-text(level='3' :href="links.brandPledge" target="_blank") BRAND PLEDGE

            .bottom-line
                a(href="/")
                    base-logo
                .copyright
                    base-text(level="4" center) © 2018 STYZ Inc. All right reserved.
                .terms
                    base-text(level='4' :to="links.privacy") {{$t('label.privacy')}}
                    base-text(level='4') {{$t('label.terms')}}（
                        base-text(:to="links.terms" level='4' inline) {{$t('label.footerCustomer')}}
                        |  /
                        base-text(:to="links.terms_associate" level='4' inline) {{$t('label.footerAssociation')}}
                        | ）
                    base-text(level='4' :to="links.law") {{$t('label.specifiedCommercialTransactionLaw')}}
</template>

<script>
import {LINK_NAME, LINK_URL, ROUTER_TO} from '../../consts/links';
import {mapState} from 'vuex';

import BaseText from '../../../syncable-commons-components/atoms/BaseText';
import BaseHeading from '../../../syncable-commons-components/atoms/BaseHeading';
import BaseLogo from '../../../syncable-commons-components/atoms/BaseLogo';

export default {
    name: 'LayoutFooter',
    components: {BaseLogo, BaseText, BaseHeading},
    computed: {
        ...mapState({
            showFooter: state => state.layout.footer.show,
        }),
        links() {
            return {
                'index': ROUTER_TO[LINK_NAME.INDEX],
                'find_assoc_from_index': ROUTER_TO[LINK_NAME.EXPLORE.ASSOCIATE.SOCIAL_CHALLENGE.SOCIAL_CHALLENGE_SLUG],
                'find_assoc_from_challenge': ROUTER_TO[LINK_NAME.EXPLORE.ASSOCIATE.INDEX],
                'campaign': ROUTER_TO[LINK_NAME.CAMPAIGN.INDEX],
                'portfolio': ROUTER_TO[LINK_NAME.PORTFOLIO.INDEX],
                'to_associate': LINK_URL.ASSOCIATE,
                'faq': LINK_URL.FAQ,
                'inquiry': LINK_URL.INQUIRY,
                'terms': ROUTER_TO[this.$i18n.locale === 'ja' ? LINK_NAME.TERMS : LINK_NAME.TERMS_EN],
                'terms_associate': ROUTER_TO[LINK_NAME.TERMS_ASSOCIATE],
                'privacy': ROUTER_TO[this.$i18n.locale === 'ja' ? LINK_NAME.PRIVACY : LINK_NAME.PRIVACY_EN],
                'law': ROUTER_TO[LINK_NAME.LAW],
                'company': LINK_URL.COMPANY,
                'unveil': process.env.UNVEIL_WEB_BASE_URL,
                'brandPledge': process.env.PLEDGE_WEB_BASE_URL,
            };
        },
    },
};
</script>

<style scoped lang="scss">
    .layout-footer {
        background-color: $c-footer !important;
        margin-bottom: 0;

        .sections {
            padding: 16px;
            display: flex;
            flex-direction: column;
            @include mq(md-and-up) {
                flex-direction: row;
                justify-content: space-between;
                width: 900px;
                margin: 0 auto;
            }

            .footer-section {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-top: 30px;

                @include mq(md-and-up) {
                    margin-top: 0;
                }

                &--wide-only {
                    display: none;
                    @include mq(md-and-up) {
                        display: flex;
                        flex-direction: column;
                    }
                }

                .footer-section-divider {
                    width: 20%;
                    border-width: 1px 0 0 0;
                    border-style: solid;
                    border-color: $c-primary;
                    margin-top: 15px;
                    margin-bottom: 5px;
                    @include mq(md-and-up) {
                        width: 100%;
                        border-width: 2px 0 0 0;
                        margin: 0;
                    }
                }

                .wide-only {
                    display: none;
                    @include mq(md-and-up) {
                        display: block;
                    }
                }

                .narrow-only {
                    display: block;
                    @include mq(md-and-up) {
                        display: none;
                    }
                }

                .ul {
                    margin-top: 10px;
                }

                .li {
                    padding: 0.15em 0;
                    list-style-type: none;
                    @include mq(md-and-up) {
                        list-style-type: disc;
                        margin-left: 1em;
                    }
                }
            }
        }

        .bottom-line {
            height: 58px;
            display: flex;
            justify-content: center;
            align-items: center;

            .copyright {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 30px;
            }

            .terms {
                display: none;

                @include mq(md-and-up) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 60px;

                    & > *:not(:first-child) {
                        margin-left: 15px;
                    }
                }
            }
        }
    }
</style>
