import {Model} from '@vuex-orm/core';
import Campaign from './Campaign';
import CampaignUsageImage from './CampaignUsageImage';

export default class CampaignUsage extends Model {
    static fields() {
        return {
            id: this.attr(null),
            price: this.number(null),
            title: this.string(''),
            message: this.string(''),

            image: this.hasOne(CampaignUsageImage, 'usage_id'),

            campaign: this.belongsTo(Campaign, 'campaign_id'),
            campaign_id: this.attr(null),
        };
    }

    toPostableJson() {
        return Object.assign({
            id: this.id,
            price: this.price,
            title: this.title,
            message: this.message,
            image: this.image ? this.image.toPostableJson() : undefined,

            campaign: this.campaign ? this.campaign.id : this.campaign_id,
        });
    }
}

CampaignUsage.entity = 'campaignUsages';
