<template lang="pug">
    v-dialog(
    v-model="dialog"
    :max-width="options.width"
    @keydown.esc="cancel()"
    :persistent="!isAlert"
    scrollable
    )
        v-card
            v-card-title
                base-heading(level="3") {{title}}
            v-card-text
                base-text(
                v-show="!!message"
                level="3"
                v-html="$options.filters.html(message)"
                )
            v-card-actions
                v-spacer
                base-button-group
                    base-button(
                    v-show="!isAlert"
                    block
                    white
                    width="130px"
                    @click.native="cancel"
                    :loading="loading"
                    ) Cancel
                    base-button(
                    block
                    @click.native="agree"
                    :loading="loading"
                    ) OK
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import BaseHeading from '../atoms/BaseHeading';
import BaseButton from '../atoms/BaseButton';
import BaseText from '../atoms/BaseText';
import BaseButtonGroup from '../atoms/BaseButtonGroup';

/**
     * layoutに配置してください。
     * this.$alertとthis.$confirmが使えるようになります。
     */
export default {
    name: 'BaseLayoutDialog',
    components: {BaseButtonGroup, BaseText, BaseButton, BaseHeading},
    data() {
        return {
            dialog: false,
            loading: false,
            resolve: null,
            reject: null,
            message: null,
            title: null,
            isAlert: false, // OKボタンのみ表示
            onAgree: () => {
            },
            options: {
                width: 400,
            },
        };
    },
    watch: {
        dialog(val) {
            if (this.isAlert && !val) {
                // alertの場合閉じたら必ずresolveする
                // (ダイアログの外側をクリックして閉じた場合に検知できないため)
                this.resolve(true);
            }
        },
    },
    beforeCreate() {
        Vue.prototype.$alert = this.$store.$alert = (payload = {}) => {
            return this.open({
                ...payload,
                alert: true,
            });
        };
        Vue.prototype.$confirm = this.$store.$confirm = (payload = {}) => {
            return this.open({
                ...payload,
                alert: false,
            });
        };
    },
    methods: {
        open({title, message, alert, onAgree, options}) {
            this.dialog = true;
            this.title = title;
            this.message = message;
            this.isAlert = alert;
            this.onAgree = onAgree;
            this.options = Object.assign(this.options, options);
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        async _agree() {
            if (this.isAlert) {
                // alertはdialogのwatchでresolveする
                return;
            }
            if (_.isFunction(this.onAgree)) {
                this.loading = true;
                await this.onAgree().finally(() => (this.loading = false));
            }
            this.resolve(true);
        },
        async agree() {
            this._agree().finally(() => (this.dialog = false));
        },
        cancel() {
            if (this.isAlert) {
                this.agree();
                return;
            }
            this.reject(false);
            this.dialog = false;
        },
    },
};
</script>

<style scoped>

</style>
