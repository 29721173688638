<template lang="pug">
    base-button(
    block
    large
    facebook
    @click="click"
    prepend-icon="facebook"
    )
        base-text Facebook
</template>

<script>
import BaseButton from '../atoms/BaseButton';
import BaseText from '../atoms/BaseText';

export default {
    name: 'BaseButtonFacebook',
    components: {BaseText, BaseButton},
    methods: {
        click(e) {
            this.$emit('click', e);
        },
    },
};
</script>

<style scoped>

</style>