<template lang="pug">
    v-app.syncable-app.application.theme--light(:class="{'syncable-app-loading': loading}")
        loading-mask(v-if="loading")
        slot
</template>

<script>
import {mapGetters} from 'vuex';
import LoadingMask from '../../syncable-commons-components/molecules/LoadingMask';

export default {
    name: 'SyncableApp',
    components: {LoadingMask},
    computed: {
        ...mapGetters('layout', ['loading']),
    },
};
</script>

<style lang="scss">
    /**
    ここのスタイルは意図的にscopedではなくしています。
    全体に関わるvuetifyのスタイルを上書うわがいたりして下さい。
     !important; はvuetifyのスタイルを上書きする場合にしか使いません
     */
    .syncable-app {
        &.application {
            &.theme--light {
                background-color: $c-bg !important;
            }

            font-family: $f-default !important;
            line-height: 1 !important;
        }

        &-loading {
            height: 100vh;
            overflow: hidden;
        }
    }
</style>
