import _ from 'lodash';
import {Model} from '@vuex-orm/core';
import AssociateVisionUseForDonationImage from './AssociateVisionUseForDonationImage';
import AssociateVisionChallengeImage from './AssociateVisionChallengeImage';
import AssociateVisionChallengeReasonImage from './AssociateVisionChallengeReasonImage';

export default class AssociateVision extends Model {
    static fields() {
        return {
            id: this.attr(null),
            vision: this.string(null),

            associate: this.attr(null),

            use_for_donation: this.string(''),
            challenge: this.string(''),
            challenge_reason: this.string(''),

            use_for_donation_images: this.hasMany(AssociateVisionUseForDonationImage, 'vision_id'),
            challenge_images: this.hasMany(AssociateVisionChallengeImage, 'vision_id'),
            challenge_reason_images: this.hasMany(AssociateVisionChallengeReasonImage, 'vision_id'),
        };
    }

    static newInstance() {
        return new AssociateVision();
    }

    /**
     * 詳細取得時に含むkey
     * @returns {Array}
     */
    static get detailKeys() {
        return [
            'use_for_donation',
            'challenge',
            'challenge_reason',
        ];
    }

    /**
     * 詳細を取得済みかどうか
     * @returns {*|{verify}}
     */
    isFetched() {
        // 一つでもtrueならokとする
        return _.some(AssociateVision.detailKeys, k => !!this[k]);
    }

    get images() {
        return {
            'challenge': this.challenge_images.map(image => image.file),
            'challenge_reason': this.challenge_reason_images.map(image => image.file),
            'use_for_donation': this.use_for_donation_images.map(image => image.file),
        };
    }
}

AssociateVision.entity = 'associateVisions';
