import {Model} from '@vuex-orm/core';

export default class CampaignMainVisual extends Model {
    static fields() {
        return {
            id: this.attr(null),
            file: this.attr(''),

            campaign_id: this.attr(null),
        };
    }
}

CampaignMainVisual.entity = 'campaignMainVisuals';
