import {axiosErrorHandler} from '../../syncable-commons-utils/utils/VuexHelper';

export default (context) => {
    const {store, $axios} = context;
    $axios.onRequest(() => {
    });

    $axios.onError(axiosErrorHandler(context));

    // ヘッダー情報の設定
    $axios.setToken(store.state.auth.token, 'Bearer');
};
