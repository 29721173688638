<template lang="pug">
    .layout-auth-content
        .layout-auth-content-container
            .mb-20
                base-heading(
                level="3"
                center
                ) 会員登録
            .mb-20
                base-button-facebook(
                @click="$emit('select', RegisterSteps.FACEBOOK)"
                )
            // .mb-20
                base-button-twitter(
                @click="$emit('select', RegisterSteps.TWITTER)"
                )
            .mb-20
                base-button(
                block
                large
                white
                prepend-icon="mail"
                @click="$emit('select', RegisterSteps.MAIL)"
                )
                    base-text メールアドレスで登録
        base-divider
        .layout-auth-content-container
            .mt-20
                base-text(level="2" center)
                    | 既に会員の方は
                    base-text(
                    level="2"
                    inline
                    highlighted
                    is-link
                    @click="openLoginDialog"
                    ) ログイン
</template>

<script>
import {mapActions} from 'vuex';
import BaseHeading from '../../../syncable-commons-components/atoms/BaseHeading';
import BaseDivider from '../../../syncable-commons-components/atoms/BaseDivider';
import BaseText from '../../../syncable-commons-components/atoms/BaseText';
import BaseButtonFacebook from '../../../syncable-commons-components/molecules/BaseButtonFacebook';
import BaseButton from '../../../syncable-commons-components/atoms/BaseButton';
import BaseButtonTwitter from '../../../syncable-commons-components/molecules/BaseButtonTwitter';
import {RegisterSteps} from '../../store/layout';

export default {
    name: 'LayoutAuthContentRegisterSelect',
    components: {
        BaseButtonTwitter,
        BaseButton,
        BaseButtonFacebook,
        BaseText,
        BaseDivider,
        BaseHeading,
    },
    data() {
        return {
            RegisterSteps: RegisterSteps,
        };
    },
    methods: {
        ...mapActions('layout', {
            openLoginDialog: 'openLoginDialog',
        }),
    },
};
</script>

<style scoped lang="scss">
</style>