import DateHelper from '../../syncable-commons-utils/utils/DateHelper';
import {GENDER} from '../../syncable-commons-consts/enums';
import LayoutHelper from '../../syncable-commons-utils/utils/LayoutHelper';
import {bodyShaper} from '../../syncable-commons-utils/utils/VuexHelper';

export const state = () => ({
    token: null,
    input: {
        email: '',
        password: '',
        fbToken: '',
    },
    registerInput: {
        private_profile: {
            gender: GENDER.UNSPECIFIED,
            birthday: '',
        },
        avatar_image: {
            file: '',
        },
    },
    isLoggedIn: false,
});

export const mutations = {
    setInput(state, val) {
        state.input = {...state.input, ...val};
    },
    setRegisterInput(state, val) {
        state.registerInput = {...state.registerInput, ...val};
    },
    setRegisterInputPrivateProfile(state, val) {
        state.registerInput.private_profile = {...state.registerInput.private_profile, ...val};
    },
    setToken(state, token) {
        state.token = token;
        this.$axios.setToken(token || '', 'Bearer');
        if (this.$cookies) { // Store token in cookies
            if (token) {
                const optionWithoutDomain = {
                    path: '/',
                    maxAge: 60 * 60 * 24 * 365,
                    sameSite: 'None',
                    secure: true,
                };

                const optionWithDomain = {
                    ...optionWithoutDomain,
                    domain: process.env.COOKIE_DOMAIN,
                };

                const option = process.env.NODE_ENV === 'production' ? optionWithDomain : optionWithoutDomain;

                this.$cookies.set('user_token', token, option);
            } else {
                const optionWithoutDomain = {
                    path: '/',
                    expires: new Date(0),
                    sameSite: 'None',
                    secure: true,
                };

                const optionWithDomain = {
                    ...optionWithoutDomain,
                    domain: process.env.COOKIE_DOMAIN,
                };

                const option = process.env.NODE_ENV === 'production' ? optionWithDomain : optionWithoutDomain;

                // to delete sub domain cookies, here use set() with a domain option, instead of using remove()
                this.$cookies.set('user_token', '', option);
            }
        }
    },
    setIsLoggedIn(state, val) {
        state.isLoggedIn = val;
    },
};

export const actions = {
    updateInput({commit}, val) {
        commit('setInput', val);
    },
    updateRegisterInput({commit}, val) {
        commit('setRegisterInput', val);
    },
    updateRegisterInputPrivateProfile({commit}, val) {
        commit('setRegisterInputPrivateProfile', val);
    },
    resetInput({commit}) {
        commit('setInput', state().input);
    },
    resetRegisterInput({commit}) {
        commit('setRegisterInput', state().registerInput);
    },
    async register({state}) {
        const shapedRegisterInput = {
            avatar_image: {
                ...bodyShaper(state.registerInput.avatar_image, {
                    imageKeys: ['avatar_image'],
                }),
            },
            private_profile: {
                ...bodyShaper(state.registerInput.private_profile, {
                    dateKeys: ['birthday'],
                }),
            },
        };

        await this.$axios.$post('/auth/register', {
            email: state.input.email,
            password: state.input.password,
            ...shapedRegisterInput,
        });
    },
    async registerAndLogin({dispatch}) {
        await dispatch('register');
        await dispatch('login');
    },
    async login({state, dispatch}) {
        try {
            await dispatch('removeAuthData');

            const {data} = await this.$axios.post('/auth/oauth/token/', {
                username: state.input.email,
                password: state.input.password,
                grant_type: 'password',
                client_id: '9NqTr76f3AtGFuiNz6xjxON8kF3ua8SBwfNEGOvi',
                client_secret: 'ix51tPfRFPK4QKhkIZialBs8pKUiQfnNlNoCYUo94tSuXz1mmVYUKafMz5NW6nfdZeDiZCOAgkgINCaWMDNvhNhR91GiM2mqxpTevTLxGjcDZ81KtlumoB8j5WZtIRgM',
            });
            await dispatch('fetchUserByAccessToken', data.access_token);

            await dispatch('resetInput');
        } catch (err) {
            await dispatch('removeAuthData');
            throw err;
        }
    },
    /**
     * facebookにログインして,facebookのtokenをセットする
     * @returns {Promise<void>}
     */
    async loginToFB({dispatch}) {
        const loginResponse = await (() => new Promise((resolve) => {
            this.$FB.login(resolve, {
                scope: 'public_profile,email,user_birthday',
                return_scopes: true,
            });
        }))();
        const authResponse = loginResponse.authResponse;
        if (!authResponse) {
            throw new Error('User cancelled login or did not fully authorize.');
        }

        await dispatch('updateInput', {fbToken: authResponse.accessToken});
    },
    /**
     * facebookからuser関連のデータを取得して登録ようのstoreに入れる
     * @param commit
     * @returns {Promise<void>}
     */
    async fetchFBUserdata({commit}) {
        const response = await (() => new Promise((resolve) => {
            this.$FB.api('/me', {
                fields: 'email,birthday,picture.type(large)',
            }, resolve);
        }))();

        const birthday = DateHelper.format(response.birthday, 'YYYY-MM-DD', 'MM/DD/YYYY');
        const defaultGender = GENDER.UNSPECIFIED;
        const imageBase64 = await LayoutHelper.getBase64FromUrl((response.picture && response.picture.data && response.picture.data.url) || '');
        commit('setRegisterInput', {
            private_profile: {
                gender: defaultGender,
                birthday: birthday,
            },
            avatar_image: {
                file: imageBase64,
            },
        });
        commit('setInput', {
            email: response.email,
        });
    },
    /**
     * facebookで登録するために必要な情報を取得する
     * syncableへの登録はここではまだ行わない
     * @param dispatch
     * @param commit
     * @returns {Promise<any>}
     */
    async tryToRegisterWithFB({dispatch}) {
        await dispatch('loginToFB');
        await dispatch('fetchFBUserdata');
    },
    async registerAndLoginWithFB({dispatch, state}) {
        await dispatch('loginWithFBToken', {
            private_profile: {
                gender: state.registerInput.private_profile.gender,
                birthday: state.registerInput.private_profile.birthday,
            },
            avatar_image: state.registerInput.avatar_image,
            access_type: 'register',
        });
    },
    async loginWithFB({dispatch}) {
        await dispatch('loginToFB');
        await dispatch('loginWithFBToken', {
            access_type: 'login',
        });
    },
    async loginWithFBToken({state, dispatch}, additionalParams = {}) {
        try {
            await dispatch('partiallyRemoveAuthData');

            const body = {
                token: state.input.fbToken,
                backend: 'facebook',
                grant_type: 'convert_token',
                client_id: '9NqTr76f3AtGFuiNz6xjxON8kF3ua8SBwfNEGOvi',
                client_secret: 'ix51tPfRFPK4QKhkIZialBs8pKUiQfnNlNoCYUo94tSuXz1mmVYUKafMz5NW6nfdZeDiZCOAgkgINCaWMDNvhNhR91GiM2mqxpTevTLxGjcDZ81KtlumoB8j5WZtIRgM',
                ...additionalParams,
            };

            const {data} = await this.$axios.post('/auth/social/convert-token/', body);
            await dispatch('fetchUserByAccessToken', data.access_token);

            await dispatch('resetInput');
        } catch (err) {
            await dispatch('removeAuthData');
            throw err;
        }
    },
    /**
     *
     * @param commit
     * @param status
     *  connected - 利用者は現在Facebookにログインしており、アプリにもログインしたことがある。
     *  not_authorized - 利用者は現在Facebookにログインしているが、アプリにはログインしたことがない。
     *  unknown - 利用者は現在Facebookにログインしていないため、アプリにログインしたことがあるかどうかがわからない。FB.logout()が先に呼び出されているため、Facebookに接続できない。
     * @param authResponse
     * ステータスがconnectedの場合はauthResponseが含まれます。このフィールドは次のフィールドで構成されます。
     *  accessToken - アプリの利用者のアクセストークンが入ります。
     *  expiresIn - トークンの有効期限をUNIX時間で示します。更新が必要になります。
     *  reauthorize_required_in - ログインの期限が切れ、再認証が必要になるまでの時間(秒)です。
     *  signedRequest - アプリの利用者に関する情報が入る署名付きパラメーターです。
     *  userID - アプリの利用者のIDです。
     * @returns {Promise<void>}
     */
    async onFBLoginStatusChanged() {
    },
    async removeAuthData({commit, dispatch}) {
        await dispatch('entities/users/logout', {}, {root: true});
        await commit('setToken', null);
        await commit('setIsLoggedIn', false);

        if (this.$FB) {
            this.$FB.getLoginStatus((response) => {
                if (response && response.status === 'connected') {
                    this.$FB.logout();
                }
            });
        }
    },
    /**
     * https://github.com/styzprojects/syncable-client/issues/1963
     */
    async partiallyRemoveAuthData({commit, dispatch}) {
        await dispatch('entities/users/logout', {}, {root: true});
        await commit('setToken', null);
        await commit('setIsLoggedIn', false);
    },
    async onUnauthorized({dispatch}) {
        await dispatch('removeAuthData');
    },
    async logout({dispatch}) {
        await dispatch('removeAuthData');
        this.$router.go(this.$router.currentRoute);
    },
    /**
     * /me/logout からのみ呼ばれるログアウトメソッド.
     * adminやinhouseからの遷移を想定しているため、currentRouteに戻ることなく、Syncable Topに遷移する.
     */
    async subDomainLogout({dispatch}) {
        await dispatch('removeAuthData');
        this.$router.replace('/');
    },
    async fetchUserByAccessToken({commit, dispatch}, token) {
        return new Promise((resolve, reject) => {
            commit('setToken', token);
            if (!token) {
                return resolve();
            }
            setTimeout(async () => {
                await dispatch('entities/users/fetchMe', {}, {root: true}).catch(reject);
                await commit('setIsLoggedIn', true);
                resolve();
            }, 0);
        });
    },
};

export const getters = {
    isLoggedIn(state) {
        // tokenで判断すると、Userデータがまだ取得できていない可能性がある
        return state.isLoggedIn;
    },
};
