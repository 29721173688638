<template lang="pug">
    .base-scroll-container-content(v-if="!swiper")
        slot
    .swiper-slide(v-else)
        slot
</template>

<script>
/**
 * TODO スワイパーモードとそうではないのがあるが、今後のデザイン次第ではこれを使っている箇所はほぼ全てスワイパーで良さそうである
 */
export default {
    name: 'BaseScrollContainerContent',
    props: {
        /**
         * ボタンで動かせるスワイパーになる
         */
        swiper: Boolean,
    },
};
</script>

<style scoped lang="scss">
    .base-scroll-container-content {
        flex: 0 0 auto;
        display: flex;
    }
</style>