import Vue from 'vue';
import NumberHelper from '../../syncable-commons-utils/utils/NumberHelper';
import TextHelper from '../../syncable-commons-utils/utils/TextHelper';
import DateHelper from '../../syncable-commons-utils/utils/DateHelper';

Vue.filter('number', NumberHelper.format);
Vue.filter('amount', NumberHelper.formatAmount);
Vue.filter('decimal', NumberHelper.formatDecimal);
Vue.filter('zipCode', TextHelper.formatZipCode);
Vue.filter('ellipsis', TextHelper.formatEllipsis);
Vue.filter('date', DateHelper.format);
Vue.filter('html', TextHelper.handleHtml);
Vue.filter('autoLink', TextHelper.autoLink);
