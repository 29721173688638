import {Model} from '@vuex-orm/core';
import Associate from './Associate';

export default class PortfolioComposition extends Model {
    static fields() {
        return {
            id: this.number(null),
            ratio: this.number(null),
            associate: this.belongsTo(Associate, 'associate_id'),

            created_at: this.string(''),
            updated_at: this.string(''),

            revision_id: this.attr(null),
            associate_id: this.attr(null),
        };
    }

    static newInstance() {
        return new PortfolioComposition({
            associate: Associate.newInstance(),
        });
    }
}

PortfolioComposition.entity = 'portfolioCompositions';
