import _ from 'lodash';
import HtmlParser from 'htmlparser2';
import Autolinker from 'autolinker';

class TextHelper {
    static camelToKebab(string) {
        return string.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    }

    /**
     * ひらがなをカタカナに変換する関数
     * @param {String} src - ひらがな
     * @returns {String} - カタカナ
     */
    static hiraganaToKatakana(src) {
        if (!_.isString(src)) {
            return '';
        }
        return src.replace(/[\u3041-\u3096]/g, function (match) {
            let chr = match.charCodeAt(0) + 0x60;
            return String.fromCharCode(chr);
        });
    }

    /**
     * 全角半角スペースを除く
     * @param val
     * @returns {*}
     */
    static trim(val = '') {
        /* eslint-disable  no-irregular-whitespace */
        return val.replace(/[\s|　]|[\s| ]/g, '');
    }

    /**
     * 数かどうか調べます
     * 123とか"123"の時のみtrue
     * @param val
     * @returns {boolean}
     */
    static isNumeric(val) {
        return ((typeof val === 'number' || typeof val === 'string') && !isNaN(Number(val)));
    }

    /**
     * 1234567を
     * 〒123-4567に整形
     * @param val
     * @returns {string}
     */
    static formatZipCode(val) {
        if (!TextHelper.isNumeric(val) || val.length !== 7) {
            return `〒${val}`;
        }
        return `〒${val.substr(0, 3)}-${val.substr(3)}`;
    }

    /**
     * valがcount文字以上の場合、count文字で切って"..."を付与
     * @param val
     * @param count
     */
    static formatEllipsis(val, count) {
        if (val && val.length > count) {
            return val.slice(0, count) + '...';
        }
        return val;
    }

    static xss(html) {
        const whiteList = [
            'div',
            'span',
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6',
            'a',
            'br',
            'p',
            'ul',
            'li',
            'ol',
            'i',
            'em',
            'strong',
            'b',
            'code',
            's',
            'del',
            'strike',
            'u',
            'blockquote',
            'pre',
            'hr',
            'image',
            'img',
            'table',
            'tbody',
            'td',
            'tr',
        ];
        const selfClosedTags = ['br', 'p'];
        let filteredHTML = '';
        let noText = true;

        const parser = new HtmlParser.Parser({
            onopentag(name, attrs) {
                noText = true;
                name = name.toLowerCase();

                // hack for <a>
                if (name === 'a') {
                    attrs.target = '_blank';
                }

                // generate attributes string
                let attibutes = '';
                _.each(attrs, (value, name) => {
                    attibutes += ` ${name} = '${value}' `;
                });

                if (whiteList.includes(name)) {
                    filteredHTML += `<${name} ${attibutes}>`;
                } else {
                    filteredHTML += `&lt;${name} ${attibutes}&gt;`;
                }
            },
            ontext(text) {
                noText = false;
                filteredHTML += text;
            },
            onclosetag(name) {
                name = name.toLowerCase();

                if (!noText || !selfClosedTags.includes(name)) {
                    if (whiteList.includes(name)) {
                        filteredHTML += `</${name}>`;
                    } else {
                        filteredHTML += `&lt;/${name}&gt;`;
                    }
                }
            },
        });

        parser.write(html);
        parser.end();

        return filteredHTML;
    }

    static handleHtml(html) {
        return TextHelper.xss(html);
    }

    static plainText(html) {
        const textTags = [
            'div',
            'span',
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6',
            'a',
            'p',
            'i',
            'em',
            'strong',
            'b',
            'del',
            'u',
        ];

        let texts = '';
        let isTextTag = false;

        const parser = new HtmlParser.Parser({
            onopentag(name) {
                isTextTag = textTags.includes(name.toLowerCase());
            },
            ontext(text) {
                if (isTextTag) {
                    texts += text;
                }
            },
            onclosetag() {
                isTextTag = false;
            },
        });

        parser.write(html);
        parser.end();

        return texts;
    }

    static autoLink(text, options = {}) {
        return TextHelper.handleHtml(TextHelper.link(text, options));
    }

    static link(text, options = {}) {
        if (!text) return '';

        const defaultOptions = {
            urls: {
                schemeMatches: true,
                wwwMatches: true,
                tldMatches: true,
            },
            email: true,
            phone: false,
            mention: false,
            hashtag: false,

            stripPrefix: true,
            stripTrailingSlash: true,
            newWindow: true,

            truncate: {
                length: 0,
                location: 'end',
            },

            className: 'autoLinked',
        };

        return Autolinker.link(String(text), {...defaultOptions, ...options});
    }

    /**
     * Get the URL parameter value
     * http://www-creators.com/archives/4463
     *
     * 本当は new URLを使いたいがcore-js@3でのみ使える。今はnuxtがcore-js@2を推奨しているようなのでやめておく。
     *
     * @param  name {string} パラメータのキー文字列
     * @param  url {string} 対象のURL文字列
     */
    static getParam(name, url) {
        name = name.replace(/[[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
}

export default TextHelper;
