import {Model} from '@vuex-orm/core';
import {PORTFOLIO_STATUS, PORTFOLIO_TYPE} from '../../../syncable-commons-consts/enums';
import User from './User';
import PortfolioRevision from './PortfolioRevision';
import PortfolioMainVisual from './PortfolioMainVisual';
import DonationMessage from './DonationMessage';
import Associate from './Associate';
import DefaultAvatar from '../../assets/img/default_avatar.svg';
import _ from 'lodash';
import { ROUTER_TO, LINK_NAME } from '../../consts/links.js';

export default class Portfolio extends Model {
    static fields() {
        return {
            id: this.number(null),
            name: this.string(''),
            description: this.string(''),
            status: this.number(PORTFOLIO_STATUS.DRAFTING),
            type: this.number(null),

            current_revision: this.hasOne(PortfolioRevision, 'portfolio_id'),
            user: this.belongsTo(User, 'user_id'),
            associate: this.belongsTo(Associate, 'associate_id'),
            main_visual: this.hasOne(PortfolioMainVisual, 'portfolio_id'),

            donation_messages: this.hasMany(DonationMessage, 'portfolio_id'),

            started_at: this.string(''),
            finished_at: this.string(''),
            created_at: this.string(''),
            updated_at: this.string(''),

            user_id: this.attr(null),
            associate_id: this.attr(null),

            // clientでcacheするよう
            is_cached: this.boolean(false),

            total_npos: this.number(null),
            total_donors: this.number(null),
            total_donation_amount: this.number(null),
        };
    }

    static newInstance() {
        return new Portfolio({
            current_revision: PortfolioRevision.newInstance(),
            main_visual: PortfolioMainVisual.newInstance(),
            user: User.newInstance(),
        });
    }

    get logo() {
        if (this.type === PORTFOLIO_TYPE.NORMAL) {
            return _.get(this.user, 'avatar_image', {});
        }

        if (this.type === PORTFOLIO_TYPE.NON_DISTRIBUTABLE) {
            return _.get(this.associate, 'logo', {});
        }
        return {};
    }

    get logoImage() {
        return this.logo.file || DefaultAvatar;
    }

    get creatorName() {
        if (this.type === PORTFOLIO_TYPE.NORMAL) {
            return _.get(this.user, 'profile.nickname', '');
        }

        if (this.type === PORTFOLIO_TYPE.NON_DISTRIBUTABLE) {
            return _.get(this.associate, 'name', '');
        }
        return '';
    }

    get creatorDescription() {
        if (this.type === PORTFOLIO_TYPE.NORMAL) {
            return _.get(this.user, 'profile.description', '');
        }

        if (this.type === PORTFOLIO_TYPE.NON_DISTRIBUTABLE) {
            return _.get(this.associate, 'profile.summary', '');
        }
        return '';
    }

    get isCreatedByUser() {
        return this.type === PORTFOLIO_TYPE.NORMAL;
    }

    get isCreatedByAssociate() {
        return this.type === PORTFOLIO_TYPE.NON_DISTRIBUTABLE;
    }

    /**
     * 控除対象団体か
     * @returns {boolean}
     */
    get isDeductible() {
        return this.associate && this.associate.isDeductible;
    }

    get isDonatable() {
        return this.status === PORTFOLIO_STATUS.STARTED;
    }

    get isAmazonpayUsable() {
        return this.isDeductible;
    }

    /**
     * routerに渡す寄付ページのto
     */
    get donationPageTo() {
        return {
            ...ROUTER_TO[LINK_NAME.PORTFOLIO.PORTFOLIO_ID.DONATE.INDEX],
            params: {
                portfolioId: this.id,
            },
        };
    }
}

Portfolio.entity = 'portfolios';
