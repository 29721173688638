<template lang="pug">
    v-layout.s-associate-util(column)
        AssociateInformationBar.s-associate-util-info(v-if="showInfo" v-bind:associate="associate")
        AssociateHeaderTab.s-associate-util-tab(:associate="associate")
</template>

<script>
import AssociateHeaderTab from './AssociateHeaderTab';
import AssociateInformationBar from './AssociateInformationBar';

export default {
    name: 'AssociateHeaderContent',
    components: {AssociateInformationBar, AssociateHeaderTab},
    props: {
        associate: Object,
        showInfo: Boolean,
    },
};
</script>

<style scoped lang="scss">
.s-associate-util {
    display: flex;
    flex-direction: column;
    @include mq(md-and-up) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 960px;
        margin: 0 auto;
    }
    &-info {

    }
    &-tab {

    }
}
</style>
