<template lang="pug">
    v-radio.base-radio.base-selection-controls(
        :value="value"
        :disabled="disabled"
        :label="label"
    )
</template>

<script>
export default {
    name: 'BaseRadio',
    props: {
        value: {
            type: undefined,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        disabled: Boolean,
    },
};
</script>

<style scoped lang="scss">
    @import "BaseSelectionControls";
</style>