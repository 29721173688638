import {Model} from '@vuex-orm/core';

export default class UserAvatarImage extends Model {
    static fields() {
        return {
            id: this.attr(null),
            file: this.attr(''),

            user_id: this.attr(null),
        };
    }

    static newInstance() {
        return new UserAvatarImage();
    }
}

UserAvatarImage.entity = 'userAvatarImages';
