import VuexORM from '@vuex-orm/core';

import User from '../orm/models/User';
import users from '../orm/modules/users';
import UserPrivateProfile from '../orm/models/UserPrivateProfile';
import privateProfiles from '../orm/modules/userPrivateProfiles';
import PaymentCreditCard from '../orm/models/PaymentCreditCard';
import paymentCreditCards from '../orm/modules/paymentCreditCards';
import Donation from '../orm/models/Donation';
import donations from '../orm/modules/donations';
import Donor from '../orm/models/Donor';
import donors from '../orm/modules/donors';
import DonationMessage from '../orm/models/DonationMessage';
import donationMessage from '../orm/modules/donationMessage';
import Associate from '../orm/models/Associate';
import associates from '../orm/modules/associates';
import AssociateSocialChallenge from '../orm/models/AssociateSocialChallenge';
import associateSocialChallenges from '../orm/modules/associateSocialChallenges';
import AssociateProfile from '../orm/models/AssociateProfile';
import associateProfiles from '../orm/modules/associateProfiles';
import AssociateBusiness from '../orm/models/AssociateBusiness';
import associateBusinesses from '../orm/modules/associateBusinesses';
import AssociateMember from '../orm/models/AssociateMember';
import associateMembers from '../orm/modules/associateMembers';
import AssociateVision from '../orm/models/AssociateVision';
import associateVisions from '../orm/modules/associateVisions';
import AssociateDonationSettingPrice from '../orm/models/AssociateDonationSettingPrice';
import AssociateDonationSetting from '../orm/models/AssociateDonationSetting';
import associateDonationSettingPriceImages from '../orm/modules/associateDonationSettingPriceImages';
import associateDonationSettingPrices from '../orm/modules/associateDonationSettingPrices';
import associateDonationSettings from '../orm/modules/associateDonationSettings';
import AssociateDonationSettingPriceImage from '../orm/models/AssociateDonationSettingPriceImage';
import AssociateMembershipPlan from '../orm/models/AssociateMembershipPlan';
import associateMembershipPlans from '../orm/modules/associateMembershipPlans';
import UserAvatarImage from '../orm/models/UserAvatarImage';
import userAvatarImages from '../orm/modules/userAvatarImages';
import UserProfile from '../orm/models/UserProfile';
import userProfiles from '../orm/modules/userProfiles';
import AssociateLogo from '../orm/models/AssociateLogo';
import associateLogos from '../orm/modules/associateLogos';
import AssociateMainVisual from '../orm/models/AssociateMainVisual';
import associateMainVisuals from '../orm/modules/associateMainVisuals';
import AssociateBusinessMainVisual from '../orm/models/AssociateBusinessMainVisual';
import associateBusinessMainVisuals from '../orm/modules/associateBusinessMainVisuals';
import AssociateBusinessActivityImage from '../orm/models/AssociateBusinessActivityImage';
import associateBusinessActivityImages from '../orm/modules/associateBusinessActivityImages';
import AssociateBusinessCostImage from '../orm/models/AssociateBusinessCostImage';
import associateBusinessCostImages from '../orm/modules/associateBusinessCostImages';
import AssociateBusinessPurposeImage from '../orm/models/AssociateBusinessPurposeImage';
import associateBusinessPurposeImages from '../orm/modules/associateBusinessPurposeImages';
import AssociateBusinessResultImage from '../orm/models/AssociateBusinessResultImage';
import associateBusinessResultImages from '../orm/modules/associateBusinessResultImages';
import AssociateVisionChallengeImage from '../orm/models/AssociateVisionChallengeImage';
import associateVisionChallengeImages from '../orm/modules/associateVisionChallengeImages';
import AssociateVisionChallengeReasonImage from '../orm/models/AssociateVisionChallengeReasonImage';
import associateVisionChallengeReasonImages from '../orm/modules/associateVisionChallengeReasonImages';
import AssociateVisionUseForDonationImage from '../orm/models/AssociateVisionUseForDonationImage';
import associateVisionUseForDonationImages from '../orm/modules/associateVisionUseForDonationImages';
import Follower from '../orm/models/Follower';
import followers from '../orm/modules/followers';
import Campaign from '../orm/models/Campaign';
import campaigns from '../orm/modules/campaigns';
import CampaignImage from '../orm/models/CampaignImage';
import campaignImages from '../orm/modules/campaignImages';
import CampaignMainVisual from '../orm/models/CampaignMainVisual';
import campaignMainVisuals from '../orm/modules/campaignMainVisuals';
import RecurringDonation from '../orm/models/RecurringDonation';
import recurringDonations from '../orm/modules/recurringDonations';
import Portfolio from '../orm/models/Portfolio';
import portfolios from '../orm/modules/portfolios';
import PortfolioComposition from '../orm/models/PortfolioComposition';
import portfolioCompositions from '../orm/modules/portfolioCompositions';
import PortfolioMainVisual from '../orm/models/PortfolioMainVisual';
import portfolioMainVisuals from '../orm/modules/portfolioMainVisuals';
import PortfolioRevision from '../orm/models/PortfolioRevision';
import portfolioRevisions from '../orm/modules/portfolioRevisions';
import PortfolioSocialChallenge from '../orm/models/PortfolioSocialChallenge';
import portfolioSocialChallenges from '../orm/modules/portfolioSocialChallenges';
import PortfolioDonation from '../orm/models/PortfolioDonation';
import portfolioDonations from '../orm/modules/portfolioDonations';
import CampaignTemplate from '../orm/models/CampaignTemplate.js';
import campaignTemplates from '../orm/modules/campaignTemplates.js';
import CampaignReport from '../orm/models/CampaignReport';
import campaignReports from '../orm/modules/campaignReports';
import campaignReportMainVisuals from '../orm/modules/campaignReportMainVisuals';
import CampaignReportMainVisual from '../orm/models/CampaignReportMainVisual';
import CampaignUsage from '../orm/models/CampaignUsage';
import campaignUsages from '../orm/modules/campaignUsages';
import CampaignUsageImage from '../orm/models/CampaignUsageImage';
import campaignUsageImages from '../orm/modules/campaignUsageImages';
import CampaignStatistic from '../orm/models/CampaignStatistic';
import campaignStatistics from '../orm/modules/campaignStatistics';
import PortfolioReport from '../orm/models/PortfolioReport';
import portfolioReports from '../orm/modules/portfolioReports';
import PortfolioReportMainVisual from '../orm/models/PortfolioReportMainVisual';
import portfolioReportMainVisuals from '../orm/modules/portfolioReportMainVisuals';
import CustomAuthor from '../orm/models/CustomAuthor';
import customAuthors from '../orm/modules/customAuthors';
import CustomAuthorImage from '../orm/models/CustomAuthorImage';
import customAuthorImages from '../orm/modules/customAuthorImages';
import UniqueDonor from '../orm/models/UniqueDonor';
import UniqueDonors from '../orm/modules/uniqueDonors';
import DonationCommission from '../orm/models/DonationCommission';
import donationCommissions from '../orm/modules/donationCommissions';
import Receipt from '../orm/models/Receipt.js';
import receipts from '../orm/modules/receipts.js';

const createDatabase = () => {

    const database = new VuexORM.Database();

    database.register(User, users);
    database.register(UserAvatarImage, userAvatarImages);
    database.register(UserProfile, userProfiles);
    database.register(UserPrivateProfile, privateProfiles);
    database.register(PaymentCreditCard, paymentCreditCards);
    database.register(Donation, donations);
    database.register(Donor, donors);
    database.register(DonationMessage, donationMessage);
    database.register(Associate, associates);
    database.register(AssociateLogo, associateLogos);
    database.register(AssociateMainVisual, associateMainVisuals);
    database.register(AssociateSocialChallenge, associateSocialChallenges);
    database.register(AssociateProfile, associateProfiles);
    database.register(AssociateBusiness, associateBusinesses);
    database.register(AssociateBusinessMainVisual, associateBusinessMainVisuals);
    database.register(AssociateBusinessActivityImage, associateBusinessActivityImages);
    database.register(AssociateBusinessCostImage, associateBusinessCostImages);
    database.register(AssociateBusinessPurposeImage, associateBusinessPurposeImages);
    database.register(AssociateBusinessResultImage, associateBusinessResultImages);
    database.register(AssociateMember, associateMembers);
    database.register(AssociateVision, associateVisions);
    database.register(AssociateVisionChallengeImage, associateVisionChallengeImages);
    database.register(AssociateVisionChallengeReasonImage, associateVisionChallengeReasonImages);
    database.register(AssociateVisionUseForDonationImage, associateVisionUseForDonationImages);
    database.register(AssociateDonationSetting, associateDonationSettings);
    database.register(AssociateDonationSettingPrice, associateDonationSettingPrices);
    database.register(AssociateDonationSettingPriceImage, associateDonationSettingPriceImages);
    database.register(AssociateMembershipPlan, associateMembershipPlans);
    database.register(Follower, followers);
    database.register(Campaign, campaigns);
    database.register(CampaignImage, campaignImages);
    database.register(CampaignMainVisual, campaignMainVisuals);
    database.register(CampaignReport, campaignReports);
    database.register(CampaignReportMainVisual, campaignReportMainVisuals);
    database.register(PortfolioReport, portfolioReports);
    database.register(PortfolioReportMainVisual, portfolioReportMainVisuals);
    database.register(RecurringDonation, recurringDonations);
    database.register(Portfolio, portfolios);
    database.register(PortfolioComposition, portfolioCompositions);
    database.register(PortfolioMainVisual, portfolioMainVisuals);
    database.register(PortfolioRevision, portfolioRevisions);
    database.register(PortfolioSocialChallenge, portfolioSocialChallenges);
    database.register(PortfolioDonation, portfolioDonations);
    database.register(CampaignTemplate, campaignTemplates);
    database.register(CampaignUsage, campaignUsages);
    database.register(CampaignUsageImage, campaignUsageImages);
    database.register(UniqueDonor, UniqueDonors);
    database.register(CustomAuthor, customAuthors);
    database.register(CustomAuthorImage, customAuthorImages);
    database.register(DonationCommission, donationCommissions);
    database.register(CampaignStatistic, campaignStatistics);
    database.register(Receipt, receipts);

    return database;
};

export const plugins = [
    store => VuexORM.install(createDatabase())(store),
];

export const state = () => ({
    user: '',
});

export const mutations = {};

export const actions = {
    nuxtServerInit({dispatch}, {app, redirect}) {
        const token = app.$cookies.get('user_token');
        if (!token) {
            dispatch('auth/removeAuthData');
            return;
        }
        return dispatch('auth/fetchUserByAccessToken', token).catch(() => {
            redirect('/');
        });
    },
};
