<template lang="pug">
    v-dialog(
    v-model="dialog"
    :max-width="options.width"
    :persistent="true"
    scrollable
    )
        v-card
            v-card-title
                base-heading(level="3") {{title}}
            v-card-text
                base-text(
                v-show="!!message"
                level="3"
                v-html="$options.filters.html(message)"
                )
</template>

<script>
import Vue from 'vue';
import BaseHeading from '../atoms/BaseHeading';
import BaseText from '../atoms/BaseText';

/**
  * this.$wall
  */
export default {
    name: 'BaseLayoutWall',
    components: {BaseText, BaseHeading},
    data() {
        return {
            dialog: false,
            message: null,
            title: null,
            options: {
                width: 400,
            },
        };
    },
    beforeCreate() {
        Vue.prototype.$wall = this.$store.$wall = (payload = {}) => {
            return this.open(payload);
        };
    },
    methods: {
        open({title, message}) {
            this.dialog = true;
            this.title = title;
            this.message = message;
            return () => {
                this.dialog = false;
            };
        },
    },
};
</script>

<style scoped>

</style>
