
/**
 *
 */
export const state = () => ({
    rate: {},
});

export const mutations = {
    setRate(state, val) {
        state.rate = {...state.rate, ...val};
    },
};

export const actions = {
    async fetchRate({commit}) {
        const {data} = await this.$axios.get('https://asia-northeast1-syncable-app.cloudfunctions.net/currency-rate');
        commit('setRate', data.value.rate);
    },
    async fetchRateOnce({state, dispatch}) {
        if (!Object.keys(state.rate).length) {
            dispatch('fetchRate');
        }
    },
};
