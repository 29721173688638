<template lang="pug">
    .base-scroll-container(
        v-if="!swiper"
        :class="classes"
    )
        slot
    .swiper-container-wrap(v-else)
        .swiper-container(v-swiper:mySwiper="swiperOption")
            .swiper-wrapper
                slot
        template(v-if="showSwiperButton")
            .swiper-button.swiper-button-prev(slot="button-prev")
                BaseIcon.swiper-button-icon.swiper-button-icon-prev(
                    icon="arrow-left"
                    highlighted
                    size="20px"
                )
            .swiper-button.swiper-button-next(slot="button-next")
                BaseIcon.swiper-button-icon.swiper-button-icon-next(
                    icon="arrow-right"
                    highlighted
                    size="20px"
                )
</template>

<script>
import BaseIcon from './BaseIcon';

/**
 * TODO スワイパーモードとそうではないのがあるが、今後のデザイン次第ではこれを使っている箇所はほぼ全てスワイパーで良さそうである
 */
export default {
    name: 'BaseScrollContainer',
    components: {BaseIcon},
    props: {
        fluid: Boolean,
        /**
         * ボタンで動かせるスワイパーになる
         */
        swiper: Boolean,
    },
    data() {
        return {
            swiperOption: {
                slidesPerView: 3,
                initialSlide: 0,
                slidesPerGroup: 3,
                loop: true,
                loopFillGroupWithBlank: true,
                spaceBetween: 32,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            },
        };
    },
    computed: {
        classes() {
            return {
                'base-scroll-container--fluid': this.fluid,
            };
        },
        showSwiperButton() {
            return (this.$slots.default && this.$slots.default.length) > this.swiperOption.slidesPerView;
        },
    },
};
</script>

<style scoped lang="scss">
    .base-scroll-container {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        padding-bottom: 15px;
        padding-left: 5px;

        &--fluid {
            padding: 0;
        }
    }

    .swiper-container-wrap {
        position: relative;
    }

    .swiper-button {
        background: inherit;
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px #E2EBE9;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;

        &-next {
            right: -16px;
        }

        &-prev {
            left: -16px;
        }

        &-icon {
            position: absolute;

            &-next {
                right: 8px;
            }

            &-prev {
                left: 8px;
            }
        }
    }

    .swiper-slide {
        box-sizing: border-box;
    }
</style>
