import {Model} from '@vuex-orm/core';

export default class PortfolioReportMainVisual extends Model {
    static entity = 'portfolioReportMainVisuals';

    static fields() {
        return {
            id: this.number(null),
            file: this.attr(''),

            report_id: this.number(null),
        };
    }
}
