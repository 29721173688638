<template lang="pug">
    BaseLinkTab(:items="items")
</template>

<script>
import _ from 'lodash';
import {LINK_NAME, ROUTER_TO} from '../../consts/links';
import BaseLinkTab from './BaseLinkTab';

export default {
    name: 'AssociateHeaderTab',
    components: {BaseLinkTab},
    props: {
        associate: Object,
    },
    computed: {
        items() {
            const itemsSource = [
                {
                    needsDisplay: () => true,
                    item: {
                        key: 'home',
                        label: 'ホーム',
                        to: {
                            ...ROUTER_TO[LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.INDEX],
                            params: {
                                associateUid: this.$route.params.associateUid,
                            },
                        },
                    },
                },
                {
                    needsDisplay: () => true,
                    item: {
                        key: 'vision',
                        label: 'ビジョン',
                        to: {
                            ...ROUTER_TO[LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.VISION.INDEX],
                            params: {
                                associateUid: this.$route.params.associateUid,
                            },
                        },
                    },
                },
                {
                    needsDisplay: (associate) => associate.businesses && associate.businesses.length,
                    item: {
                        key: 'business',
                        label: '取り組み',
                        to: {
                            ...ROUTER_TO[LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.BUSINESSES.INDEX],
                            params: {
                                associateUid: this.$route.params.associateUid,
                            },
                        },
                    },
                },
                {
                    needsDisplay: (associate) => associate.campaigns && associate.campaigns.length,
                    item: {
                        key: 'campaigns',
                        label: 'キャンペーン',
                        to: {
                            ...ROUTER_TO[LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.CAMPAIGN.INDEX],
                            params: {
                                associateUid: this.$route.params.associateUid,
                            },
                        },
                    },
                },
                {
                    needsDisplay: (associate) => associate.members && associate.members.length,
                    item: {
                        key: 'member',
                        label: 'メンバー',
                        to: {
                            ...ROUTER_TO[LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.MEMBERS.INDEX],
                            params: {
                                associateUid: this.$route.params.associateUid,
                            },
                        },
                    },
                },
            ];
            return _.reduce(itemsSource, (result, value) => {
                if (value.needsDisplay(this.associate)) {
                    result.push(value.item);
                }
                return result;
            }, []);
        },
    },
};
</script>

<style scoped lang="scss">
</style>
