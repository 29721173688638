<template lang="pug">
    base-image(
    circle
    :src="src"
    :alt="alt"
    :height="size"
    :width="size"
    )
</template>

<script>
import BaseImage from '../../../syncable-commons-components/atoms/BaseImage';

export default {
    name: 'AssociateLogo',
    components: {BaseImage},
    props: {
        src: String,
        alt: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            default: '90px',
        },
    },
};
</script>

<style scoped lang="scss">
</style>