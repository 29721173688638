import _ from 'lodash';
import {
    CAMPAIGN_TYPE_TEXT,
    COUNTRY,
    DONATION_TYPE,
    GENDER,
    PAYMENT_METHOD, PAYMENT_METHOD_TEXT,
} from '../../../syncable-commons-consts/enums';
import { LINK_NAME, ROUTER_TO } from '../../consts/links';
import User from '../models/User';
import UserPrivateProfile from '../models/UserPrivateProfile';
import PaymentCreditCard from '../models/PaymentCreditCard';
import Associate from '../models/Associate';
import AssociateMembershipPlan from '../models/AssociateMembershipPlan';
import { CardUpdatingCanceledError } from './paymentCreditCards';
import Campaign from '../models/Campaign';
import GoogleAnalyticsHelper from '../../../syncable-commons-utils/utils/GoogleAnalyticsHelper';
import {getMaxDonationAmount} from '../../domain/donation/maxAndMinDonationAmount';


const sendEventToGaIfDonatedToCampaign = (data, db, ga) => {
    if (data.type === DONATION_TYPE.CAMPAIGN) {
        const campaignId = Number(data.campaign);
        const campaign = db.model(Campaign).query().where('id', campaignId).first();

        GoogleAnalyticsHelper.sendEvent(ga, {
            eventCategory: 'donate',
            eventAction: 'campaign',
            eventLabel: campaignId,
            eventValue: data.amount,
            customDimension: {
                'dimension5': PAYMENT_METHOD_TEXT[data.payment_method],
                'dimension6': CAMPAIGN_TYPE_TEXT[campaign.type],
            },
        });
    }
};

/**
 * @param {Number} amount
 * @param {Associate} associate
 * @param {String} accessTokenForAmazonPay
 * @return {PAYMENT_METHOD}
 */
const calculateDefaultPaymentMethod = (amount, associate, accessTokenForAmazonPay) => {
    if (accessTokenForAmazonPay) {
        return PAYMENT_METHOD.AMAZON_PAY;
    }

    const maxDonationAmountForCreditCard = getMaxDonationAmount(PAYMENT_METHOD.CREDIT_CARD);
    return amount > maxDonationAmountForCreditCard ? PAYMENT_METHOD.Bank : PAYMENT_METHOD.CREDIT_CARD;
};

const initializeState = () => ({
    valid: {
        amount: false,
        donor: false,
        card: false,
        confirmation: false,
    },
    // input(入力中のもの) と inputted(確定したもの)の２段構成に後から変更したので、ややこしい作りになっています。
    input: {
        amount: '',
        payment_method: PAYMENT_METHOD.CREDIT_CARD,
        isRecurring: false,

        membership_plan_id: '',

        card: {
            number: '',
            expiration_month: '',
            expiration_year: '',
            name: '',
            security_code: '',
        },
        donor: {
            phone: '',
            gender: GENDER.UNSPECIFIED,
            birthday: '',
            country: COUNTRY.JP,
            zip_code: '',
            area: '',
            city: '',
            address1: '',
            address2: '',
            last_name: '',
            first_name: '',
        },
        email: '',

        message: '',
        need_receipt: false,
        need_news_letter: false,

        password: '',

        is_commission_contains: false,
        commission_amount: 0,
    },
    inputted: {
        amount: '',
        payment_method: PAYMENT_METHOD.CREDIT_CARD,
        isRecurring: false,

        membership_plan_id: '',

        card: {
            number: '',
            expiration_month: '',
            expiration_year: '',
            name: '',
            security_code: '',
        },

        donor: {
            phone: '',
            gender: GENDER.UNSPECIFIED,
            birthday: '',
            country: COUNTRY.JP,
            zip_code: '',
            area: '',
            city: '',
            address1: '',
            address2: '',
            last_name: '',
            first_name: '',
        },
        email: '',
        is_commission_contains: false,
        commission_amount: 0,
        virtual_account: {
            account_id: undefined,
            branch_code: undefined,
            branch_name_kana: undefined,
            account_number: undefined,
        },
    },
    /**
     * "ユーザーが入力した項目以外でbodyにいれる項目"のことらしい
     * bodyとは多分requestBodyのことを指しているのだと思う
     */
    bodySource: {
        customer_id: '',
        associate_id: '',

        agency_commission_code: '',
        campaignId: null,
    },
    mode: {
        hideFrequency: false,
        hideAmazonPay: false,
        hideLogIn: false,
        hideSignUp: false,
        backTo: undefined,
        isMembership: false,
        isCampaign: false,
        isEdit: false,
    },
    amazonPay: {
        token: '',
        amazonReferenceId: '',
        isAddressBookTobeRefreshed: false,
        consentStatus: false,
    },
    donationMessage: {},
    defaultRoute: {
        params: '',
        query: '',
        name: '',
    },
    recaptchaToken: null,
});

export default {
    state: initializeState,

    mutations: {
        setValid(state, val = {}) {
            state.valid = {...state.valid, ...val};
        },
        setInput(state, val = {}) {
            state.input = {...state.input, ...val};
        },
        setInputted(state, val = {}) {
            state.inputted = {...state.inputted, ...val};
        },
        setBodySource(state, val = {}) {
            state.bodySource = {...state.bodySource, ...val};
        },
        setMode(state, val = {}) {
            state.mode = {...state.mode, ...val};
        },
        setDefaultRoute(state, val = {}) {
            state.defaultRoute = {...state.defaultRoute, ...val};
        },
        setMembership(state, val = {}) {
            state.membership = {...state.membership, ...val};
        },
        setAmazonPay(state, val = {}) {
            state.amazonPay = {...state.amazonPay, ...val};
        },
        setDonationMessage(state, val = {}) {
            state.donationMessage = {...state.donationMessage, ...val};
        },
        setRecaptchaToken(state, val) {
            state.recaptchaToken = val;
        },
        setVirtualAccount(state, val) {
            state.virtual_account = {...state.virtual_account, ...val};
        },
    },

    actions: {
        onExitDonatePage({commit}) {
            const initState = initializeState();
            commit('setValid', initState.valid);
            commit('setInput', initState.input);
            commit('setDefaultRoute', initState.defaultRoute);
            commit('setAmazonPay', initState.amazonPay);

            // dispatch('layout/updateLang', 'ja', {root: true});
        },
        onExitCompletePage({commit, dispatch}) {
            const initState = initializeState();
            commit('setMode', initState.mode);
            commit('setMembership', initState.membership);
            commit('setDonationMessage', initState.donationMessage);
            dispatch('layout/updateLang', 'ja', {root: true});
        },
        updateValid({commit}, val) {
            commit('setValid', val);
        },
        updateInput({commit}, val) {
            commit('setInput', val);
        },
        updateDefaultInput({commit}, val) {
            commit('setInput', val);
            commit('setInputted', val);
        },
        updateMembership({commit}, val) {
            commit('setMembership', val);
        },
        updateMode({commit}, val) {
            commit('setMode', val);
        },
        updateAmazonPay({commit}, val) {
            commit('setAmazonPay', val);
        },
        /**
         * /donate/にランディングした時の処理
         * クエリパラメータ、ログイン中のユーザー、対象の団体などの情報を初期値としてストア内に保持していく
         */
        async onLandedDonationPage({dispatch}, route = {}) {
            await dispatch('updateByBrowsingAssociate');
            await dispatch('updateDefaultRoute', route);
            await dispatch('setCurrentUserDataToDefaultInput');
        },
        /**
         * /donate/cardにランディングした時の処理
         * フォームと直接バインディングされているinputの中身を全てクリアする
         *
         * @param dispatch
         * @returns {Promise<void>}
         */
        onLandedDonationCardPage({dispatch}) {
            const clearedEntity = {
                card: initializeState().input.card,
            };

            dispatch('updateInput', clearedEntity);
        },
        /**
         * ランディング時のroute情報の更新と
         * それに伴う初期値の設定
         */
        async updateDefaultRoute({state, commit, dispatch}, route = {}) {
            await commit('setDefaultRoute', {
                name: route.name,
                params: route.params,
                query: route.query,
            });

            const query = route.query || {};
            // 各種クエリパラメーターによる初期値の設定やモードの切り替え
            // 今の所、全て併用で問題ない
            const queryRecurring = query.recurring;
            const queryAmount = query.amount;
            const queryAgency = query.agency;
            const queryLang = query.lang;
            const queryIsCommissionContains = query.isCommissionContains;
            const queryCampaignId = query.campaignId;
            const queryHideAmazonPay = query.hideAmazonPay;
            const queryHideLogIn = query.hideLogIn;
            const queryHideSignUp = query.hideSignUp;
            const queryBackTo = query.backTo && decodeURIComponent(query.backTo);
            const accessToken = query.access_token;

            if (queryLang === 'en') {
                await dispatch('layout/updateLang', 'en', {root: true});
            }
            if (accessToken) {
                commit('setAmazonPay', {
                    token: accessToken,
                });
            }

            if (queryIsCommissionContains === 'true' || queryIsCommissionContains === true) {
                commit('setInputted', {
                    is_commission_contains: true,
                });
                commit('setInput', {
                    is_commission_contains: true,
                });
            }

            const isMonthlyFunding = (() => {
                if (!queryCampaignId) {
                    return false;
                }

                const campaign = this.$db().model(Campaign).query().where('id', Number(queryCampaignId)).first();
                if (!campaign) {
                    return false;
                }

                return campaign.isMonthlyFunding || campaign.isMonthlyFundingWithoutCommission;
            })();
            const hasValidRecurring = (queryRecurring === 'true' || queryRecurring === true) ||
                (queryRecurring === 'false' || queryRecurring === false);
            const isRecurring = (() => {
                if (queryAgency || isMonthlyFunding) {
                    return true;
                }
                if (hasValidRecurring) {
                    if (queryRecurring === 'true' || queryRecurring === true) {
                        return true;
                    }
                    if (queryRecurring === 'false' || queryRecurring === false) {
                        return false;
                    }
                }

                return false;
            })();
            await dispatch('updateDefaultInput', {
                amount: queryAmount ? Number(queryAmount) : '',
                isRecurring: isRecurring,
                payment_method: calculateDefaultPaymentMethod(
                    queryAmount,
                    await this.$db().model(Associate).getters('awaitGetter')(this)('associate_uid', this.$db().model(Associate).getters('browsingUid')),
                    accessToken,
                ),
            });
            commit('setBodySource', {
                agency_commission_code: queryAgency || '',
                campaignId: Number(queryCampaignId),
            });
            commit('setMode', {
                hideFrequency: !accessToken && (hasValidRecurring || !!queryAgency),
                hideAmazonPay: queryHideAmazonPay === 'true',
                hideLogIn: queryHideLogIn === 'true',
                hideSignUp: queryHideSignUp === 'true',
                backTo: queryBackTo,
                isCampaign: !!Number(queryCampaignId),
                isMembership: route.name === LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.DONATE.MEMBERSHIP,
            });
        },
        /**
         * ログイン中のユーザーのデータをdefaultInputに入れます。
         * ログインしていない場合は空が入ります。
         */
        async setCurrentUserDataToDefaultInput({commit, dispatch, state}) {
            if (state.input.password) {
                // 寄付をして会員登録中なのでスキップ
                return;
            }
            const UserModel = this.$db().model(User);
            const UserPrivateProfileModel = this.$db().model(UserPrivateProfile);
            const PaymentCreditCardModel = this.$db().model(PaymentCreditCard);

            const user = await UserModel.getters('me')(this)|| new UserModel();
            const privateProfile = user.private_profile || new UserPrivateProfileModel();
            const donor = privateProfile.getDonorInfo();
            const email = user.email;
            const card = user.user_credit_card || new PaymentCreditCardModel();

            await dispatch('updateDefaultInput', {
                donor: donor,
                email: email,
                card: card.getViewData(),
            });
            commit('setBodySource', {
                customer_id: card.customer_code,
            });
        },
        async updateByBrowsingAssociate({commit}) {
            const AssociateModel = this.$db().model(Associate);
            const associate = await AssociateModel.getters('awaitGetter')(this)('associate_uid', AssociateModel.getters('browsingUid'));

            commit('setBodySource', {
                associate_id: associate.id,
            });
        },
        /**
         * ログイン中ユーザーのカードを更新して
         * 決済用のcustomer_idをbodySourceに入れます。
         */
        async updateCardForUser({dispatch, commit, state}) {
            await dispatch('entities/paymentCreditCards/updateInput', {
                card: state.input.card,
            }, {root: true});
            const card = await dispatch('entities/paymentCreditCards/updateCard', {}, {root: true});

            const cord = card.customer_code;
            commit('setInput', {
                card: card.getViewData(),
            });
            commit('setBodySource', {
                customer_id: cord,
            });
        },
        /**
         * 非ログインユーザーの場合、
         * 入力されたカード情報から、customer_idを生成してbodySourceに入れます
         */
        async createCustomerCodeForGuest({dispatch, commit, state}) {
            await dispatch('entities/paymentCreditCards/updateInput', {
                email: state.input.email,
                card: state.input.card,
            }, {root: true});
            const code = await dispatch('entities/paymentCreditCards/createCustomerCode', {}, {root: true});
            commit('setBodySource', {
                customer_id: code,
            });
        },

        /**
         * 入力情報をもとに、ユーザーの寄付者情報を更新します。
         */
        async updateDonorForUser({dispatch, state}) {
            await dispatch('meSettingsDonor/init', {}, {root: true});
            await dispatch('meSettingsDonor/updateInput', state.input.donor, {root: true});
            await dispatch('meSettingsDonor/update', {}, {root: true});
            await dispatch('meSettingsDonor/reset', {}, {root: true});
        },
        /**
         * 次のステップに行きます。
         */
        async goNextStep({commit, state, getters, rootGetters, dispatch}) {
            try {
                const isLoggedIn = rootGetters['auth/isLoggedIn'];
                const prePushOnIndex = async () => {
                    commit('setInputted', {
                        amount: state.input.amount,
                        payment_method: state.input.payment_method,
                        isRecurring: state.input.isRecurring,
                        is_commission_contains: state.input.is_commission_contains,
                        commission_amount: state.input.commission_amount,
                    });
                };
                const prePushOnMembership = async () => {
                    commit('setInputted', {
                        amount: state.input.amount,
                        membership_plan_id: state.input.membership_plan_id,
                    });
                };
                const prePushOnDonor = async () => {
                    if (isLoggedIn) {
                        await dispatch('updateDonorForUser');
                    }
                    commit('setInputted', {
                        donor: state.input.donor,
                        email: state.input.email,
                    });
                };
                const prePushOnCardCancel = () => {
                    commit('setInput', state.inputted);
                };
                const prePushOnCardConfirm = async () => {
                    if (isLoggedIn) {
                        await dispatch('updateCardForUser');
                    } else {
                        await dispatch('createCustomerCodeForGuest');
                    }

                    commit('setInputted', {
                        card: state.input.card,
                    });
                };
                const prePushOnCard = async () => {
                    const card = state.input.card;
                    if (_.every(card, i => i === '')) {
                        return prePushOnCardCancel();
                    }

                    return prePushOnCardConfirm();
                };

                const prePushFunctions = {
                    [LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.DONATE.INDEX]: prePushOnIndex,
                    [LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.DONATE.MEMBERSHIP]: prePushOnMembership,
                    [LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.DONATE.DONOR]: prePushOnDonor,
                    [LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.DONATE.CARD]: prePushOnCard,
                };

                const prePush = prePushFunctions[this.app.context.route.name];
                if (!_.isFunction(prePush)) {
                    return;
                }
                await prePush();

                commit('setMode', {
                    isEdit: false,
                });

                this.$router.push({
                    ...ROUTER_TO[getters['nextLinkName']],
                    ...getters['routerOptions'],
                });
            } catch (e) {
                if (e instanceof CardUpdatingCanceledError) {
                    return;
                }

                this.$alert({
                    message: (e && e.message) || this.app.i18n.t('error.general'),
                });
            }
        },
        /**
         * @return {Promise<boolean>} 中断されるべきか
         */
        async _checkFinishedCampaign({state, commit}) {
            if (!state.mode.isCampaign) {
                return false;
            }
            const campaign = this.$db().model(Campaign).find(state.bodySource.campaignId);
            if (!campaign || !campaign.isFinished) {
                return false;
            }

            try {
                await this.$confirm({
                    message: 'このキャンペーンは既に終了しています。キャンペーンに対してではなく、そのまま団体に対して寄付しますか？',
                });
            } catch (e) {
                // Cancelもしくはダイアログを閉じた場合
                return true;
            }
            commit('setBodySource', {
                campaignId: null,
            });
            commit('setMode', {
                isCampaign: false,
            });

            await new Promise(resolve => setTimeout(resolve, 500));

            try {
                await this.$alert({
                    message: '寄付先を団体に切り替えます。決済を完了するには、再度「寄付を実行」を押して下さい。団体への応援メッセージを入力することもできます。',
                });
            } catch (e) {
                // Cancelもしくはダイアログを閉じた場合
                return true;
            }
            await this.$router.replace({
                query: {
                    campaignId: undefined,
                },
            });
            return true;
        },
        async _checkDuplication({state}) {
            // TODO Amazon Payで非ログインの場合の障害が起きているので、一旦無効化する
            if (state.inputted.payment_method === PAYMENT_METHOD.AMAZON_PAY) {
                return;
            }

            const {data} = await this.$axios.post('/donation/check-duplication/', {
                email: state.inputted.email,
                associate_id: state.bodySource.associate_id,
            });
            if (data['possible_duplication']) {
                try {
                    await this.$confirm({
                        message: '過去10分以内に同一団体に対して寄付をした履歴があります。続行しますか？'},
                    );
                } catch (e) {
                    // Cancelもしくはダイアログを閉じた場合
                    return true;
                }

                return false;
            }
        },
        /**
         * 寄付します。
         */
        async donate({commit, state, dispatch, rootGetters, getters}, {
            recaptchaToken=undefined,
        } = {}) {
            const executor = async () => {
                if (!rootGetters['auth/isLoggedIn']) {
                    await dispatch('preDonationForGuest');
                }

                if (state.mode.isMembership) {
                    await dispatch('createAnnualDonation');
                    return;
                }
                if (state.input.isRecurring) {
                    await dispatch('createRecurringDonation');
                    return;
                }
                await dispatch('createOnetimeDonation');
            };

            try {
                commit('setRecaptchaToken', recaptchaToken);

                const shouldBeCanceledByCampaign = await dispatch('_checkFinishedCampaign');
                if (shouldBeCanceledByCampaign) {
                    return;
                }
                const shouldBeCanceledByDuplication = await dispatch('_checkDuplication');
                if (shouldBeCanceledByDuplication) {
                    return;
                }

                await executor();

                const close = this.$wall({title: '決済が完了しました。', message: '画面が切り替わるまで少々お待ちください…'});

                let linkName = state.mode.isCampaign
                    ? LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.CONTRIBUTE.MESSAGE
                    : LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.CONTRIBUTE.COMPLETE;

                let routerOptions = {
                    ...ROUTER_TO[linkName],
                    ...getters['routerOptions'],
                };
                routerOptions.query = {
                    ...routerOptions.query,
                };

                // 2秒待つ
                await new Promise(resolve => setTimeout(resolve, 2000));
                this.$router.push(routerOptions, close);
            } catch (e) {
                dispatch('refreshAmazonPayAddressBookWidget');
                throw e;
            }
        },
        /**
         * 非ログインユーザー用の寄付の前の処理
         */
        async preDonationForGuest({state, dispatch}) {
            if (state.inputted.payment_method === PAYMENT_METHOD.AMAZON_PAY) {
                await dispatch('preDonationForAmazonGuest');
                return;
            }

            await dispatch('preDonationForCardGuest');
        },
        /**
         * amazon payを使用しない非ログインユーザー用の寄付の前の処理
         */
        async preDonationForCardGuest({state, dispatch}) {
            if (!state.input.password) {
                // 会員登録しない
                return;
            }

            // 会員登録する
            await dispatch('auth/updateInput', {
                email: state.input.email,
                password: state.input.password,
            }, {root: true});
            await dispatch('auth/registerAndLogin', {}, {root: true});
            await Promise.all([
                dispatch('updateDonorForUser'), // 寄付者情報を登録
                dispatch('updateCardForUser'), // カード情報を登録
            ]);
        },
        /**
         * amazon payを使用する非ログインユーザー用の寄付の前の処理
         *
         * - パスワードが入力された時、会員登録をする
         * - パスワードが入力された時、会員登録をしない
         */
        async preDonationForAmazonGuest({state, dispatch}) {
            if (!state.input.password) {
                return;
            }

            const {PrimaryEmail} = await this.$AmazonPay.retrieveProfile(state.amazonPay.token);
            dispatch('updateDefaultInput', {
                email: PrimaryEmail,
            });
            await dispatch('auth/updateInput', {
                email: state.input.email,
                password: state.input.password,
            }, {root: true});
            await dispatch('auth/registerAndLogin', {}, {root: true});
            await Promise.all([
                dispatch('updateDonorForUser'), // 寄付者情報を登録
                dispatch('updateCardForUser'), // カード情報を登録
            ]);
        },
        /**
         * 年会費会員
         */
        async createAnnualDonation({commit, getters}) {
            const data = await this.$axios.$post('/donation/recurring/annual/legacy/', getters['requestBody']);

            sendEventToGaIfDonatedToCampaign(data, this.$db(), this.$ga);

            commit('setDonationMessage', data.donations[0].donation_message);
        },
        /**
         * 毎月寄付
         */
        async createRecurringDonation({commit, getters, state}) {
            const endpoint = state.inputted.payment_method === PAYMENT_METHOD.AMAZON_PAY ? '/donation/recurring/amazon_pay/' : '/donation/recurring/legacy/';
            const data = await this.$axios.$post(endpoint, getters['requestBody']);

            sendEventToGaIfDonatedToCampaign(data, this.$db(), this.$ga);

            commit('setDonationMessage', data.donations[0].donation_message);
        },
        /**
         * 単発寄付
         */
        async createOnetimeDonation({commit, getters, state}) {
            const endpoint = state.inputted.payment_method === PAYMENT_METHOD.AMAZON_PAY ? '/donation/amazon_pay/' : '/donation/legacy/';
            const data = await this.$axios.$post(endpoint, getters['requestBody']);

            sendEventToGaIfDonatedToCampaign(data, this.$db(), this.$ga);

            commit('setDonationMessage', data.donation_message);
        },

        refreshAmazonPayAddressBookWidget({commit}) {
            commit('setAmazonPay', {
                isAddressBookTobeRefreshed: true,
            });
        },
        /**
         * 「団体からのお知らせ」をOFFにする。
         */
        async unsubscribe(context, {donationId}) {
            await this.$axios.post(`/donation/${donationId}/unsubscribe/`);
        },
        async issueVirtualAccount({commit, getters}, {recaptchaToken = undefined} = {}) {
            commit('setRecaptchaToken', recaptchaToken);

            const data = await this.$axios.$post('donation/deposit/', {
                donation: getters['requestBody'],
            });

            commit('setVirtualAccount', data['virtual_account']);
            commit('setDonationMessage', data['donation']['donation_message']);

            return this.$router.push({
                ...ROUTER_TO[LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.CONTRIBUTE.CONFIRMATION_BANK],
                ...getters['routerOptions'],
            });
        },
    },

    getters: {
        /**
         * 次にいくべきページのnameを返します。
         */
        nextLinkName(state, getters, rootState, rootGetters) {
            const isAmountInputted = state.mode.isMembership ? state.input.amount > 0 : state.valid.amount;
            const isCardInputted = state.bodySource.customer_id || state.valid.card;
            // if logged in, we just check existences
            // if not, check strictly
            const isLoggedIn = rootGetters['auth/isLoggedIn'];
            const isDonorInputted = isLoggedIn ? _.every(state.input.donor, (v, key) => {
                if (key === 'address2') {
                    // 必須項目ではないもの
                    return true;
                }
                return !!v;
            }) : state.valid.donor;
            const returnAmountPage = isMembership => {
                if (isMembership) {
                    return LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.DONATE.MEMBERSHIP;
                }
                return LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.DONATE.INDEX;
            };

            if (!isAmountInputted) {
                return returnAmountPage(state.mode.isMembership);
            } else if (state.input.payment_method === PAYMENT_METHOD.AMAZON_PAY) {
                // amazon payの場合は、カードと寄付者情報の入力は不要
                // 金額は必須
                if (state.amazonPay.token) {
                    return LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.DONATE.CONFIRMATION;
                }
                return returnAmountPage(state.mode.isMembership);
            } else if (!isDonorInputted) {
                return LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.DONATE.DONOR;
            } else if (state.input.payment_method === PAYMENT_METHOD.CREDIT_CARD && !isCardInputted) {
                return LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.DONATE.CARD;
            } else {
                return LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.DONATE.CONFIRMATION;
            }
        },
        routerOptions(state) {
            return {
                params: state.defaultRoute.params,
                query: state.defaultRoute.query,
            };
        },
        selectingPlan: state => store => {
            const selectedId = state.input.membership_plan_id;
            if (!selectedId) {
                return {};
            }
            return store.$db().model(AssociateMembershipPlan).query().where('id', Number(selectedId)).first();
        },
        selectedPlan: state => store => {
            const selectedId = state.inputted.membership_plan_id;
            if (!selectedId) {
                return {};
            }
            return store.$db().model(AssociateMembershipPlan).query().where('id', Number(selectedId)).first();
        },
        /**
         * POSTの時のbody
         */
        requestBody(state, getters, rootState) {
            const confirmationInput = {
                message: state.input.message,
                need_receipt: state.input.need_receipt,
                need_news_letter: state.input.need_news_letter,
            };

            // 手数料を負担する場合は金額に加算する
            let amount = state.inputted.amount;
            if (state.inputted.is_commission_contains) {
                amount = Number.parseInt(amount) + state.inputted.commission_amount;
            }

            let commonFields = {
                ...confirmationInput,
                type: DONATION_TYPE.NORMAL,
                associate_id: state.bodySource.associate_id,
                amount: amount,
                recaptcha_token: state.recaptchaToken,
                lang: rootState.layout.lang,
                is_commission_contains: state.inputted.is_commission_contains,
            };
            if (state.mode.isCampaign) {
                // キャンペーンのみのパラメータ
                commonFields = {
                    ...commonFields,
                    campaign_id: state.bodySource.campaignId,
                    type: DONATION_TYPE.CAMPAIGN,
                };
            }
            if (state.mode.isMembership) {
                // 年会費のみのパラメータ
                commonFields = {
                    ...commonFields,
                    membership_plan_id: state.inputted.membership_plan_id,
                };
            }
            if (state.inputted.isRecurring) {
                // 継続寄付のみのパラメータ
                commonFields = {
                    ...commonFields,
                    agency_commission_code: state.bodySource.agency_commission_code,
                };
            }
            if (state.inputted.payment_method === PAYMENT_METHOD.AMAZON_PAY) {
                // amazon pay
                // donor情報はサーバーサイドで取得
                return {
                    ...commonFields,
                    payment_method: PAYMENT_METHOD.AMAZON_PAY,
                    amazon_pay: {
                        amazon_reference_id: state.amazonPay.amazonReferenceId,
                        token: state.amazonPay.token,
                    },
                };
            }
            // amazon pay以外(今はomiseのみ)
            commonFields = {
                ...commonFields,
                payment_method: state.inputted.payment_method,
                customer_id: state.bodySource.customer_id,
            };
            const donor = {
                ...state.inputted.donor,
                email: state.inputted.email,
            };

            if (state.mode.isMembership || state.inputted.isRecurring) {
                // 継続と単発で寄付者情報の入れ方が違うので注意
                return {
                    ...commonFields,
                    ...donor,
                };
            }
            return {
                ...commonFields,
                donor: donor,
            };
        },
        donationMessage(state) {
            return state.donationMessage;
        },
    },
};
