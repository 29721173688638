import CampaignUsage from '../models/CampaignUsage';

export default {
    actions: {
        async postRequest({dispatch}, payload) {
            const {data} = await this.$axios.post('campaign/usage/', payload).catch(() => {});

            dispatch('insertOrUpdate', {
                data: data,
            });
        },
        async putRequest({dispatch}, payload) {
            const {data} = await this.$axios.put(`campaign/usage/${payload['id']}/`, payload).catch(() => {});

            dispatch('insertOrUpdate', {
                data: data,
            });
        },
        async deleteRequest(context, payload) {
            await this.$axios.delete(`campaign/usage/${payload.id}/`).catch(() => {});
            await CampaignUsage.delete(payload.id);
        },
    },
};
