import { render, staticRenderFns } from "./LoadingMask.vue?vue&type=template&id=0fa24732&scoped=true&lang=pug&"
import script from "./LoadingMask.vue?vue&type=script&lang=js&"
export * from "./LoadingMask.vue?vue&type=script&lang=js&"
import style0 from "./LoadingMask.vue?vue&type=style&index=0&id=0fa24732&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../syncable/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fa24732",
  null
  
)

export default component.exports