import CustomAuthor from '../models/CustomAuthor';
import CustomAuthorImage from '../models/CustomAuthorImage';

export default {
    actions: {
        storeChange({commit}, payload) {
            commit('setIsChanged', true);
            commit('setCustomAuthor', payload);
        },
        async createRequest(context, payload) {
            const campaignId = payload.campaignId;
            delete payload.campaignId;

            const {data} = await this.$axios.post(`campaign/${campaignId}/custom-author/`, payload)
                .catch(() => {});

            await this.$db().model(CustomAuthor).insertOrUpdate({data: data});
        },
        async patchRequest(context, payload) {
            const targetId = payload.id;
            delete payload.id;

            const {data} = await this.$axios.patch(`campaign/custom-author/${targetId}/`, payload).catch(() => {});
            await this.$db().model(CustomAuthor).update({data: data});
        },
        async deleteRequest(context, id) {
            await this.$axios.delete(`campaign/custom-author/${id}/`).catch(() => {});

            const customAuthor = CustomAuthor.query().withAllRecursive().find(id);
            await CustomAuthorImage.delete(customAuthor['custom_author_image']['id']);
            await customAuthor.$delete();
        },
    },
};
