import {Model} from '@vuex-orm/core';
import {COUNTRY, COUNTRY_TEXT, GENDER, GENDER_TEXT} from '../../../syncable-commons-consts/enums';

export default class UserPrivateProfile extends Model {
    static fields() {
        return {
            id: this.attr(null),
            last_name: this.attr(''),
            first_name: this.attr(''),
            country: this.string(COUNTRY.JP),
            zip_code: this.attr(''),
            area: this.attr(''),
            city: this.attr(''),
            address1: this.attr(''),
            address2: this.attr(''),
            phone: this.attr(''),
            birthday: this.attr(''),
            gender: this.attr(GENDER.UNSPECIFIED),
            created_at: this.attr(''),
            updated_at: this.attr(''),

            user_id: this.attr(null),
        };
    }

    static newInstance() {
        return new UserPrivateProfile();
    }

    /**
     * 日本語文字です。
     */
    static get keyText() {
        return {
            id: 'ID',
            email: 'メールアドレス',
            last_name: '姓',
            first_name: '名',
            birthday: '生年月日',
            gender: '性別',
            country: '国',
            zip_code: '郵便番号',
            area: '都道府県',
            city: '市区町村',
            address1: '丁目・番地',
            address2: '建物名',
            phone: '電話番号',
        };
    }

    /**
     * 寄付者情報のkey
     * @returns {string[]}
     */
    static get donorInfoKeys() {
        return [
            'last_name',
            'first_name',
            'birthday',
            'gender',
            'country',
            'zip_code',
            'area',
            'city',
            'address1',
            'address2',
            'phone',
        ];
    }

    /**
     * 日付情報を表すレコードのkey
     * Djangoは空文字の日付情報を受け取った際にエラーを返すため、nullに成形するために保持しておく
     */
    static get dateKeys() {
        return [
            'birthday',
            'created_at',
            'updated_at',
        ];
    }

    /**
     *
     * @returns {string|{[p: string]: *}}
     */
    getDonorInfo() {
        const dateKeys = UserPrivateProfile.dateKeys;

        return UserPrivateProfile.donorInfoKeys.reduce((object, key) => ({
            ...object,
            [key]: (dateKeys && dateKeys.includes(key) && this[key] === '') ? null : this[key],
        }), {});
    }

    /**
     * 値を文字として表示する際に、変換が必要なkeyと変換用関数のマッピングです。
     *
     * @returns {{gender: (function(*): *)}}
     */
    static get textConverter() {
        return {
            country: val => COUNTRY_TEXT[val],
            gender: val => GENDER_TEXT[val],
        };
    }

    /**
     * 表示用の値を返します。
     *
     * @param key
     * @returns {*}
     */
    getText(key) {
        const converter = UserPrivateProfile.textConverter[key];
        if (converter) {
            return converter(this[key]);
        }
        return this[key];
    }

    /**
     *
     * @param ctx
     * @returns {Promise<EntityCollection>}
     */
    async updateDonorInfo(ctx) {
        const body = this.getDonorInfo();
        const data = await ctx.$axios.$patch('/user/private_profile/update_me/', body);
        return UserPrivateProfile.update({
            where: record => record.id === data.id,
            data: data,
        });
    }

    /**
     * なぜか寄付をしていても名前や住所がないユーザーがいるので、それを判定する
     */
    get isFilledProfile() {
        return this.last_name !== '' && this.zip_code !== '';
    }

    get fullName() {
        let _fullName = this.last_name || '';
        if (this.first_name) {
            _fullName += ` ${this.first_name}`;
        }
        return _fullName;
    }
}

UserPrivateProfile.entity = 'userPrivateProfiles';
