import numeral from 'numeral';
import 'numeral/locales/ja';
import _ from 'lodash';

numeral.locale('ja');

export default class NumberHelper {
    static _format(val, format) {
        if (!_.isNumber(val) && !_.isString(val)) {
            return numeral(0).format(format);
        }
        return numeral(val).format(format);
    }

    static format(val) {
        return NumberHelper._format(val, '0,0');
    }

    static formatAmount(val) {
        return NumberHelper._format(val, '$0,0');
    }

    static formatDecimal(val, digits = 1) {
        if (digits < 1) {
            return NumberHelper.format(val);
        }
        const format = '0,0.' + _.repeat('0', digits);
        return NumberHelper._format(val, format);
    }
}
