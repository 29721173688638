<template lang="pug">
    .base-input-label
        .required-label(v-if="required")
            BaseText.required-text(level="4" error) 必須
        .base-input-label-label(
            :class="classes"
        )
            slot
        base-help-icon.ml-10(
            v-if="helpMessage"
            :title="helpTitle"
            :message="helpMessage"
        )
</template>

<script>
import BaseText from './BaseText';
import BaseHelpIcon from './BaseHelpIcon';

export default {
    name: 'BaseInputLabel',
    components: {BaseHelpIcon, BaseText},
    props: {
        disabled: Boolean,
        required: Boolean,
        helpTitle: {
            type: String,
            default: '',
        },
        helpMessage: {
            type: String,
            default: '',
        },
    },
    computed: {
        classes() {
            const isJp = !this.$i18n || this.$i18n.locale === 'ja';

            return {
                'base-input-label-label--disabled': this.disabled,
                'base-input-label-label--required-ja': this.required && isJp,
                'base-input-label-label--required-other': this.required && !isJp,
            };
        },
    },
};
</script>

<style scoped lang="scss">
    .base-input-label {
        height: 16px; // labelがない場合も高さを保つ
        box-sizing: content-box;
        display: flex;
        align-items: center;
        padding-bottom: 10px;

        &-label {

            font-weight: bold;
            font-size: 16px;
            color: map-get($c-black, 80);
            line-height: 16px;

            &--disabled {
                color: $c-text-note
            }
        }
    }
    .required-label {
        border: 1px solid $c-error;
        border-radius: 4px;
        padding: 1px 6px;

        margin-right: 6px;
        .required-text {
            width: 24px;
        }
    }
</style>
