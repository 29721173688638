<template lang="pug">
    v-navigation-drawer.drawer-menu(
        right
        fixed
        clipped
        app
        hide-overlay
        disable-resize-watcher
        touchless
        mobile-break-point="9999"
        :width="$vuetify.breakpoint.width"
        v-model="isActive"
    )
        drawer-item-list(
            :items="drawerItems"
            hide-close
        )
</template>

<script>
import { LINK_URL } from '../../consts/links';
import DrawerItemList from '../../../syncable-commons-components/organisms/DrawerItemList';

/**
     * mobile-break-point="9999"
     * とすることによってv-navigation-drawerを常にmobileモードで使えるようにしています
     * mobileモードじゃない場合はrouteが変わった時に自動で閉じない。
     * PCの場合に:width="$vuetify.breakpoint.width"をやめるなどした場合は変更の検討が必要
     */
export default {
    name: 'LayoutDrawerMenu',
    components: {DrawerItemList},
    computed: {
        isActive: {
            get() {
                return this.$store.state.layout.sidebar;
            },
            set(val) {
                this.$store.dispatch('layout/updateSidebar', val);
            },
        },
        drawerItems() {
            return [
                {
                    title: '団体を探す',
                    childItems: [
                        {
                            href: '/explore/associate/social-challenge',
                            title: '社会課題から探す',
                        },
                        {
                            href: '/associate',
                            title: 'すべての団体から探す',
                        },
                    ],
                },
                {
                    href: '/campaign',
                    title: 'キャンペーンを探す',
                },
                {
                    href: LINK_URL.INQUIRY,
                    title: 'お問い合わせ',
                },
                {
                    href: LINK_URL.FAQ,
                    linkTarget: '_blank',
                    title: 'FAQ',
                },
                {
                    href: LINK_URL.ASSOCIATE,
                    linkTarget: '_blank',
                    title: '掲載希望団体の方へ',
                },
            ];
        },
    },
};
</script>

<style scoped lang="scss">
    /* 支援するボタンのz-indexが1000なので、上に来るように上書き */
    .navigation-drawer {
        z-index: $z-navigation-drawer;
    }

    .drawer-menu {
        overflow: hidden;
        padding-bottom: 0;
    }
</style>
