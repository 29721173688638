import PortfolioReport from '../models/PortfolioReport';
import {bodyShaper} from '../../../syncable-commons-utils/utils/VuexHelper';

const initializeState = () => ({
    pagination: {
        rowsPerPage: 3,
        page: 1,
        total: 0,
    },
    currentIds: [], // portfolio reportsはキャッシュしないことにするのでAssociateなどとは違いcurrentIdsのみ保持しておく
    loading: false,
    input: {
        reportId: '',
        portfolioId: '',
        title: '',
        body: '',
        mainVisual: {
            id: '',
            file: '',
        },
    },
});
export default {
    state: initializeState,
    mutations: {
        fetchByPortfolioIdSuccess(state, {page, total, ids}) {
            state.pagination.page = page;
            state.pagination.total = total;
            state.currentIds = ids;
        },
        startInput(state, {reportId, portfolioId, title, body, mainVisual}) {
            state.input.reportId = reportId;
            state.input.portfolioId = portfolioId;
            state.input.title = title;
            state.input.body = body;
            state.input.mainVisual = mainVisual;
        },
        setInput(state, val = {}) {
            state.input = {...state.input, ...val};
        },
        saveStart(state) {
            state.loading = true;
        },
        saveFinish(state) {
            state.loading = false;
        },
        saveSuccess(state) {
            state.input = initializeState().input;
        },
    },
    actions: {
        async fetchByPortfolioId({commit, state}, {portfolioId, params: {page = initializeState().pagination.page}}) {
            const data = await this.$axios.$get(`/portfolio/${portfolioId}/reports/`, {
                params: {
                    rowsPerPage: state.pagination.rowsPerPage,
                    page: page,
                },
            });
            commit('fetchByPortfolioIdSuccess', {
                page: data.page,
                total: data.count,
                ids: data.results.map(r => r.id),
            });
            await this.$db().model(PortfolioReport).insertOrUpdate({
                data: data.results,
            });
        },
        async fetchByPortfolioIdAndReportId(_, {portfolioId, reportId}) {
            const data = await this.$axios.$get(`/portfolio/${portfolioId}/reports/${reportId}/`);
            await this.$db().model(PortfolioReport).insertOrUpdate({
                data: data,
            });
        },
        updateInput({commit}, val) {
            commit('setInput', val);
        },
        startCreateInput({commit}, {portfolioId}) {
            commit('startInput', {
                ...initializeState().input,
                portfolioId: portfolioId,
            });
        },
        /**
         * 編集開始前に呼ぶ
         * 現時点のPortfolioReportのプロパティの値を、編集用フィールドの初期値として設定する
         */
        startUpdateInput({commit}, {reportId}) {
            const target = this.$db().model(PortfolioReport)
                .query()
                .with('main_visual')
                .where('id', Number(reportId))
                .first();
            commit('startInput', {
                title: target.title,
                body: target.body,
                mainVisual: {
                    id: target.main_visual.id,
                    file: target.main_visual.file,
                },
                portfolioId: target.portfolio_id,
                reportId: reportId,
            });
        },
        async executeSave({state, dispatch, commit}) {
            commit('saveStart');
            const action = state.input.reportId ? '__executeUpdate' : '__executeCreate';
            const data = await dispatch(action).finally(() => {
                commit('saveFinish');
            });
            commit('saveSuccess');
            return data;
        },
        async __executeCreate({state}) {
            let body = bodyShaper({
                title: state.input.title,
                body: state.input.body,
                main_visual: {
                    ...(state.input.mainVisual.file && {file: state.input.mainVisual.file}),
                },
            }, {
                imageKeys: [
                    'main_visual',
                ],
            });
            const data = await this.$axios.$post(`/portfolio/${state.input.portfolioId}/reports/`, body);

            await this.$db().model(PortfolioReport).insert({data: data});

            return data;
        },
        async __executeUpdate({state}) {
            let body = bodyShaper({
                title: state.input.title,
                body: state.input.body,
                main_visual: {
                    id: state.input.mainVisual.id,
                    file: state.input.mainVisual.file,
                },
            }, {
                imageKeys: [
                    'main_visual',
                ],
            });
            const data = await this.$axios.$patch(`/portfolio/${state.input.portfolioId}/reports/${state.input.reportId}/`, body);

            await this.$db().model(PortfolioReport).insertOrUpdate({data: data});

            return data;
        },
        async executeDelete(context, {reportId}) {
            const target = this.$db().model(PortfolioReport).find(Number(reportId));
            await this.$axios.$delete(`/portfolio/${target.portfolio_id}/reports/${target.id}/`);
            // なぜかここで死ぬ...
            // deleteしなくても一覧表示に問題はないのでコメントアウトで目を瞑る
            // await target.$delete();
        },
    },
};
