import {Model} from '@vuex-orm/core';
import User from './User';
import Associate from './Associate';

export default class AssociateMember extends Model {
    static fields() {
        return {
            id: this.string(null),
            message: this.string(''),
            role: this.string(''),
            status: this.string(null),
            user: this.belongsTo(User, 'user_id'),
            user_id: this.attr(null),
            associate: this.belongsTo(Associate, 'associate_id'),
            associate_id: this.attr(null),
        };
    }

    get about() {
        return this.user.profile.description;
    }
}

AssociateMember.entity = 'associateMembers';
