import {Model} from '@vuex-orm/core';
import PortfolioReportMainVisual from './PortfolioReportMainVisual';
import Portfolio from './Portfolio';

export default class PortfolioReport extends Model {
    static entity = 'portfolioReports';

    static fields() {
        return {
            id: this.number(null),
            title: this.string(''),
            body: this.string(''),
            main_visual: this.hasOne(PortfolioReportMainVisual, 'report_id'),

            portfolio: this.belongsTo(Portfolio, 'portfolio_id'),
            portfolio_id: this.number(null),

            created_at: this.string(null),
            updated_at: this.string(null),
        };
    }
}
