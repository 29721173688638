export const state = () => ({
    expansions: {},
});

export const mutations = {
    setExpansions(state, expansions) {
        state.expansions = {...expansions};
    },
};

export const actions = {
    initAccordion({commit}, expansions) {
        commit('setExpansions', expansions);
    },
    initAccordionItem({state}, {ref, name}) {
        if (state.expansions[name]) {
            ref.toggle(ref._uid);
        }
    },
};
