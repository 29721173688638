<template lang="pug">
    .base-input-set
        base-input-label.base-input-set-label(
            v-if="!!label && !hideLabel"
            :help-message="helpMessage"
            :help-title="helpTitle"
            :required="required"
        ) {{label}}
        base-text(v-if="!!explanation") {{explanation}}
        .base-input-set-input
            base-input(
                v-model="localValue"
                :placeholder="placeholder"
                :disabled="disabled"
                :readonly="readonly"
                :appendIcon="computedAppendIcon"
                :prependIcon="prependIcon"
                :required="required"
                :counter="counter"
                :hint="hint"
                :multi-line="multiLine"
                :rules="computedRules"
                :name="computedName"
                :autocomplete="computedAutocomplete"
                :type="computedType"
                :onInput="computedOnInput"
                :twoWaySetter="computedTwoWaySetter"
                :label="label"
            )
</template>

<script>
import BaseText from '../atoms/BaseText';
import BaseInput from '../atoms/BaseInput';
import FormValidator from '../../syncable-commons-utils/utils/FormValidator';
import BaseInputLabel from '../atoms/BaseInputLabel';
import REGEXP from '../../syncable-commons-consts/regexp';
import postalCode from 'japan-postal-code';

const InputTypes = Object.freeze({
    NORMAL: 'normal',
    ID: 'id',
    EMAIL: 'email',
    CC_NUMBER: 'cc-number',
    CC_NAME: 'cc-name',
    CC_CSC: 'cc-csc',
    LAST_NAME: 'last-name',
    FIRST_NAME: 'first-name',
    AREA: 'area',
    CITY: 'city',
    ADDRESS_LEVEL_1: 'address-level-1',
    ADDRESS_LEVEL_2: 'address-level-2',
    ZIP_CODE: 'zip-code',
    PHONE: 'phone',
    NEW_PASSWORD: 'new-password',
    CURRENT_PASSWORD: 'current-password',
    AMOUNT: 'amount',
    SEARCH: 'search',
    URL: 'url',
    TRACKING_ID: 'tracking-id',
});
const InputParams = Object.freeze({
    maxLength: {
        [InputTypes.NORMAL]: 0,
        [InputTypes.ID]: 20,
    },
    name: {
        [InputTypes.NORMAL]: '',
        [InputTypes.ID]: 'id',
        [InputTypes.EMAIL]: 'email',
        [InputTypes.CC_NUMBER]: 'cc-number',
        [InputTypes.CC_NAME]: 'cc-name',
        [InputTypes.CC_CSC]: 'cc-csc',
        [InputTypes.AMOUNT]: 'amount',
        [InputTypes.LAST_NAME]: 'family-name',
        [InputTypes.FIRST_NAME]: 'given-name',
        [InputTypes.ZIP_CODE]: 'postal-code',
        [InputTypes.AREA]: 'address-level1',
        [InputTypes.CITY]: 'address-level2',
        [InputTypes.ADDRESS_LEVEL_1]: 'address-level-1',
        [InputTypes.ADDRESS_LEVEL_2]: 'address-level-2',
        [InputTypes.PHONE]: 'tel',
        [InputTypes.NEW_PASSWORD]: 'new-password',
        [InputTypes.CURRENT_PASSWORD]: 'current-password',
    },
    autocomplete: {
        [InputTypes.NORMAL]: '',
        [InputTypes.EMAIL]: 'email',
        [InputTypes.CC_NUMBER]: 'cc-number',
        [InputTypes.CC_NAME]: 'cc-name',
        [InputTypes.CC_CSC]: 'cc-csc',
        [InputTypes.LAST_NAME]: 'family-name',
        [InputTypes.FIRST_NAME]: 'given-name',
        [InputTypes.ZIP_CODE]: 'postal-code',
        [InputTypes.AREA]: 'address-level1',
        [InputTypes.CITY]: 'address-level2',
        [InputTypes.ADDRESS_LEVEL_1]: 'address-level-1',
        [InputTypes.ADDRESS_LEVEL_2]: 'address-level-2',
        [InputTypes.PHONE]: 'tel',
        [InputTypes.NEW_PASSWORD]: 'new-password',
        [InputTypes.CURRENT_PASSWORD]: 'current-password',
    },
    type: {
        [InputTypes.NORMAL]: 'text',
        [InputTypes.EMAIL]: 'email',
        [InputTypes.CC_NUMBER]: 'cc-number',
        [InputTypes.CC_NAME]: 'cc-name',
        [InputTypes.CC_CSC]: 'cc-csc',
        [InputTypes.AMOUNT]: 'number',
        [InputTypes.LAST_NAME]: 'family-name',
        [InputTypes.FIRST_NAME]: 'given-name',
        [InputTypes.PHONE]: 'tel',
        [InputTypes.NEW_PASSWORD]: 'password',
        [InputTypes.CURRENT_PASSWORD]: 'password',
        [InputTypes.AMOUNT]: 'tel',
    },
    appendIcon: {
        [InputTypes.NORMAL]: '',
        [InputTypes.SEARCH]: 'search',
    },
});
export default {
    name: 'BaseInputSet',
    components: {
        BaseInput,
        BaseInputLabel,
        BaseText,
    },
    props: {
        ...BaseInput.props,
        label: {
            type: String,
            required: false,
            default: '',
        },
        hideLabel: {
            type: Boolean,
            required: false,
            default: false,
        },
        counter: {
            type: String,
            required: false,
            default: undefined,
        },
        maxLength: {
            type: String,
            required: false,
            default: '',
        },
        minVal: {
            type: Number,
            required: false,
            default: 0,
        },
        maxVal: {
            type: Number,
            required: false,
            default: 0,
        },
        errorTemplate: {
            type: Object,
            default: () => ({}),
        },
        inputType: {
            type: String,
            required: false,
            default: InputTypes.NORMAL,
        },
        helpTitle: {
            type: String,
            required: false,
            default: '',
        },
        helpMessage: {
            type: String,
            required: false,
            default: '',
        },
        explanation: {
            type: String,
            required: false,
            default: '',
        },
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        computedRules() {
            let rules = this.rules || [];
            if (this.required) {
                rules.push(v => FormValidator.required(v, this.label, this.errorTemplate.required));
            }
            if (this.computedMaxLength) {
                rules.push(v => FormValidator.maxLength(v, this.computedMaxLength, this.label, this.errorTemplate.maxLength));
            }
            if (this.minVal) {
                rules.push(v => FormValidator.minValue(v, this.minVal, this.label, this.errorTemplate.minVal));
            }
            if (this.maxVal) {
                rules.push(v => FormValidator.maxValue(v, this.maxVal, this.label, this.errorTemplate.maxVal));
            }
            const typeRules = {
                [InputTypes.EMAIL]: [
                    v => FormValidator.pattern(v, REGEXP.EMAIL, this.label, null, this.errorTemplate.correct),
                ],
                [InputTypes.ID]: [
                    v => FormValidator.pattern(v, REGEXP.ALPHA_DASH, this.label, this.errorTemplate.alphanumericAndHyphen, this.errorTemplate.pattern),
                ],
                [InputTypes.CURRENT_PASSWORD]: [
                    v => {
                        if (!this.required && v.length === 0) {
                            return true;
                        }
                        return FormValidator.minLength(v, 6, this.label, this.errorTemplate.minLength);
                    },
                ],
                [InputTypes.NEW_PASSWORD]: [
                    v => {
                        if (!this.required && v.length === 0) {
                            return true;
                        }
                        return FormValidator.minLength(v, 6, this.label, this.errorTemplate.minLength);
                    },
                ],
                [InputTypes.ZIP_CODE]: [
                    v => FormValidator.pattern(v, REGEXP.TEL, this.label, this.errorTemplate.alphanumericAndHyphen, this.errorTemplate.pattern),
                ],
                [InputTypes.PHONE]: [
                    v => FormValidator.pattern(v, REGEXP.TEL, this.label, null, this.errorTemplate.numeric),
                ],
                [InputTypes.CC_CSC]: [
                    v => FormValidator.pattern(v, REGEXP.NUM_HALF, this.label, this.errorTemplate.halfWidthNumber, this.errorTemplate.pattern),
                ],
                [InputTypes.URL]: [
                    v => FormValidator.pattern(v, REGEXP.URL, this.label, null, this.errorTemplate.correct),
                ],
                [InputTypes.AMOUNT]: [
                    v => FormValidator.pattern(v, REGEXP.NUM_HALF, this.label, this.errorTemplate.halfWidthNumber, this.errorTemplate.pattern),
                ],
                [InputTypes.TRACKING_ID]: [
                    v => FormValidator.pattern(v, REGEXP.TRACKING_ID, this.label, null, this.errorTemplate.correct),
                ],
            };
            const additionalRules = typeRules[this.inputType] || [];
            return [
                ...rules,
                ...additionalRules,
            ];
        },
        computedMaxLength() {
            if (this.maxLength) {
                return Number(this.maxLength);
            }
            return Number(InputParams.maxLength[this.inputType || InputTypes.NORMAL]);
        },
        computedName() {
            if (this.name) {
                return this.name;
            }
            return InputParams.name[this.inputType || InputTypes.NORMAL];
        },
        computedAutocomplete() {
            if (this.autocomplete) {
                return this.autocomplete;
            }
            return InputParams.autocomplete[this.inputType || InputTypes.NORMAL];
        },
        computedType() {
            if (this.type) {
                return this.type;
            }
            return InputParams.type[this.inputType || InputTypes.NORMAL];
        },
        computedAppendIcon() {
            if (this.appendIcon) {
                return this.appendIcon;
            }
            return InputParams.appendIcon[this.inputType || InputTypes.NORMAL];
        },
        computedOnInput() {
            const onInput = {
                [InputTypes.ZIP_CODE]: (value = '') => {
                    const numbers = value.replace(/[^0-9]/g, '');
                    if (this.$store.state.layout.lang !== 'ja') {
                        // 日本語のみ
                        return;
                    }
                    if (numbers.length !== 7) {
                        return;
                    }
                    postalCode.get(numbers, (addressObj = {}) => {
                        this.$emit('input-address', addressObj);
                    });
                },
            };
            return onInput[this.inputType];
        },
        computedTwoWaySetter() {
            const twoWaySetter = {
                [InputTypes.ZIP_CODE]: (val) => {
                    return val.replace(/[^0-9]+/g, '');
                },
            };
            return twoWaySetter[this.inputType];
        },
    },
};
</script>
