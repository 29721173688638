<template lang="pug">
    .associate-information-bar
        a(:href="staticAssociateTopHref")
            AssociateLogo(
            :src="associate.logo && associate.logo.file"
            :alt="associate.fullName"
            :size="size"
            )
        .associate-information-bar-body
            AssociateLabelDeductible.mx-10(:deductible="associate.isDeductible")
            .associate-information-bar-name-and-button
                a(:href="staticAssociateTopHref")
                    BaseHeading.mx-10(:level="$vuetify.breakpoint.smAndDown ? '5' : '1'") {{associate.fullName | ellipsis(20)}}
                AssociateFollowButton.associate-information-bar-follow-button(:associate-id="associate.id")
</template>

<script>
import AssociateLogo from '../atoms/AssociateLogo';
import AssociateLabelDeductible from '../atoms/AssociateLabelDeductible';
import BaseHeading from '../../../syncable-commons-components/atoms/BaseHeading';
import AssociateFollowButton from '../atoms/AssociateFollowButton';
import {LINK_NAME, ROUTER_TO} from '../../consts/links';

export default {
    name: 'AssociateInformationBar',
    components: {AssociateFollowButton, BaseHeading, AssociateLabelDeductible, AssociateLogo},
    props: {
        associate: Object,
    },
    computed: {
        size() {
            return this.$vuetify.breakpoint.smAndDown ? '68px' : '120px';
        },
        associateTop() {
            return {
                ...ROUTER_TO[LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.INDEX],
                params: {
                    ...this.$route.params,
                },
            };
        },
        staticAssociateTopHref() {
            return this.$router.resolve(this.associateTop).href;
        },
    },
};
</script>

<style scoped lang="scss">
    .associate-information-bar {
        display: flex;
        align-items: center;
        padding: 15px;
        @include mq(md-and-up) {
            // width: 960px;
            // margin: 0 auto;
            padding: 15px 0;
            align-items: flex-end;
        }
        &-body {
            margin-left: 10px;
            @include mq(md-and-up) {
                margin-left: 20px;
            }
        }
        &-name-and-button {
            @include mq(md-and-down) {
                display: flex;
                align-items: center;
            }
        }
        &-follow-button {
            @include mq(md-and-up) {
                margin: 20px 10px 0 10px;
            }
        }
    }
</style>
