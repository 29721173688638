<template lang="pug">
    .layout-auth-content
        .layout-auth-content-container
            BaseText(level="2" center)
                | アカウントをお持ちでない方は
                BaseText(
                level="2"
                inline
                highlighted
                is-link
                @click="openRegisterDialog"
                ) 新規登録

            BaseText.mt-15(level="2" center)
                | 以前のデータを引き継ぐ方は
                BaseText(
                level="2"
                inline
                highlighted
                :to="migration" @click="closeAuthDialog"
                ) こちら

        BaseDivider

        .layout-auth-content-container
            .mb-20
                BaseHeading(
                level="3"
                center
                ) SNSでログイン
            .mb-20
                BaseButtonFacebook(
                @click="clickFB"
                )
            // .mb-20
                base-button-twitter

        BaseDivider

        .layout-auth-content-container
            UserLoginForm(
            @success="finishAuth"
            )
</template>

<script>
import BaseHeading from '../../../syncable-commons-components/atoms/BaseHeading';
import BaseDivider from '../../../syncable-commons-components/atoms/BaseDivider';
import BaseText from '../../../syncable-commons-components/atoms/BaseText';
import BaseButtonFacebook from '../../../syncable-commons-components/molecules/BaseButtonFacebook';
import BaseButtonTwitter from '../../../syncable-commons-components/molecules/BaseButtonTwitter';
import UserLoginForm from '../molecules/UserLoginForm';
import {LINK_NAME, ROUTER_TO} from '../../consts/links';
import {mapActions} from 'vuex';

export default {
    name: 'LayoutAuthContentLogin',
    components: {
        UserLoginForm,
        BaseButtonTwitter,
        BaseButtonFacebook,
        BaseText,
        BaseDivider,
        BaseHeading,
    },
    data() {
        return {
            migration: {
                ...ROUTER_TO[LINK_NAME.AUTH.MIGRATION.INDEX],
            },
        };
    },
    methods: {
        ...mapActions('layout', {
            openRegisterDialog: 'openRegisterDialog',
            closeAuthDialog: 'closeAuthDialog',
            finishAuth: 'finishAuth',
        }),
        ...mapActions('auth', {
            loginWithFB: 'loginWithFB',
        }),
        async clickFB() {
            try {
                await this.loginWithFB();
                await this.finishAuth();
            } catch (e) {
                // 未登録でfacebookログインした時、サーバーエラーを返して新規登録を促す作りになっている
                // その時4xx系の画面に遷移して欲しくないので握りつぶす
            }
        },
    },
};
</script>

<style scoped lang="scss">
    .layout-auth-content {
        &-container {
            padding: 22.5px;
        }
    }
</style>