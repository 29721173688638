import AssociateBusiness from '../models/AssociateBusiness';

const state = () => ({
    browsingId: '',
});

export default {
    state,
    mutations: {
        setBrowsingId(state, val) {
            state.browsingId = val;
        },
    },
    actions: {
        async onLandedBusinessPage({commit, dispatch}, val) {
            await commit('setBrowsingId', val);
            await dispatch('fetchById', val);
        },
        async onExitBusinessPage({commit}) {
            setTimeout(() => {
                commit('setBrowsingId', '');
            }, 0);
        },
        async fetchById({dispatch}, businessId) {
            const data = await this.$axios.$get(`/associate/business/${businessId}/`).catch(() => null);
            if (!data) {
                return;
            }
            await dispatch('insertOrUpdate', {data: data});
        },
    },
    getters: {
        browsingBusiness: state => store => {
            const AssociateBusinessModel = store.$db().model(AssociateBusiness);
            return AssociateBusinessModel
                .query()
                .where('id', Number(state.browsingId))
                .withAllRecursive()
                .first() || new AssociateBusinessModel();
        },
    },
};
