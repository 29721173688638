import {Model} from '@vuex-orm/core';
import CustomAuthor from './CustomAuthor';

export default class CustomAuthorImage extends Model {
    static fields() {
        return {
            id: this.attr(null),
            file: this.attr(''),

            custom_author: this.belongsTo(CustomAuthor, 'custom_author_id'),
            custom_author_id: this.attr(null),
        };
    }

    get doesImageExists() {
        return this.file !== undefined
            && this.file !== null
            && this.file !== '';
    }

    toPostableJson() {
        return Object.assign({
            id: this.id,
            file: this.file,
            custom_author_id: this['custom_author_id'],
        });
    }
}

CustomAuthorImage.entity = 'customAuthorImages';
