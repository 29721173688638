import Campaign from '../models/Campaign';
import DonationMessage from '../models/DonationMessage';

const initializeState = () => ({
    browsingId: '',
    browsingSupportFundingIds: [],
    search: {
        params: {
            sortBy: '-created_at',
            pickup: null,
            limit: null,
            // 一覧ページ用のフィルターとソート
            dList: null,
            associate_name_or_title: null,
        },
        pagination: {
            descending: true,
            page: 1,
            rowsPerPage: 18,
            total: 0,
            totalPages: 1,
        },
        results: {},
        currentKey: '',
    },
    /**
     * CPページを見てから団体の通常寄付ページに行った場合にもCPへの寄付とするためにキャッシュするcampaign id
     * 団体の通常寄付ページに行く前に最後に見たCPページが当該団体のものであればCPへの寄付とする。
     */
    donationCacheId: '',
});

export default {
    state: initializeState,
    mutations: {
        setBrowsingId(state, val) {
            state.browsingId = Number(val);
        },
        setDonationCacheId(state, val) {
            state.donationCacheId = Number(val);
        },
        setSearchConditions(state, val = {}) {
            const page = val.page;
            delete val.page;
            const pagination = {
                page: parseInt(page || '1', 10),
            };
            const defaultParams = initializeState().search.params;
            const newParams = {
                ...state.search.params,
                ...defaultParams,
                ...val,
            };
            const newPagination = {
                ...state.search.pagination,
                ...pagination,
            };
            state.search = {
                ...state.search,
                ...{params: newParams},
                ...{pagination: newPagination},
            };
        },
        setSearchResults(state, val = {}) {
            state.search.results = {...state.search.results, ...val};
        },
        setSearchPagination(state, val = {}) {
            state.search.pagination = {...state.search.pagination, ...val};
        },
        setSearchCurrentKey(state, val = '') {
            state.search.currentKey = val;
        },
        setBrowsingSupportFundingIds(state, val) {
            state.browsingSupportFundingIds = val;
        },
    },

    actions: {
        async onLandedCampaignPage({commit, dispatch}, campaignId) {
            await commit('setBrowsingId', campaignId);

            await Promise.all([
                dispatch('fetchById', campaignId),
                this.$db().model(DonationMessage).dispatch('fetchByCampaignId', {
                    campaignId: campaignId,
                    offset: 0,
                    limit: 3,
                }),
            ]);

            // キャンペーンページを見た後に、キャンペーン対象団体の通常寄付ページに行った場合もキャンペーン経由の寄付とする対応
            const campaign = await this.$db().model(Campaign).query().where('id', Number(campaignId)).first();
            if (campaign && campaign.isDonatable) {
                commit('setDonationCacheId', campaignId);
            }
            await dispatch('fetchSupportFundingCampaigns', campaignId);
        },
        async fetch({commit, dispatch, state, getters}, condition) {
            await commit('setSearchConditions', condition);
            if (!state.search.results[getters.resultsKey]) {
                await dispatch('_fetchAndCache');
            }
            const result = state.search.results[getters.resultsKey] || {};
            commit('setSearchPagination', result.pagination);
            commit('setSearchCurrentKey', getters.resultsKey);
        },
        async _fetchAndCache({commit, dispatch, state, getters}) {
            const data = await this.$axios.$get('/campaign/',
                {
                    params: getters.searchParams,
                    ext: {
                        longtime: true,
                    },
                },
            );
            await dispatch('insertOrUpdate', {data: data.results});

            await commit('setSearchResults', {
                [getters.resultsKey]: {
                    ids: data.results.map(r => r.id),
                    pagination: {
                        total: data.count,
                        totalPages: Math.ceil(data.count / state.search.pagination.rowsPerPage),
                    },
                },
            });
        },
        async fetchById(_, campaignId) {
            const CampaignModel = this.$db().model(Campaign);
            const campaign = await CampaignModel.query().where('id', Number(campaignId)).first();
            if (!campaignId || (campaign && campaign.is_cached)) {
                // すでにキャッシュしている場合は取らない
                return;
            }
            const data = await this.$axios.$get(`/campaign/${campaignId}/`).catch(() => null);
            if (!data) {
                return;
            }
            await CampaignModel.insertOrUpdate({
                data: {
                    ...data,
                    is_cached: true,
                },
            });
        },
        async refreshById(_, campaignId) {
            const data = await this.$axios.$get(`/campaign/${campaignId}/`);
            await this.$db().model(Campaign).insertOrUpdate({
                data: {
                    ...data,
                    is_cached: true,
                },
            });
        },
        async fetchSupportFundingCampaigns({commit, dispatch}, campaignId) {
            const data = await this.$axios.$get(`/campaign/support-funding/?campaign_id=${campaignId}`);
            await dispatch('insertOrUpdate', {data: data});
            commit('setBrowsingSupportFundingIds', data.map(campaign => campaign.id));
        },
    },

    getters: {
        browsingCampaign: state => store => {
            return store.$db().model(Campaign)
                .query()
                .where('id', state.browsingId)
                .withAllRecursive()
                .first() || Campaign.newInstance();
        },
        browsingSupportFundingCampaigns: state => store => {
            return store.$db().model(Campaign)
                .query()
                .whereIdIn(state.browsingSupportFundingIds)
                .withAllRecursive()
                .get();
        },
        donationCacheCampaign(state) {
            return Campaign
                .query()
                .where('id', state.donationCacheId)
                .withAllRecursive()
                .first() || Campaign.newInstance();
        },
        searchParams(state) {
            return {
                ...state.search.params,
                page: state.search.pagination.page,
                rowsPerPage: state.search.pagination.rowsPerPage,
            };
        },
        resultsKey(state, getters) {
            return Object.values(getters.searchParams).toString();
        },
        currentIds(state) {
            const result = state.search.results[state.search.currentKey] || {};
            return result.ids || [];
        },
    },
};
