import {Model} from '@vuex-orm/core';
import {
    ASSOCIATE_SOCIAL_CHALLENGE_VALUE,
    ASSOCIATE_SOCIAL_CHALLENGE_VALUE_TEXT,
} from '../../../syncable-commons-consts/enums';

export default class AssociateSocialChallenge extends Model {
    static fields() {
        return {
            id: this.number(null),
            value: this.number(ASSOCIATE_SOCIAL_CHALLENGE_VALUE.OTHERS),
            created_at: this.string(null),
            updated_at: this.string(null),
            associate: this.attr(null),

            // associate_id: this.attr(null)
        };
    }

    get text() {
        return ASSOCIATE_SOCIAL_CHALLENGE_VALUE_TEXT[this.value];
    }
}

AssociateSocialChallenge.entity = 'associateSocialChallenges';
