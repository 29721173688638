import {Model} from '@vuex-orm/core';
import Donor from './Donor';
import {DONATION_STATUS, DONATION_TYPE, PAYMENT_METHOD} from '../../../syncable-commons-consts/enums';
import Portfolio from './Portfolio';
import PortfolioRevision from './PortfolioRevision';

export default class PortfolioDonation extends Model {
    static fields() {
        return {
            id: this.number(null),
            amount: this.number(0),
            payment_method: this.number(PAYMENT_METHOD.CREDIT_CARD),
            payment_id: this.string(null),
            type: this.number(DONATION_TYPE.NORMAL),
            status: this.number(DONATION_STATUS.BEFORE_PAYMENT),
            is_recurring: this.boolean(false),
            recurring_donation: this.number(null),
            commission: this.number(0),
            message: this.string(''),
            need_receipt: this.boolean(false),
            need_news_letter: this.boolean(false),

            portfolio: this.belongsTo(Portfolio, 'portfolio_id'),
            portfolio_revision: this.belongsTo(PortfolioRevision, 'portfolio_revision_id'),
            portfolio_id: this.attr(null),
            portfolio_revision_id: this.attr(null),

            created_at: this.string(''),
            updated_at: this.string(''),
            paid_at: this.string(''),

            donor: this.hasOne(Donor, 'donation_id'),
            user_id: this.number(null),
        };
    }
}

PortfolioDonation.entity = 'portfolioDonations';
