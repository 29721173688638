<template lang="pug">
    v-divider.base-divider(
    :style="styles"
    :class="classes"
    )
</template>

<script>
export default {
    name: 'BaseDivider',
    props: {
        width: String,
        black: Boolean,
    },
    computed: {
        styles() {
            return {
                width: this.width || false,
            };
        },
        classes() {
            return {
                'base-divider--black': this.black,
            };
        },
    },
};
</script>

<style scoped lang="scss">
    .base-divider {
        max-height: 1px;

        &.divider {
            background-color: $c-divider;
        }

        &--black {
            &.divider {
                background-color: $c-divider-black;
            }
        }
    }
</style>