<template lang="pug">
    v-checkbox.base-check-box.base-selection-controls(
        v-model="localValue"
        :class="classes"
        :value="checkValue"
        :required="required"
        :disabled="disabled"
        :rules="computedRules"
        :hide-details="hideDetails"
    )
        template(slot="label")
            slot {{label}}
</template>

<script>
import BaseText from './BaseText';
import FormValidator from '../../syncable-commons-utils/utils/FormValidator';
import _ from 'lodash';

export default {
    name: 'BaseCheckBox',
    components: {BaseText},
    props: {
        value: {
            type: undefined,
            required: true,
        },
        /**
         * checkした時にv-modelに入る値
         * 未設定の場合はtrue/false
         */
        checkValue: {
            type: undefined,
            default: null,
            required: false,
        },
        label: {
            type: String,
            default: '',
        },
        rules: {
            type: Array,
            default: () => [],
        },
        required: Boolean,
        disabled: Boolean,
        hideDetails: Boolean,
        errorMessage: {
            type: String,
            default: '',
        },
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        classes() {
            return {
                'base-check-box--hide-details': this.hideDetails,
            };
        },
        computedRules() {
            let rules = [...this.rules];
            if (this.required) {
                rules.push(v => {
                    let result = _.isArray(v) ? FormValidator.requiredList(v, this.label) : FormValidator.required(v, this.label);
                    return result !== true ? (this.errorMessage || result) : result;
                });
            }
            return rules;
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "BaseSelectionControls";

    .base-check-box.input-group.checkbox {
        & /deep/ {
            display: block;
            position: relative;
            line-height: 0 !important;

            label {
                left: 24px;
                width: calc(100% - 24px)
            }

            .input-group__input {
                position: absolute;
                top: 0;
                left: 0;
            }

            .input-group__details {
                font-size: 12px;
                line-height: 12px;
                padding-top: 8px;
                padding-bottom: 4px;
                min-height: 24px;
            }
        }

        &.base-check-box--hide-details {
            & /deep/ {
                .input-group__details {
                    // v-checkboxのpropsのhide-detailsはこの要素は残るので消す
                    display: none;
                }
            }
        }

        &.input-group {
            &--required {
                & /deep/ {
                    label:after {
                        content: ""
                    }
                }
            }
        }
    }
</style>