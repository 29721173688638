import {Model} from '@vuex-orm/core';
import AssociateDonationSettingPriceImage from './AssociateDonationSettingPriceImage';
import {DONATION_SETTING_PRICE_TYPE} from '../../../syncable-commons-consts/enums';

export default class AssociateDonationSettingPrice extends Model {
    static fields() {
        return {
            id: this.attr(null),

            message: this.string(false),
            price: this.number(null),
            type: this.number(DONATION_SETTING_PRICE_TYPE.ONETIME),

            image: this.hasOne(AssociateDonationSettingPriceImage, 'donation_setting_price'),

            donation_setting: this.attr(null),
        };
    }

    isOnetime() {
        return this.type === DONATION_SETTING_PRICE_TYPE.ONETIME;
    }

    isRecurring() {
        return this.type === DONATION_SETTING_PRICE_TYPE.RECURRING;
    }
}

AssociateDonationSettingPrice.entity = 'associateDonationSettingPrices';
