<template lang="pug">
    i.base-icon(
    :class="classes"
    :style="styles"
    @click="$emit('click')"
    )
</template>

<script>
import _ from 'lodash';

export default {
    name: 'BaseIcon',
    props: {
        icon: String,
        level: {
            type: String,
            default: '3',
            validator(val) {
                return _.includes(['1', '2', '3', '4', '5'], val);
            },
        },
        /**
             * サイズを固定で指定したい場合に指定
             * width, height, font-sizeがこの値になる
             */
        size: {
            type: String,
            required: false,
        },
        highlighted: Boolean,
        black: Boolean,
        white: Boolean,
        note: Boolean,
    },
    computed: {
        classes() {
            return {
                [`icon-${this.icon}`]: !!this.icon,
                [`base-icon-${this.level}`]: (!!this.level && !this.size),
                'base-icon--highlighted': this.highlighted,
                'base-icon--black': this.black,
                'base-icon--white': this.white,
                'base-icon--note': this.note,
            };
        },
        styles() {
            return {
                'font-size': this.size,
                'width': this.size,
                'height': this.size,
            };
        },
    },
};
</script>

<style scoped lang="scss">
    .base-icon {
        color: $c-text;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        @for $i from 1 through 5 {
            &.base-icon-#{$i} {
                font-size: map-get(map-get($text, $i), fs);
                width: map-get(map-get($text, $i), fs);
                height: map-get(map-get($text, $i), lh);
            }
        }
        &--highlighted {
            color: $c-primary;
        }
        &--black {
            color: $c-text-black;
        }
        &--white {
            color: $c-white-base;
        }
        &--note {
            color: $c-text-note;
        }
    }
</style>
<style lang="scss">
    .icon-support {
        width: 35px;
        height: 20px;
    }
</style>