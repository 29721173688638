import {Model} from '@vuex-orm/core';
import Donor from './Donor';
import {DONATION_STATUS, DONATION_TYPE, PAYMENT_METHOD} from '../../../syncable-commons-consts/enums';
import Associate from './Associate';
import DonationMessage from './DonationMessage';
import PortfolioDonation from './PortfolioDonation';
import UniqueDonor from './UniqueDonor';
import User from './User';
import DonationCommission from './DonationCommission';
import Receipt from './Receipt';

export default class Donation extends Model {
    static fields() {
        return {
            id: this.number(null),
            amount: this.number(0),
            is_user: this.boolean(false),
            associate: this.belongsTo(Associate, 'associate_id'),
            payment_method: this.number(PAYMENT_METHOD.CREDIT_CARD),
            payment_id: this.string(null),
            type: this.number(DONATION_TYPE.NORMAL),
            fund: this.number(null),
            project: this.number(null),
            minipost: this.number(null),
            status: this.number(DONATION_STATUS.BEFORE_PAYMENT),
            is_recurring: this.boolean(false),
            need_receipt: this.boolean(false),
            recurring_donation: this.number(null),
            commission: this.belongsTo(DonationCommission, 'commission_id'),
            commission_id: this.attr(null),
            message: this.string(''),
            created_at: this.string(''),
            updated_at: this.string(''),
            paid_at: this.string(''),
            portfolio_donation: this.hasOne(PortfolioDonation, 'portfolio_donation_id'),
            is_commission_contains: this.boolean(false),

            user: this.belongsTo(User, 'user_id'),
            user_id: this.number(null),

            associate_id: this.string(''),
            customer_id: this.string(''),
            donation_id: this.number(null),
            portfolio_donation_id: this.number(null),
            donor: this.hasOne(Donor, 'donation_id'),
            donation_message: this.hasOne(DonationMessage, 'donation_id'),

            unique_donor: this.belongsTo(UniqueDonor, 'unique_donor_id'),
            unique_donor_id: this.attr(null),
            receipt: this.hasOne(Receipt, 'donation_id'),
        };
    }

    get profile() {
        if (this.is_user && this.user.private_profile.isFilledProfile) {
            return this.user.private_profile;
        }

        return this.donor;
    }
}

Donation.entity = 'donations';
