import {Model} from '@vuex-orm/core';

export default class AssociateProfile extends Model {
    static fields() {
        return {
            id: this.attr(null),
            summary: this.string(null),
            representative_name: this.string(null),
            zip_code: this.string(null),
            prefecture: this.string(null),
            address: this.string(null),
            building: this.string(null),
            phone: this.string(null),
            hp_url: this.string(null),
            address_is_public: this.boolean(true),
            representative_name_is_public: this.boolean(true),
            phone_is_public: this.boolean(true),
            sns_id_twitter: this.string(null),

            associate: this.attr(null),
            // associate_id: this.attr(null)
        };
    }

    static newInstance() {
        return new AssociateProfile();
    }
}

AssociateProfile.entity = 'associateProfiles';
