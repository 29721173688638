export const state = () => ({
    isAccessible: false,
});

export const mutations = {
    updateIsAccessible(state, val) {
        state.isAccessible = Boolean(val);
    },
};

export const actions = {
    updateAccessibility({commit}, val) {
        commit('updateIsAccessible', val);
    },
};

export const getters = {
    isAccessible(state) {
        return state.isAccessible;
    },
};
