<script>
import _ from 'lodash';
import {isNextDeployedRoute} from '../../syncable-commons-consts/partial-deployment';

export default {
    name: 'BaseText',
    props: {
        level: {
            type: String,
            default: '3',
            validator(val) {
                return _.includes(['1', '2', '3', '4', '5'], val);
            },
        },
        /**
             * font-sizeとline-heightにheadingのものが適用される
             */
        big: Boolean,
        bold: Boolean,
        highlighted: Boolean,
        pre: Boolean,
        black: Boolean,
        white: Boolean,
        note: Boolean,
        center: Boolean,
        pcCenter: Boolean,
        right: Boolean,
        error: Boolean,
        ellipsis: Boolean,
        underLine: Boolean,
        textDecorationUnderLine: Boolean,
        inline: Boolean,
        to: {
            type: [Object, String],
            required: false,
            default: null,
        },
        target: {
            type: String,
            required: false,
            default: null,
        },
        href: {
            type: String,
            required: false,
            default: null,
        },
        isLink: Boolean,
        required: Boolean,
    },
    computed: {
        classes() {
            return {
                'base-text': true,
                [`base-text-${this.level}`]: !!this.level,
                'base-text--big': this.big,
                'base-text--bold': this.bold,
                'base-text--highlighted': this.highlighted,
                'base-text--inline': this.inline,
                'base-text--black': this.black,
                'base-text--white': this.white,
                'base-text--note': this.note,
                'base-text--center': this.center,
                'base-text--pc--center': this.pcCenter,
                'base-text--right': this.right,
                'base-text--error': this.error,
                'base-text--ellipsis': this.ellipsis,
                'base-text--underLine': this.underLine,
                'base-text--textDecoration--underLine': this.textDecorationUnderLine,
                'base-text--pre': this.pre,
                'base-text--link': this.isLink,
                'base-text--required': this.required,
            };
        },
    },
    methods: {
        click(e) {
            this.$emit('click', e);
        },
    },
    render(createElement) {
        const tag = 'div';
        const data = {
            class: this.classes,
            on: {
                click: this.click,
            },
        };
        const el = createElement(tag, data, this.$slots.default);

        if (this.href) {
            // hrefがあれば'a'にする
            const routerTag = 'a';
            let routerData = {
                attrs: {},
            };
            if (this.href) routerData.attrs.href = this.href;
            if (this.target) routerData.attrs.target = this.target;
            return createElement(routerTag, routerData, [el]);
        }
        if (this.to) {
            // toがあれば'nuxt-link'にする
            const shouldBePlainAnchor = isNextDeployedRoute(this.to);

            const routerTag = shouldBePlainAnchor ? 'a' : 'nuxt-link';
            const routerData = shouldBePlainAnchor ? {
                attrs: {
                    href: this.$router.resolve(this.to).href,
                },
            } : {
                props: {
                    to: this.to,
                },
            };
            return createElement(routerTag, routerData, [el]);
        }

        return el;
    },
};
</script>

<style scoped lang="scss">
    @mixin font-color($color) {
        color: $color;
    }

    .base-text {
        color: $c-text;
        font-weight: 500;
        &--bold {
            font-weight: bold;
        }
        &--highlighted {
            @include font-color($c-primary);
        }
        &--black {
            @include font-color($c-text-black);
        }
        &--white {
            @include font-color($c-white-base);
        }
        &--note {
            @include font-color($c-text-note);
        }
        &--center {
            text-align: center;
        }
        &--pc--center {
            @include mq(md-and-up) {
                text-align: center;
            }
        }
        &--right {
            text-align: right;
        }
        &--error {
            @include font-color($c-text-error);
        }
        &--ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        &--inline {
            display: inline-block;
        }
        &--underLine {
            border-bottom: $border-primary;
        }
        &--textDecoration--underLine {
            text-decoration: underline;
        }
        &--pre {
            white-space: pre-wrap; /* Since CSS 2.1 */
            white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
            white-space: -o-pre-wrap; /* Opera 7 */
            word-wrap: break-word; /* Internet Explorer 5.5+ */
        }
        &--link {
            cursor: pointer;
        }
        &--required::after {
            content: '*'
        }
    }

    @for $i from 1 through 5 {
        .base-text-#{$i} {
            font-size: map-get(map-get($text, $i), fs);
            line-height: map-get(map-get($text, $i), lh);
            &.base-text--big{
                // headingのサイズを適用する
                font-size: map-get(map-get($heading, $i), fs);
                line-height: map-get(map-get($heading, $i), lh);
            }
        }
    }
</style>
