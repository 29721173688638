<template lang="pug">
    v-toolbar.layout-header(
        :height="height"
        app
        clipped-right
        card
        drawer
        :fixed="true"
        scroll-off-screen
        :class="classes"
        :extension-height="additionalHeight"
        :prominent="!!additionalContent"
        :manual-scroll="!showHeader"
    )
        v-toolbar-title
            a(:href="indexHref")
                BaseLogo
        v-toolbar-items.hidden-sm-and-down.s-toolbar-items.text-menu
            BaseText(:to="to.findAssociates") 団体を探す
            BaseText(:to="to.findCampaigns") キャンペーンを探す
            BaseText(
                :href="rawUrls.forAssociateUser"
                target="_blank"
            ) 掲載希望団体の方へ

        v-spacer

        v-toolbar-items.s-toolbar-items.login-button-wrapper
            BaseButton.small-button(
                v-if="!isLoggedIn"
                white
                small
                @click="openLoginDialog"
            ) ログイン

            LayoutUserMenuIcon(
                v-if="isLoggedIn"
                :user="me"
            )

        v-toolbar-items.hidden-md-and-up.s-toolbar-items
            BaseHamburger(
                @click.native="toggleSidebar"
                :active="sidebar"
            )
        v-layout(
            slot="extension"
            v-if="additionalContent && !sidebar"
            column
        )
            v-flex(xs12)
                BaseDivider
            v-flex(xs12)
                AssociateHeaderContent(
                    v-show="showAssociate"
                    :show-info="showInfo"
                    :associate="associate"
                )
                //- 以下はお知らせ用の表示エリア
                //- ヘッダーの下に細い緑色のエリアがぴょろっと出る
                //-
                //- ポートフォリオ機能が実装された時に、告知用として用意された
                //- 用があるならアンコメントして使う
                //-
                //- nuxt-link.layout-header-news(
                //- v-show="showNews"
                //- :to="newsLink"
                //- )
                //-     BaseText(
                //-     :level="$vuetify.breakpoint.xsOnly ? '5' : '2'"
                //-     white
                //-     )
                //-         | &lt;NEWS&gt;【ここにお知らせ文言を書く】
                //-         span.layout-header-news-link 詳しくはこちら
</template>

<script>
import {mapActions} from 'vuex';
import {LINK_NAME, LINK_URL, ROUTER_TO} from '../../consts/links';
import BaseDivider from '../../../syncable-commons-components/atoms/BaseDivider';
import AssociateHeaderTab from '../molecules/AssociateHeaderTab';
import {HeaderAdditionalContents} from '../../store/layout';
import AssociateHeaderContent from '../molecules/AssociateHeaderContent';
import Associate from '../../orm/models/Associate';
import _ from 'lodash';
import User from '../../orm/models/User';
import BaseAvatarIcon from '../../../syncable-commons-components/atoms/BaseAvatarIcon';
import BaseButton from '../../../syncable-commons-components/atoms/BaseButton';
import BaseIcon from '../../../syncable-commons-components/atoms/BaseIcon';
import LayoutUserMenuIcon from './LayoutUserMenuIcon';
import BaseLogo from '../../../syncable-commons-components/atoms/BaseLogo';
import BaseText from '../../../syncable-commons-components/atoms/BaseText';
import BaseHamburger from '../../../syncable-commons-components/atoms/BaseHamburger';

export default {
    name: 'LayoutHeader',
    components: {
        BaseHamburger,
        BaseText,
        BaseLogo,
        LayoutUserMenuIcon,
        BaseIcon,
        BaseButton,
        BaseAvatarIcon,
        AssociateHeaderContent,
        AssociateHeaderTab,
        BaseDivider,
    },
    data() {
        return {
            to: {
                index: ROUTER_TO[LINK_NAME.INDEX],
                findAssociates: ROUTER_TO[LINK_NAME.EXPLORE.ASSOCIATE.INDEX],
                findCampaigns: ROUTER_TO[LINK_NAME.CAMPAIGN.INDEX],
                findPortfolios: ROUTER_TO[LINK_NAME.PORTFOLIO.INDEX],
            },
            rawUrls: {
                forAssociateUser: LINK_URL.ASSOCIATE,
            },
        };
    },
    computed: {
        indexHref() {
            return this.$router.resolve(ROUTER_TO[LINK_NAME.INDEX]).href;
        },
        Associate() {
            return this.$store.$db().model(Associate);
        },
        User() {
            return this.$store.$db().model(User);
        },
        isLoggedIn() {
            return this.$store.getters['auth/isLoggedIn'];
        },
        me() {
            return this.User.getters('me')(this.$store);
        },
        sidebar() {
            return this.$store.state.layout.sidebar;
        },
        classes() {
            return {
                'is-extended': this.additionalContent,
            };
        },
        height() {
            // return LayoutHelper.calcPxFromRem(120);
            return '60px';
        },
        additionalHeight() {
            if (!this.additionalContent) {
                return '0px';
            }
            if (this.showInfo) {
                return '147px';
            }
            if (this.showAssociate) {
                return '50px';
            }
            if (this.showNews) {
                return this.$vuetify.breakpoint.xsOnly ? '30px' : '60px';
            }
            return '';
        },
        showHeader() {
            return this.$store.state.layout.header.show;
        },
        additionalContent() {
            return this.$store.state.layout.header.additionalContent;
        },
        showAssociate() {
            return _.includes([
                HeaderAdditionalContents.ASSOCIATE,
                HeaderAdditionalContents.ASSOCIATE_INFO,
            ], this.additionalContent);
        },
        showInfo() {
            return this.additionalContent === HeaderAdditionalContents.ASSOCIATE_INFO;
        },
        showNews() {
            return this.additionalContent === HeaderAdditionalContents.NEWS;
        },
        // newsLink() {
        //     return ROUTER_TO[LINK_NAME.INDEX];
        // },
        associate() {
            if (!this.showAssociate) {
                return {};
            }
            return this.Associate.getters('browsingAssociate')(this.$store);
        },
    },
    methods: {
        ...mapActions('layout', {
            toggleSidebar: 'toggleSidebar',
            openLoginDialog: 'openLoginDialog',
        }),
    },
};
</script>

<style scoped lang="scss">
    .layout-header {
        background-color: $c-header !important;
        border-bottom: $border-default;

        &.is-extended {
            border-bottom: none;
        }

        // &-news {
        //     background-color: $c-primary;
        //     height: 30px;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     @include mq(sm-and-up) {
        //         height: 60px;
        //     }

        //     &-link {
        //         text-decoration: underline !important;
        //     }
        // }

        .s-toolbar-items {
            display: flex;
            align-items: center;
        }

        .text-menu {
            margin-left: 60px;

            & > *:not(:first-child) {
                margin-left: 30px;
            }
        }

        .login-button-wrapper {
            margin-right: 8px;
            @include mq(md-and-up) {
                margin-right: 16px;
            }
        }
    }

    .small-button {
        // vuetifyの.btn--smallがv-toolbar-itemsのスタイルに打ち消されてしまうみたいなので
        height: 28px;
    }
</style>
