import { mapState } from 'vuex';
import _ from 'lodash';

export const mapStatesTwoWay = (namespace, states, updateCb) => {
    const mappedStates = mapState(namespace, states);
    const res = {};
    Object.keys(mappedStates).forEach((key) => {
        res[key] = {
            get() {
                return mappedStates[key].call(this);
            },
            set(value) {
                updateCb.call(this, {[key]: value});
            },
        };
    });
    return res;
};

/**
 * request bodyを共通で整形する用
 * @param body
 * @param imageKeys
 * @param dateKeys
 * @returns {*}
 */
export const bodyShaper = (body, {imageKeys, dateKeys}) => {
    const imageArrayProcessor = (array) => {
        return _.reduce(array, (result, val) => {
            if (_.isEmpty(val)) {
                return result;
            }
            result.push(val);
            return result;
        }, []);
    };
    return _.reduce(body, (result, val, key) => {
        if (_.includes(imageKeys, key)) {
            if (_.isEmpty(val)) {
                // 画像の場合,空objectはrequestに含めない
                return result;
            }
            if (_.isArray(val)) {
                val = imageArrayProcessor(val);
            }
        }
        if (_.includes(dateKeys, key)) {
            // djangoのdate型のためにfalseなら明示的にnullとする
            val = val || null;
        }
        result[key] = val;
        return result;
    }, {});
};

/**
 * axiosのエラーハンドラー
 *
 * @param store
 * @param redirect
 * @param error
 * @returns {function(*=): *}
 */
export const axiosErrorHandler = ({store, error}) => {
    const errorMap = {
        'invalid_grant': 'メールアドレスかパスワードに誤りがあります。<br>※ 2018年11月以前に登録を行っている方は、以前のパスワードを再設定する必要があります。',
    };
    const errorStatusCodeMap = {
        401: 'ログインが必要です。',
    };
    const errorOriginalCodeMap = {
        8000001: 'すでに登録済みのメールアドレスです。<br>「メールアドレスでログイン」よりパスワードを入力してログインください。<br>※ 2018年11月以前に登録を行っている方は、Syncableのリニューアルに伴い、それ以前の会員情報の引き継ぎ作業が必要になります。「以前のデータを引き継ぐ方はこちら」の「Facebookでログイン」より引き継ぎ作業をお願いいたします。',
    };

    const errorMessage = (responseData = {}) => {
        // すごく適当に出している。あと言語。
        let message = '';
        if (responseData) {
            let code = responseData.error;
            let statusCode = responseData.status_code;
            let originalCode = responseData.code;
            message = errorOriginalCodeMap[originalCode] ||
                errorMap[code] ||
                errorStatusCodeMap[statusCode] ||
                responseData.message ||
                responseData.error_description ||
                responseData.detail ||
                '';
            if (!message) {
                _.each(responseData, (val, key) => {
                    if (_.isArray(val)) {
                        // djangoのデフォルトのバリデーションエラーである可能性が高い
                        // 分かりにくいがstringにして表示しておく.
                        message += `${key}:${val}<br>`;
                    } else if (val.message) {
                        // 最良ではないが、少しでも汎用エラーを避けるためにメッセージがある場合はそれを表示するための対応
                        // 上記でバリデーションエラーとあるが、Arrayでない場合もある
                        // 項目名がkeyで直接オブジェクトが紐づき、messageがエラーメッセージとなる
                        // ※少なくても1件のエラーの場合にはArrayでない
                        message += `${key}:${val.message}<br>`;
                    }
                });
            }
        }
        return message || 'エラーが発生しました。再度お試しください。<br>この状態が続く場合はしばらく時間を置いてから、お試しください。';
    };

    const onClientError = (error, message) => {
        let onFinish = () => Promise.reject(error);
        if (!store.$alert) {
            alert(message);
            return onFinish();
        }
        return store.$alert({
            title: 'エラー',
            message: message,
        }).then(onFinish).catch(onFinish);
    };

    const onServerError = async (err, message) => {
        let onFinish = () => Promise.reject(err);
        const response = err.response || {};
        if (response.status === 401) {
            await store.dispatch('auth/onUnauthorized');
        }

        error({
            statusCode: response.status,
            message,
        });
        return onFinish();
    };

    return (e = {}) => {
        let responseData = e.response && e.response.data;
        let message = errorMessage(responseData);
        if (process.browser) {
            return onClientError(e, message);
        }
        return onServerError(e, message);
    };
};

/**
 * field==valueでであるmodelが取れるまで待つための関数を返します。
 * nestしたページのnuxtのfetchでmodelが欲しい場合などに使う
 * @param modelClass vuex-ormのモデルクラス
 * @param existPredicate modelが取れたと判断する関数
 * @returns {function(*=, *=): Promise<*>}
 */
export const createAwaitGetter = (modelClass, existPredicate) => {
    return async (field, value) => {
        const executor = async () => {
            const model = await modelClass.query().where(field, value).first();
            return existPredicate(model) ? model : null;
        };
        return new Promise((resolve, reject) => {
            let count = 0;
            const timer = setInterval(async () => {
                count++;
                if (count > 200) {
                    // 諦める
                    clearInterval(timer);
                    return reject(new Error(`not found. entity: ${modelClass.entity}, field: ${field}, value: ${value}`));
                }
                const result = await executor();
                if (!result) {
                    return;
                }
                clearInterval(timer);
                resolve(result);
            }, 100);
        });
    };
};
