<template lang="pug">
    v-form(v-model="valid" @submit.native.prevent="")
        .mb-20
            base-heading(
            level="3"
            center
            ) メールアドレスでログイン
        base-input-set(
        v-model="email"
        label="メールアドレス"
        input-type="email"
        required
        )
        base-input-set(
        v-model="password"
        label="パスワード"
        input-type="current-password"
        required
        )
        .mb-20
            base-text(level="3" center)
                | パスワードを忘れた方は
                base-text(
                level="3"
                inline
                highlighted
                :to="recovery_password_link"
                @click="closeAuthDialog"
                ) こちら
        base-button(
        block
        large
        @click.native="submit"
        :disabled="!valid"
        :loading="loading"
        ) ログイン
</template>

<script>
import {mapStatesTwoWay} from '../../../syncable-commons-utils/utils/VuexHelper';
import {mapActions} from 'vuex';
import BaseHeading from '../../../syncable-commons-components/atoms/BaseHeading';
import BaseInputSet from '../../../syncable-commons-components/molecules/BaseInputSet';
import BaseText from '../../../syncable-commons-components/atoms/BaseText';
import BaseButton from '../../../syncable-commons-components/atoms/BaseButton';
import {LINK_NAME, ROUTER_TO} from '../../consts/links';

export default {
    name: 'UserLoginForm',
    components: {BaseButton, BaseText, BaseInputSet, BaseHeading},
    data() {
        return {
            loading: false,
            valid: false,
        };
    },
    computed: {
        ...mapStatesTwoWay('auth', {
            email: state => state.input.email,
            password: state => state.input.password,
        }, function (stateUpdate) {
            this.updateInput(stateUpdate);
        }),
        recovery_password_link() {
            return {
                ...ROUTER_TO[LINK_NAME.AUTH.PASSWORD.RECOVERY],
            };
        },
    },
    methods: {
        ...mapActions('auth', {
            updateInput: 'updateInput',
            login: 'login',
        }),
        ...mapActions('layout', {
            closeAuthDialog: 'closeAuthDialog',
        }),
        async submit() {
            this.loading = true;
            try {
                await this.login();
            } catch (e) {
                this.loading = false;
                return;
            }
            this.loading = false;
            this.$emit('success');
        },
    },
};
</script>

<style scoped>

</style>
