import Vue from 'vue';
import VueI18n from 'vue-i18n';

const messages = {
    en: {
        label: {
            email: 'Email',
            donorName: 'Your Name',
            birthday: 'Birth date (YYYY/MM/DD)',
            country: 'Country',
            zipCode: 'Postal code',
            zipCodePrefix: '',
            area: 'State / territory',
            city: 'City / town',
            addressLevel1: 'Street address',
            addressLevel2: 'Building',
            phone: 'Phone',
            gender: 'Gender',

            name: 'Name',
            address: 'Address',
            mailMagazine: 'Newsletter',
            necessary: 'Necessary',
            unnecessary: 'Unnecessary',
            issueReceipt: 'Issue Receipt',
            agreement: 'Terms of Service & Privacy Policy',
            terms: 'Terms of Service',
            agreeTo: 'Agree to ',
            privacy: 'Privacy Policy',
            specifiedCommercialTransactionLaw: 'Notation based on Specified Commercial Transaction Law',
            footerCustomer: 'Customer',
            footerAssociation: 'Association',
        },
        donation: {
            selectAmount: 'Donation Amount',
            selectPaymentMethod: 'Select Payment Method',
            selectFrequency: 'Donation Frequency',
            donateWithLogin: 'Donate with login',
            pleaseInputAmount: 'Enter amount',
            back: 'Back',
            next: 'Next',
            amountFrequency: '{frequency} ¥{amount} ',
            isSelected: 'is Selected',
            monthly: 'Monthly',
            onetime: 'One time',
            maxIs: 'Up to ¥{amount}',
            usageOfDonation: 'Usage of Donation',
            inputCreditCard: 'Credit Card Information',
            creditCardNumber: 'Credit card number (no space or hyphen)',
            expirationDate: 'Expiration Date',
            expirationMonth: 'Expiration Month',
            expirationYear: 'Expiration Year',
            expirationMonthYear: 'Expiration date (month / year)',
            expirationSelectTextSuffix: {
                year: '',
                month: '',
                date: '',
            },
            cardHolderName: 'Name of card holder',
            securityCode: 'Security code',
            inputDonor: 'Donor Information',
            inputMember: 'Input Annual Member Information',
            confirmInput: '',
            inputAmount: 'Decide Donation Amount',

            amazonDescription: 'You can donate using the address and credit card information registered on Amazon.co.jp.',
            creditCard: 'Credit card',
            bitCoin: 'Bit Coin',
            blandPledge: 'Bland Pledge',
            amazonPay: 'Amazon Pay',
            bankTransfer: 'Bank Transfer',
            donationAmount: 'Donation Amount',
            stepPlan: 'Plan',
            stepAmount: 'AMT.',
            stepMethod: 'PAYMENT',
            stepPayerInfo: 'YOUR INFO.',
            stepConfirmation: 'CHECK',
            stepFinish: 'DONE',
            currencyRateNote: 'Payment will be made in Japanese yen (dollar amount for reference only).',
            toEdit: 'Edit',
            onetimeDonation: 'One time',
            onetimeDonationForPortfolio: 'One time',
            monthlyDonation: 'Monthly',
            monthlyDonationForPortfolio: 'Monthly',
            monthlyDonationTiming: 'The first payment will be made when the "donate" button is clicked, and the subsequent payments will be made automatically on the 1st of every month.',
            membershipDonationTiming: 'The first payment will be made when the "donate" button is clicked, and the subsequent payments will be made automatically on {date} every year.',
            paymentMethod: 'Payment Method',
            expirationDay: 'Expiration Day',
            payerInformation: 'Payer Information',
            others: 'Others',
            donateTo: 'Donate to',
            totalAmount: 'Total Payment',
            donorInformation: 'Donor Information',
            memberInformation: 'Annual Member Information',
            receiptNotes: 'We basically issue the recepit for person, but please consent the case that we cannot issue the recepit of organizations such as ones which do not receive tax credits',
            message: 'Comments, inquiries',
            registerSyncable: 'Register for Syncable',
            pleaseInputPw: 'Please enter your password if you want to register for Syncable',
            password: 'Password',
            passwordRequirements: '6 or more alphanumeric characters',
            syncableDescriptions: 'Registration for Syncable enables you to receive news from supported organizations and you can check your donation history.',
            donate: 'Donate',
            isNotFinished: 'Registration NOT Complete',
            pleaseConfirm: 'Please confirm the information below and click DONATE',
            thankYou: 'Thank you for your donation',
            thankYouPf: 'Thank you for your donation',
            thankYouCampaign: 'The donation is complete. Thank you for your encouragement!',
            letsShare: 'Share',
            toTop: 'Top',
            toPfTop: 'Top',
            toCampaignTop: 'Go back to campaign top',
            selectMembershipPlan: 'Select Membership Plan',
            chooseThisPlan: 'Choose this Plan',
            selectedPlan: 'Selected Plan',
            membershipDonation: 'Annually',
            confirm: 'Confirm',
            publishUserName: 'Publish username on the page',
            giveMessage: 'Give a message',
            giveBirthdayMessage: 'Give a birthday congratulatory message to {name}',
            giveCampaignSupportMessage: 'Give a message of support for the campaign of {associateName}',
            currencyAnnotation: 'Your donation is denominated in Japanese yen, but made in the currency of the country in which your credit card was issued, using the exchange rate that the credit card company applies.',
            mailSent: 'An email has been sent to confirm your gift from noreply@syncable.biz , the operator of this donation platform. Please make sure you can receive emails from this domain.',
            cannotUseOverseasCredit: '',
            commissionCheck: 'Donate with an additional {platformFeePercentage}% settlement fee + tax ({commissionAmount} yen)',
            commissionAnnotation: 'The total amount including the fees paid will be considered as the donation amount.',
            commissionConfirmation: 'Includes settlement fee {platformFeePercentage}% + tax ({commissionAmount} yen)',
            donorLastNamePlaceholder: 'John',
            donorFirstNamePlaceholder: 'Smith',
            safetyNote: 'Credit card information is not retained by us, and is safely managed by Omise Japan, a settlement agency, in accordance with international standards.',
            messagePage: {
                headerDonationIsComplete: 'Donation is Complete',
                labelMessage: 'Message',
                selectionPublish: 'Public',
                selectionUnPublish: 'Private',
            },
            accessibilityCheck: 'Turn on the feature for the visually impaired',
            needIssueReceipt: 'I would like to receive a receipt.',
        },
        formValidation: {
            required: 'Please input {0}',
            correct: 'Please input {0} in correct format',
            pattern: 'Please enter {0} in {1}',
            numeric: 'numeric characters',
            halfWidthNumber: 'numeric characters',
            alphanumericAndHyphen: 'alphanumeric characters and hyphen',
            maxLength: 'Please enter {0} using up to {1} characters',
            minLength: 'Please enter {0} with at least {1} characters',
            maxVal: 'Please enter {0} no more then {1} {2}',
            minVal: 'Please enter {0} at least {1} {2}',
        },
        error :{
            nominal: 'Error',
            general: 'An error has been occurred.',
        },
    },
    ja: {
        label: {
            email: 'メールアドレス',
            donorName: '姓名',
            birthday: '生年月日',
            country: '国',
            zipCode: '郵便番号',
            zipCodePrefix: '〒',
            area: '都道府県',
            city: '市区町村',
            addressLevel1: '丁目・番地',
            addressLevel2: '建物名',
            phone: '電話番号',
            gender: '性別',

            name: '氏名',
            address: '住所',
            mailMagazine: '団体からのお知らせ',
            necessary: '希望する',
            unnecessary: '希望しない',
            issueReceipt: '領収書の発行',
            agreement: '確認事項に同意して寄付',
            terms: '利用規約',
            agreeTo: 'に同意する',
            privacy: 'プライバシーポリシー',
            specifiedCommercialTransactionLaw: '特定商取引法に基づく表記',
            footerCustomer: '一般の方',
            footerAssociation: '掲載団体',
        },
        donation: {
            selectAmount: '寄付金額',
            selectPaymentMethod: '支払方法',
            selectFrequency: '寄付頻度',
            donateWithLogin: 'ログインして寄付する',
            pleaseInputAmount: 'ご希望の金額を入力',
            back: '戻る',
            next: '次へ',
            amountFrequency: '{frequency} ¥{amount} ',
            isSelected: 'を選択中',
            monthly: '毎月',
            onetime: '今回のみ',
            maxIs: '寄付金額は一度につき¥{amount}までとなります。',
            usageOfDonation: '寄付金でできること',
            inputCreditCard: 'クレジットカード情報を入力',
            creditCardNumber: 'クレジットカード番号',
            expirationDate: '有効期限',
            expirationMonth: '有効期限（月）',
            expirationYear: '有効期限（年）',
            expirationMonthYear: '有効期限',
            expirationSelectTextSuffix: {
                year: '年',
                month: '月',
                date: '日',
            },
            cardHolderName: 'クレジットカード名義',
            securityCode: 'セキュリティコード',
            inputDonor: '寄付者情報を入力',
            inputMember: '年会員情報を入力',
            confirmInput: '寄付内容の確認',
            inputAmount: '寄付金額を決定',

            amazonDescription: 'Amazon.co.jpにご登録の住所・クレジットカード情報を利用して寄付できます。',
            creditCard: 'クレジットカード',
            bitCoin: 'ビットコイン',
            blandPledge: 'Bland Pledge',
            amazonPay: 'Amazon Pay',
            bankTransfer: '直接振込',
            donationAmount: '寄付内容',
            stepPlan: 'プラン',
            stepAmount: '金額',
            stepMethod: '支払方法',
            stepPayerInfo: '支払者情報',
            stepConfirmation: 'ご確認',
            stepFinish: '完了',
            currencyRateNote: '支払いは日本円で行われるため、表示金額は目安となります',
            toEdit: '変更',
            onetimeDonation: '単発',
            onetimeDonationForPortfolio: '単発寄付',
            monthlyDonation: '毎月',
            monthlyDonationForPortfolio: '毎月寄付',
            monthlyDonationTiming: '※今回の決済は「寄付する」ボタンが押された際に行われ、翌月から毎月1日に自動で決済されます。',
            membershipDonationTiming: '※今回の決済は「寄付する」ボタンが押された際に行われ、次回から毎年{date}に自動で決済されます。',
            paymentMethod: 'お支払い方法',
            expirationDay: '有効期限',
            payerInformation: '支払者情報',
            others: 'そのほか',
            donateTo: '寄付先',
            totalAmount: '寄付金額',
            donorInformation: '寄付者情報',
            memberInformation: '年会員情報',
            receiptNotes: '※原則希望者には領収書を発行いたしますが、税控除対象ではない団体をはじめ、領収書を発行していないケースもありますので予めご了承ください。',
            message: '団体へのメッセージ',
            registerSyncable: 'Syncableへの登録',
            pleaseInputPw: 'Syncableへの登録も合わせて行いたい方は、パスワードをご記入ください。',
            password: 'パスワード',
            passwordRequirements: '6文字以上の半角英数字',
            syncableDescriptions: '登録すると、寄付先団体からの情報を始め、寄付履歴の閲覧やSyncableの全ての機能をご利用いただけるようになります。',
            donate: '寄付を実行',
            isNotFinished: 'まだ寄付は完了していません',
            pleaseConfirm: '寄付内容の最終確認をしてください',
            thankYou: 'ありがとうございます。寄付が完了しました！\n' +
                'もしよろしければ、寄付したことをSNSで知らせて団体を応援しませんか？',
            thankYouPf: 'ありがとうございます。寄付が完了しました！\n' +
                'もしよろしければ、寄付したことをSNSで知らせて応援しませんか？',
            thankYouCampaign: '寄付が完了しました。ご声援ありがとうございました。',
            letsShare: 'シェアして応援しよう',
            toTop: '団体トップ画面へ戻る',
            toPfTop: 'ポートフォリオトップ画面へ戻る',
            toCampaignTop: 'キャンペーントップへ戻る',
            selectMembershipPlan: '入会プランを選択',
            chooseThisPlan: 'このプランを選んで次へ',
            selectedPlan: '入会プラン',
            membershipDonation: '年',
            confirm: '確定する',
            publishUserName: 'ページにユーザー名（未設定の場合は氏名）を公開する',
            giveMessage: 'メッセージを贈る',
            giveBirthdayMessage: '{name}さんに誕生日のお祝いメッセージを贈ろう',
            giveCampaignSupportMessage: '{associateName}のキャンペーンの応援メッセージを贈ろう',
            currencyAnnotation: '',
            mailSent: 'いただきましたご寄付の完了メールが noreply@syncable.biz  より送信されました。このドメインからのメールを受け取れるようにご確認をお願い致します。',
            cannotUseOverseasCredit: 'ただいま一時的に日本国外発行のクレジットカードはご利用いただけません。',
            commissionCheck: '決済手数料{platformFeePercentage}%+税（{commissionAmount}円）を追加で負担して寄付する',
            commissionAnnotation: 'ご負担いただいた手数料分も含めた合計金額が寄付額とみなされます。',
            commissionConfirmation: '決済手数料{platformFeePercentage}%+税（{commissionAmount}円)を含む',
            donorLastNamePlaceholder: '田中',
            donorFirstNamePlaceholder: '太郎',
            safetyNote: 'クレジットカード情報は当社で保持せず、決済代行会社のOmise株式会社にて国際基準に準拠して安全に管理されます。',
            messagePage: {
                headerDonationIsComplete: '寄付を受け付けました！',
                labelMessage: 'メッセージ',
                selectionPublish: '公開',
                selectionUnPublish: '非公開',
            },
            accessibilityCheck: '視覚障害者向け機能をONにする。このチェックボックスをONにすることで、キーボード操作が可能になります。',
            needIssueReceipt: '領収書の発行を希望する',
        },
        formValidation: {
            required: '{0}を入力して下さい',
            correct: '{0}を正しく入力して下さい',
            pattern: '{0}は{1}で入力して下さい',
            numeric: '数値',
            halfWidthNumber: '半角数字',
            alphanumericAndHyphen: '半角英数字と"-"',
            maxLength: '{0}は{1}文字以下で入力して下さい',
            minLength: '{0}は{1}文字以上で入力して下さい',
            maxVal: '{0}は{1}{2}以下で入力して下さい',
            minVal: '{0}は{1}{2}以上で入力して下さい',
        },
        error: {
            nominal: 'エラー',
            general: 'エラーが発生しました。',
        },
    },
};

Vue.use(VueI18n);

export default ({app, store}) => {
    app.i18n = new VueI18n({
        locale: store.state.layout.lang || 'ja',
        messages,
    });
};
