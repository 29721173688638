export const RegisterSteps = Object.freeze({
    SELECT: 'select',
    MAIL: 'mail',
    FACEBOOK: 'facebook',
    TWITTER: 'twitter',
    SUCCESS: 'success',
});

export const HeaderAdditionalContents = Object.freeze({
    ASSOCIATE: 'associate',
    ASSOCIATE_INFO: 'associate-info',
    NEWS: 'news',
});

export const state = () => ({
    lightBox: {
        images: [],
        index: 0,
        image: '',
    },
    sidebar: false,
    authDialog: {
        open: false,
        redirectTo: {
            name: '',
            params: '',
            query: '',
            hash: '',
        },
        registerStep: RegisterSteps.SELECT,
        isLogin: false,
    },
    userMenu: {
        show: false,
    },
    header: {
        show: true,
        currentHeight: '',
        additionalContent: '',
    },
    footer: {
        show: true,
    },
    loading: false,
    lang: 'ja',
});

export const mutations = {
    setSidebar(state, val) {
        state.sidebar = val;
    },
    setLightBoxImages(state, val) {
        state.lightBox.images = val;
    },
    setLightBoxIndex(state, val) {
        state.lightBox.index = val;
    },
    setLightBoxImage(state, val) {
        state.lightBox.image = val;
    },
    setAuthDialogRedirectTo(state, val) {
        state.authDialog.redirectTo = {
            name: val.name,
            params: val.params,
            query: val.query,
            hash: val.hash,
        };
    },
    setAuthDialogIsLogin(state, val) {
        state.authDialog.isLogin = val;
    },
    setAuthDialogOpen(state, val) {
        state.authDialog.open = val;
    },
    setAuthDialogRegisterStep(state, val) {
        state.authDialog.registerStep = val;
    },
    setHeaderShow(state, val) {
        state.header.show = val;
    },
    setHeaderAdditionalContent(state, val) {
        state.header.additionalContent = val;
    },
    setHeaderAdditionalAssociate(state, val) {
        state.header.associate = val;
    },
    setUserMenuShow(state, val) {
        state.userMenu.show = val;
    },
    setLoading(state, val) {
        state.loading = val;
    },
    setLang(state, val) {
        // SSRで言語の設定がされた際にクライアント側で引き継げないようなのでstoreでも管理
        this.app.i18n.locale = val;
        state.lang = val;
    },
    setFooterShow(state, val) {
        state.footer.show = val;
    },
};

export const actions = {
    updateAuthDialogOpen({commit}, val) {
        commit('setAuthDialogOpen', val);
    },
    updateAuthDialogRegisterStep({commit}, val) {
        commit('setAuthDialogRegisterStep', val);
    },
    openRegisterDialog({commit}) {
        commit('setAuthDialogIsLogin', false);
        commit('setAuthDialogRedirectTo', this.app.context.route);
        commit('setAuthDialogOpen', true);
    },
    openLoginDialog({commit}) {
        commit('setAuthDialogIsLogin', true);
        commit('setAuthDialogRedirectTo', this.app.context.route);
        commit('setAuthDialogOpen', true);
    },
    closeAuthDialog({commit}) {
        const initialState = state();
        commit('setAuthDialogOpen', initialState.authDialog.open);
        commit('setAuthDialogRedirectTo', initialState.authDialog.redirectTo);
        commit('setAuthDialogRegisterStep', initialState.authDialog.registerStep);
    },
    finishAuth({dispatch}) {
        dispatch('closeAuthDialog');
    },
    toggleSidebar({commit, state}) {
        commit('setSidebar', !state.sidebar);
    },
    updateSidebar({commit}, val) {
        commit('setSidebar', val);
    },
    updateLang({commit}, val) {
        commit('setLang', val);
    },
    resetLightBox({commit}) {
        const initialStore = state();
        commit('setLightBoxImages', initialStore.lightBox.images);
        commit('setLightBoxIndex', initialStore.lightBox.index);
        commit('setLightBoxImage', initialStore.lightBox.image);
    },
    async showImages({commit, dispatch}, {images, index}) {
        await dispatch('resetLightBox');
        commit('setLightBoxImages', images);
        commit('setLightBoxIndex', index);
    },
    async showImage({commit, dispatch}, image) {
        await dispatch('resetLightBox');
        commit('setLightBoxImage', image);
    },
    toNextImage({commit, state}) {
        if (state.lightBox.images.length < 1) {
            return;
        }
        const index = state.lightBox.index;
        if (index === state.lightBox.images.length - 1) {
            return commit('setLightBoxIndex', 0);
        }
        return commit('setLightBoxIndex', index + 1);
    },
    toPreviousImage({commit, state}) {
        if (state.lightBox.images.length < 1) {
            return;
        }
        const index = state.lightBox.index;
        if (index === 0) {
            return commit('setLightBoxIndex', state.lightBox.images.length - 1);
        }
        return commit('setLightBoxIndex', index - 1);
    },
    hideHeader({commit}) {
        commit('setHeaderShow', false);
    },
    showHeader({commit}) {
        commit('setHeaderShow', true);
    },
    showHeaderAssociate({commit}) {
        commit('setHeaderShow', true);
        commit('setHeaderAdditionalContent', HeaderAdditionalContents.ASSOCIATE);
    },
    showHeaderAssociateInfo({commit}) {
        commit('setHeaderShow', true);
        commit('setHeaderAdditionalContent', HeaderAdditionalContents.ASSOCIATE_INFO);
    },
    /**
     * ヘッダーにNEWSの帯が出る。
     * NEWSのコンテンツ自体は {@link LayoutHeader} に書く。
     * @param commit
     */
    showHeaderNews({commit}) {
        commit('setHeaderShow', true);
        commit('setHeaderAdditionalContent', HeaderAdditionalContents.NEWS);
    },
    hideHeaderAdditionalContent({commit}) {
        commit('setHeaderAdditionalContent', '');
    },
    updateUserMenuShow({commit}, val) {
        commit('setUserMenuShow', val);
    },
    updateLoading({commit}, val) {
        commit('setLoading', val);
    },
    hideFooter({commit}) {
        commit('setFooterShow', false);
    },
    showFooter({commit}) {
        commit('setFooterShow', true);
    },
};

export const getters = {
    loading(state) {
        return state.loading;
    },
};
