import {Model} from '@vuex-orm/core';
import User from './User';

export default class UniqueDonor extends Model {
    static fields() {
        return {
            id: this.attr(null),

            updated_at: this.attr(''),
            created_at: this.attr(''),

            user: this.belongsTo(User, 'user_id'),
            user_id: this.attr(null),
        };
    }
}

UniqueDonor.entity = 'uniqueDonors';
