import {Model} from '@vuex-orm/core';
import {ASSOCIATE_MEMBERSHIP_PLAN_STATUS} from '../../../syncable-commons-consts/enums';
import DateHelper from '../../../syncable-commons-utils/utils/DateHelper';

export default class AssociateMembershipPlan extends Model {
    static fields() {
        return {
            id: this.number(null),
            name: this.string(''),
            unit_amount: this.number(null),
            allow_bulk: this.boolean(false),
            benefit_text: this.string(''),
            is_pst_donation: this.boolean(false),
            is_deductible: this.boolean(false),
            on_month: this.number(null),
            period: this.number(null),
            every: this.number(null),
            status: this.number(ASSOCIATE_MEMBERSHIP_PLAN_STATUS.PUBLIC),
            associate: this.attr(null),
        };
    }

    get onDate() {
        return 1;
    }

    get computedOnMonth() {
        return this.on_month > 0 ? this.on_month : DateHelper.currentMonth();
    }

    getSettlementDate(format = 'MM-DD') {
        return DateHelper.format(`${this.computedOnMonth}-${this.onDate}`, format, 'M-D');
    }

    getNextSettlementDate(jpFormat = 'YYYY-MM-DD', enFormat, locale) {
        const destFormat = (locale === 'en' && !!enFormat) ? enFormat : jpFormat; // 基本はjpフォーマット

        const formatter = (val) => DateHelper.format(val, destFormat, '', locale);
        let currentMonth = DateHelper.currentMonth();
        let currentYear = DateHelper.currentYear();

        if (this.on_month === 0) {
            // 決済月が指定されていない場合は１年後の今日
            return formatter(`${currentYear + 1}-${currentMonth}-${this.onDate}`);
        }

        if (this.on_month > DateHelper.currentMonth()) {
            // this.on_monthが来月以降なら今年
            return formatter(`${currentYear}-${this.on_month}-${this.onDate}`);
        }
        // そうでないなら来年
        return formatter(`${currentYear + 1}-${this.on_month}-${this.onDate}`);
    }
}

AssociateMembershipPlan.entity = 'associateMembershipPlans';
