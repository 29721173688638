<template lang="pug">
    v-list-tile.drawer-item(
        v-if="!isHidden"
        :nuxt="!!to"
        exact
        :target="linkTarget"
        :href="href"
        :to="to"
        :class="classes"
    )
        v-list-tile-action.drawer-item__prepend-icon
            v-icon(v-if="icon") {{icon}}
        v-list-tile-content
            .drawer-item__label {{title}}
        v-list-tile-action(v-if="appendIcon")
            v-icon(:color="appendIcon.color") {{appendIcon.text}}
</template>

<script>
import BaseText from '../atoms/BaseText';

export default {
    name: 'DrawerItem',
    components: {BaseText},
    props: {
        to: {
            type: Object,
            required: false,
            default: () => null,
        },
        linkTarget: {
            type: String,
            required: false,
            default: '',
        },
        href: {
            type: String,
            required: false,
            default: '',
        },
        icon: {
            type: String,
            required: false,
            default: '',
        },
        /**
         * メニューのテキストの右側に出るアイコン
         * warningなど
         */
        appendIcon: {
            type: [Object, null],
            required: false,
            default: () => null,
        },
        title: {
            type: String,
            required: true,
        },
        child: {
            type: Boolean,
            required: false,
            default: false,
        },
        solo: {
            type: Boolean,
            required: false,
            default: false,
        },
        bold: {
            type: Boolean,
            required: false,
            default: false,
        },
        isHidden: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        classes() {
            return {
                'drawer-item--solo': this.solo,
                'drawer-item--bold': this.bold,
            };
        },
    },
};
</script>

<style scoped lang="scss">
    .drawer-item {
        text-decoration: none;

        &--solo {
            border-left: 4px solid transparent;
            .drawer-item__label {
                font-weight: bold;
            }
        }

        &--bold {
            .drawer-item__label {
                font-weight: bold;
            }
        }

        & /deep/ {
            .icon {
                font-size: 20px;
            }

            .list__tile {
                padding: 0;
            }
        }
    }

    .drawer-item__label {
        font-size: 18px;
        line-height: 22px;
    }

    .drawer-item__prepend-icon {
        padding-left: 14px;
        padding-right: 6px;
        min-width: 20px;
        box-sizing: content-box;

        .icon {
            color: $c-primary;
        }
    }


    /deep/ {
        .drawer-item {
            .list__tile.list__dense {
                height: 48px;
            }
        }
    }
</style>
