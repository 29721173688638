<template lang="pug">
    v-dialog(
    v-model="show"
    max-width="80vw"
    )
        .layout-image-light-box
            img.layout-image-light-box-image(:src="displayingImage")
            .layout-image-light-box-controller.is--previous(@click="toPreviousImage")
                .layout-image-light-box-controller-icon
                    v-icon keyboard_arrow_left
            .layout-image-light-box-controller.is--next(@click="toNextImage")
                .layout-image-light-box-controller-icon
                    v-icon keyboard_arrow_right
</template>

<script>
import {mapActions} from 'vuex';

export default {
    name: 'LayoutImageLightBox',
    data() {
        return {
            show: false,
        };
    },
    computed: {
        isSingle() {
            return !!this.$store.state.layout.lightBox.image;
        },
        images() {
            if (this.isSingle) {
                return [];
            }
            return this.$store.state.layout.lightBox.images;
        },
        index() {
            if (this.isSingle) {
                return 0;
            }
            return this.$store.state.layout.lightBox.index;
        },
        displayingImage() {
            if (this.isSingle) {
                return this.$store.state.layout.lightBox.image;
            }
            if (this.images && this.images.length > this.index) {
                return this.images[this.index];
            }
            return '';
        },
    },
    watch: {
        displayingImage(val) {
            this.show = !!val;
        },
        show(val) {
            if (!val) {
                this.$store.dispatch('layout/resetLightBox');
            }
        },
    },
    methods: {
        ...mapActions('layout', {
            toNextImage: 'toNextImage',
            toPreviousImage: 'toPreviousImage',
        }),
    },
};
</script>

<style scoped lang="scss">
    .layout-image-light-box {
        background-color: $c-white-base;
        position: relative;
        &-image {
            max-width: 100%;
            max-height: 100%;
        }
        &-controller {
            user-select: none;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            height: 100%;
            width: 50%;
            background: rgba(0, 0, 0, .0);

            display: flex;
            align-items: center;
            &.is--previous {
                left: 0;
                justify-content: flex-start;
            }
            &.is--next {
                right: 0;
                justify-content: flex-end;
            }

            .layout-image-light-box-controller-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                width: 40px;
                border-radius: 50%;
                i {
                    color: $c-bg;
                }
            }
        }
    }
</style>