<template lang="pug">
    v-dialog(
        transition="slide-y-transition"
        fullscreen
        hide-overlay
        content-class="layout-user-menu"
        v-model="active"
    )
        .layout-user-menu-icon(
            slot="activator"
            :class="classes"
        )
            //- ここは元BaseAvatarIconだったが、そちらのスタイルを壊さないように改変するのが無理だったので独立させた
                できれば統合したい
            .layout-user-menu-icon-icon
                img.layout-user-menu-icon-icon__image(
                    :src="avatarIcon"
                    alt="avatar"
                )
                .layout-user-menu-icon-icon__badge(v-show="showBadge")
        .layout-user-menu-wrapper
            .layout-user-menu-content
                user-menu-list(:user="user")
</template>

<script>
import UserMenuList from '../molecules/UserMenuList';

export default {
    name: 'LayoutUserMenuIcon',
    components: {UserMenuList},
    props: {
        user: Object,
    },
    computed: {
        active: {
            get() {
                return this.$store.state.layout.userMenu.show;
            },
            set(val) {
                this.$store.dispatch('layout/updateUserMenuShow', val);
            },
        },
        classes() {
            return {
                'is-active': this.active,
            };
        },
        showBadge() {
            return this.user && this.user.draft_campaign_count && this.user.draft_campaign_count > 0;
        },
        avatarIcon() {
            const defaultAvatar = require('../../../syncable-commons-components/assets/img/default_avatar.svg');
            return this.user.avatar_image && this.user.avatar_image.file || defaultAvatar;
        },
    },
    watch: {
        '$route'() {
            this.active = false;
        },
    },
    beforeDestroy() {
        this.active = false;
    },
};
</script>

<style scoped lang="scss">
</style>
<style lang="scss">
    .layout-user-menu {
        &.dialog--fullscreen {
            top: 60px;
            height: calc(100% - 60px);
            border-radius: 0;
            box-shadow: none;
            overflow: visible; // 三角のため
            @include mq(md-and-up) {
                left: auto;
                right: 5px;
                width: 245px;
                height: auto;
            }
        }

        &-wrapper {
            border-top: $border-default;
            background: $c-bg;
            height: 100%;
            @include mq(md-and-up) {
                height: auto;
                border: $border-default;
                border-radius: 2px;
            }
        }

        &-content {
            position: relative;

            &:before, &:after {
                /*三角形を描く*/
                content: "";
                position: absolute;
                display: inline-block;
                border: 12px solid transparent;
                right: 68px;
                top: -25px;
                height: 0;
                width: 0;
                @include mq(md-and-up) {
                    right: 15px;
                }
            }

            &:before {
                border-bottom-color: $c-border;
            }

            &:after {
                border-bottom-color: $c-bg;
                margin: 1px 0px 0px 0px;
            }
        }
    }

    .layout-user-menu-icon-icon {
        position: relative;
    }

    .layout-user-menu-icon-icon__image {
        width: 32px;
        height: 32px;

        object-fit: cover;
        border-radius: 50%;
    }

    .layout-user-menu-icon-icon__badge {
        position: absolute;
        top: 0;
        right: 0;

        content: '';
        width: 12px;
        height: 12px;
        border-radius: 10px;

        background-color: $c-pink;
    }
</style>
