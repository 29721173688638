import {Model} from '@vuex-orm/core';

export default class AssociateVisionUseForDonationImage extends Model {
    static fields() {
        return {
            id: this.attr(null),
            file: this.attr(''),

            vision_id: this.attr(null),
        };
    }
}

AssociateVisionUseForDonationImage.entity = 'associateVisionUseForDonationImages';
