import moment from 'moment';

moment.locale('ja');
export default class DateHelper {
    static isDate(val, format = null) {
        return moment(val, format).isValid();
    }

    static currentYear() {
        return moment().year();
    }

    /**
     * 今月の数字を返します。
     * 0始まりなのでプラス１してます。
     * @returns {number}
     */
    static currentMonth() {
        return moment().month() + 1;
    }

    /**
     * 指定のフォーマットに変換する関数を返します。
     * @param bateTime
     * @param outputFormat
     * @param inputFormat
     * @returns {string}
     */
    static format(bateTime, outputFormat = 'YYYY/MM/DD', inputFormat = '', lang) {
        if (!DateHelper.isDate(bateTime, inputFormat)) {
            return '';
        }

        return lang
            ? moment(bateTime, inputFormat).locale(lang).format(outputFormat)
            : moment(bateTime, inputFormat).format(outputFormat);
    }

    /**
     * baseTimeにmomentUnitでnumだけ進めたDateを返します。
     * @param baseTime
     * @param num
     * @param momentUnit
     * @returns {Date}
     */
    static add(baseTime, num, momentUnit) {
        return moment(baseTime).add(num, momentUnit).toDate();
    }

    /**
     * targetTimeからtargetTimeまでのdiffを計算する
     * diffの単位はmomentUnitで与えられた単位
     *
     * @param baseTime
     * @param targetTime
     * @param momentUnit
     * @returns {*}
     */
    static count(baseTime, targetTime, momentUnit) {
        const baseMoment = moment(baseTime);
        const targetMoment = moment(targetTime);
        return baseMoment.diff(targetMoment, momentUnit);
    }

    /**
     * baseTimeがtargetTimeよりもあとならtrue
     * @param baseTime
     * @param targetTime
     * @param format
     * @param momentUnit
     */
    static isAfter(baseTime, targetTime, format = '', momentUnit = 'day') {
        return moment(baseTime, format).isAfter(moment(targetTime, format), momentUnit);
    }

    static isSameOrAfter(baseTime, targetTime, format = '', momentUnit = 'day') {
        return moment(baseTime, format).isSameOrAfter(moment(targetTime, format), momentUnit);
    }

    static diff(left, right, measurement) {
        return moment(left).diff(right, measurement);
    }
}
