import {Model} from '@vuex-orm/core';
import Campaign from './Campaign';

export default class CampaignStatistic extends Model {
    static fields() {
        return {
            id: this.attr(null),
            total_donors: this.number(null),
            total_donation_amount: this.number(null),

            campaign_id: this.attr(null),
            campaign: this.belongsTo(Campaign, 'campaign_id'),
        };
    }
}

CampaignStatistic.entity = 'campaignStatistics';
