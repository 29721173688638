<template lang="pug">
    .s-container(:class="classes")
        slot
</template>

<script>
export default {
    name: 'BaseContainer',
    props: {
        full: Boolean,
        wide: Boolean,
        wider: Boolean,
        narrow: Boolean,
        green: Boolean,
        white: Boolean,
        nospace: Boolean,
        relative: Boolean,
        pt0: Boolean,
    },
    computed: {
        classes() {
            return {
                'is-full': this.full,
                'is-wide': this.wide,
                'is-wider': this.wider,
                'is-narrow': this.narrow,
                'is-green': this.green,
                'is-white': this.white,
                'is-nospace': this.nospace,
                'is-relative': this.relative,
                'pt-0': this.pt0,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
    .s-container {
        // width related classes
        width: 100%;
        padding: 15px;
        // background: #fff;
        @include mq(md-and-up) {
            width: 960px;
            margin: 0 auto;
        }
        &.is-full {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            @include mq(md-and-up) {
                width: 100%;
                margin: 0 auto;
            }
        }
        &.is-narrow {
            @include mq(md-and-up) {
                width: 750px;
                margin: 0 auto;
            }
        }
        &.is-wide {
            @include mq(md-and-up) {
                width: 960px;
                margin: 0 auto;
                padding: 0;
            }
        }
        &.is-wider {
            @include mq(md-and-up) {
                width: 1200px;
                margin: 0 auto;
            }
        }

        // space related classes
        &.is-nospace {
            padding: 0;
        }

        // background color related classes
        &.is-white {
            background-color: $c-bg;
        }
        &.is-green {
            background-color: map-get($c-green, alpha10);
        }

        // position related classes
        &.is-relative {
            position: relative;
        }
        &.pt-0 {
            padding-top: 0;
        }
    }
</style>
