export const USE_NEXT_ON_PARTIAL_DEPLOYMENT = [
    // トップ
    'index',
    // キャンペーン系
    'campaign',
    'campaign-campaignId-index',
    'campaign-campaignId-index-reports',
    'campaign-campaignId-index-reports-campaignReportId',
    // 団体系
    'explore-associate',
    'explore-associate-social-challenge-socialChallengeSlug',
    'associate-associateUid-index',
    'associate-associateUid-index-businesses',
    'associate-associateUid-index-businesses-businessId',
    'associate-associateUid-index-vision',
    'associate-associateUid-index-campaign',
    'associate-associateUid-index-members',
    'associate-associateUid-index-donate',
    'associate-associateUid-index-donate-membership',
    // 利用規約系
    'terms',
    'terms-en',
    'terms-associate',
    // プライバシーポリシー系
    'privacy',
    'privacy-en',
    // 特定商取引法上の表記
    'law',
    // パスワード再設定
    'auth-password-recovery',
];

export const isNextDeployedRoute = (to) => {
    return USE_NEXT_ON_PARTIAL_DEPLOYMENT.includes(to.name);
};