<template lang="pug">
    .layout-auth-content
        .my-20
            base-heading(
            level="3"
            center
            ) メールアドレスで登録
        base-container
            user-register-form(
            @success="onSuccess"
            )
</template>

<script>
import UserRegisterForm from '../molecules/UserRegisterForm';
import BaseHeading from '../../../syncable-commons-components/atoms/BaseHeading';
import {RegisterSteps} from '../../store/layout';
import BaseContainer from '../molecules/BaseContainer';

export default {
    name: 'LayoutAuthContentRegisterMail',
    components: {
        BaseContainer,
        BaseHeading,
        UserRegisterForm,
    },
    computed: {
        registerStep: {
            get() {
                return this.$store.state.layout.authDialog.registerStep;
            },
            set(val) {
                this.$store.dispatch('layout/updateAuthDialogRegisterStep', val);
            },
        },
    },
    methods: {
        onSuccess() {
            this.registerStep = RegisterSteps.SUCCESS;
        },
    },
};
</script>

<style scoped lang="scss">
</style>