import {Model} from '@vuex-orm/core';
import _ from 'lodash';
import UserPrivateProfile from './UserPrivateProfile';
import PaymentCreditCard from './PaymentCreditCard';
import UserProfile from './UserProfile';
import UserAvatarImage from './UserAvatarImage';
import Follower from './Follower';
import AssociateMember from './AssociateMember';
import Donation from './Donation';
import Campaign from './Campaign';
import RecurringDonation from './RecurringDonation';
import PortfolioDonation from './PortfolioDonation';

export default class User extends Model {
    static fields() {
        return {
            id: this.attr(null),
            username: this.attr(''),
            email: this.attr(''),
            last_login: this.attr(''),
            is_active: this.attr(''),
            is_staff: this.attr(''),
            is_admin: this.attr(''),
            notification_preference: this.attr(''),
            updated_at: this.attr(''),
            created_at: this.attr(''),
            profile: this.hasOne(UserProfile, 'user_id'),
            avatar_image: this.hasOne(UserAvatarImage, 'user_id'),
            private_profile: this.hasOne(UserPrivateProfile, 'user_id'),
            user_credit_card: this.hasOne(PaymentCreditCard, 'user_id'),
            following_associates: this.hasMany(Follower, 'user_id'),
            associate_members: this.hasMany(AssociateMember, 'user_id'),
            donations: this.hasMany(Donation, 'user_id'),
            portfolio_donations: this.hasMany(PortfolioDonation, 'user_id'),
            recurring_donations: this.hasMany(RecurringDonation, 'user_id'),
            campaigns: this.hasMany(Campaign, 'user_id'),

            // SerializerMethodField用
            total_donations: this.attr(''),
            total_following_associates: this.attr(''),
            draft_campaign_count: this.number(''),
        };
    }

    static newInstance() {
        return new User({
            profile: UserProfile.newInstance(),
            avatar_image: UserAvatarImage.newInstance(),
            private_profile: UserPrivateProfile.newInstance(),
            user_credit_card: PaymentCreditCard.newInstance(),
        });
    }

    get fullName() {
        if (!this.private_profile.last_name || !this.private_profile.first_name) {
            return '';
        }
        return `${this.private_profile.last_name} ${this.private_profile.first_name}`;
    }

    get followingCount() {
        return this.total_following_associates;
    }

    get donationAmount() {
        return this.total_donations;
    }

    isFollowing(associateId) {
        return _.findIndex(this.following_associates, ({associate}) => (associate.id === associateId)) > -1;
    }

    /**
     * このユーザーがcampaignを編集する権限があるか
     * - adminである場合
     *  - 可能だがクライアント側ではおそらく不要なので考慮しない
     * - バースデードネーションなど
     *   - 作成ユーザー本人であるかを返す
     * - マンスリーファンディング
     *  - 作成ユーザー本人である
     *  - もしくは
     *  - 所属している団体作成のキャンペーンである
     * @param campaign
     */
    hasRightToUpdateCampaign(campaign = {}) {
        return (campaign.user_id === this.id) ||
            ((campaign.isMonthlyFunding || campaign.isMonthlyFundingWithoutCommission) && this.associate_members.filter(m => m.associate_id === campaign.associate_id).length > 0);
    }

    /**
     * このユーザーがportfolioを編集する権限があるか
     * - 未ログインである場合
     *   - 不可
     * - adminである場合
     *   - 可能だがクライアント側ではおそらく不要なので考慮しない
     * @param portfolio Portfolioインスタンス
     */
    hasRightToUpdatePortfolio(portfolio) {
        if (!portfolio || !this.id) {
            return false;
        }

        return _.get(portfolio, 'user_id') === this.id;
    }
}

User.entity = 'users';
