import {Model} from '@vuex-orm/core';

export default class PortfolioMainVisual extends Model {
    static fields() {
        return {
            id: this.attr(null),
            file: this.attr(''),

            portfolio_id: this.attr(null),
        };
    }

    static newInstance() {
        return new PortfolioMainVisual();
    }
}

PortfolioMainVisual.entity = 'portfolioMainVisuals';
