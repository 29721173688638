export const state = () => ({});

export const mutations = {};

export const actions = {
    async signOut() {
        await this.$firebase.auth().signOut();
    },
    async onAuthStateChanged() {
    },
    async signInWithFacebook({dispatch}) {
        try {
            // firebase で facebook login
            const result = await this.$firebase.auth().signInWithPopup(this.$firebase.FacebookAuthProvider);
            const fbToken = result.credential.accessToken;
            const firebaseUserId = result.user.uid;
            await dispatch('auth/updateInput', {
                fbToken: fbToken,
            }, {root: true});
            await dispatch('auth/loginWithFBToken', {
                firebase_user_id: firebaseUserId,
            }, {root: true});
        } finally {
            await dispatch('signOut');
        }
    },
};

export const getters = {};
