import {Model} from '@vuex-orm/core';

export default class Receipt extends Model {
    static fields() {
        return {
            id: this.attr(null),
            donation_id: this.attr(null),
            file: this.attr(''),
        };
    }
}

Receipt.entity = 'receipts';
