import CampaignReport from '../models/CampaignReport';
import {bodyShaper} from '../../../syncable-commons-utils/utils/VuexHelper';

const initializeState = () => ({
    pagination: {
        rowsPerPage: 3,
        page: 1,
        total: 0,
    },
    currentIds: [], // campaign reportsはキャッシュしないことにするのでAssociateなどとは違いcurrentIdsのみ保持しておく
    loading: false,
    input: {
        reportId: '',
        campaignId: '',
        title: '',
        body: '',
        mainVisual: {
            id: '',
            file: '',
        },
    },
});
export default {
    state: initializeState,
    mutations: {
        fetchByCampaignIdSuccess(state, {page, total, ids}) {
            state.pagination.page = page;
            state.pagination.total = total;
            state.currentIds = ids;
        },
        startInput(state, {reportId, campaignId, title, body, mainVisual}) {
            state.input.reportId = reportId;
            state.input.campaignId = campaignId;
            state.input.title = title;
            state.input.body = body;
            state.input.mainVisual = mainVisual;
        },
        setInput(state, val = {}) {
            state.input = {...state.input, ...val};
        },
        saveStart(state) {
            state.loading = true;
        },
        saveFinish(state) {
            state.loading = false;
        },
        saveSuccess(state) {
            state.input = initializeState().input;
        },
    },
    actions: {
        async fetchByCampaignId({commit, state}, {campaignId, params: {page = initializeState().pagination.page}}) {
            const data = await this.$axios.$get(`/campaign/${campaignId}/reports/`, {
                params: {
                    rowsPerPage: state.pagination.rowsPerPage,
                    page: page,
                },
            });
            commit('fetchByCampaignIdSuccess', {
                page: data.page,
                total: data.count,
                ids: data.results.map(r => r.id),
            });
            await this.$db().model(CampaignReport).insertOrUpdate({
                data: data.results,
            });
        },
        async fetchByCampaignIdAndReportId(_, {campaignId, reportId}) {
            // TODO これcampaign idは要らなくないか？暇ができたら直そう
            // https://github.com/styzprojects/syncable-client/issues/2262
            const data = await this.$axios.$get(`/campaign/${campaignId}/reports/${reportId}/`);
            await this.$db().model(CampaignReport).insertOrUpdate({
                data: data,
            });
        },
        updateInput({commit}, val) {
            commit('setInput', val);
        },
        startCreateInput({commit}, {campaignId}) {
            commit('startInput', {
                ...initializeState().input,
                campaignId: campaignId,
            });
        },
        /**
         * 編集開始前に呼ぶ
         * 現時点のCampaignReportのプロパティの値を、編集用フィールドの初期値として設定する
         */
        startUpdateInput({commit}, {reportId}) {
            const target = this.$db().model(CampaignReport)
                .query()
                .with('main_visual')
                .where('id', Number(reportId))
                .first();
            commit('startInput', {
                title: target.title,
                body: target.body,
                mainVisual: {
                    id: target.main_visual.id,
                    file: target.main_visual.file,
                },
                campaignId: target.campaign_id,
                reportId: reportId,
            });
        },
        async executeSave({state, dispatch, commit}) {
            commit('saveStart');
            const action = state.input.reportId ? '__executeUpdate' : '__executeCreate';
            const data = await dispatch(action).finally(() => {
                commit('saveFinish');
            });
            commit('saveSuccess');
            return data;
        },
        async __executeCreate({state}) {
            let body = bodyShaper({
                title: state.input.title,
                body: state.input.body,
                main_visual: {
                    ...(state.input.mainVisual.file && {file: state.input.mainVisual.file}),
                },
            }, {
                imageKeys: [
                    'main_visual',
                ],
            });
            const data = await this.$axios.$post(`/campaign/${state.input.campaignId}/reports/`, body);

            await this.$db().model(CampaignReport).insert({data: data});

            return data;
        },
        async __executeUpdate({state}) {
            let body = bodyShaper({
                title: state.input.title,
                body: state.input.body,
                main_visual: {
                    id: state.input.mainVisual.id,
                    file: state.input.mainVisual.file,
                },
            }, {
                imageKeys: [
                    'main_visual',
                ],
            });
            const data = await this.$axios.$patch(`/campaign/${state.input.campaignId}/reports/${state.input.reportId}/`, body);

            await this.$db().model(CampaignReport).insertOrUpdate({data: data});

            return data;
        },
        async executeDelete(_, {reportId}) {
            const target = this.$db().model(CampaignReport).find(Number(reportId));
            await this.$axios.$delete(`/campaign/${target.campaign_id}/reports/${target.id}/`);
            await target.$delete();
        },
        uploadImage({state}, file) {
            return this.$axios.$post(`/campaign/${state.input.campaignId}/reports/upload-image/`, {file: file});
        },
    },
};
