import {PAYMENT_METHOD} from '../../../syncable-commons-consts/enums';

const MAX_DONATION_AMOUNT_DEFUALT = 6000000;
const MIN_DONATION_AMOUNT_DEFAULT = 300;
const MAX_DONATION_AMOUNT_AOZORA = 9999999;
const MIN_DONATION_AMOUNT_AOZORA = 300;

/**
 * @param {PAYMENT_METHOD} paymentMethod
 * @return {number}
 */
export const getMaxDonationAmount = (paymentMethod) => {
    return (paymentMethod === PAYMENT_METHOD.Bank)
        ? MAX_DONATION_AMOUNT_AOZORA
        : MAX_DONATION_AMOUNT_DEFUALT;
};

/**
 * @param {PAYMENT_METHOD} paymentMethod
 * @return {number}
 */
export const getMinDonationAmount = (paymentMethod) => {
    return (paymentMethod === PAYMENT_METHOD.Bank)
        ? MIN_DONATION_AMOUNT_AOZORA
        : MIN_DONATION_AMOUNT_DEFAULT;
};
