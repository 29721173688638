<template lang="pug">
    v-dialog.layout-auth-dialog(
    v-model="open"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
    )
        v-card.layout-auth-dialog-card(tile)
            v-toolbar(card)
                v-spacer
                v-btn(icon @click="closeAuthDialog")
                    v-icon close
            v-card-text
                layout-auth-content-login(v-if="isLogin")
                layout-auth-content-register(v-else)

</template>

<script>
import {mapActions} from 'vuex';
import LayoutAuthContentLogin from './LayoutAuthContentLogin';
import LayoutAuthContentRegister from './LayoutAuthContentRegister';

export default {
    name: 'LayoutAuthDialog',
    components: {LayoutAuthContentRegister, LayoutAuthContentLogin},
    computed: {
        redirectTo() {
            return this.$store.state.layout.authDialog.redirectTo;
        },
        isLogin() {
            return this.$store.state.layout.authDialog.isLogin;
        },
        open: {
            get() {
                return this.$store.state.layout.authDialog.open;
            },
            set(val) {
                this.$store.dispatch('layout/updateAuthDialogOpen', val);
            },
        },
    },
    watch: {
        open(val) {
            if (!val) {
                this.closeAuthDialog();
            }
        },
    },
    beforeDestroy() {
        this.closeAuthDialog();
    },
    methods: {
        ...mapActions('layout', {
            closeAuthDialog: 'closeAuthDialog',
        }),
    },
};
</script>

<style scoped lang="scss">
</style>
<style lang="scss">
    .layout-auth-dialog {
        position: fixed;
        z-index: $z-navigation-drawer;
        &-card {
            background-color: $c-bg;
            .layout-auth-content {
                &-container {
                    padding: 22.5px;
                }
            }
            .card__text {
                background-color: $c-secondary;
                height: 100%;
            }
        }
    }
</style>
