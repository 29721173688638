const REGEXP = {
    ALPHA_DASH: /^$|^[a-zA-Z0-9-]+$/,
    TEL: /^$|^[0-9-０-９ー]+$/,
    NUM_HALF: /^$|^[0-9]+$/,
    NUM_HALF_COMMA: /^$|^[0-9,]+$/,
    URL: /^$|^(https?)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)$/,
    KANA: /^$|^[ぁ-ゔァ-ヴー－]+$/,
    EMAIL: /^$|^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/,
    // 全角カタカナは小文字や濁音等が間に含まれるため範囲指定ではなく、全て列挙する
    BANK_REGISTER: /^[アイウエオカキクケコサシスセソタチツテトナニヌネノハヒフヘホマミムメモヤユヨラリルレロワヲンガギグゲゴザジズゼゾダヂヅデドバビブベボヴパピプペポ０-９Ａ-Ｚ（）．ー　／]+$/g, // eslint-disable-line no-irregular-whitespace
    SMALL_KANA: /^[^ァィゥェォヵㇰヶㇱㇲッㇳㇴㇵㇶㇷㇸㇹㇺャュョㇻㇼㇽㇾㇿヮｧｨｩｪｫｯｬｭｮぁぃぅぇぉゕゖっゃゅょゎ]*$/g,
    TRACKING_ID: /(^UA-[0-9]+-[0-9]+$)|(^G-[a-zA-Z0-9]+$)|^$/,
};

export default REGEXP;
