<template lang="pug">
    .drawer-item-list
        v-list.drawer-item-list__list(expand)
            .drawer-item-list--inner(
                v-for="(item,i) in items"
                :class="{'drawer-item-list--inner--active': item.isActive}"
                @click="$emit('click-list-item')"
            )
                DrawerItem.drawer-item-list__item(
                    v-if="!item.childItems"
                    :to="item.to"
                    :linkTarget="item.linkTarget"
                    :href="item.href"
                    :icon="item.icon"
                    :title="item.title"
                    solo
                )
                DrawerItemGroup.drawer-item-list__item-group(
                    v-if="item.childItems"
                    :items="item.childItems"
                    :icon="item.icon"
                    :appendIcon="item.appendIcon"
                    :title="item.title"
                    ref="groups"
                )
        .drawer-item-list__footer(v-if="!hideClose")
            DrawerItem.drawer-item-list__close(
                title="閉じる"
                icon="icon-hamburger"
                solo
                @click.stop.native="toggleSidebar"
            )
</template>

<script>
import {mapActions, mapState} from 'vuex';
import DrawerItem from '../molecules/DrawerItem';
import DrawerItemGroup from '../molecules/DrawerItemGroup';

export default {
    name: 'DrawerItemList',
    components: {DrawerItemGroup, DrawerItem},
    props: {
        items: {
            type: Array,
            required: true,
        },
        hideClose: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        ...mapState('layout', [
            'sidebar',
        ]),
    },
    watch: {
        sidebar(val) {
            if (!this.$refs || !this.$refs.groups
                    || this.$vuetify.breakpoint.xsOnly) {

                return;
            }
            // スマホでない場合サイドバーの開閉とともにitem-groupも開閉させる
            if (val) {
                this.$refs.groups.forEach(group => group.open());
            } else {
                this.$refs.groups.forEach(group => group.close());
            }
        },
    },
    methods: {
        ...mapActions({
            toggleSidebar: 'layout/toggleSidebar',
        }),
    },
};
</script>

<style scoped lang="scss">
    .drawer-item-list {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .drawer-item-list__list.list {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 16px;
        padding-bottom: 96px;

        color: $c-text;


        .drawer-item-list__item {
            box-shadow: inset 0px -1px 0px #F3F5F4;

            &:first-of-type {
                box-shadow: inset 0px -1px 0px #F3F5F4, inset 0px 1px 0px #F3F5F4;
            }
        }

        .drawer-item-list--inner--active {
            background-color: $c-bg-green;

            .drawer-item-list__item-group, .drawer-item-list__item {
                border-left: 4px solid $c-primary;
            }
        }
    }

    .drawer-item-list__close {
        cursor: pointer;
        box-shadow: inset 0px -1px 0px #F3F5F4, inset 0px 1px 0px #F3F5F4;
    }
</style>
