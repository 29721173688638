import {Model} from '@vuex-orm/core';
import {COUNTRY, GENDER} from '../../../syncable-commons-consts/enums';
import Donation from './Donation';

export default class Donor extends Model {
    static fields() {
        return {
            donation_id: this.number(null),
            user_id: this.number(null),

            id: this.number(null),
            donation: this.hasOne(Donation, 'donation_id'),
            email: this.string(''),
            phone: this.string(''),
            is_user: this.boolean(false),
            user: this.number(''),
            gender: this.number(GENDER.UNSPECIFIED),
            birthday: this.string(''),
            country: this.string(COUNTRY.JP),
            zip_code: this.string(''),
            area: this.string(''),
            city: this.string(''),
            address1: this.string(''),
            address2: this.string(''),
            last_name: this.string(''),
            first_name: this.string(''),
            last_name_kana: this.string(''),
            first_name_kana: this.string(''),
            created_at: this.string(''),
            updated_at: this.string(''),
        };
    }

    get fullName() {
        let _fullName = this.last_name || '';
        if (this.first_name) {
            _fullName += ` ${this.first_name}`;
        }
        return _fullName;
    }
}

Donor.entity = 'donors';
