<template lang="pug">
    .base-button-group
        slot
</template>

<script>
export default {
    name: 'BaseButtonGroup',
};
</script>

<style lang="scss">
    .base-button-group {
        display: flex;
        justify-content: center;
        .base-button {
            margin-right: 7.5px;
            margin-left: 7.5px;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
</style>