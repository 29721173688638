import {Model} from '@vuex-orm/core';

export default class UserProfile extends Model {
    static fields() {
        return {
            id: this.attr(null),
            nickname: this.attr(''),
            description: this.attr(''),
            sns_id_twitter: this.attr(''),
            sns_id_facebook: this.attr(''),

            user_id: this.attr(null),
        };
    }

    static newInstance() {
        return new UserProfile();
    }

    static get snsIdKeys() {
        return ['sns_id_twitter', 'sns_id_facebook'];
    }
}

UserProfile.entity = 'userProfiles';
