<template lang="pug">
    base-text.base-label(
    :level="level"
    :highlighted="highlighted"
    :black="black"
    :note="note"
    )
        base-icon.base-label-icon.mr-5(
        v-if="!!icon"
        :icon="icon"
        :level="level"
        :highlighted="highlighted"
        :black="black"
        :note="note"
        )
        span.base-label-text
            slot
</template>

<script>
import _ from 'lodash';
import BaseIcon from '../atoms/BaseIcon';
import BaseText from '../atoms/BaseText';

export default {
    name: 'BaseLabel',
    components: {BaseText, BaseIcon},
    props: {
        level: {
            type: String,
            default: '3',
            validator(val) {
                return _.includes(['1', '2', '3', '4', '5'], val);
            },
        },
        icon: String,
        highlighted: Boolean,
        black: Boolean,
        note: Boolean,
    },
};
</script>

<style scoped lang="scss">
    .base-label {
        display: flex;
        align-items: flex-start;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    }
</style>