import {Model} from '@vuex-orm/core';

export default class DonationCommission extends Model {
    static fields() {
        return {
            id: this.attr(null),
            donation_id: this.attr(null),
            commission: this.number(0),
            agency_commission_amount: this.number(0),
        };
    }
}

DonationCommission.entity = 'donationCommissions';
