import {Model} from '@vuex-orm/core';
import Portfolio from './Portfolio';
import PortfolioComposition from './PortfolioComposition';
import PortfolioSocialChallenge from './PortfolioSocialChallenge';

export default class PortfolioRevision extends Model {
    static fields() {
        return {
            id: this.number(null),

            portfolio: this.belongsTo(Portfolio, 'portfolio_id'),
            compositions: this.hasMany(PortfolioComposition, 'revision_id'),
            social_challenges: this.hasMany(PortfolioSocialChallenge, 'revision_id'),

            created_at: this.string(''),
            updated_at: this.string(''),

            portfolio_id: this.attr(null),
        };
    }

    static newInstance() {
        return new PortfolioRevision();
    }

    get maxDonationAmount() {
        return 100000 * this.compositions.length;
    }

    get minDonationAmount() {
        return 300;
    }

    getSortedSocialChallenges(sliceEnd = null) {
        return this.social_challenges.sort((a, b) => b.ratio - a.ratio).slice(0, sliceEnd || this.social_challenges.length + 1);
    }
}

PortfolioRevision.entity = 'portfolioRevisions';
