<template lang="pug">
    .layout-auth-content
        LayoutAuthContentRegisterSelect(
        v-if="registerStep===RegisterSteps.SELECT"
        @select="onSelect"
        )
        LayoutAuthContentRegisterMail(
        v-if="registerStep===RegisterSteps.MAIL"
        )
        LayoutAuthContentRegisterFacebook(
        v-if="registerStep===RegisterSteps.FACEBOOK"
        )
</template>

<script>
import { mapActions } from 'vuex';
import BaseHeading from '../../../syncable-commons-components/atoms/BaseHeading';
import BaseDivider from '../../../syncable-commons-components/atoms/BaseDivider';
import BaseText from '../../../syncable-commons-components/atoms/BaseText';
import BaseButtonFacebook from '../../../syncable-commons-components/molecules/BaseButtonFacebook';
import BaseIcon from '../../../syncable-commons-components/atoms/BaseIcon';
import BaseButtonTwitter from '../../../syncable-commons-components/molecules/BaseButtonTwitter';
import LayoutAuthContentRegisterSelect from './LayoutAuthContentRegisterSelect';
import LayoutAuthContentRegisterMail from './LayoutAuthContentRegisterMail';
import { RegisterSteps } from '../../store/layout';
import LayoutAuthContentRegisterFacebook from './LayoutAuthContentRegisterFacebook';

export default {
    name: 'LayoutAuthContentRegister',
    components: {
        LayoutAuthContentRegisterFacebook,
        LayoutAuthContentRegisterMail,
        LayoutAuthContentRegisterSelect,
        BaseButtonTwitter,
        BaseIcon,
        BaseButtonFacebook,
        BaseText,
        BaseDivider,
        BaseHeading,
    },
    data() {
        return {
            RegisterSteps: RegisterSteps,
        };
    },
    computed: {
        registerStep: {
            get() {
                return this.$store.state.layout.authDialog.registerStep;
            },
            set(val) {
                this.$store.dispatch('layout/updateAuthDialogRegisterStep', val);
            },
        },
    },
    watch: {
        registerStep(val) {
            if (val === RegisterSteps.SUCCESS) {
                this.finishAuth();
            }
        },
    },
    methods: {
        ...mapActions('layout', {
            openLoginDialog: 'openLoginDialog',
            finishAuth: 'finishAuth',
        }),
        ...mapActions('auth', {
            tryToRegisterWithFB: 'tryToRegisterWithFB',
        }),
        async onSelect(key) {
            if (key === RegisterSteps.FACEBOOK) {
                await this.tryToRegisterWithFB();
            }
            this.registerStep = key;
        },
    },
};
</script>

<style scoped lang="scss">
</style>