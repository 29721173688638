import {Model} from '@vuex-orm/core';

export default class AssociateVisionChallengeImage extends Model {
    static fields() {
        return {
            id: this.attr(null),
            file: this.attr(''),

            vision_id: this.attr(null),
        };
    }
}

AssociateVisionChallengeImage.entity = 'associateVisionChallengeImages';
