import _ from 'lodash';

const LINK_URL = {
    COMPANY: 'https://styz.io',
    ASSOCIATE: 'https://associate.syncable.biz',
    FAQ: 'https://help.syncable.biz/hc/ja',
    INQUIRY: 'https://help.syncable.biz/hc/ja/requests/new',
};

const LINK_NAME = {
    INDEX: 'index',
    TERMS: 'terms',
    TERMS_EN: 'terms-en',
    TERMS_ASSOCIATE: 'terms-associate',
    LAW: 'law',
    PRIVACY: 'privacy',
    PRIVACY_EN: 'privacy-en',
    AUTH: {
        MIGRATION: {
            INDEX: 'auth-migration',
        },
        PASSWORD: {
            UPDATE: 'auth-password-update',
            RECOVERY: 'auth-password-recovery',
        },
        APPLICATION: {
            EDIT_RECURRING_DONATION: {
                COMPLETE: 'auth-application-edit-recurring-donations-complete',
            },
        },
    },
    EXPLORE: {
        ASSOCIATE: {
            INDEX: 'explore-associate',
            SOCIAL_CHALLENGE: {
                SOCIAL_CHALLENGE_SLUG:
                    'explore-associate-social-challenge-socialChallengeSlug',
            },
        },
        CAMPAIGN: {
            INDEX: 'explore-campaign',
        },
    },
    ASSOCIATE: {
        ASSOCIATE_UID: {
            INDEX: {
                INDEX: 'associate-associateUid-index',
                BUSINESSES: {
                    INDEX: 'associate-associateUid-index-businesses',
                    BUSINESS_ID: 'associate-associateUid-index-businesses-businessId',
                },
                VISION: {
                    INDEX: 'associate-associateUid-index-vision',
                },
                CAMPAIGN: {
                    INDEX: 'associate-associateUid-index-campaign',
                    CREATE: {
                        INDEX: 'associate-associateUid-index-campaign-create',
                        CAMPAIGN_TYPE: 'associate-associateUid-index-campaign-create-campaignType',
                    },
                },
                MEMBERS: {
                    INDEX: 'associate-associateUid-index-members',
                },
                CONTRIBUTE: {
                    COMPLETE: 'associate-associateUid-index-contribute-complete',
                    MESSAGE: 'associate-associateUid-index-contribute-message',
                    CONFIRMATION_BANK: 'associate-associateUid-index-contribute-confirmation-bank',
                },
                DONATE: {
                    INDEX: 'associate-associateUid-index-donate',
                    MEMBERSHIP: 'associate-associateUid-index-donate-membership',
                    DONOR: 'associate-associateUid-index-donate-donor',
                    CARD: 'associate-associateUid-index-donate-card',
                    CONFIRMATION: 'associate-associateUid-index-donate-confirmation',
                    CONFIRMATION_BANK: 'associate-associateUid-index-donate-confirmation-bank',
                },
            },
        },
    },
    CAMPAIGN: {
        INDEX: 'campaign',
        CAMPAIGN_ID: {
            INDEX: {
                INDEX: 'campaign-campaignId-index',
                REPORTS: 'campaign-campaignId-index-reports',
                REPORTS_CREATE: 'campaign-campaignId-index-reports-create',
                REPORTS_REPORT_ID: 'campaign-campaignId-index-reports-campaignReportId',
                REPORTS_REPORT_ID_EDIT: 'campaign-campaignId-index-reports-campaignReportId-edit',
            },
            EDIT: {
                INDEX: 'campaign-campaignId-edit',
                CONFIG: 'campaign-campaignId-edit-config',
            },
            CREATE_SUPPORT_FUNDING: {
                CAMPAIGN_TYPE: 'campaign-campaignId-create-support-funding-campaignType',
            },
        },
    },
    ME: {
        SETTINGS: {
            INDEX: 'me-settings',
            PROFILE: 'me-settings-profile',
        },
        MYPAGE: {
            INDEX: 'me-mypage',
            FOLLOWINGS: {
                INDEX: 'me-mypage-followings',
            },
            DONATIONS: {
                INDEX: 'me-mypage-donations',
                DONATION_TYPE: 'me-mypage-donations-donationType',
                HISTORIES: {
                    INDEX: 'me-mypage-donations-histories',
                    DONATION_TYPE: 'me-mypage-donations-histories-donationType',
                },
            },
            CAMPAIGNS: {
                INDEX: 'me-mypage-campaigns',
            },
        },
    },
    PORTFOLIO: {
        INDEX: 'portfolios',
        PORTFOLIO_ID: {
            INDEX: {
                INDEX: 'portfolios-portfolioId-index',
                REPORTS: 'portfolios-portfolioId-index-reports',
                REPORTS_CREATE: 'portfolios-portfolioId-index-reports-create',
                REPORTS_REPORT_ID: 'portfolios-portfolioId-index-reports-portfolioReportId',
                REPORTS_REPORT_ID_EDIT: 'portfolios-portfolioId-index-reports-portfolioReportId-edit',
            },
            DONATE: {
                INDEX: 'portfolios-portfolioId-donate',
                DONOR: 'portfolios-portfolioId-donate-donor',
                CARD: 'portfolios-portfolioId-donate-card',
                CONFIRMATION: 'portfolios-portfolioId-donate-confirmation',
            },
            CONTRIBUTE: {
                COMPLETE: 'portfolios-portfolioId-contribute-complete',
                MESSAGE: 'portfolios-portfolioId-contribute-message',
            },
            CAMPAIGNS: {
                // INDEX: 'portfolios-portfolioId-campaigns',
                CREATE: {
                    // INDEX: 'portfolios-portfolioId-campaigns-create',
                    CAMPAIGN_TYPE: 'portfolios-portfolioId-campaigns-create-campaignType',
                },
            },
        },
    },
};

let ROUTER_TO = {};
const toGenerator = (values = []) => {
    values.forEach(value => {
        if (_.isObject(value)) {
            return toGenerator(Object.values(value));
        }
        ROUTER_TO[value] = {
            name: value,
        };
    });
};
toGenerator(Object.values(LINK_NAME));

export {LINK_NAME, ROUTER_TO, LINK_URL};
