import User from '../models/User';
import Follower from '../models/Follower';
import Associate from '../models/Associate';
import _ from 'lodash';

const state = () => ({});

export default {
    state,
    actions: {
        async follow(context, associateId) {
            const data = await this.$axios.$post('/follower/', {
                associate_id: associateId,
            });
            await this.$db().model(Follower).insert({
                data: data,
            });
            await Promise.all([
                await this.$db().model(User).update({
                    where: data.user,
                    data(record) {
                        record.following_associates.push(data.id);
                    },
                }),
                await this.$db().model(Associate).update({
                    where: data.associate,
                    data(record) {
                        record.followers.push(data.id);
                        record.total_followers++;
                    },
                }),
            ]);
        },
        async unfollow(context, associateId) {
            const UserModel = this.$db().model(User);
            const FollowerModel = this.$db().model(Follower);
            const AssociateModel = this.$db().model(Associate);

            const me = UserModel.getters('me')(this);
            const follower = FollowerModel
                .query()
                .where('user_id', me.id)
                .where('associate_id', associateId)
                .first();
            await this.$axios.$delete(`/follower/${follower.id}/`);
            await follower.$delete();

            await Promise.all([
                await UserModel.update({
                    where: follower.user_id,
                    data(record) {
                        _.remove(record.following_associates, {id: follower.id});
                    },
                }),
                await AssociateModel.update({
                    where: follower.associate_id,
                    data(record) {
                        _.remove(record.followers, {id: follower.id});
                        record.total_followers--;
                    },
                }),
            ]);
        },
    },
};
