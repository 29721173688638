export const state = () => ({
    userAgent: '',
    isMobile: false,
    isAndroid: false,
});

export const mutations = {
    setUserAgent(state, val) {
        state.userAgent = val;

        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
        // according to mozilla's article, using `Mobi` to detect mobile device
        state.isMobile = val && val.indexOf('Mobi') > -1;

        state.isAndroid = val && val.indexOf('Android') > -1;
    },
};

export const actions = {
    updateUserAgent({commit}, val) {
        commit('setUserAgent', val);
    },
};

export const getters = {
    isMobile(state) {
        return state.isMobile;
    },
    isAndroid(state) {
        return state.isAndroid;
    },
};
