<template lang="pug">
    base-check-box-group(
        :value="[]"
        :items="items"
    )
        template(v-slot:default="{item}")
            base-check-box(
                v-model="checks[item.label]"
                hide-details
            )
                span(v-if="isEn") {{$t('label.agreeTo')}}
                base-link(blank :to="to[item.label]") {{$t(`label.${[item.label]}`)}}
                span(v-if="!isEn") {{$t('label.agreeTo')}}
</template>

<script>
import BaseCheckBox from '../atoms/BaseCheckBox';
import BaseLink from '../atoms/BaseLink';
import _ from 'lodash';
import { LINK_NAME, ROUTER_TO } from '../../syncable/consts/links';
import BaseCheckBoxGroup from './BaseCheckBoxGroup';

export default {
    name: 'BaseCheckBoxSetTermsAndPrivacy',
    components: {BaseCheckBoxGroup, BaseLink, BaseCheckBox},
    props: {
        value: Boolean,
    },
    data() {
        return {
            checks: {
                terms: false,
                privacy: false,
            },
            items: [
                {
                    value: 'terms',
                    label: 'terms',
                },
                {
                    value: 'privacy',
                    label: 'privacy',
                },
            ],
        };
    },
    computed: {
        isEn() {
            return this.$store.state.layout.lang === 'en';
        },
        to() {
            return {
                terms: ROUTER_TO[this.$store.state.layout.lang === 'ja' ? LINK_NAME.TERMS : LINK_NAME.TERMS_EN],
                privacy: ROUTER_TO[this.$store.state.layout.lang === 'ja' ? LINK_NAME.PRIVACY : LINK_NAME.PRIVACY_EN],
            };
        },
    },
    watch: {
        checks: {
            deep: true,
            handler: function (val) {
                this.$emit('input', _.every(val, flag => flag));
            },
        },
    },
};
</script>

<style scoped>

</style>
