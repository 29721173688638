import {LINK_NAME, LINK_URL, ROUTER_TO} from '../consts/links';
import Campaign from '../orm/models/Campaign';

export default async function ({store, route, redirect, query}) {
    if (process.env.client) {
        return;
    }

    if (process.env.IS_UNDER_MAINTENANCE) {
        if (query.maintenanceKey === process.env.MAINTENANCE_KEY) {
            store.dispatch('maintenance/enableTestMode');
        }

        if (!store.state.maintenance.inTestMode) {
            return redirect('/maintenance');
        }
    }

    const redirectMap = {
        '/home': '/',
        '/about/associate': LINK_URL.ASSOCIATE,
    };
    const redirectTo = redirectMap[route.path];
    if (redirectTo) {
        return redirect(redirectTo);
    }

    // forward  "/associate/hpcso/campaign/${campaign.m_firebase_id}"
    // to       "/campaign/${campaign.id}"
    const matchedCampaignRouteParts = route.path.match(/^\/associate\/([0-9a-z-_]+?)\/campaign\/([0-9a-z-]+?)\/?$/i);
    if (matchedCampaignRouteParts && matchedCampaignRouteParts[2]) {
        const oldCampaignId = matchedCampaignRouteParts[2];

        // get campaign id from the old one
        await store.dispatch('entities/campaigns/fetch', {m_firebase_id: oldCampaignId}, {root: true});

        const campaign = store.$db().model(Campaign).query().where('m_firebase_id', oldCampaignId).first();

        if (campaign && campaign.id) {
            // do redirect
            return redirect({
                ...ROUTER_TO[LINK_NAME.CAMPAIGN.CAMPAIGN_ID.INDEX.INDEX],
                params: {
                    campaignId: campaign.id,
                },
                query: route.query,
            });
        }
    }
}
