// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../syncable/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".base-input label{display:none}", ""]);
// Exports
exports.locals = {
	"cGreenAlpha10": "#e6faf5",
	"cGreenBase": "#00cc9f",
	"cProgressbar2": "#00a681",
	"cProgressbar3": "#008265",
	"cSc_international": "#51b3b1",
	"cSc_environment": "#51b370",
	"cSc_animal": "#7051b3",
	"cSc_child": "#fff",
	"cSc_child-education": "#c96f14",
	"cSc_woman": "#ff99af",
	"cSc_human-rights": "#ffb933",
	"cSc_disabilities": "#ffe133",
	"cSc_employment": "#5b81c9",
	"cSc_disaster": "#f0ede1",
	"cSc_local-community": "#c9a25b",
	"cSc_local-industry": "#9cc93c",
	"cSc_social-education": "#81c95b",
	"cSc_health": "#a35bc9",
	"cSc_culture": "#c93c3f",
	"cSc_economic-development": "#c9c600",
	"cSc_non-profits-development": "#c95a89",
	"cSc_others": "#9b9b9b",
	"cLegendOthers": "#e1e1e1"
};
module.exports = exports;
