import { Model } from '@vuex-orm/core';
import { ASSOCIATE_STATUS, ASSOCIATE_TYPE, ASSOCIATE_TYPE_TEXT } from '../../../syncable-commons-consts/enums';
import AssociateSocialChallenge from './AssociateSocialChallenge';
import AssociateProfile from './AssociateProfile';
import AssociateBusiness from './AssociateBusiness';
import AssociateMember from './AssociateMember';
import AssociateVision from './AssociateVision';
import _ from 'lodash';
import AssociateDonationSetting from './AssociateDonationSetting';
import AssociateMembershipPlan from './AssociateMembershipPlan';
import AssociateLogo from './AssociateLogo';
import AssociateMainVisual from './AssociateMainVisual';
import Follower from './Follower';
import Campaign from './Campaign';
import CampaignTemplate from './CampaignTemplate.js';
import { LINK_NAME, ROUTER_TO } from '../../consts/links';

export default class Associate extends Model {
    static fields() {
        return {
            id: this.number(null),
            name: this.attr(null),
            logo: this.hasOne(AssociateLogo, 'associate'),
            main_visual: this.hasOne(AssociateMainVisual, 'associate'),
            associate_uid: this.attr(null),
            associate_type: this.number(ASSOCIATE_TYPE.NONE),
            display_type: this.attr(null),
            status: this.attr(ASSOCIATE_STATUS.UNPUBLISHABLE),
            social_challenges: this.hasMany(AssociateSocialChallenge, 'associate'),
            profile: this.hasOne(AssociateProfile, 'associate'),
            businesses: this.hasMany(AssociateBusiness, 'associate_id'),
            members: this.hasMany(AssociateMember, 'associate_id'),
            vision: this.hasOne(AssociateVision, 'associate'),
            donation_setting: this.hasOne(AssociateDonationSetting, 'associate'),
            membership_plans: this.hasMany(AssociateMembershipPlan, 'associate'),
            campaign_templates: this.hasMany(CampaignTemplate, 'associate'),
            campaigns: this.hasMany(Campaign, 'associate_id'),
            followers: this.hasMany(Follower, 'associate_id'),

            total_followers: this.number(0),
            // clientでcacheするよう
            is_cached: this.boolean(false),

            tracking_id: this.attr(null),
        };
    }

    static newInstance() {
        return new Associate({
            logo: AssociateLogo.newInstance(),
            main_visual: AssociateMainVisual.newInstance(),
            profile: AssociateProfile.newInstance(),
            vision: AssociateVision.newInstance(),
            donation_setting: AssociateDonationSetting.newInstance(),
        });
    }

    /**
     * 控除対象団体か
     * @returns {boolean}
     */
    get isDeductible() {
        return _.includes([
            ASSOCIATE_TYPE.APPROVED_NPO,
            ASSOCIATE_TYPE.SPECIALLY_APPROVED_NPO,
            ASSOCIATE_TYPE.PUBLIC_INTEREST_INCORPORATED,
            ASSOCIATE_TYPE.PUBLIC_INTEREST_FOUNDATION,
            ASSOCIATE_TYPE.RESEARCH_DEVELOPMENT,
            ASSOCIATE_TYPE.SOCIAL_WELFARE,
            ASSOCIATE_TYPE.EDUCATIONAL,
            ASSOCIATE_TYPE.INCORPORATED_ADMINISTRATIVE,
            ASSOCIATE_TYPE.LOCAL_INCORPORATED_ADMINISTRATIVE,
            ASSOCIATE_TYPE.MUNICIPALITY,
            ASSOCIATE_TYPE.NATIONAL_UNIVERSITY_CORPORATION,
            ASSOCIATE_TYPE.SPECIALLY_PUBLIC_INTEREST_INCORPORATED_ORGANIZATION,
        ], this.associate_type);
    }

    /**
     * 団体種別+団体名
     */
    get fullName() {
        return `${ASSOCIATE_TYPE_TEXT[this.display_type || this.associate_type]} ${this.name}`;
    }

    get dashboardUrl() {
        return `${process.env.ADMIN_BASE_URL}/dashboard/${this.associate_uid}`;
    }

    get brandPledgeUrl() {
        return `${process.env.PLEDGE_WEB_BASE_URL}/associate/${this.associate_uid}`;
    }

    /**
     * 全ての情報が揃っているか
     * @returns {boolean}
     */
    hasFullInfo() {
        return !_.some(Object.keys(Associate.fields()), k => {
            // 空文字や0を許容するフィールドがある場合は注意
            return !this[k];
        });
    }

    isVisionFetched() {
        return !!this.vision && this.vision.isFetched();
    }

    hasMembershipPlans() {
        return this.membership_plans && this.membership_plans.length > 0;
    }

    hasBusiness() {
        return this.businesses && this.businesses.length > 0;
    }

    isAcceptBrandPledge() {
        return this.donation_setting && this.donation_setting.is_brandpledge;
    }

    get isAmazonpayUsable() {
        return this.donation_setting && this.donation_setting.is_amazon_pay;
    }

    /**
     * routerに渡す寄付ページのto
     */
    get donationPageTo() {
        // ToDo: Campaignモデルのアクセス方法がおかしいので別のやり方にしないといけない
        // 当該団体のcampaign idが寄付対象としてキャッシュしてある場合、そのキャンペーンの寄付ページに飛ぶ
        const donationCacheCampaign = Campaign.getters('donationCacheCampaign');
        if (donationCacheCampaign.associate_id === this.id) {
            return donationCacheCampaign.donationPageTo;
        }

        return {
            ...ROUTER_TO[LINK_NAME.ASSOCIATE.ASSOCIATE_UID.INDEX.DONATE.INDEX],
            params: {
                associateUid: this.associate_uid,
            },
        };
    }
}

Associate.entity = 'associates';
