import { Model } from '@vuex-orm/core';
import {CAMPAIGN_TEMPLATE_TYPE, CAMPAIGN_TYPE_TEXT, CAMPAIGN_TYPE} from '../../../syncable-commons-consts/enums';

export default class CampaignTemplate extends Model {
    static entity = 'campaignTemplates'

    static fields() {
        return {
            id: this.number(null),
            type: this.number(null),
            template_name: this.string(null),
            body: this.string(null),
            is_public: this.boolean(null),

            associate: this.attr(null),

            created_at: this.string(null),
            updated_at: this.string(null),
        };
    }

    static getTemplates(campaignInstance) {
        const _associateId = campaignInstance.associate ? campaignInstance.associate.id : campaignInstance.associate_id;
        const campaignType = campaignInstance.type;
        let _templateType = campaignType;
        if (campaignInstance.parent_campaign || campaignInstance.parent_campaign_id) {
            _templateType = CAMPAIGN_TEMPLATE_TYPE.SUPPORT_FUNDING;
        } else if (campaignType === CAMPAIGN_TYPE.ANNUAL) {
            _templateType = CAMPAIGN_TEMPLATE_TYPE.ANNUAL;
        } // else if(TreePath.xxx) クライアント側でTreePathを取り回す必要が出てきたらこの辺に書く

        return this.query()
            .where('associate', _associateId)
            .where('type', _templateType)
            .where('is_public', true)
            .all() || [];
    }

    get campaignTypeText() {
        return CAMPAIGN_TYPE_TEXT[this.type];
    }
}
