<template lang="pug">
    v-btn.base-hamburger(icon)
        BaseIcon.base-hamburger-icon(
            :icon="active ? 'close' : 'hamburger'"
            size="28.8px"
            :white="white"
        )
</template>

<script>
import BaseIcon from './BaseIcon';

export default {
    name: 'BaseHamburger',
    components: {BaseIcon},
    props: {
        active: Boolean,
        white: Boolean,
    },
};
</script>

<style scoped lang="scss">
    .base-hamburger {
        margin: 0;
        width: 32px;
        height: 32px;
    }
</style>