<template lang="pug">
    .user-menu-list
        nuxt-link.user-menu-list-user.pa-20(
        v-ripple
        :to="to"
        )
            .user-menu-list-user-avatar
                base-avatar-icon(
                :src="user.avatar_image && user.avatar_image.file"
                alt="avatar"
                :size="size"
                )
            .user-menu-list-user-detail.px-15
                base-text(level="2" bold) {{user.profile.nickname}}
                base-text(level="4" note) {{user.email}}
            v-spacer
            .user-menu-list-user-chevron
                base-icon(
                icon="arrow-right"
                note
                )

        base-divider

        v-list(dense)
            v-list-tile.user-menu-list-item(
            v-for="(item,i) in items"
            :key="i"
            nuxt
            exact
            :to="item.to"
            @click="click(item.click)"
            )
                v-list-tile-content
                    .user-menu-list-content
                        base-text.user-menu-list-content__text(
                        note
                        bold
                        ) {{item.title}}
                        .user-menu-list-content__badge(v-if="item.badge && item.badge.show")
                            base-text.user-menu-list-content__badge__text(
                            note
                            bold
                            ) {{item.badge.number || ''}}

        base-divider
        v-list(dense)
            v-list-tile.user-menu-list-item(
            v-for="(associateMember,i) in user.associate_members"
            :key="i"
            :href="associateMember.associate.dashboardUrl"
            target="_blank"
            )
                v-list-tile-content
                    base-text(
                    note
                    bold
                    ) {{associateMember.associate.name}}
</template>

<script>
import {mapActions} from 'vuex';
import _ from 'lodash';
import BaseAvatarIcon from '../../../syncable-commons-components/atoms/BaseAvatarIcon';
import BaseText from '../../../syncable-commons-components/atoms/BaseText';
import BaseDivider from '../../../syncable-commons-components/atoms/BaseDivider';
import {LINK_NAME, ROUTER_TO} from '../../consts/links';
import BaseIcon from '../../../syncable-commons-components/atoms/BaseIcon';

export default {
    name: 'UserMenuList',
    components: {BaseIcon, BaseDivider, BaseText, BaseAvatarIcon},
    props: {
        user: Object,
    },
    data() {
        return {
            to: ROUTER_TO[LINK_NAME.ME.MYPAGE.INDEX],
        };
    },
    computed: {
        items() {
            return [
                {
                    to: {
                        ...ROUTER_TO[LINK_NAME.ME.SETTINGS.PROFILE],
                    },
                    title: 'プロフィール編集',
                },
                {
                    to: {
                        ...ROUTER_TO[LINK_NAME.ME.SETTINGS.INDEX],
                    },
                    title: 'アカウント設定',
                },
                {
                    to: {
                        ...ROUTER_TO[LINK_NAME.ME.MYPAGE.CAMPAIGNS.INDEX],
                    },
                    title: '作成中のキャンペーン',
                    badge: {
                        show: this.user && this.user.draft_campaign_count && this.user.draft_campaign_count > 0,
                        number: this.user.draft_campaign_count,
                    },
                },
                {
                    title: 'ログアウト',
                    click: this.clickLogout,
                },
            ];
        },
        size() {
            return this.$vuetify.breakpoint.smAndDown ? '12.8vw' : '46px';
        },
    },
    methods: {
        ...mapActions('auth', {
            logout: 'logout',
        }),
        clickLogout() {
            this.$confirm({
                title: 'ログアウト',
                message: 'ログアウトします。',
            }).then(() => {
                this.logout();
            });
        },
        click(method) {
            if (_.isFunction(method)) {
                method();
            }
        },
    },
};
</script>

<style scoped lang="scss">
    .user-menu-list-user {
        display: flex;
        &-detail {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        &-chevron {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .user-menu-list-content {
        display: flex;
    }

    .user-menu-list-content__text {
        margin-right: 8px;
    }

    .user-menu-list-content__badge {
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 10px;

        background-color: $c-pink;
    }

    .user-menu-list-content__badge__text {
        color: map-get($c-white, base);
    }
</style>
