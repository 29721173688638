export const PORTFOLIO_DONATION_PRICES = [
    {
        price: 500,
    },
    {
        price: 1000,
    },
    {
        price: 3000,
    },
    {
        price: 5000,
    },
    {
        price: 10000,
    },
    {
        price: 30000,
    },
];

export const NON_DISTRIBUTABLE_PORTFOLIO_DONATION_PRICES = [
    {
        price: 10000,
    },
    {
        price: 5000,
    },
    {
        price: 1000,
    },
    {
        price: 30000,
    },
    {
        price: 100000,
    },
    {
        price: 300000,
    },
];

export const DEFAULT_TARGET_AMOUNT_FOR_CAMPAIGN = 20000;
