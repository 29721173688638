import DateHelper from './DateHelper';
import _ from 'lodash';

/**
 * format('{0}さん、こんにちは！{1}ですか？', ['鈴木', '今日も元気'])
 * @param {string} template テンプレート文字列
 * @param {string[]} variables テンプレートのindexに対応する値の配列
 */
const format = (template, variables=[]) => {
    return template.replace(/{([0-9]+)}/g, (mathedChars, groupChars) => {
        const index = parseInt(groupChars);
        if (isNaN(index)) return mathedChars;
        return variables[index] !== void(0) ? variables[index] : mathedChars;
    });
};

class FormValidator {
    /**
     * 必須項目
     * @param v
     * @param label
     * @returns {boolean|string}
     */
    static required(v, label, errorTemplate='{0}を入力して下さい') {
        return (v !== undefined && v !== null && v !== '') || format(errorTemplate, [label]);
    }

    /**
     * 必須項目
     * @param v
     * @param label
     * @returns {boolean|string}
     */
    static requiredRadio(v, label, errorTemplate='{0}を入力して下さい') {
        return (v !== undefined && v !== null) || format(errorTemplate, [label]);
    }

    /**
     * 必須項目（Array）
     * @param v
     * @param label
     * @returns {boolean|string}
     */
    static requiredList(v = '', label, errorTemplate='{0}を選択して下さい') {
        return !!v.length || format(errorTemplate, [label]);
    }

    static inList(v, list, label, errorTemplate='{0}を選択して下さい') {
        return _.indexOf(list, v) !== -1 || format(errorTemplate, [label]);
    }

    /**
     * 最大文字数
     * @param v
     * @param maxLength
     * @param label
     * @returns {boolean|string}
     */
    static maxLength(v = '', maxLength, label, errorTemplate='{0}は{1}文字以下で入力して下さい') {
        return v.length <= maxLength || format(errorTemplate, [label, maxLength]);
    }

    /**
     * 最小文字数
     * 最小文字数
     * @param v
     * @param minLength
     * @param label
     * @returns {boolean|string}
     */
    static minLength(v = '', minLength, label, errorTemplate='{0}は{1}文字以上で入力して下さい') {
        return v.length >= minLength || format(errorTemplate, [label, minLength]);
    }

    /**
     * 最大個数
     * @param v
     * @param maxLength
     * @param label
     * @returns {boolean|string}
     */
    static maxLengthList(v = '', maxLength, label, errorTemplate='{0}は{1}つまで選択できます。') {
        return v.length <= maxLength || format(errorTemplate, [label, maxLength]);
    }

    /**
     * 正規表現
     * @param v
     * @param pattern
     * @param label
     * @param patternLabel
     * @returns {boolean|string}
     */
    static pattern(v = '', pattern, label, patternLabel, errorTemplate='{0}を正しく入力してください。') {
        const message = patternLabel ? format(errorTemplate, [label, patternLabel]) : format(errorTemplate, [label]);
        return (v !== undefined && v !== null && String(v).match(pattern) !== null) || message;
    }

    /**
     * 最大値(数字)
     * @param v
     * @param maxValue
     * @param label
     * @param unit 単位
     * @returns {boolean|string}
     */
    static maxValue(v = 0, maxValue, label, unit = '', errorTemplate='{0}は{1}{2}以下で入力してください。') {
        return Number(maxValue) >= Number(v) || format(errorTemplate, [label, unit, maxValue]);
    }

    /**
     * 最小値(数字)
     * @param v
     * @param minValue
     * @param label
     * @param unit 単位
     * @returns {boolean|string}
     */
    static minValue(v = 0, minValue, label, unit = '', errorTemplate='{0}は{1}{2}以上で入力してください。') {
        return Number(minValue) <= Number(v) || format(errorTemplate, [label, unit, minValue]);
    }

    static isAfter(v, {
        targetTime,
        label,
        targetLabel,
        dateFormat,
        unit,
    }) {
        return DateHelper.isAfter(v, targetTime, dateFormat, unit) || format('{0}は{1}より後を入力してください。', [label, targetLabel]);
    }

    static isSameOrAfter(v, {
        targetTime=undefined,
        label=undefined,
        targetLabel=undefined,
        dateFormat=undefined,
        unit=undefined,
        errorTemplate=undefined,
        hasSubject=undefined,
    }={}) {
        if (!v || !targetTime) {
            return true;
        }

        if (hasSubject) {
            return DateHelper.isSameOrAfter(v, targetTime, dateFormat, unit) || format(errorTemplate, [label, targetLabel]);
        }
        return DateHelper.isSameOrAfter(v, targetTime, dateFormat, unit) || format(errorTemplate, [targetLabel]);
    }
}

export default FormValidator;
