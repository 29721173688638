import {Model} from '@vuex-orm/core';

export default class AssociateBusinessActivityImage extends Model {
    static fields() {
        return {
            id: this.attr(null),
            file: this.attr(''),

            business_id: this.attr(null),
        };
    }
}

AssociateBusinessActivityImage.entity = 'associateBusinessActivityImages';
