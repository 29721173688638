<template lang="pug">
    // for users
    base-button.associate-follow-button(
    v-if="isLoggedIn"
    small
    :loading="loading"
    :inverted="!text && following"
    :text="text"
    :highlighted="text"
    @click="click"
    ) {{following ? 'Following' : 'Follow'}}
    // for guests
    base-button.associate-follow-button(
    v-else
    small
    :text="text"
    :highlighted="text"
    @click="guestClick"
    ) Follow
</template>

<script>
import BaseButton from '../../../syncable-commons-components/atoms/BaseButton';
import User from '../../orm/models/User';

export default {
    name: 'AssociateFollowButton',
    components: {BaseButton},
    props: {
        associateId: [Number, String],
        text: Boolean,
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        User() {
            return this.$store.$db().model(User);
        },
        isLoggedIn() {
            return this.$store.getters['auth/isLoggedIn'];
        },
        following() {
            const me = this.User.getters('me')(this.$store);
            return me.isFollowing(this.associateId);
        },
    },
    methods: {
        async click() {
            this.loading = true;
            try {
                this.following ? await this.unfollow() : await this.follow();
            } catch (e) {
                console.log(e);
            }
            this.loading = false;
        },
        async guestClick() {
            await this.$store.dispatch('layout/openLoginDialog');
        },
        async follow() {
            await this.$store.dispatch('entities/followers/follow', this.associateId);
        },
        async unfollow() {
            await this.$store.dispatch('entities/followers/unfollow', this.associateId);
        },
    },
};
</script>

<style scoped lang="scss">
    .associate-follow-button {
        margin: 0;
        width: 5.375rem;
    }
</style>
