<template lang="pug">
    base-icon.base-help-icon(
    highlighted
    icon="question-box"
    @click="onClick"
    )
</template>

<script>
import BaseIcon from './BaseIcon';

export default {
    name: 'BaseHelpIcon',
    components: {BaseIcon},
    props: {
        title: String,
        message: String,
    },
    methods: {
        onClick() {
            this.$alert({
                title: this.title,
                message: this.message,
            });
        },
    },
};
</script>

<style scoped lang="scss">
    .base-help-icon:hover {
        cursor: pointer;
    }
</style>