<template lang="pug">
    base-button(
    block
    large
    twitter
    @click="click"
    prepend-icon="twitter"
    )
        base-text Twitter
</template>

<script>
import BaseButton from '../atoms/BaseButton';
import BaseText from '../atoms/BaseText';

export default {
    name: 'BaseButtonTwitter',
    components: {BaseText, BaseButton},
    methods: {
        click(e) {
            this.$emit('click', e);
        },
    },
};
</script>

<style scoped>

</style>