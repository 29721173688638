import {Model} from '@vuex-orm/core';
import CustomAuthorImage from './CustomAuthorImage';
import Campaign from './Campaign';


export default class CustomAuthor extends Model {
    static fields() {
        return {
            id: this.attr(null),
            author_name: this.attr(''),

            campaign: this.belongsTo(Campaign, 'campaign_id'),
            campaign_id: this.attr(null),

            custom_author_image: this.hasOne(CustomAuthorImage, 'custom_author_id'),
        };
    }

    static newInstance() {
        return new CustomAuthor({
            id: undefined,
            author_name: undefined,
            campaign_id: undefined,
            custom_author_image: undefined,
        });
    }
    
    toPostableJson() {
        return Object.assign({
            id: this.id,
            // TODO: なければユーザ名
            author_name: this.author_name ? this.author_name.toPostableJson() : undefined,
            // TODO: なければユーザアバター
            custom_author_image: this.custom_author_image ? this.custom_author_image.toPostableJson() : undefined,
            campaign_id: this.campaign_id,
        });
    }
}

CustomAuthor.entity = 'customAuthors';
