import Portfolio from '../models/Portfolio';
import {createAwaitGetter} from '../../../syncable-commons-utils/utils/VuexHelper';

const initializeState = () => ({
    search: {
        params: {
            sortBy: '-started_at',
            pickup: null,
            limit: null,
            // 一覧ページ用のフィルターとソート
            dList: null,
        },
        pagination: {
            descending: true,
            page: 1,
            rowsPerPage: 18,
            total: 0,
            totalPages: 1,
        },
        results: {},
        currentKey: '',
    },
    browsingId: '',
});

export default {
    state: initializeState,
    mutations: {
        setSearchConditions(state, val = {}) {
            const page = val.page;
            delete val.page;
            const pagination = {
                page: parseInt(page || '1', 10),
            };
            const defaultParams = initializeState().search.params;
            const newParams = {
                ...state.search.params,
                ...defaultParams,
                ...val,
            };
            const newPagination = {
                ...state.search.pagination,
                ...pagination,
            };
            state.search = {
                ...state.search,
                ...{params: newParams},
                ...{pagination: newPagination},
            };
        },
        setSearchResults(state, val = {}) {
            state.search.results = {...state.search.results, ...val};
        },
        setSearchPagination(state, val = {}) {
            state.search.pagination = {...state.search.pagination, ...val};
        },
        setSearchCurrentKey(state, val = '') {
            state.search.currentKey = val;
        },
        setBrowsingId(state, val) {
            state.browsingId = Number(val);
        },
    },
    actions: {
        async fetch({commit, dispatch, state, getters}, condition) {
            await commit('setSearchConditions', condition);
            if (!state.search.results[getters.resultsKey]) {
                await dispatch('_fetchAndCache');
            }
            const result = state.search.results[getters.resultsKey] || {};
            commit('setSearchPagination', result.pagination);
            commit('setSearchCurrentKey', getters.resultsKey);
        },
        async _fetchAndCache({commit, dispatch, state, getters}) {
            const data = await this.$axios.$get('/portfolio/',
                {
                    params: getters.searchParams,
                    ext: {
                        longtime: true,
                    },
                },
            );
            await dispatch('insertOrUpdate', {data: data.results});

            await commit('setSearchResults', {
                [getters.resultsKey]: {
                    ids: data.results.map(r => r.id),
                    pagination: {
                        total: data.count,
                        totalPages: Math.ceil(data.count / state.search.pagination.rowsPerPage),
                    },
                },
            });
        },
        async onLandedPortfolioPage({commit, dispatch}, id) {
            await commit('setBrowsingId', id);
            await dispatch('fetchById', id);
        },
        async fetchById(_, id) {
            if (!id) {
                return;
            }
            const data = await this.$axios.$get(`/portfolio/${id}/`).catch(() => null);
            if (!data) {
                return;
            }
            await this.$db().model(Portfolio).insertOrUpdate({
                data: {
                    ...data,
                    is_cached: true,
                },
            });
        },
        async onExitPortfolioPage({commit}) {
            setTimeout(() => {
                commit('setBrowsingId', '');
            }, 0);
        },
    },
    getters: {
        browsingId(state) {
            return state.browsingId;
        },
        awaitGetter: () => store => {
            return createAwaitGetter(store.$db().model(Portfolio), (portfolio) => portfolio && portfolio.is_cached);
        },
        browsingPortfolio: state => store => {
            const PortfolioModel = store.$db().model(Portfolio);
            return PortfolioModel
                .query()
                .where('id', state.browsingId)
                .with('donation_messages', (query) => {
                    query.orderBy('created_at', 'desc');
                    query.withAllRecursive();
                })
                .withAllRecursive()
                .first() || PortfolioModel.newInstance();
        },
        searchParams(state) {
            return {
                ...state.search.params,
                page: state.search.pagination.page,
                rowsPerPage: state.search.pagination.rowsPerPage,
            };
        },
        resultsKey(state, getters) {
            return Object.values(getters.searchParams).toString();
        },
        currentIds(state) {
            const result = state.search.results[state.search.currentKey] || {};
            return result.ids || [];
        },
    },
};
