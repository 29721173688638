<template lang="pug">
    .base-check-box-group
        base-input-label.base-check-box-group__label(
            v-if="!!label"
            :disabled="disabled"
            :required="required"
        ) {{label}}
        .base-check-box-group__item(
            v-for="(item,i) in items"
            :key="i"
        )
            slot(v-bind:item="item")
                base-check-box(
                    v-model="localValue"
                    :check-value="item.value"
                    :hide-details="i !== (items.length - 1)"
                    :disabled="disabled"
                    :required="hasError"
                    :error-message="errorMessage"
                ) {{item.label}}
</template>

<script>
import BaseCheckBox from '../atoms/BaseCheckBox';
import BaseInputLabel from '../atoms/BaseInputLabel';

/**
 * vuetifyのv-checkboxのvalidを切り替えられるように変な作りになっています。
 * v-formでvalidationを管理するためにv-checkboxのvalidを切り替える必要がある。
 * v-checkboxにerrorというpropsがあるがこれをtrueにしてもvalid=falseになるわけではない。
 * validはv-checkboxのrulesによってのみ変わる。
 * よってBaseCheckBoxGroupがhasErrorかどうかによってv-checkboxにわたすrulesが動的に切り替わるようになっています。
 * なお、requiredしか考慮していません。
 */
export default {
    name: 'BaseCheckBoxGroup',
    components: {BaseInputLabel, BaseCheckBox},
    props: {
        value: {
            type: Array,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        items: {
            type: Array,
            required: true,
        },
        required: Boolean,
        disabled: Boolean,
    },
    data() {
        return {
            hasError: false,
        };
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        errorMessage() {
            // requiredしか考慮していません
            return this.hasError ? '選択してください' : '';
        },
    },
    watch: {
        localValue() {
            this.validate();
        },
    },
    mounted() {
        this.validate();
    },
    methods: {
        validate() {
            if (!this.required) {
                return false;
            }
            return this.hasError = this.localValue.length === 0;
        },
    },
};
</script>

<style scoped lang="scss">
    .base-check-box-group {
        &__label {
            padding-bottom: 16px;
        }

        &__item {
            &:not(:last-of-type) {
                margin-bottom: 12px;
            }
        }
    }
</style>