import { Model } from '@vuex-orm/core';
import { DONATION_TYPE, PAYMENT_METHOD, RECURRING_DONATION_STATUS } from '../../../syncable-commons-consts/enums';
import Associate from './Associate';
import AssociateMembershipPlan from './AssociateMembershipPlan';
import Portfolio from './Portfolio';
import User from './User';

export default class RecurringDonation extends Model {
    static fields() {
        return {
            id: this.number(null),
            amount: this.number(0),
            every: this.number(null),
            associate: this.belongsTo(Associate, 'associate_id'),
            portfolio: this.belongsTo(Portfolio, 'portfolio_id'),
            payment_method: this.number(PAYMENT_METHOD.CREDIT_CARD),
            type: this.number(DONATION_TYPE.NORMAL),
            membership_plan: this.belongsTo(AssociateMembershipPlan, 'membership_plan_id'),
            need_receipt: this.boolean(null),
            created_at: this.string(''),
            status: this.number(RECURRING_DONATION_STATUS.APPLY),
            user: this.belongsTo(User, 'user_id'),

            membership_plan_id: this.attr(null),
            associate_id: this.attr(null),
            portfolio_id: this.attr(null),
            user_id: this.attr(null),

            /**
             * この継続寄付にひもづくCustomerにひもづくCardの情報の抜粋
             * customer_idをもとにOmiseから取得した値が入る
             */
            omise_card: this.attr({
                last_digits: '',
                expiration_month: '',
                expiration_year: '',
            }),
        };
    }
}

RecurringDonation.entity = 'recurringDonations';
