<template lang="pug">
    img.base-logo(
        alt="syncable"
        :class="classes"
        :src="src"
    )
</template>

<script>
export default {
    name: 'BaseLogo',
    props: {
        white: Boolean,
        vertical: Boolean,
    },
    computed: {
        src() {
            const path = this.white ? '/img/logo-white.svg' : (this.vertical ? '/img/logo_v.svg' : '/img/logo.svg');
            return `${process.env.SYNCABLE_GLOBAL_ASSETS_BASE_URL}${path}`;
        },
        classes() {
            return {
                'is--vertical': this.vertical,
            };
        },
    },
};
</script>

<style scoped lang="scss">
    .base-logo {
        width: calc-rem(265px);
        height: calc-rem(60px);
        @include mq(md-and-up) {
            height: 41.65px;
            width: 177px;
        }
    }
</style>