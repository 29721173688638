
/**
 * /auth/application/用のストア
 */
export const state = () => ({
    input: {
        email: null,
    },
});

export const mutations = {
    setInput(state, val) {
        state.input = {...state.input, ...val};
    },
};

export const actions = {
    eraseAll({commit}) {
        commit('setInput', state());
    },
    updateInput({commit}, val) {
        commit('setInput', val);
    },
    async applyForStopRecurringDonation({state}) {
        await this.$axios.post('/auth/generate-onetime-token/', {
            email: state.input.email,
        });
    },
};