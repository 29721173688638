<template lang="pug">
    img.base-image-circle-image(
    :style="styles"
    :class="classes"
    :src="source"
    :alt="alt"
    )
</template>

<script>
import DefaultAvatar from '../assets/img/default_avatar.svg';

export default {
    name: 'BaseAvatarIcon',
    props: {
        src: String,
        alt: String,
        size: String,
        gray: Boolean,
    },
    computed: {
        source() {
            return this.src || DefaultAvatar;
        },
        styles() {
            return {
                width: this.size,
                height: this.size,
            };
        },
        classes() {
            return {
                'base-image-circle-image--gray': this.gray,
            };
        },
    },
};
</script>

<style scoped lang="scss">
    .base-image-circle-image {
        object-fit: cover;
        border-radius: 50%;
        &--gray {
            filter: grayscale(100%);
        }
    }
</style>