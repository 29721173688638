export default class GoogleAnalyticsHelper {
    static sendPvToAssociate(trackingId, path) {
        if (!process.browser || window.ga === undefined || Boolean(trackingId) === false) {
            return;
        }

        window.ga('create', trackingId, 'auto', 'associateTracker', {
            allowLinker: true,
        });
        window.ga('associateTracker.send', 'pageview', path);
    }

    static sendEvent(ga,{
        eventCategory=undefined,
        eventAction=undefined,
        eventLabel=undefined,
        eventValue=undefined,
        customDimension=undefined,
    }={}) {
        if (!ga) return;
        ga.event(eventCategory, eventAction, eventLabel, eventValue, customDimension);
    }
}

