// Associate Status
export const ASSOCIATE_STATUS = Object.freeze({
    UNPUBLISHABLE: 1, // '審査中',
    PUBLISHABLE: 2, // '公開前',
    PUBLISHED: 3, // 公開中
    STOPPED: 4, // '停止中',
    DISMISSED: 5, // 'テスト/重複',
});

// Associate Status
export const ASSOCIATE_STATUS_TEXT = Object.freeze({
    [ASSOCIATE_STATUS.UNPUBLISHABLE]: '公開不可',
    [ASSOCIATE_STATUS.PUBLISHABLE]: '公開可能',
    [ASSOCIATE_STATUS.PUBLISHED]: '公開中',
    [ASSOCIATE_STATUS.STOPPED]: '停止中',
    [ASSOCIATE_STATUS.DISMISSED]: 'テスト/重複',
});

// publishing Status
export const PUBLISHING_STATUS = Object.freeze({
    UNPUBLISHED: 1, // '公開前',
    PUBLISHED: 2, // 公開中
    STOPPED: 3, // '停止中',
});

// Associate Type
export const ASSOCIATE_TYPE = Object.freeze({
    PRIVATE_ORGANIZATION: 1, // 任意団体
    APPROVED_NPO: 2, // 認定NPO法人
    SPECIALLY_APPROVED_NPO: 3, // 特例認定NPO法人
    NPO: 4, // NPO法人
    PUBLIC_INTEREST_INCORPORATED: 5, // 公益社団法人
    GENERAL_INCORPORATED: 6, // 一般社団法人
    PUBLIC_INTEREST_FOUNDATION: 7, // 公益財団法人
    CORPORATE_JURIDICAL: 8, // 一般財団法人
    RESEARCH_DEVELOPMENT: 9, // 国立研究開発法人
    SOCIAL_WELFARE: 10, // 社会福祉法人
    EDUCATIONAL: 11, // 学校法人
    INCORPORATED_ADMINISTRATIVE: 12, // 独立行政法人
    LOCAL_INCORPORATED_ADMINISTRATIVE: 13, // 地方独立行政法人
    MUNICIPALITY: 14, // 自治体
    MEDICAL_CORPORATION: 15, // 医療法人社団
    MEDICAL_CORPORATION_FOUNDATION: 16, // 医療法人財団
    LABOR_UNION: 17, //労働組合
    QUASI_EDUCATIONAL: 18, // 準学校法人
    NATIONAL_UNIVERSITY_CORPORATION: 19, // 国立大学法人
    WORKERS_COOPERATIVE: 20, // 労働者協同組合
    SPECIALLY_PUBLIC_INTEREST_INCORPORATED_ORGANIZATION: 21, // 特定公益増進法人
    NONE: 99, // 未設定
});

export const ASSOCIATE_TYPE_TEXT = Object.freeze({
    [ASSOCIATE_TYPE.PRIVATE_ORGANIZATION]: '任意団体',
    [ASSOCIATE_TYPE.APPROVED_NPO]: '認定NPO法人',
    [ASSOCIATE_TYPE.SPECIALLY_APPROVED_NPO]: '特例認定NPO法人',
    [ASSOCIATE_TYPE.NPO]: 'NPO法人',
    [ASSOCIATE_TYPE.PUBLIC_INTEREST_INCORPORATED]: '公益社団法人',
    [ASSOCIATE_TYPE.GENERAL_INCORPORATED]: '一般社団法人',
    [ASSOCIATE_TYPE.PUBLIC_INTEREST_FOUNDATION]: '公益財団法人',
    [ASSOCIATE_TYPE.CORPORATE_JURIDICAL]: '一般財団法人',
    [ASSOCIATE_TYPE.RESEARCH_DEVELOPMENT]: '国立研究開発法人',
    [ASSOCIATE_TYPE.SOCIAL_WELFARE]: '社会福祉法人',
    [ASSOCIATE_TYPE.EDUCATIONAL]: '学校法人',
    [ASSOCIATE_TYPE.INCORPORATED_ADMINISTRATIVE]: '独立行政法人',
    [ASSOCIATE_TYPE.LOCAL_INCORPORATED_ADMINISTRATIVE]: '地方独立行政法人',
    [ASSOCIATE_TYPE.MUNICIPALITY]: '自治体',
    [ASSOCIATE_TYPE.MEDICAL_CORPORATION]: '医療法人社団',
    [ASSOCIATE_TYPE.MEDICAL_CORPORATION_FOUNDATION]: '医療法人財団',
    [ASSOCIATE_TYPE.LABOR_UNION]: '労働組合',
    [ASSOCIATE_TYPE.QUASI_EDUCATIONAL]: '準学校法人',
    [ASSOCIATE_TYPE.NATIONAL_UNIVERSITY_CORPORATION]: '国立大学法人',
    [ASSOCIATE_TYPE.WORKERS_COOPERATIVE]: '労働者協同組合',
    [ASSOCIATE_TYPE.SPECIALLY_PUBLIC_INTEREST_INCORPORATED_ORGANIZATION]: '特定公益増進法人',
    [ASSOCIATE_TYPE.NONE]: '未設定',
});

// AssociateAntisocialExamination Status
export const ASSOCIATE_ANTISOCIAL_EXAMINATION_STATUS = Object.freeze({
    UNENTERED: 1, // '未入力',
    BEFORE_EXAMINATION: 2, // 申請内容確認中
    UNDER_EXAMINATION: 3, // '審査中',
    OK: 4, // '審査OK',
    NG: 5, // '審査NG',
});

// AssociateAntisocialExamination Status Text
export const ASSOCIATE_ANTISOCIAL_EXAMINATION_STATUS_TEXT = Object.freeze({
    [ASSOCIATE_ANTISOCIAL_EXAMINATION_STATUS.UNENTERED]: '未入力',
    [ASSOCIATE_ANTISOCIAL_EXAMINATION_STATUS.BEFORE_EXAMINATION]: '審査前',
    [ASSOCIATE_ANTISOCIAL_EXAMINATION_STATUS.UNDER_EXAMINATION]: '審査中',
    [ASSOCIATE_ANTISOCIAL_EXAMINATION_STATUS.OK]: '審査OK',
    [ASSOCIATE_ANTISOCIAL_EXAMINATION_STATUS.NG]: '審査NG',
});

export const ASSOCIATE_DOCUMENT_EXAMINATION_STATUS = {
    BEFORE_APPLYING: 1, // 審査前
    OK: 2, // 審査OK
    NG: 3, // 審査NG
};

export const ASSOCIATE_DOCUMENT_EXAMINATION_STATUS_TEXT = {
    [ASSOCIATE_DOCUMENT_EXAMINATION_STATUS.BEFORE_APPLYING]: '審査前',
    [ASSOCIATE_DOCUMENT_EXAMINATION_STATUS.OK]: '審査OK',
    [ASSOCIATE_DOCUMENT_EXAMINATION_STATUS.NG]: '審査NG',
};

// AssociateSocialChallenge Value
export const ASSOCIATE_SOCIAL_CHALLENGE_VALUE = Object.freeze({
    INTERNATIONAL: 1, // 国際協力
    ENVIRONMENT: 2, // 自然・環境保全
    ANIMAL: 3, // 動物保護
    CHILD: 4, // 出産・子育て支援
    CHILD_EDUCATION: 5, // 子どもの教育
    WOMAN: 6, // 女性支援
    HUMAN_RIGHTS: 7, // 人権保護
    DISABILITIES: 8, // 障がい・介護支援
    EMPLOYMENT: 9, // 就職・雇用支援
    DISASTER: 10, // 防災・被災地支援
    LOCAL_COMMUNITY: 11, // 観光・まちづくり支援
    LOCAL_INDUSTRY: 12, // 地域産業振興
    SOCIAL_EDUCATION: 13, // 社会教育推進
    HEALTH: 14, // 保健・医療増進
    CULTURE: 15, // 芸術・スポーツ振興
    ECONOMIC_DEVELOPMENT: 16, // 経済活性化
    NON_PROFITS_DEVELOPMENT: 17, // NPO/NGO支援
    OTHERS: 99, // その他
});

// AssociateSocialChallenge Value Text
export const ASSOCIATE_SOCIAL_CHALLENGE_VALUE_TEXT = Object.freeze({
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.INTERNATIONAL]: '国際協力',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.ENVIRONMENT]: '自然・環境保全',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.ANIMAL]: '動物保護',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.CHILD]: '出産・子育て支援',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.CHILD_EDUCATION]: '子どもの教育',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.WOMAN]: '女性支援',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.HUMAN_RIGHTS]: '人権保護',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.DISABILITIES]: '障がい・介護支援',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.EMPLOYMENT]: '就職・雇用支援',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.DISASTER]: '防災・被災地支援',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.LOCAL_COMMUNITY]: '観光・まちづくり支援',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.LOCAL_INDUSTRY]: '地域産業振興',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.SOCIAL_EDUCATION]: '社会教育推進',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.HEALTH]: '保健・医療増進',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.CULTURE]: '芸術・スポーツ振興',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.ECONOMIC_DEVELOPMENT]: '経済活性化',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.NON_PROFITS_DEVELOPMENT]: 'NPO/NGO支援',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.OTHERS]: 'その他',
});

export const ASSOCIATE_SOCIAL_CHALLENGE_VALUE_TEXT_WILL = Object.freeze({
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.INTERNATIONAL]: '国際協力をしたい',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.ENVIRONMENT]: '自然・環境を守りたい',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.ANIMAL]: '動物を守りたい',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.CHILD]: '出産・子育てを支えたい',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.CHILD_EDUCATION]: '子どもに教育を届けたい',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.WOMAN]: '女性の支援をしたい',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.HUMAN_RIGHTS]: '人権を守りたい',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.DISABILITIES]: '障がい・介護を支えたい',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.EMPLOYMENT]: '就職・雇用支援をしたい',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.DISASTER]: '防災・被災地支援をしたい',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.LOCAL_COMMUNITY]: '観光・まちづくりを盛り上げたい',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.LOCAL_INDUSTRY]: '地域産業を応援したい',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.SOCIAL_EDUCATION]: '社会教育を推進したい',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.HEALTH]: '医療を支えたい',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.CULTURE]: '芸術・スポーツを応援したい',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.ECONOMIC_DEVELOPMENT]: '経済を支えたい',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.NON_PROFITS_DEVELOPMENT]: '市民活動を支えたい',
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.OTHERS]: 'その他',
});

export const ASSOCIATE_SOCIAL_CHALLENGE_VALUE_STRING = Object.freeze({
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.INTERNATIONAL]: 'international', // '国際協力'
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.ENVIRONMENT]: 'environment', // '自然・環境保全'
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.ANIMAL]: 'animal', // '動物保護'
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.CHILD]: 'child', // '出産・子育て支援'
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.CHILD_EDUCATION]: 'child-education', // '子どもの教育'
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.WOMAN]: 'woman', // '女性支援'
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.HUMAN_RIGHTS]: 'human-rights', // '人権保護'
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.DISABILITIES]: 'disabilities', // '障がい・介護支援'
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.EMPLOYMENT]: 'employment', // '就職・雇用支援'
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.DISASTER]: 'disaster', // '防災・被災地支援'
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.LOCAL_COMMUNITY]: 'local-community', // '観光・まちづくり支援'
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.LOCAL_INDUSTRY]: 'local-industry', // '地域産業振興'
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.SOCIAL_EDUCATION]: 'social-education', // '社会教育推進'
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.HEALTH]: 'health', // '保健・医療増進'
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.CULTURE]: 'culture', // '芸術・スポーツ振興'
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.ECONOMIC_DEVELOPMENT]: 'economic-development', // '経済活性化'
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.NON_PROFITS_DEVELOPMENT]: 'non-profits-development', // 'NPO/NGO支援'
    [ASSOCIATE_SOCIAL_CHALLENGE_VALUE.OTHERS]: 'others', // 'その他'
});

export const ASSOCIATE_ACTIVITY_AREA = Object.freeze({
    HOKKAIDO: 1,
    AOMORI: 2,
    IWATE: 3,
    MIYAGI: 4,
    AKITA: 5,
    YAMAGATA: 6,
    FUKUSHIMA: 7,
    IBARAKI: 8,
    TOCHIGI: 9,
    GUNMA: 10,
    SAITAMA: 11,
    CHIBA: 12,
    TOKYO: 13,
    KANAGAWA: 14,
    NIIGATA: 15,
    TOYAMA: 16,
    ISHIKAWA: 17,
    FUKUI: 18,
    YAMANASHI: 19,
    NAGANO: 20,
    GIFU: 21,
    SHIZUOKA: 22,
    AICHI: 23,
    MIE: 24,
    SHIGA: 25,
    KYOTO: 26,
    OSAKA: 27,
    HYOGO: 28,
    NARA: 29,
    WAKAYAMA: 30,
    TOTTORI: 31,
    SHIMANE: 32,
    OKAYAMA: 33,
    HIROSHIMA: 34,
    YAMAGUCHI: 35,
    TOKUSHIMA: 36,
    KAGAWA: 37,
    EHIME: 38,
    KOCHI: 39,
    FUKUOKA: 40,
    SAGA: 41,
    NAGASAKI: 42,
    KUMAMOTO: 43,
    OITA: 44,
    MIYAZAKI: 45,
    KAGOSHIMA: 46,
    OKINAWA: 47,
    NATION: 80,
    OVERSEAS: 90,
    UNDEFINED: 99,
});

export const ASSOCIATE_ACTIVITY_AREA_TEXT = Object.freeze({
    [ASSOCIATE_ACTIVITY_AREA.HOKKAIDO]: '北海道',
    [ASSOCIATE_ACTIVITY_AREA.AOMORI]: '青森',
    [ASSOCIATE_ACTIVITY_AREA.IWATE]: '岩手',
    [ASSOCIATE_ACTIVITY_AREA.MIYAGI]: '宮城',
    [ASSOCIATE_ACTIVITY_AREA.AKITA]: '秋田',
    [ASSOCIATE_ACTIVITY_AREA.YAMAGATA]: '山形',
    [ASSOCIATE_ACTIVITY_AREA.FUKUSHIMA]: '福島',
    [ASSOCIATE_ACTIVITY_AREA.IBARAKI]: '茨城',
    [ASSOCIATE_ACTIVITY_AREA.TOCHIGI]: '栃木',
    [ASSOCIATE_ACTIVITY_AREA.GUNMA]: '群馬',
    [ASSOCIATE_ACTIVITY_AREA.SAITAMA]: '埼玉',
    [ASSOCIATE_ACTIVITY_AREA.CHIBA]: '千葉',
    [ASSOCIATE_ACTIVITY_AREA.TOKYO]: '東京',
    [ASSOCIATE_ACTIVITY_AREA.KANAGAWA]: '神奈川',
    [ASSOCIATE_ACTIVITY_AREA.NIIGATA]: '新潟',
    [ASSOCIATE_ACTIVITY_AREA.TOYAMA]: '富山',
    [ASSOCIATE_ACTIVITY_AREA.ISHIKAWA]: '石川',
    [ASSOCIATE_ACTIVITY_AREA.FUKUI]: '福井',
    [ASSOCIATE_ACTIVITY_AREA.YAMANASHI]: '山梨',
    [ASSOCIATE_ACTIVITY_AREA.NAGANO]: '長野',
    [ASSOCIATE_ACTIVITY_AREA.GIFU]: '岐阜',
    [ASSOCIATE_ACTIVITY_AREA.SHIZUOKA]: '静岡',
    [ASSOCIATE_ACTIVITY_AREA.AICHI]: '愛知',
    [ASSOCIATE_ACTIVITY_AREA.MIE]: '三重',
    [ASSOCIATE_ACTIVITY_AREA.SHIGA]: '滋賀',
    [ASSOCIATE_ACTIVITY_AREA.KYOTO]: '京都',
    [ASSOCIATE_ACTIVITY_AREA.OSAKA]: '大阪',
    [ASSOCIATE_ACTIVITY_AREA.HYOGO]: '兵庫',
    [ASSOCIATE_ACTIVITY_AREA.NARA]: '奈良',
    [ASSOCIATE_ACTIVITY_AREA.WAKAYAMA]: '和歌山',
    [ASSOCIATE_ACTIVITY_AREA.TOTTORI]: '鳥取',
    [ASSOCIATE_ACTIVITY_AREA.SHIMANE]: '島根',
    [ASSOCIATE_ACTIVITY_AREA.OKAYAMA]: '岡山',
    [ASSOCIATE_ACTIVITY_AREA.HIROSHIMA]: '広島',
    [ASSOCIATE_ACTIVITY_AREA.YAMAGUCHI]: '山口',
    [ASSOCIATE_ACTIVITY_AREA.TOKUSHIMA]: '徳島',
    [ASSOCIATE_ACTIVITY_AREA.KAGAWA]: '香川',
    [ASSOCIATE_ACTIVITY_AREA.EHIME]: '愛媛',
    [ASSOCIATE_ACTIVITY_AREA.KOCHI]: '高知',
    [ASSOCIATE_ACTIVITY_AREA.FUKUOKA]: '福岡',
    [ASSOCIATE_ACTIVITY_AREA.SAGA]: '佐賀',
    [ASSOCIATE_ACTIVITY_AREA.NAGASAKI]: '長崎',
    [ASSOCIATE_ACTIVITY_AREA.KUMAMOTO]: '熊本',
    [ASSOCIATE_ACTIVITY_AREA.OITA]: '大分',
    [ASSOCIATE_ACTIVITY_AREA.MIYAZAKI]: '宮崎',
    [ASSOCIATE_ACTIVITY_AREA.KAGOSHIMA]: '鹿児島',
    [ASSOCIATE_ACTIVITY_AREA.OKINAWA]: '沖縄',
    [ASSOCIATE_ACTIVITY_AREA.NATION]: '全国',
    [ASSOCIATE_ACTIVITY_AREA.OVERSEAS]: '海外',
    [ASSOCIATE_ACTIVITY_AREA.UNDEFINED]: '未設定',
});

// Gender
export const GENDER = Object.freeze({
    MALE: 1, // 男
    FEMALE: 2, // 女
    UNSPECIFIED: 3, // 指定なし
});

// Gender Text
export const GENDER_TEXT = Object.freeze({
    [GENDER.MALE]: '男性',
    [GENDER.FEMALE]: '女性',
    [GENDER.UNSPECIFIED]: '指定なし',
});

// Prefecture
export const PREFECTURE = Object.freeze(({
    HOKKAIDO: 1,
}));

// Prefecture
export const PREFECTURE_TEXT = Object.freeze({
    [PREFECTURE.HOKKAIDO]: '北海道',
});

export const PAYMENT_METHOD = Object.freeze(({
    CREDIT_CARD: 1,
    BIT_COIN: 2,
    BRAND_PLEDGE: 3,
    AMAZON_PAY: 4,
    DIRECT: 5,
    BankTransfer: 6,
    Bank: 7,
}));

export const PAYMENT_METHOD_TEXT = Object.freeze({
    [PAYMENT_METHOD.CREDIT_CARD]: 'クレジットカード',
    [PAYMENT_METHOD.BIT_COIN]: 'ビットコイン',
    [PAYMENT_METHOD.BRAND_PLEDGE]: 'Brand Pledge',
    [PAYMENT_METHOD.AMAZON_PAY]: 'Amazon Pay',
    [PAYMENT_METHOD.DIRECT]: '直接振り込み',
    [PAYMENT_METHOD.Bank]: '銀行振込',
});

export const PAYMENT_METHOD_TEXT_i18n_KEY = Object.freeze({
    [PAYMENT_METHOD.CREDIT_CARD]: 'donation.creditCard',
    [PAYMENT_METHOD.BIT_COIN]: 'donation.bitCoin',
    [PAYMENT_METHOD.BRAND_PLEDGE]: 'donation.brandPledge',
    [PAYMENT_METHOD.AMAZON_PAY]: 'donation.amazonPay',
    [PAYMENT_METHOD.DIRECT]: 'donation.bankTransfer',
});

export const DONATION_TYPE = Object.freeze(({
    NORMAL: 1,
    CAMPAIGN: 2,
    PORTFOLIO: 3,
    AGENCY: 6,
}));

export const DONATION_TYPE_TEXT = Object.freeze({
    [DONATION_TYPE.NORMAL]: '通常',
    [DONATION_TYPE.CAMPAIGN]: 'キャンペーン',
    [DONATION_TYPE.PORTFOLIO]: 'ポートフォリオ',
    [DONATION_TYPE.AGENCY]: '代理店',
});

export const DONATION_STATUS = Object.freeze(({
    BEFORE_PAYMENT: 1,
    PAID: 2,
    CAN_TRANSFER: 3,
    TRANSFERRED: 4,
    FAILED: 5,
}));

export const DONATION_STATUS_TEXT = Object.freeze({
    [DONATION_STATUS.BEFORE_PAYMENT]: '決済前',
    [DONATION_STATUS.PAID]: '決済済み',
    [DONATION_STATUS.CAN_TRANSFER]: '振込可能',
    [DONATION_STATUS.TRANSFERRED]: '振込済み',
    [DONATION_STATUS.FAILED]: '失敗',
});

export const RECURRING_DONATION_STATUS = Object.freeze({
    APPLY: 1,
    REGISTERED: 2,
    FIRST_PAYMENT_FAILED: 3,
    FAILED: 4,
    SUSPENSION: 5,
});

export const RECURRING_DONATION_STATUS_FOR_ADMIN = Object.freeze({
    ON_GOING: 1,
    UNSETTLED_THIS_MONTH: 2,
    TERMINATED: 3,
    HIDDEN: 99,
});

export const RECURRING_DONATION_STATUS_FOR_ADMIN_TEXT = Object.freeze({
    [RECURRING_DONATION_STATUS_FOR_ADMIN.ON_GOING]: '継続中',
    [RECURRING_DONATION_STATUS_FOR_ADMIN.UNSETTLED_THIS_MONTH]: '今月未寄付',
    [RECURRING_DONATION_STATUS_FOR_ADMIN.TERMINATED]: '解約済み',
});

export const RECURRING_DONATION_PERIOD = Object.freeze({
    MONTH: 1,
    // YEAR: 2
});

export const RECURRING_DONATION_PERIOD_TEXT = Object.freeze({
    [RECURRING_DONATION_PERIOD.MONTH]: '月',
    // [RECURRING_DONATION_PERIOD.YEAR]: '年'
});

export const RECURRING_DONATION_PERIOD_STATUS_TEXT = Object.freeze({
    [RECURRING_DONATION_STATUS.APPLY]: '登録前',
    [RECURRING_DONATION_STATUS.REGISTERED]: '登録済み',
    [RECURRING_DONATION_STATUS.FIRST_PAYMENT_FAILED]: '決済失敗',
    [RECURRING_DONATION_STATUS.FAILED]: '失敗',
    [RECURRING_DONATION_STATUS.SUSPENSION]: '停止',
});

export const RECEIPT_SENDING_OPTION = Object.freeze({
    UNSPECIFIED: 0,
    EMAIL: 1,
    POSTING: 2,
});

export const RECEIPT_SENDING_OPTION_TEXT = Object.freeze({
    [RECEIPT_SENDING_OPTION.UNSPECIFIED]: '',
    [RECEIPT_SENDING_OPTION.EMAIL]: 'メール',
    [RECEIPT_SENDING_OPTION.POSTING]: '郵送',
});

export const MEMBERSHIP_PLAN_STATUS = Object.freeze({
    PUBLIC: 1,
    STOP: 2,
});

export const MEMBERSHIP_PLAN_STATUS_TEXT = Object.freeze({
    [MEMBERSHIP_PLAN_STATUS.PUBLIC]: '公開',
    [MEMBERSHIP_PLAN_STATUS.STOP]: '停止',
});

// RecipientType
export const RECIPIENT_TYPE = Object.freeze({
    INDIVIDUAL: 1,
    CORPORATION: 2,
});

export const RECIPIENT_TYPE_TEXT = Object.freeze({
    [RECIPIENT_TYPE.INDIVIDUAL]: '個人',
    [RECIPIENT_TYPE.CORPORATION]: '企業',
});

// BankAccountType
export const BANK_ACCOUNT_TYPE = Object.freeze({
    NORMAL: 1,
    CURRENT: 2,
});

export const BANK_ACCOUNT_TYPE_TEXT = Object.freeze({
    [BANK_ACCOUNT_TYPE.NORMAL]: '普通口座',
    [BANK_ACCOUNT_TYPE.CURRENT]: '当座口座',
});

// BankAccountVerificationStatus
export const BANK_ACCOUNT_VERIFICATION_STATUS = Object.freeze({
    BEFORE_EXAMINATION: 0, // 審査前
    READY_TO_EXAMINATION: 1, // 審査可能
    UNDER_EXAMINATION: 2, // 審査中
    ACCEPTED: 3, // 承認済み
    REJECTED: 4, // 非承認
});

// TransferRequestStatus
export const TRANSFER_REQUEST_STATUS = Object.freeze({
    REQUEST: 1,
    TRANSFERRED: 2,
    FAILED: 3,
});

export const TRANSFER_REQUEST_STATUS_TEXT = Object.freeze({
    [TRANSFER_REQUEST_STATUS.REQUEST]: '申請中',
    [TRANSFER_REQUEST_STATUS.TRANSFERRED]: '振込完了',
    [TRANSFER_REQUEST_STATUS.FAILED]: '失敗',
});

export const AGENCY_STATUS = Object.freeze({
    UNDER_EXAMINATION: 1, // '審査中',
    UNPUBLISHED: 2, // '公開前',
    PUBLISHED: 3, // 公開中
    STOPPED: 4, // '停止中',
});

export const AGENCY_STATUS_TEXT = Object.freeze({
    [AGENCY_STATUS.UNDER_EXAMINATION]: '審査中',
    [AGENCY_STATUS.UNPUBLISHED]: '公開前',
    [AGENCY_STATUS.PUBLISHED]: '公開中',
    [AGENCY_STATUS.STOPPED]: '停止中',
});

export const ASSOCIATE_MEMBER_STATUS = Object.freeze({
    MASTER: 1, // 'マスター',
    MEMBER: 2, // 'メンバー',
});

export const ASSOCIATE_MEMBER_STATUS_TEXT = Object.freeze({
    [ASSOCIATE_MEMBER_STATUS.MASTER]: 'マスター',
    [ASSOCIATE_MEMBER_STATUS.MEMBER]: 'メンバー',
});

export const DONATION_SETTING_PRICE_TYPE = Object.freeze({
    ONETIME: 1,
    RECURRING: 2,
});

export const DONATION_SETTING_PRICE_TYPE_TEXT = Object.freeze({
    [DONATION_SETTING_PRICE_TYPE.ONETIME]: '単発寄付',
    [DONATION_SETTING_PRICE_TYPE.RECURRING]: '継続寄付',
});

export const ASSOCIATE_MEMBERSHIP_PLAN_STATUS = Object.freeze({
    PUBLIC: 1,
    STOP: 2,
});

export const ASSOCIATE_MEMBERSHIP_PLAN_STATUS_TEXT = Object.freeze({
    [ASSOCIATE_MEMBERSHIP_PLAN_STATUS.PUBLIC]: '公開',
    [ASSOCIATE_MEMBERSHIP_PLAN_STATUS.STOP]: '停止',
});

export const COUNTRY = Object.freeze({
    AF: 'AF',
    AL: 'AL',
    DZ: 'DZ',
    AS: 'AS',
    AD: 'AD',
    AO: 'AO',
    AI: 'AI',
    AQ: 'AQ',
    AG: 'AG',
    AR: 'AR',
    AM: 'AM',
    AW: 'AW',
    AU: 'AU',
    AT: 'AT',
    AZ: 'AZ',
    BS: 'BS',
    BH: 'BH',
    BD: 'BD',
    BB: 'BB',
    BY: 'BY',
    BE: 'BE',
    BZ: 'BZ',
    BJ: 'BJ',
    BM: 'BM',
    BT: 'BT',
    BO: 'BO',
    BA: 'BA',
    BW: 'BW',
    BV: 'BV',
    BR: 'BR',
    IO: 'IO',
    BN: 'BN',
    BG: 'BG',
    BF: 'BF',
    BI: 'BI',
    CI: 'CI',
    KH: 'KH',
    CM: 'CM',
    CA: 'CA',
    CV: 'CV',
    KY: 'KY',
    CF: 'CF',
    TD: 'TD',
    CL: 'CL',
    CN: 'CN',
    CX: 'CX',
    CC: 'CC',
    CO: 'CO',
    KM: 'KM',
    CG: 'CG',
    CD: 'CD',
    CK: 'CK',
    CR: 'CR',
    HR: 'HR',
    CU: 'CU',
    CY: 'CY',
    CZ: 'CZ',
    DK: 'DK',
    DJ: 'DJ',
    DM: 'DM',
    DO: 'DO',
    TP: 'TP',
    EC: 'EC',
    EG: 'EG',
    SV: 'SV',
    GQ: 'GQ',
    ER: 'ER',
    EE: 'EE',
    ET: 'ET',
    FK: 'FK',
    FO: 'FO',
    FJ: 'FJ',
    FI: 'FI',
    FR: 'FR',
    FX: 'FX',
    GF: 'GF',
    PF: 'PF',
    TF: 'TF',
    GA: 'GA',
    GM: 'GM',
    GE: 'GE',
    DE: 'DE',
    GH: 'GH',
    GI: 'GI',
    GR: 'GR',
    GL: 'GL',
    GD: 'GD',
    GP: 'GP',
    GU: 'GU',
    GT: 'GT',
    GN: 'GN',
    GW: 'GW',
    GY: 'GY',
    HT: 'HT',
    HM: 'HM',
    HN: 'HN',
    HK: 'HK',
    HU: 'HU',
    IS: 'IS',
    IN: 'IN',
    ID: 'ID',
    IR: 'IR',
    IQ: 'IQ',
    IE: 'IE',
    IL: 'IL',
    IT: 'IT',
    JM: 'JM',
    JP: 'JP',
    JO: 'JO',
    KZ: 'KZ',
    KE: 'KE',
    KI: 'KI',
    KR: 'KR',
    KW: 'KW',
    KG: 'KG',
    LA: 'LA',
    LV: 'LV',
    LB: 'LB',
    LS: 'LS',
    LR: 'LR',
    LY: 'LY',
    LI: 'LI',
    LT: 'LT',
    LU: 'LU',
    MO: 'MO',
    MK: 'MK',
    MG: 'MG',
    MW: 'MW',
    MY: 'MY',
    MV: 'MV',
    ML: 'ML',
    MT: 'MT',
    MH: 'MH',
    MQ: 'MQ',
    MR: 'MR',
    MU: 'MU',
    YT: 'YT',
    MX: 'MX',
    FM: 'FM',
    MD: 'MD',
    MC: 'MC',
    MN: 'MN',
    MS: 'MS',
    MA: 'MA',
    MZ: 'MZ',
    MM: 'MM',
    NA: 'NA',
    NR: 'NR',
    NP: 'NP',
    AN: 'AN',
    NL: 'NL',
    NC: 'NC',
    NZ: 'NZ',
    NI: 'NI',
    NE: 'NE',
    NG: 'NG',
    NU: 'NU',
    NF: 'NF',
    KP: 'KP',
    MP: 'MP',
    NO: 'NO',
    OM: 'OM',
    PK: 'PK',
    PW: 'PW',
    PA: 'PA',
    PG: 'PG',
    PY: 'PY',
    PE: 'PE',
    PH: 'PH',
    PN: 'PN',
    PL: 'PL',
    PT: 'PT',
    PR: 'PR',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RU: 'RU',
    RW: 'RW',
    ST: 'ST',
    WS: 'WS',
    SM: 'SM',
    SA: 'SA',
    SN: 'SN',
    SC: 'SC',
    SL: 'SL',
    SG: 'SG',
    SK: 'SK',
    SI: 'SI',
    SB: 'SB',
    SO: 'SO',
    ZA: 'ZA',
    GS: 'GS',
    ES: 'ES',
    LK: 'LK',
    SH: 'SH',
    KN: 'KN',
    LC: 'LC',
    PM: 'PM',
    VC: 'VC',
    SD: 'SD',
    SR: 'SR',
    SJ: 'SJ',
    SZ: 'SZ',
    SE: 'SE',
    CH: 'CH',
    SY: 'SY',
    TW: 'TW',
    TJ: 'TJ',
    TZ: 'TZ',
    TH: 'TH',
    TG: 'TG',
    TK: 'TK',
    TO: 'TO',
    TT: 'TT',
    TN: 'TN',
    TR: 'TR',
    TM: 'TM',
    TC: 'TC',
    TV: 'TV',
    UM: 'UM',
    UG: 'UG',
    UA: 'UA',
    AE: 'AE',
    GB: 'GB',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VU: 'VU',
    VA: 'VA',
    VE: 'VE',
    VN: 'VN',
    VI: 'VI',
    VG: 'VG',
    WF: 'WF',
    YE: 'YE',
    YU: 'YU',
    ZM: 'ZM',
    ZW: 'ZW',
});

export const COUNTRY_TEXT = Object.freeze({
    [COUNTRY.AF]: 'Afghanistan',
    [COUNTRY.AL]: 'Albania',
    [COUNTRY.DZ]: 'Algeria',
    [COUNTRY.AS]: 'American Samoa',
    [COUNTRY.AD]: 'Andorra',
    [COUNTRY.AO]: 'Angola',
    [COUNTRY.AI]: 'Anguilla',
    [COUNTRY.AQ]: 'Antarctica',
    [COUNTRY.AG]: 'Antigua and Barbuda',
    [COUNTRY.AR]: 'Argentina',
    [COUNTRY.AM]: 'Armenia',
    [COUNTRY.AW]: 'Aruba',
    [COUNTRY.AU]: 'Australia',
    [COUNTRY.AT]: 'Austria',
    [COUNTRY.AZ]: 'Azerbaijan',
    [COUNTRY.BS]: 'Bahamas, The',
    [COUNTRY.BH]: 'Bahrain',
    [COUNTRY.BD]: 'Bangladesh',
    [COUNTRY.BB]: 'Barbados',
    [COUNTRY.BY]: 'Belarus',
    [COUNTRY.BE]: 'Belgium',
    [COUNTRY.BZ]: 'Belize',
    [COUNTRY.BJ]: 'Benin',
    [COUNTRY.BM]: 'Bermuda',
    [COUNTRY.BT]: 'Bhutan',
    [COUNTRY.BO]: 'Bolivia',
    [COUNTRY.BA]: 'Bosnia and Herzegovina',
    [COUNTRY.BW]: 'Botswana',
    [COUNTRY.BV]: 'Bouvet Island',
    [COUNTRY.BR]: 'Brazil',
    [COUNTRY.IO]: 'British Indian Ocean Territory',
    [COUNTRY.BN]: 'Brunei',
    [COUNTRY.BG]: 'Bulgaria',
    [COUNTRY.BF]: 'Burkina Faso',
    [COUNTRY.BI]: 'Burundi',
    [COUNTRY.CI]: 'Côte d\'Ivoire',
    [COUNTRY.KH]: 'Cambodia',
    [COUNTRY.CM]: 'Cameroon',
    [COUNTRY.CA]: 'Canada',
    [COUNTRY.CV]: 'Cape Verde',
    [COUNTRY.KY]: 'Cayman Islands',
    [COUNTRY.CF]: 'Central African Republic',
    [COUNTRY.TD]: 'Chad',
    [COUNTRY.CL]: 'Chile',
    [COUNTRY.CN]: 'China',
    [COUNTRY.CX]: 'Christmas Island',
    [COUNTRY.CC]: 'Cocos (Keeling) Islands',
    [COUNTRY.CO]: 'Colombia',
    [COUNTRY.KM]: 'Comoros',
    [COUNTRY.CG]: 'Congo',
    [COUNTRY.CD]: 'Congo (DRC)',
    [COUNTRY.CK]: 'Cook Islands',
    [COUNTRY.CR]: 'Costa Rica',
    [COUNTRY.HR]: 'Croatia',
    [COUNTRY.CU]: 'Cuba',
    [COUNTRY.CY]: 'Cyprus',
    [COUNTRY.CZ]: 'Czech Republic',
    [COUNTRY.DK]: 'Denmark',
    [COUNTRY.DJ]: 'Djibouti',
    [COUNTRY.DM]: 'Dominica',
    [COUNTRY.DO]: 'Dominican Republic',
    [COUNTRY.TP]: 'East Timor',
    [COUNTRY.EC]: 'Ecuador',
    [COUNTRY.EG]: 'Egypt',
    [COUNTRY.SV]: 'El Salvador',
    [COUNTRY.GQ]: 'Equatorial Guinea',
    [COUNTRY.ER]: 'Eritrea',
    [COUNTRY.EE]: 'Estonia',
    [COUNTRY.ET]: 'Ethiopia',
    [COUNTRY.FK]: 'Falkland Islands (Islas Malvinas)',
    [COUNTRY.FO]: 'Faroe Islands',
    [COUNTRY.FJ]: 'Fiji Islands',
    [COUNTRY.FI]: 'Finland',
    [COUNTRY.FR]: 'France',
    [COUNTRY.FX]: 'France, Metropolitan',
    [COUNTRY.GF]: 'French Guiana',
    [COUNTRY.PF]: 'French Polynesia',
    [COUNTRY.TF]: 'French Southern and Antarctic Lands',
    [COUNTRY.GA]: 'Gabon',
    [COUNTRY.GM]: 'Gambia, The',
    [COUNTRY.GE]: 'Georgia',
    [COUNTRY.DE]: 'Germany',
    [COUNTRY.GH]: 'Ghana',
    [COUNTRY.GI]: 'Gibraltar',
    [COUNTRY.GR]: 'Greece',
    [COUNTRY.GL]: 'Greenland',
    [COUNTRY.GD]: 'Grenada',
    [COUNTRY.GP]: 'Guadeloupe',
    [COUNTRY.GU]: 'Guam',
    [COUNTRY.GT]: 'Guatemala',
    [COUNTRY.GN]: 'Guinea',
    [COUNTRY.GW]: 'Guinea-Bissau',
    [COUNTRY.GY]: 'Guyana',
    [COUNTRY.HT]: 'Haiti',
    [COUNTRY.HM]: 'Heard Island and McDonald Islands',
    [COUNTRY.HN]: 'Honduras',
    [COUNTRY.HK]: 'Hong Kong SAR',
    [COUNTRY.HU]: 'Hungary',
    [COUNTRY.IS]: 'Iceland',
    [COUNTRY.IN]: 'India',
    [COUNTRY.ID]: 'Indonesia',
    [COUNTRY.IR]: 'Iran',
    [COUNTRY.IQ]: 'Iraq',
    [COUNTRY.IE]: 'Ireland',
    [COUNTRY.IL]: 'Israel',
    [COUNTRY.IT]: 'Italy',
    [COUNTRY.JM]: 'Jamaica',
    [COUNTRY.JP]: 'Japan',
    [COUNTRY.JO]: 'Jordan',
    [COUNTRY.KZ]: 'Kazakhstan',
    [COUNTRY.KE]: 'Kenya',
    [COUNTRY.KI]: 'Kiribati',
    [COUNTRY.KR]: 'Korea',
    [COUNTRY.KW]: 'Kuwait',
    [COUNTRY.KG]: 'Kyrgyzstan',
    [COUNTRY.LA]: 'Laos',
    [COUNTRY.LV]: 'Latvia',
    [COUNTRY.LB]: 'Lebanon',
    [COUNTRY.LS]: 'Lesotho',
    [COUNTRY.LR]: 'Liberia',
    [COUNTRY.LY]: 'Libya',
    [COUNTRY.LI]: 'Liechtenstein',
    [COUNTRY.LT]: 'Lithuania',
    [COUNTRY.LU]: 'Luxembourg',
    [COUNTRY.MO]: 'Macau SAR',
    [COUNTRY.MK]: 'Macedonia, Former Yugoslav Republic of',
    [COUNTRY.MG]: 'Madagascar',
    [COUNTRY.MW]: 'Malawi',
    [COUNTRY.MY]: 'Malaysia',
    [COUNTRY.MV]: 'Maldives',
    [COUNTRY.ML]: 'Mali',
    [COUNTRY.MT]: 'Malta',
    [COUNTRY.MH]: 'Marshall Islands',
    [COUNTRY.MQ]: 'Martinique',
    [COUNTRY.MR]: 'Mauritania',
    [COUNTRY.MU]: 'Mauritius',
    [COUNTRY.YT]: 'Mayotte',
    [COUNTRY.MX]: 'Mexico',
    [COUNTRY.FM]: 'Micronesia',
    [COUNTRY.MD]: 'Moldova',
    [COUNTRY.MC]: 'Monaco',
    [COUNTRY.MN]: 'Mongolia',
    [COUNTRY.MS]: 'Montserrat',
    [COUNTRY.MA]: 'Morocco',
    [COUNTRY.MZ]: 'Mozambique',
    [COUNTRY.MM]: 'Myanmar',
    [COUNTRY.NA]: 'Namibia',
    [COUNTRY.NR]: 'Nauru',
    [COUNTRY.NP]: 'Nepal',
    [COUNTRY.AN]: 'Netherlands Antilles',
    [COUNTRY.NL]: 'Netherlands, The',
    [COUNTRY.NC]: 'New Caledonia',
    [COUNTRY.NZ]: 'New Zealand',
    [COUNTRY.NI]: 'Nicaragua',
    [COUNTRY.NE]: 'Niger',
    [COUNTRY.NG]: 'Nigeria',
    [COUNTRY.NU]: 'Niue',
    [COUNTRY.NF]: 'Norfolk Island',
    [COUNTRY.KP]: 'North Korea',
    [COUNTRY.MP]: 'Northern Mariana Islands',
    [COUNTRY.NO]: 'Norway',
    [COUNTRY.OM]: 'Oman',
    [COUNTRY.PK]: 'Pakistan',
    [COUNTRY.PW]: 'Palau',
    [COUNTRY.PA]: 'Panama',
    [COUNTRY.PG]: 'Papua New Guinea',
    [COUNTRY.PY]: 'Paraguay',
    [COUNTRY.PE]: 'Peru',
    [COUNTRY.PH]: 'Philippines',
    [COUNTRY.PN]: 'Pitcairn Islands',
    [COUNTRY.PL]: 'Poland',
    [COUNTRY.PT]: 'Portugal',
    [COUNTRY.PR]: 'Puerto Rico',
    [COUNTRY.QA]: 'Qatar',
    [COUNTRY.RE]: 'Reunion',
    [COUNTRY.RO]: 'Romania',
    [COUNTRY.RU]: 'Russia',
    [COUNTRY.RW]: 'Rwanda',
    [COUNTRY.ST]: 'São Tomé and Príncipe',
    [COUNTRY.WS]: 'Samoa',
    [COUNTRY.SM]: 'San Marino',
    [COUNTRY.SA]: 'Saudi Arabia',
    [COUNTRY.SN]: 'Senegal',
    [COUNTRY.SC]: 'Seychelles',
    [COUNTRY.SL]: 'Sierra Leone',
    [COUNTRY.SG]: 'Singapore',
    [COUNTRY.SK]: 'Slovakia',
    [COUNTRY.SI]: 'Slovenia',
    [COUNTRY.SB]: 'Solomon Islands',
    [COUNTRY.SO]: 'Somalia',
    [COUNTRY.ZA]: 'South Africa',
    [COUNTRY.GS]: 'South Georgia and the South Sandwich Islands',
    [COUNTRY.ES]: 'Spain',
    [COUNTRY.LK]: 'Sri Lanka',
    [COUNTRY.SH]: 'St. Helena',
    [COUNTRY.KN]: 'St. Kitts and Nevis',
    [COUNTRY.LC]: 'St. Lucia',
    [COUNTRY.PM]: 'St. Pierre and Miquelon',
    [COUNTRY.VC]: 'St. Vincent and the Grenadines',
    [COUNTRY.SD]: 'Sudan',
    [COUNTRY.SR]: 'Suriname',
    [COUNTRY.SJ]: 'Svalbard and Jan Mayen',
    [COUNTRY.SZ]: 'Swaziland',
    [COUNTRY.SE]: 'Sweden',
    [COUNTRY.CH]: 'Switzerland',
    [COUNTRY.SY]: 'Syria',
    [COUNTRY.TW]: 'Taiwan',
    [COUNTRY.TJ]: 'Tajikistan',
    [COUNTRY.TZ]: 'Tanzania',
    [COUNTRY.TH]: 'Thailand',
    [COUNTRY.TG]: 'Togo',
    [COUNTRY.TK]: 'Tokelau',
    [COUNTRY.TO]: 'Tonga',
    [COUNTRY.TT]: 'Trinidad and Tobago',
    [COUNTRY.TN]: 'Tunisia',
    [COUNTRY.TR]: 'Turkey',
    [COUNTRY.TM]: 'Turkmenistan',
    [COUNTRY.TC]: 'Turks and Caicos Islands',
    [COUNTRY.TV]: 'Tuvalu',
    [COUNTRY.UM]: 'U.S. Minor Outlying Islands',
    [COUNTRY.UG]: 'Uganda',
    [COUNTRY.UA]: 'Ukraine',
    [COUNTRY.AE]: 'United Arab Emirates',
    [COUNTRY.GB]: 'United Kingdom',
    [COUNTRY.US]: 'United States',
    [COUNTRY.UY]: 'Uruguay',
    [COUNTRY.UZ]: 'Uzbekistan',
    [COUNTRY.VU]: 'Vanuatu',
    [COUNTRY.VA]: 'Vatican City',
    [COUNTRY.VE]: 'Venezuela',
    [COUNTRY.VN]: 'Viet Nam',
    [COUNTRY.VI]: 'Virgin Islands',
    [COUNTRY.VG]: 'Virgin Islands, British',
    [COUNTRY.WF]: 'Wallis and Futuna',
    [COUNTRY.YE]: 'Yemen',
    [COUNTRY.YU]: 'Yugoslavia',
    [COUNTRY.ZM]: 'Zambia',
    [COUNTRY.ZW]: 'Zimbabwe',
});

export const CAMPAIGN_TYPE = Object.freeze({
    BIRTHDAY: 1,
    FREE: 2,
    MONTHLY: 3,
    ANNUAL: 4,
    MONTHLY_WITHOUT_COMMISSION: 5,
});

export const CAMPAIGN_TYPE_TEXT = Object.freeze({
    [CAMPAIGN_TYPE.BIRTHDAY]: 'バースデー',
    [CAMPAIGN_TYPE.FREE]: 'フリー',
    [CAMPAIGN_TYPE.MONTHLY]: 'マンスリー',
    [CAMPAIGN_TYPE.ANNUAL]: 'アニュアル',
    [CAMPAIGN_TYPE.MONTHLY_WITHOUT_COMMISSION]: 'マンスリー',
});

export const CAMPAIGN_TEMPLATE_TYPE = Object.freeze({
    BIRTHDAY: 1,
    FREE: 2,
    MONTHLY: 3,
    SUPPORT_FUNDING: 4,
    ANNUAL: 5,
});

export const CAMPAIGN_TEMPLATE_TYPE_TEXT = Object.freeze({
    [CAMPAIGN_TEMPLATE_TYPE.BIRTHDAY]: 'バースデー',
    [CAMPAIGN_TEMPLATE_TYPE.FREE]: 'フリー',
    [CAMPAIGN_TEMPLATE_TYPE.MONTHLY]: 'マンスリー',
    [CAMPAIGN_TEMPLATE_TYPE.SUPPORT_FUNDING]: 'サポートファンディング',
    [CAMPAIGN_TEMPLATE_TYPE.ANNUAL]: 'アニュアル',
});

export const CAMPAIGN_EVENT_TYPE = Object.freeze({
    DEFAULT: 0,
    OSAKA_MARATHON_PARENT_2023: 1,
    OSAKA_MARATHON_RUNNER_2022: 2,
    OSAKA_MARATHON_RUNNER_2023: 3,
    OSAKA_MARATHON_PARENT_2024: 4,
    OSAKA_MARATHON_RUNNER_2024: 5,
    OSAKA_MARATHON_PARENT_2025: 6,
    OSAKA_MARATHON_RUNNER_2025: 7,
});

export const CAMPAIGN_STATUS = Object.freeze({
    BEFORE_START: 1,
    STARTED: 2,
    FINISHED: 3,
    STOP: 4,
    REMOVED: 99,
});

export const CAMPAIGN_STATUS_TEXT = Object.freeze({
    [CAMPAIGN_STATUS.BEFORE_START]: '開催前',
    [CAMPAIGN_STATUS.STARTED]: '開催中',
    [CAMPAIGN_STATUS.FINISHED]: '終了',
    [CAMPAIGN_STATUS.STOP]: '停止中',
    [CAMPAIGN_STATUS.REMOVED]: '削除済み',
});

export const PORTFOLIO_STATUS = Object.freeze({
    DRAFTING: 1,
    STARTED: 2,
    FINISHED: 3,
});

export const PORTFOLIO_STATUS_TEXT = Object.freeze({
    [PORTFOLIO_STATUS.DRAFTING]: '開催前',
    [PORTFOLIO_STATUS.STARTED]: '開催中',
    [PORTFOLIO_STATUS.FINISHED]: '終了',
});

export const PORTFOLIO_TYPE = Object.freeze({
    NORMAL: 1,
    NON_DISTRIBUTABLE: 2,
});

export const PORTFOLIO_TYPE_TEXT = Object.freeze({
    [PORTFOLIO_TYPE.NORMAL]: '通常',
});

export const CAMPAIGN_TARGET_TYPE = Object.freeze({
    MONEY: 1,
    DONORS: 2,
});

export const DONATION_MESSAGE_TYPE = Object.freeze({
    NORMAL: 1,
    FROM_OWNER: 2,
    FROM_ASSOCIATE_MEMBER: 3,
});

export const ASSOCIATE_MONTHLY_FUNDING_STATUS = Object.freeze({
    Unsent: 1,
    NewInvitationSent: 2,
    Hold: 3,
    Scheduled: 4,
    Done: 5,
    Denied: 6,
    Excluded: 7,
});

export const ASSOCIATE_MONTHLY_FUNDING_STATUS_TEXT = Object.freeze({
    [ASSOCIATE_MONTHLY_FUNDING_STATUS.Unsent]: '未送信',
    [ASSOCIATE_MONTHLY_FUNDING_STATUS.NewInvitationSent]: '新規済',
    [ASSOCIATE_MONTHLY_FUNDING_STATUS.Hold]: '保留',
    [ASSOCIATE_MONTHLY_FUNDING_STATUS.Scheduled]: '実施予定',
    [ASSOCIATE_MONTHLY_FUNDING_STATUS.Done]: '実施済',
    [ASSOCIATE_MONTHLY_FUNDING_STATUS.Denied]: '断られた',
    [ASSOCIATE_MONTHLY_FUNDING_STATUS.Excluded]: '除外',
});

export const CRON_MAIL_SCHEDULE_TYPE = Object.freeze({
    New: 1,
    NewReminder_1 : 2,
    NewReminder_2 : 3,
    NewReminder_3 : 4,
    Hold : 10,
    HoldReminder_1 : 11,
    HoldReminder_2 : 12,
    HoldReminder_3 : 13,
});

export const CRON_MAIL_SCHEDULE_TYPE_TEXT = Object.freeze({
    [CRON_MAIL_SCHEDULE_TYPE.New]: '新規',
    [CRON_MAIL_SCHEDULE_TYPE.NewReminder_1]: '新規（リマインド1回目）',
    [CRON_MAIL_SCHEDULE_TYPE.NewReminder_2]: '新規（リマインド2回目）',
    [CRON_MAIL_SCHEDULE_TYPE.NewReminder_3]: '新規（リマインド3回目以降）',
    [CRON_MAIL_SCHEDULE_TYPE.Hold]: '状況確認',
    [CRON_MAIL_SCHEDULE_TYPE.HoldReminder_1]: '状況確認（リマインド1回目）',
    [CRON_MAIL_SCHEDULE_TYPE.HoldReminder_2]: '状況確認（リマインド2回目）',
    [CRON_MAIL_SCHEDULE_TYPE.HoldReminder_3]: '状況確認（リマインド3回目以降）',
});

export const REGISTRATION_TYPE = Object.freeze({
    None: 0,
    UNDER_ASSOCIATE_REGISTRATION: 1, // 団体登録途をするために新規ユーザー登録鵜をして団体登録を終えていない
});

export const VISION_STATUS = Object.freeze({
    Unentered: 1, // 入力未完了
    Entered: 2, // 必須項目全て入力完了
});


// 決済失敗理由
export const REASON_OF_PAYMENT_FAILED = Object.freeze({
    //Omiseのエラー
    INVALID_SECURITY_CODE: 1,  // セキュリティコードが無効
    INSUFFICIENT_FUND: 2,  // 与信限度額を超えている
    STOLEN_OR_LOST_CARD: 3,  // 盗難・紛失されたカード
    FAILED_PROCESSING: 4,  // トランザクション処理のプロセスが失敗
    PAYMENT_REJECTED: 5,  // 何らかの理由により課金が拒否された
    FAILED_FOUND_CHECK: 6,  // カードが不正と判断された
    INVALID_ACCOUNT_NUMBER: 7,  // 利用できないカード番号が使用されている

    // Amazon Payのエラー
    TRANSACTION_TIMED_OUT: 10,  // 処理がタイムアウトしました。
    AMAZON_REJECTED: 11,  // Amazonから支払いが拒否されました。
    PAYMENT_METHOD_NOT_ALLOWED: 12,  // 有効なカードではありません。
    INVALID_PAYMENT_METHOD: 13,  // 与信枠を超過している可能性があります。

    // PAYJPのエラー
    INVALID_NUMBER: 21,
    INVALID_CVC: 22,
    INVALID_EXPIRATION_DATE: 23,
    INVALID_EXPIRY_MONTH: 24,
    INVALID_EXPIRY_YEAR: 25,
    EXPIRED_CARD: 26,
    CARD_DECLINED: 27,
    PROCESSING_ERROR: 28,
    MISSING_CARD: 29,
    UNACCEPTABLE_BRAND: 30,

    // Syncable独自のエラーかつDBには入れない
    EXPIRED: 80,  // 有効期限切れ

    OTHER: 99,  // その他
    NO_PROBLEM: 100, // 問題なし
});

// 決済失敗理由 Text
export const REASON_OF_PAYMENT_FAILED_TEXT = Object.freeze({
    //Omiseのエラー
    [REASON_OF_PAYMENT_FAILED.INVALID_SECURITY_CODE]: '無効なセキュリティコード',
    [REASON_OF_PAYMENT_FAILED.INSUFFICIENT_FUND]: '与信枠超過',
    [REASON_OF_PAYMENT_FAILED.STOLEN_OR_LOST_CARD]: '盗難・紛失',
    [REASON_OF_PAYMENT_FAILED.FAILED_PROCESSING]: 'トランザクション失敗',
    [REASON_OF_PAYMENT_FAILED.PAYMENT_REJECTED]: '不明な理由',
    [REASON_OF_PAYMENT_FAILED.FAILED_FOUND_CHECK]: '不正なカード',
    [REASON_OF_PAYMENT_FAILED.INVALID_ACCOUNT_NUMBER]: '利用できないカード番号',

    // Amazon Payのエラー
    [REASON_OF_PAYMENT_FAILED.TRANSACTION_TIMED_OUT]: '処理がタイムアウト',
    [REASON_OF_PAYMENT_FAILED.AMAZON_REJECTED]: 'Amazonから拒否された支払い',
    [REASON_OF_PAYMENT_FAILED.PAYMENT_METHOD_NOT_ALLOWED]: '無効なカード',
    [REASON_OF_PAYMENT_FAILED.INVALID_PAYMENT_METHOD]: '与信枠超過',

    // PAYJPのエラー
    [REASON_OF_PAYMENT_FAILED.INVALID_NUMBER]: '',
    [REASON_OF_PAYMENT_FAILED.INVALID_CVC]: '',
    [REASON_OF_PAYMENT_FAILED.INVALID_EXPIRATION_DATE]: '',
    [REASON_OF_PAYMENT_FAILED.INVALID_EXPIRY_MONTH]: '',
    [REASON_OF_PAYMENT_FAILED.INVALID_EXPIRY_YEAR]: '',
    [REASON_OF_PAYMENT_FAILED.EXPIRED_CARD]: '',
    [REASON_OF_PAYMENT_FAILED.CARD_DECLINED]: '',
    [REASON_OF_PAYMENT_FAILED.PROCESSING_ERROR]: '',
    [REASON_OF_PAYMENT_FAILED.MISSING_CARD]: '',
    [REASON_OF_PAYMENT_FAILED.UNACCEPTABLE_BRAND]: '',

    // Syncable独自のエラーかつDBには入れない
    [REASON_OF_PAYMENT_FAILED.EXPIRED]: '有効期限切れ',

    [REASON_OF_PAYMENT_FAILED.OTHER]: 'その他',
    [REASON_OF_PAYMENT_FAILED.NO_PROBLEM]: '',
});

export const SURVEY_QUESTION_STATUS = Object.freeze({
    OPEN: 1,
    PAUSED: 2,
    DISABLED: 3,
});
