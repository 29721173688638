import User from '../orm/models/User';
import UserPrivateProfile from '../orm/models/UserPrivateProfile';

export const state = () => ({
    valid: false,
    input: new UserPrivateProfile(),
});

export const getters = {
    /**
     * 編集対象を取得できます。
     * @returns {UserPrivateProfile}
     */
    target: () => store => {
        const me = store.$db().model(User).getters('me')(store);
        return me ? me.private_profile : new store.$db().model(UserPrivateProfile)();
    },
};

export const mutations = {
    setValid(state, val) {
        state.valid = val;
    },
    setInput(state, val = {}) {
        state.input = {...state.input, ...val};
    },
};

export const actions = {
    init({commit, getters}) {
        commit('setInput', getters.target(this));
    },
    reset({commit}) {
        commit('setInput', state().input);
    },
    updateValid({commit}, val) {
        commit('setValid', val);
    },
    updateInput({commit}, val) {
        commit('setInput', val);
    },
    /**
     *
     * @param state
     * @param getters
     * @returns {Promise<void>}
     */
    async update({state, getters}) {
        const deepCopyAndRemoveForeignKeys = v => {
            const deepV = JSON.parse(JSON.stringify(v));
            if (deepV.hasOwnProperty('id')) delete deepV['id'];
            if (deepV.hasOwnProperty('$id')) delete deepV['$id'];
            if (deepV.hasOwnProperty('user_id')) delete deepV['user_id'];

            return deepV;
        };

        await UserPrivateProfile.update({
            where: getters.target(this)['id'],
            data: deepCopyAndRemoveForeignKeys(state.input),
        });
        const updatedProfile = getters.target(this); // 要再取得
        await updatedProfile.updateDonorInfo(this);
    },
};
