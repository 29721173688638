<template lang="pug">
    .base-radio-group
        base-input-label.base-radio-group__label(
            v-if="!!label"
            :disabled="disabled"
            :required="required"
        ) {{label}}
        v-radio-group.base-radio-group__group(
            v-model="localValue"
            mandatory
            :required="required"
            :rules="computedRules"
            :disabled="disabled"
            :column="column"
        )
            slot(name="radio-items")
                .base-radio-group__item(
                    v-for="(item,i) in computedItems"
                    :key="i"
                )
                    base-radio.base-radio-group__item-radio(
                        :value="item.value"
                        :label="item.label"
                        :disabled="disabled"
                    )
</template>

<script>
import BaseText from './BaseText';
import BaseInputLabel from './BaseInputLabel';
import {GENDER, GENDER_TEXT} from '../../syncable-commons-consts/enums';
import FormValidator from '../../syncable-commons-utils/utils/FormValidator';
import BaseRadio from './BaseRadio';

const RadioTypes = {
    NORMAL: 'normal',
    GENDER: 'gender',
    NECESSARY: 'necessary',
    NOTIFICATION_PREFENRENCE: 'notification-preference',
};
const RadioParams = {
    itemCreator: {
        [RadioTypes.NORMAL]: () => [],
        [RadioTypes.GENDER]: () => Object.values(GENDER).map(v => ({label: GENDER_TEXT[v], value: v})),
        [RadioTypes.NECESSARY]: (context) => {
            return [
                {
                    value: true,
                    label: context.$t('label.necessary'),
                },
                {
                    value: false,
                    label: context.$t('label.unnecessary'),
                },
            ];
        },
        [RadioTypes.NOTIFICATION_PREFENRENCE]: () => {
            return [
                {
                    value: 1,
                    label: 'On',
                },
                {
                    value: 0,
                    label: 'Off',
                },
            ];
        },
    },
};
export default {
    name: 'BaseRadioGroup',
    components: {BaseRadio, BaseInputLabel, BaseText},
    props: {
        value: {
            type: undefined,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        required: Boolean,
        disabled: Boolean,
        items: {
            type: Array,
            default: () => [],
        },
        rules: {
            type: Array,
            default: () => [],
        },
        radioType: {
            type: String,
            default: RadioTypes.NORMAL,
        },
        column: {
            type: Boolean,
            required: false,
            default: () => false,
        },
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        computedItems() {
            if (this.items && this.items.length) {
                return this.items;
            }
            return RadioParams.itemCreator[this.radioType](this);
        },
        computedRules() {
            let rules = this.rules || [];
            if (this.required) {
                rules.push(v => FormValidator.requiredRadio(v, this.label));
            }
            return rules;
        },
    },
};
</script>

<style scoped lang="scss">
    .base-radio-group__label {
        padding-bottom: 16px;
    }

    .base-radio-group__group.input-group.radio-group {
        padding: 0;

        & /deep/ {
            > .input-group {
                &__input {
                    min-height: 20px;
                    @include mq(sm-and-up) {
                        // v-radio-groupにrowのoptionがあるが、mqでやりたいのでこのようにrowにしています。
                        flex-direction: row;
                        display: flex;
                        flex-wrap: wrap;

                        // flex-wrapで改行した時にmarginがつくhack
                        margin-top: -12px;
                        .base-radio-group__item {
                            margin-top: 12px;
                        }
                    }
                }

                &__details {
                    font-size: 12px;
                    line-height: 12px;
                    padding-top: 8px;
                    padding-bottom: 4px;
                    min-height: 24px;
                }
            }
        }

        &.radio-group--column /deep/ {
            > .input-group {
                &__input {
                    // 下記の注釈の部分の意図が不明で手が出せなかったため、プロパティにてcolumnを受け取った場合のみ上書いて打ち消す
                    // "v-radio-groupにrowのoptionがあるが、mqでやりたいのでこのようにrowにしています。"
                    flex-direction: column !important;
                }
            }
        }

        .base-radio-group__item {
            @include mq(sm-and-down) {
                &:not(:last-of-type) {
                    margin-bottom: 12px;
                }
            }

            @include mq(sm-and-up) {
                margin-bottom: 0;
                margin-right: 16px;
            }
        }

        &.input-group {
            &--error {
                & /deep/ {
                    i,
                    .input-group--selection-controls__ripple {
                        color: $c-selection-control-error;
                    }
                }
            }

            &--required {
                & /deep/ {
                    label:after {
                        content: ""
                    }
                }
            }
        }
    }
</style>
