import {Model} from '@vuex-orm/core';

export default class PaymentCreditCard extends Model {
    static fields() {
        return {
            id: this.number(null),
            user: this.number(null),
            customer_code: this.string(''),
            card_id: this.string(''),
            name: this.string(''),
            postal_code: this.string(''),
            city: this.string(''),
            brand: this.string(''),
            expiration_month: this.number(0),
            expiration_year: this.number(0),
            last_digits: this.string(''),
            created_at: this.string(''),
            updated_at: this.string(''),

            user_id: this.number(null),
        };
    }

    static newInstance() {
        return new PaymentCreditCard();
    }

    /**
     * 日本語文字です。
     */
    static get keyText() {
        return {
            name: '名義人',
            brand: 'ブランド',
            expiration_month: '有効期限(月)',
            expiration_year: '有効期限(年)',
            last_digits: '下4桁',
        };
    }

    /**
     * データがあるかどうか
     * @returns {boolean}
     */
    isValid() {
        return !!this.card_id;
    }

    /**
     * 表示用のデータを返します。
     */
    getViewData() {
        return {
            number: this.isValid() ? `************${this.last_digits}` : '',
            expiration_month: this.expiration_month,
            expiration_year: this.expiration_year,
            name: this.name,
            security_code: this.isValid() ? '***' : '',
        };
    }
}

PaymentCreditCard.entity = 'paymentCreditCards';
