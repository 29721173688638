// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".layout-user-menu.dialog--fullscreen{top:60px;height:calc(100% - 60px);border-radius:0;box-shadow:none;overflow:visible}@media only screen and (min-width:960px){.layout-user-menu.dialog--fullscreen{left:auto;right:5px;width:245px;height:auto}}.layout-user-menu-wrapper{border-top:1px solid #d0d6d5;background:#fff;height:100%}@media only screen and (min-width:960px){.layout-user-menu-wrapper{height:auto;border:1px solid #d0d6d5;border-radius:2px}}.layout-user-menu-content{position:relative}.layout-user-menu-content:after,.layout-user-menu-content:before{content:\"\";position:absolute;display:inline-block;border:12px solid transparent;right:68px;top:-25px;height:0;width:0}@media only screen and (min-width:960px){.layout-user-menu-content:after,.layout-user-menu-content:before{right:15px}}.layout-user-menu-content:before{border-bottom-color:#d0d6d5}.layout-user-menu-content:after{border-bottom-color:#fff;margin:1px 0 0}.layout-user-menu-icon-icon{position:relative}.layout-user-menu-icon-icon__image{width:32px;height:32px;-o-object-fit:cover;object-fit:cover;border-radius:50%}.layout-user-menu-icon-icon__badge{position:absolute;top:0;right:0;content:\"\";width:12px;height:12px;border-radius:10px;background-color:#f05898}", ""]);
// Exports
exports.locals = {
	"cGreenAlpha10": "#e6faf5",
	"cGreenBase": "#00cc9f",
	"cProgressbar2": "#00a681",
	"cProgressbar3": "#008265",
	"cSc_international": "#51b3b1",
	"cSc_environment": "#51b370",
	"cSc_animal": "#7051b3",
	"cSc_child": "#fff",
	"cSc_child-education": "#c96f14",
	"cSc_woman": "#ff99af",
	"cSc_human-rights": "#ffb933",
	"cSc_disabilities": "#ffe133",
	"cSc_employment": "#5b81c9",
	"cSc_disaster": "#f0ede1",
	"cSc_local-community": "#c9a25b",
	"cSc_local-industry": "#9cc93c",
	"cSc_social-education": "#81c95b",
	"cSc_health": "#a35bc9",
	"cSc_culture": "#c93c3f",
	"cSc_economic-development": "#c9c600",
	"cSc_non-profits-development": "#c95a89",
	"cSc_others": "#9b9b9b",
	"cLegendOthers": "#e1e1e1"
};
module.exports = exports;
