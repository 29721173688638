import {Model} from '@vuex-orm/core';
import CampaignUsage from './CampaignUsage';

export default class CampaignUsageImage extends Model {
    static fields() {
        return {
            id: this.attr(null),
            file: this.attr(''),

            usage: this.belongsTo(CampaignUsage, 'usage_id'),
            usage_id: this.attr(null),
        };
    }

    get doesImageExists() {
        return this.file !== undefined
            && this.file !== null
            && this.file !== '';
    }

    toPostableJson() {
        return Object.assign({
            id: this.id,
            file: this.file,

            usage_id: this['usage_id'],
        });
    }
}

CampaignUsageImage.entity = 'campaignUsageImages';
