<template lang="pug">
    v-form(v-model="valid" @submit.native.prevent="")
        .non-facebook-input(v-if="!facebook")
            BaseInputSet(
                v-model="email"
                label="メールアドレス"
                input-type="email"
                required
                placeholder="mail@syncable.com"
                v-if="!facebook"
            )
            BaseInputSet(
                v-if="!facebook"
                v-model="password"
                label="パスワード"
                input-type="new-password"
                required
                placeholder="6文字以上の半角英数字"
            )
        .facebook-info-showcase(v-else)
            .pb-20
                BaseInputLabel メールアドレス
                BaseText.ml-15 {{email}}
        //- Facebookから誕生日が取れないことがあるので
        .birthday
            div(v-if="showBirthdayInput")
                BaseInputSetDate(
                    v-model="birthday"
                    label="生年月日"
                    required
                    placeholder="1993年5月22日"
                )
            .pb-20(v-else)
                BaseInputLabel 生年月日
                BaseText.ml-15 {{formattedBirthday}}
        .always-show-input
            BaseRadioGroup(
                v-model="gender"
                label="性別"
                required
                radio-type="gender"
            )

        BaseCheckBoxSetTermsAndPrivacy(
            v-model="checks"
        )

        .mb-20
            BaseButton(
                block
                large
                @click.native="submit"
                :disabled="!isButtonActive"
                :loading="loading"
            )  登録
</template>

<script>
import BaseButton from '../../../syncable-commons-components/atoms/BaseButton';
import BaseIcon from '../../../syncable-commons-components/atoms/BaseIcon';
import BaseText from '../../../syncable-commons-components/atoms/BaseText';
import BaseInputSet from '../../../syncable-commons-components/molecules/BaseInputSet';
import BaseInputSetDate from '../../../syncable-commons-components/molecules/BaseInputSetDate';
import BaseRadioGroup from '../../../syncable-commons-components/atoms/BaseRadioGroup';
import { mapStatesTwoWay } from '../../../syncable-commons-utils/utils/VuexHelper';
import { mapActions } from 'vuex';
import BaseCheckBoxSetTermsAndPrivacy
    from '../../../syncable-commons-components/molecules/BaseCheckBoxSetTermsAndPrivacy';
import BaseInputLabel from '../../../syncable-commons-components/atoms/BaseInputLabel';
import { GENDER_TEXT } from '../../../syncable-commons-consts/enums';
import DateHelper from '../../../syncable-commons-utils/utils/DateHelper';

export default {
    name: 'UserRegisterForm',
    components: {
        BaseInputLabel,
        BaseCheckBoxSetTermsAndPrivacy,
        BaseRadioGroup,
        BaseInputSetDate,
        BaseInputSet,
        BaseText,
        BaseIcon,
        BaseButton,
    },
    props: {
        facebook: Boolean,
    },
    data() {
        return {
            valid: false,
            loading: false,
            checks: false,
            showBirthdayInput: false,
        };
    },
    computed: {
        isButtonActive() {
            return this.valid && this.checks;
        },
        ...mapStatesTwoWay('auth', {
            email: state => state.input.email,
            password: state => state.input.password,
        }, function (stateUpdate) {
            this.updateInput(stateUpdate);
        }),
        ...mapStatesTwoWay('auth', {
            gender: state => state.registerInput.private_profile.gender,
            birthday: state => state.registerInput.private_profile.birthday,
        }, function (stateUpdate) {
            this.updateRegisterInputPrivateProfile(stateUpdate);
        }),
        genderText() {
            return GENDER_TEXT[this.gender];
        },
        formattedBirthday() {
            return !DateHelper.isDate(this.birthday) ? '' : DateHelper.format(this.birthday, 'YYYY年M月D日');
        },
    },
    mounted() {
        if (!this.birthday) {
            this.showBirthdayInput = true;
        }
    },
    methods: {
        ...mapActions('auth', {
            updateInput: 'updateInput',
            updateRegisterInputPrivateProfile: 'updateRegisterInputPrivateProfile',
            registerAndLogin: 'registerAndLogin',
            registerAndLoginWithFB: 'registerAndLoginWithFB',
        }),
        async submit() {
            this.loading = true;
            try {
                if (this.facebook) {
                    await this.registerAndLoginWithFB();
                } else {
                    await this.registerAndLogin();
                }
                this.loading = false;
                this.$emit('success');
            } catch (e) {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>

</style>