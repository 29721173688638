<template lang="pug">
    .base-image-wrapper
        .base-image(
        :style="style"
        )
            img.base-image-img(
            ref="image"
            :src="src"
            :alt="alt"
            :class="classes"
            )
</template>

<script>
export default {
    name: 'BaseImage',
    props: {
        src: {
            type: String,
            default: '',
        },
        alt: String,
        height: String,
        width: String,
        vertical: Number,
        horizontal: Number,
        maxWidth: String,
        circle: Boolean,
        contain: Boolean,
        transparent: Boolean,
        center: Boolean,
        isLink: Boolean,
    },
    computed: {
        isFlexible() {
            return this.vertical && this.horizontal;
        },
        classes() {
            return {
                'is--circle': this.circle,
                'is--contain': this.contain,
                'is--link': this.isLink,
                'is--transparent': this.transparent,
            };
        },
        style() {
            let style = {
                maxWidth: this.maxWidth || this.width,
            };
            if (this.width && this.height) {
                style = {
                    ...style,
                    height: this.height,
                    width: this.width,
                };
            }
            if (this.vertical && this.horizontal) {
                style = {
                    ...style,
                    paddingTop: `${100 * this.vertical / this.horizontal}%`,
                };
            }
            return style;
        },
    },
};
</script>

<style scoped lang="scss">
    .base-image {
        &-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        position: relative;
        width: 100%;

        &-img {
            background-color: $c-bg;
            object-fit: cover;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;

            &.is--circle {
                border-radius: 50%;
                border: $border-default;
                box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05);
            }

            &.is--contain {
                object-fit: contain;
            }

            &.is--transparent {
                background-color: transparent;
            }

            &.is--link {
                cursor: pointer;
            }
        }
    }
</style>