import {Model} from '@vuex-orm/core';
import Campaign from './Campaign';
import Donation from './Donation';
import User from './User';
import Portfolio from './Portfolio';
import PortfolioDonation from './PortfolioDonation';
import Donor from './Donor';
import { DONATION_MESSAGE_TYPE } from '../../../syncable-commons-consts/enums.js';
import _ from 'lodash';

export default class DonationMessage extends Model {
    static fields() {
        return {
            id: this.number(null),
            message: this.string(''),
            color: this.string(''),
            is_name_public: this.boolean(false),
            type: this.number(null),

            published_from: this.attr(null),
            created_at: this.string(''),
            updated_at: this.string(''),

            campaign_id: this.number(null),
            campaign: this.belongsTo(Campaign, 'campaign_id'),
            portfolio_id: this.number(null),
            portfolio: this.belongsTo(Portfolio, 'portfolio_id'),
            donation_id: this.number(null),
            donation: this.belongsTo(Donation, 'donation_id'),
            portfolio_donation_id: this.number(null),
            portfolio_donation: this.belongsTo(PortfolioDonation, 'portfolio_donation_id'),
            user_id: this.number(null),
            user: this.belongsTo(User, 'user_id'),
        };
    }

    get donor() {
        if (this.portfolio_donation) {
            return this.portfolio_donation.donor;
        }
        if (this.donation) {
            return this.donation.donor;
        }
        return new Donor();
    }

    get donorName() {
        return (() => {
            if (!this.is_name_public) {
                return '';
            }
            if (this.user && this.user.profile) {
                return this.user.profile.nickname;
            }
            if (this.donation) {
                return _.get(this.donation, 'donor.fullName');
            }
            if (this.portfolio_donation) {
                return _.get(this.portfolio_donation, 'donor.fullName');
            }

            return '';
        })() || '非公開';
    }

    /**
     * メッセージがキャンペーンの作成者からのものか否か
     */
    get isFromOwner() {
        return this.type === DONATION_MESSAGE_TYPE.FROM_OWNER;
    }

    /**
     * メッセージが団体メンバーからのものか否か
     */
    get isFromAssociateMember() {
        return this.type === DONATION_MESSAGE_TYPE.FROM_ASSOCIATE_MEMBER;
    }

    get displayName() {
        if (this.isFromOwner && this.campaign) {
            return this.campaign.creatorName;
        }
        return this.donorName;
    }

    get avatar() {
        if (!this.is_name_public) {
            return '';
        }
        if (this.isFromOwner && this.campaign) {
            return this.campaign.logoImage;
        }
        const filePath = _.get(this, 'user.avatar_image.file', '');
        return filePath || '';
    }
}

DonationMessage.entity = 'donationMessage';
